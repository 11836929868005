/* eslint-disable import/prefer-default-export */
import { getPosClient,posClient } from 'request'
import { POS_API_ENDPOINTS } from 'Utils/constants'

export const getPosConfiguration = async (organizationId, storeId) => {

  const payload = {
    organizationId,
    storeId
  }
  
  const response = await posClient(POS_API_ENDPOINTS.GET_POS_CONFIGURATION, payload, 'POST')

  const { data } = await response.json()

  return data
}

export const createStorePOSConfiguration = (payload) => {
    
  return posClient(POS_API_ENDPOINTS.CREATE_POS_CONFIGURATION, payload, 'POST')
}

export const getPosOrganization = () => {
    
  return getPosClient(POS_API_ENDPOINTS.GET_ORGANIZATIONS, 'GET')
}

