import { CheckCircleOutlined } from '@ant-design/icons'
import {
  Button,
  Modal,
  Radio,
  Typography
} from 'antd'
import { Card, IntlMessages, Title } from 'components'
import { orderxClient as client, orderxClient } from 'index'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { setErrMsg } from 'Utils'
import { COLORS, ROLE_TYPE, STATUS } from 'Utils/constants'
import { setIsUnsavedStoreDetails } from 'Utils/localStorageHandlers/setter'

import { DeliveryFleetRadioButtonStyle } from '../styles'
import DeliveryChargeAndArea from './DeliveryChargeAndArea'
import ManageDeliveryAgent from './ManageDeliveryAgent'
import PartnerDeliveryService from './PartnerDeliveryService'
const { Text } = Typography

export default function ConfigureDeliveryFleet({ useStoreData, storeId }) {
  const [getStoreData, setStoreData] = useStoreData()
  const [deliveryAgents, setDeliveryAgents] = useState([])
  const [internalFleetStatus, setInternalFleetStatus] = useState<string>('')
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const intl = useIntl()
  const isInternalFleet = internalFleetStatus === STATUS.ACTIVE
  const isEdit = Boolean(storeId)
  const toggleFleetOption = e => {
    const { geoLocation } = getStoreData()?.store || {}

    if (e.target.value === STATUS.ACTIVE && !geoLocation) {
      setErrMsg('store.setYourLocationFirst', intl)

      return
    } else if (e.target.value === STATUS.INACTIVE) {
      if ((deliveryAgents && deliveryAgents.length > 0) || isEdit) {
        toggleModal()

        return
      }
    }
    setInternalFleetStatus(e.target.value)
    setIsUnsavedStoreDetails(true)
  }

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  function deleteBulkActiveAgent() {
    toggleModal()
    setInternalFleetStatus(STATUS.INACTIVE)
  }

  useEffect(() => {
    if (getStoreData()?.store) {
      const { staff } = getStoreData().store
      const deliveryAgents = staff.filter(staff => staff.staffRole === ROLE_TYPE.DELIVERY && staff.enabled)

      setDeliveryAgents(deliveryAgents)
      const internalFleetStatus = deliveryAgents.filter(agent => agent.status === STATUS.ACTIVE).length > 0 ? STATUS.ACTIVE : STATUS.INACTIVE

      setInternalFleetStatus(internalFleetStatus)
    }
  }, [])

  useEffect(() => {
    setStoreData({ deliveryAgents })
  }, [deliveryAgents])

  useEffect(() => {
    setStoreData({ internalFleetStatus })
  }, [internalFleetStatus])

  return (
    <>
      <Card style={{ margin: '12px 0' }}>
        <Title level='h4'>
          <IntlMessages id="store.configureDeliveryFleet" />
        </Title>
        <DeliveryFleetRadioButtonStyle>
          <Title level="h5" style={{ paddingRight: '5%' }}>
            <IntlMessages id="store.doYouHaveYourOwnDeliveryFleet?" />
          </Title>
          <div>
            <Radio.Group
              buttonStyle="solid"
              value={internalFleetStatus}
              onChange={toggleFleetOption}>
              <Radio.Button
                value={STATUS.ACTIVE}
                style={{ border: `1px solid ${COLORS.BORDER}`, backgroundColor: internalFleetStatus === STATUS.ACTIVE ? COLORS.PRIMARY : COLORS.BG_WHITE }}>
                <IntlMessages id="button.yes" />
              </Radio.Button>
              <Radio.Button
                value={STATUS.INACTIVE}
                style={{ border: `1px solid ${COLORS.BORDER}`, backgroundColor: internalFleetStatus === STATUS.INACTIVE ? COLORS.PRIMARY : COLORS.BG_WHITE }}>
                <IntlMessages id="button.no" />
              </Radio.Button>
            </Radio.Group>
          </div>
          {internalFleetStatus === STATUS.INACTIVE && (
            <span className="fleet-msg-wrapper">
              <CheckCircleOutlined className="fleet-msg-icon" />
              <Text className="fleet-msg-text">
                <IntlMessages id="store.peppoDeliveryMsg" />
              </Text>
            </span>
          )}
        </DeliveryFleetRadioButtonStyle>
        {isInternalFleet && <ManageDeliveryAgent agents={deliveryAgents} setAgents={setDeliveryAgents} client={client} />}
      </Card>
      {isInternalFleet && <DeliveryChargeAndArea getStoreData={getStoreData} setData={setStoreData} intl={intl} storeId={storeId} />}
      {internalFleetStatus === STATUS.INACTIVE && <PartnerDeliveryService useStoreData={useStoreData} storeId={storeId} />}
      <Modal
        visible={showModal}
        title={
          <IntlMessages id="store.confirmationMsg.disableDeliveryFleet" />
        }
        onCancel={toggleModal}
        footer={[
          <Button
            key="rejectDelete"
            onClick={toggleModal}>
            <IntlMessages id="button.no" />
          </Button>,
          <Button
            type="primary"
            danger
            loading={isLoading}
            key="confirmDelete"
            onClick={deleteBulkActiveAgent}>
            <IntlMessages id="button.yes" />
          </Button>
        ]}></Modal>
    </>
  )
}