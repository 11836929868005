import { Col, Divider, message, Progress, Row, Upload } from 'antd'
import { ClientManager } from 'appApollo/client'
import { Button, Card, IntlMessages, Text, Title } from 'components'
import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import Lottie from 'react-lottie'
import { fetchMenu, uploadMenuFile } from 'services'
import styled from 'styled-components'
import {
  COLORS,
  LISTENER_TYPE,
  SAMPLE_EXCEL_SHEET,
  SUPPORTED_FILE_TYPES
} from 'Utils/constants'
import {
  defaultOptions,
  getUploadApiConfig,
  isSupportedFileType
} from 'Utils/onboardingUtils'

import FbNotificationManager from '../../../firebaseInit'
import { ErrorMsg } from './ErrorMsg'
import MenuLinkForm from './MenuForm'

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const Status = styled.div`
  color: ${props => (props.pending ? COLORS.ERROR : COLORS.PRIMARY)};
  font-size: 13px;
  line-height: 31px;
  display: flex;
  justify-content: end;
`
const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const minutes = 3
const sec = minutes * 60
const incrementPercent = 100 / sec

const MenuLinkCard = ({ brandLogo, intl, getCatalogs, isCatalogCreated }) => {
  const pmsClient = ClientManager.getInstance().pms
  const notificationManager = FbNotificationManager.getInstance()
  const [processingError, setProcessingError] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [fileTypeError, setFileTypeError] = useState(false)
  const [uploadingFile, setUploadingFile] = useState(false)
  const [percent, setPercent] = useState(0)
  const [fetchPercent, setFetchPercent] = useState(0)
  const [validationError, setValidationError] = useState('')
  const [scrapingError, setScrapingError] = useState('')
  const [uploadTriggered, setUploadTriggered] = useState(
    notificationManager.isMenuUploading
  )
  const [fetchingMenu, setFetchingMenu] = useState(false)
  const [uploadingMenu, setUploadingMenu] = useState(false)
  let firebaseToken

  notificationManager
    .getFirebaseNotificationToken()
    .then(firebaseTokenRes => {
      firebaseToken = firebaseTokenRes

      console.log('firebaseToken', firebaseToken)
    })
    .catch(err => {
      console.log(err.message)

      return err
    })

  useEffect(() => {
    notificationManager.addListener(
      LISTENER_TYPE.MENU_UPLOAD_UPDATE,
      message => {
        setFetchPercent(100)
        setPercent(100)
        setUploadingFile(false)
        setUploadTriggered(false)
        setProcessing(false)

        setFetchingMenu(false)
        setUploadingMenu(false)
        onMenuSave()
        notificationManager.updateProp('isMenuUploading', false)
        localStorage.removeItem('isMenuUploading')
      }
    )
    if (uploadTriggered) setFetchingMenu(uploadTriggered)
  }, [])

  const onMenuSave = () => {
    getCatalogs(false)
  }

  const onLinkSubmit = async values => {
    const { organizationId } = getUploadApiConfig()
    const { url } = values

    setProcessing(true)
    setValidationError('')
    setScrapingError('')
    setFetchPercent(0)
    startFetchProgress()

    setFetchingMenu(true)
    try {
      const fetchMenuInput = {
        id: organizationId,
        menuLink: url,
        firebaseToken
      }
      const fetchMenuRes = await fetchMenu(pmsClient, fetchMenuInput)

      if (fetchMenuRes) {
        if (fetchMenuRes?.data?.fetchAndUploadMenu?.success === 'true') {
          notificationManager.updateProp('isMenuUploading', true)
          setUploadTriggered(true)
          onMenuSave()
        } else {
          const scrapingError = fetchMenuRes.data.fetchAndUploadMenu.message

          notificationManager.updateProp('isMenuUploading', false)
          setFetchingMenu(false)
          setUploadTriggered(false)
          setScrapingError(scrapingError)
          setProcessing(false)
        }
      }
    } catch (err) {
      setFetchingMenu(false)
      notificationManager.updateProp('isMenuUploading', false)
      setProcessing(false)
      console.log('Fetch menu error', err)
    }
  }

  const onFetchCall = async options => {
    const { file, onSuccess, onError } = options

    if (isSupportedFileType(file)) {
      setFileTypeError(true)

      return false
    }
    setFileTypeError(false)
    setValidationError('')
    setScrapingError('')
    setPercent(0)
    setUploadingFile(true)
    startProgress()
    setUploadingMenu(true)
    uploadMenuFile(options, firebaseToken)
      .then(response => response.text())
      .then(result => {
        const value = JSON.parse(result)

        if (value.data.uploadMenuFile.success === 'true') {
          notificationManager.updateProp('isMenuUploading', true)
          setUploadTriggered(true)
          message.info(value.data.uploadMenuFile.message)
          onMenuSave()
        } else {
          const validationError = value.data.uploadMenuFile.message

          setUploadingMenu(false)
          notificationManager.updateProp('isMenuUploading', false)
          setUploadTriggered(false)
          setUploadingFile(false)
          setValidationError(validationError)
        }
        onSuccess('ok')
      })
      .catch(error => {
        console.log('error', error)
        setUploadingMenu(false)
        notificationManager.updateProp('isMenuUploading', false)
        setUploadingFile(false)
        onError('ok')
      })
  }

  const startProgress = () => {
    if (percent <= 100) {
      const progressCounter = setInterval(() => {
        setPercent(prev => {
          if (prev + incrementPercent >= 100) {
            clearInterval(progressCounter)
          }

          return prev + incrementPercent
        })
      }, 1000)
    }
  }

  const startFetchProgress = () => {
    if (fetchPercent <= 100) {
      const fetchProgressCounter = setInterval(() => {
        setFetchPercent(prev => {
          if (prev + incrementPercent >= 100) {
            clearInterval(fetchProgressCounter)
          }

          return prev + incrementPercent
        })
      }, 1000)
    }
  }

  const isCardDisable =
    !brandLogo ||
    fetchingMenu ||
    uploadingMenu ||
    (isCatalogCreated && !fetchingMenu)
  const isPending =
    !isCatalogCreated || !brandLogo || uploadingMenu || fetchingMenu

  return (
    <Row>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 18, offset: 3 }}
        lg={{ span: 12, offset: 6 }}>
        <div className="mt-1">
          <Card>
            <StatusContainer>
              <Title level={'h3'}>
                <IntlMessages id={'onboarding.menuLinkCard.title'} />
                <span style={{ marginLeft: '3px' }}>*</span>
              </Title>
              <CardStatus isPending={isPending} />
            </StatusContainer>
            <Text level="body-2">
              <IntlMessages id="onboarding.menuLinkCard.text.pleaseAddLink" />
            </Text>
            <MenuLinkForm
              intl={intl}
              processingError={processingError}
              onLinkChange={() => {
                setProcessingError(false)
                setScrapingError('')
              }}
              onSubmit={values => onLinkSubmit(values)}
              processing={processing}
              disable={isCardDisable || uploadTriggered}
            />
            <ProgressBar loading={processing} percent={fetchPercent} />
            {scrapingError && scrapingError.length && (
              <Text level="caption" style={{ color: COLORS.ERROR }}>
                {scrapingError}
              </Text>
            )}
            <Divider>
              {' '}
              <Text level="overline">
                <IntlMessages id="onboarding.menuLinkCard.text.or" />
              </Text>
            </Divider>
            <Title level="h5" style={{ color: COLORS.DARK_TEXT }}>
              <IntlMessages id="onboarding.menuLinkCard.secondary.title" />
            </Title>
            <StyledRow>
              <div style={{ width: '50%' }}>
                <Text level="body-2">
                  <IntlMessages id="onboarding.menuLinkCard.text.fillYourMenu" />
                </Text>
                <a
                  href={SAMPLE_EXCEL_SHEET}
                  target="_blank"
                  rel="noopener noreferrer">
                  <Button type="link" style={{ marginBottom: 0 }}>
                    {' '}
                    <IntlMessages
                      id={'onboarding.menuLinkCard.link.download'}
                    />{' '}
                  </Button>
                </a>
              </div>
              <div>
                <Upload
                  customRequest={onFetchCall}
                  name="file"
                  fileList={[]}
                  accept=".xls, .xlsx, .csv">
                  <Button
                    type="secondary"
                    style={{ marginBottom: 0 }}
                    loading={uploadingFile}
                    disabled={isCardDisable || uploadTriggered}>
                    {' '}
                    <IntlMessages id={'button.uploadMenu'} />{' '}
                  </Button>
                </Upload>
              </div>
            </StyledRow>
            <ProgressBar loading={uploadingFile} percent />
            {fileTypeError && (
              <ErrorMsg msg={'error.onboarding.menuLinkCard.fileTypeError'} />
            )}
            {validationError && validationError.length && (
              <Text level="caption" style={{ color: COLORS.ERROR }}>
                {validationError}
              </Text>
            )}
          </Card>
        </div>
      </Col>
    </Row>
  )
}

export default injectIntl(MenuLinkCard)

const ProgressBar = ({ loading, percent }) => {
  return (
    loading && (
      <Progress
        percent={Math.floor(percent)}
        status={'active'}
        strokeColor={COLORS.SUCCESS}
      />
    )
  )
}

const CardStatus = ({ isPending }) => (
  <Status>
    <Text
      level="caption"
      style={{
        color: isPending ? COLORS.ERROR : COLORS.PRIMARY,
        fontSize: 11,
        display: 'flex'
      }}>
      {isPending ? (
        <span>
          *<IntlMessages id={'pending'} />
        </span>
      ) : (
        <>
          <IntlMessages id={'completed'} />
          <Lottie
            options={defaultOptions}
            height={31}
            width={31}
            style={{ marginLeft: 8 }}
          />
        </>
      )}
    </Text>
  </Status>
)
