import * as Sentry from '@sentry/react'
import { Button, Title } from 'components'
import React, { Component } from 'react'

interface IProps {}
interface IState {
  hasError: boolean
  error: any
  errorInfo: any
  eventId: any
}
class ErrorBoundary extends Component<IProps, IState> {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      errorInfo: '',
      hasError: false,
      eventId: ''
    }
  }

  /*
   * Static getDerivedStateFromError(error) {
   *   // Update state so the next render will show the fallback UI.
   *   return { hasError: true, error };
   * }
   */

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    const eventId = Sentry.captureException(error)

    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      // This.setState({ eventId, errorInfo, error });
    })
    Sentry.showReportDialog({
      eventId,
      subtitle2: 'Please refresh your cache to continue (cmd+R / ctrl+R)',
      onLoad: () => {
        const elem = document.querySelector('.sentry-error-embed .close')

        elem && elem.addEventListener('click', () => {
          // Your onClose logic or redirect to root path with
          window.location.reload()
        })
      }
    })
    localStorage.clear()
  }

  render() {
    const { hasError, eventId } = this.state

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div className="justifyContent-center" style={{ flexDirection: 'column' }}>
          <div>
            <Title level="h1">Something went wrong.</Title>
          </div>
          <div>
            <Button
              htmlType="button"
              onClick={() => {
                Sentry.showReportDialog({
                  eventId,
                  subtitle2: 'Please refresh your cache to continue (cmd+R / ctrl+R)'
                })
              }}
              size="default"
              type="primary"
              style={{ marginTop: '15px' }}>
              Report Feedback
            </Button>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary