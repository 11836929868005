import { withApollo } from '@apollo/client/react/hoc'
import { Col, message, Row, Spin } from 'antd'
import { Button, IntlMessages, Title, WeekTimeSlots } from 'components'
import * as compose from 'lodash/flowRight'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { addMenuTimings, createMenuTimings, getMenuTimings, removeMenuTimings, removeMenuTimingsById, updateMenuTimings } from 'services'
import { getTimeSlotWithPaddingZero, getUniqueCode, intlAlertMessage, isDuplicateCode, setErrMsg } from 'Utils'
import { COLORS, DEFAULT_TIME_SLOT, HH_MM_FORMAT } from 'Utils/constants'

import { addToSavedTimeGroups, checkForDuplicateAndOverlapTimeSlot, getAllTimeSlotIds, getModifiedTimeSlots, getNewTimeSlots, getParsedMenuTimings, getSortedWeekDays, getTimeGroupsWithRemovedGroup, getTimeGroupWithModifiedTime, getTimeGroupWithRemovedTime, getUpdatedTimeGroups, isNewTimeGroup, isNoTimeSlot, removeFromSavedTimeGroups, republishCatalog, setSuccessMessage, updateTimeGroupTimings, updateTimeGroupWithAddedTimeSlot } from '../../../Utils/menuUtils'
import { AddItemStyle, ClearAllSlotsContainer, GroupItemStyle, ItemBoxStyle, SaveButtonContainer, ScrollableListContainer, StyledContainer } from '../../../Utils/menuUtils/styleConstants'
import { DeleteModal, SlotTimingModal } from '../components'
import EmptyTimeSlotsTable from '../components/EmptyTimeSlotsTable'

const MenuTimings = (props) => {

  const timeSlotIdsToDelete = useRef([])
  const savedTimeGroups = useRef([])

  const [timeGroups, setTimeGroups] = useState([])
  const [timeSlotGroupName, setTimeSlotGroupName] = useState('')
  const [selectedTimeGroup, setSelectedTimeGroup] = useState(null)
  const [isUpdateSlot, setIsUpdateSlot] = useState(false)
  const [isAddSlot, setIsAddSlot] = useState(false)
  const [timeSlot, setTimeSlot] = useState({ days: '', data: {} })
  const [isApplyAllDays, setIsApplyAllDays] = useState(false)
  const [selectedTimeSlotIndex, setSelectedTimeSlotIndex] = useState(null)
  const [savingTimings, setSavingTimings] = useState(false)
  const [showDeleteSlotModal, setShowDeleteSlotModal] = useState(false)
  const [showClearAllSlotModal, setShowClearAllSlotModal] = useState(false)
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false)
  const [fetchingTimeGroups, setFetchingTimeGroups] = useState(false)
  const [totalTimeSlot, setTotalTimeSlot] = useState(0)

  useEffect(() => {
    getMenuTiming()
  }, [])

  const getMenuTiming = async () => {
    const { client } = props

    setFetchingTimeGroups(true)
    const menuTimingsResponse = await getMenuTimings(client).catch(err => {
      console.error('get time groups error', err)
    })

    setFetchingTimeGroups(false)
    const timeGroups = menuTimingsResponse?.data?.getMenuTimings

    if (timeGroups && timeGroups.length) {
      savedTimeGroups.current = timeGroups.map(timeGroup => timeGroup.code)
      timeGroups[0].timings = getParsedMenuTimings(timeGroups[0].timings)
      timeGroups[0].timings = getSortedWeekDays(timeGroups[0].timings)
      setTimeGroups(timeGroups)
      setSelectedTimeGroup({ ...timeGroups[0] })
    }
  }

  const createMenuTiming = async () => {
    let menuTimings = selectedTimeGroup.timings.filter(time => time.data.length > 0)

    menuTimings = menuTimings.map(time => {
      time.days = time.days[0]

      return time
    })
    const createMenuTimingsInput = {
      name: selectedTimeGroup.name,
      code: selectedTimeGroup.code,
      menuTimings
    }
    const createMenuTimingsResponse = await createMenuTimings(props.client, createMenuTimingsInput).catch(err => setSavingTimings(false))
    let newTimeGroup = createMenuTimingsResponse?.data?.createMenuTimings

    if (newTimeGroup) {
      savedTimeGroups.current = addToSavedTimeGroups(savedTimeGroups.current, newTimeGroup.code)
      newTimeGroup = updateTimeGroupTimings(newTimeGroup)
      
      setSelectedTimeGroup(newTimeGroup)
      setSavingTimings(false)
    }
  }

  const addMenuTiming = async newTimings => {
    const { client } = props
    const addMenuTimingsInput = {
      code: selectedTimeGroup.code,
      menuTimings: newTimings
    }
    const addMenuTimingsResponse = await addMenuTimings(client, addMenuTimingsInput).catch(err => {
      console.error('add time slots to selected time group error', err)
    })
    let timeGroupWithAddedTiming = addMenuTimingsResponse?.data?.addMenuTimings

    if (timeGroupWithAddedTiming) {
      timeGroupWithAddedTiming = updateTimeGroupTimings(timeGroupWithAddedTiming)
      const selectedTimeGroupWithAddedTimeSlot = updateTimeGroupWithAddedTimeSlot(selectedTimeGroup, timeGroupWithAddedTiming)

      setSelectedTimeGroup(selectedTimeGroupWithAddedTimeSlot)
      setSavingTimings(false)
    }
  }

  const updateMenuTiming = async modifiedTimings => {
    const updateMenuTimingsInput = {
      code: selectedTimeGroup.code,
      menuTimings: modifiedTimings
    }

    await updateMenuTimings(props.client, updateMenuTimingsInput).catch(err => setSavingTimings(false))
  }

  const removeMenuTiming = async () => {

    if (savedTimeGroups.current.indexOf(selectedTimeGroup.code) !== -1) {
      const removeMenuTimingsInput = {
        code: selectedTimeGroup.code
      }
      const removeMenuTimingsResponse = await removeMenuTimings(props.client, removeMenuTimingsInput).catch(err => {
        console.error('remove time group error', err)
      })
      const removedMenuTimings = removeMenuTimingsResponse?.data?.removeMenuTimings

      if (removedMenuTimings) {
        savedTimeGroups.current = removeFromSavedTimeGroups([...savedTimeGroups.current], selectedTimeGroup.code)
        republishCatalog(props.client)
      }
    }
    const updatedTimegroups = getTimeGroupsWithRemovedGroup([...timeGroups], selectedTimeGroup)

    if (updatedTimegroups.length) {
      onGroupChange({ ...updatedTimegroups[0] })
    } else {
      setTimeGroups(updatedTimegroups)
      setSelectedTimeGroup(null)
    }
    setTimeGroups(updatedTimegroups)
    setShowDeleteGroupModal(false)
  }

  const removeMenuTimingById = async () => {
    const removeMenuTimingsByIdInput = {
      code: selectedTimeGroup.code,
      id: timeSlotIdsToDelete.current
    }
    const removeMenuTimingsResponse = await removeMenuTimingsById(props.client, removeMenuTimingsByIdInput).catch(err => console.log('Remove time slots  error', err))

    return removeMenuTimingsResponse?.data?.removeMenuTimingsById
  }

  const onGroupChange = newSelectedTimeGroup => {
    if (selectedTimeGroup.code === newSelectedTimeGroup.code) return
    const updatedTimeGroup = updateTimeGroupTimings(newSelectedTimeGroup)

    setSelectedTimeGroup(updatedTimeGroup)
  }

  const addTimeGroup = () => {
    const formattedTimeSlotGroupName = timeSlotGroupName.trim()
    const newTimeGroupCode = getUniqueCode(formattedTimeSlotGroupName)

    if (newTimeGroupCode === selectedTimeGroup?.code) return
    if (
      isDuplicateCode(
        newTimeGroupCode,
        timeGroups.map(timeGroup => timeGroup.code)
      )
    ) {
      setErrMsg('menuTimings.duplicateCode', props.intl)
    } else {
      const newTimeGroup = {
        name: formattedTimeSlotGroupName,
        code: newTimeGroupCode,
        timings: getParsedMenuTimings([])
      }

      timeGroups.unshift(newTimeGroup)
      setTimeGroups(timeGroups)
      setSelectedTimeGroup(newTimeGroup)
      setTimeSlotGroupName('')
    }
  }

  const toggleAddSlot = day => {
    timeSlot.days = day
    timeSlot.data = {
      openTime: DEFAULT_TIME_SLOT.OPEN,
      closeTime: DEFAULT_TIME_SLOT.CLOSE
    }
    setIsAddSlot(!isAddSlot)
    setIsUpdateSlot(false)
    setTimeSlot(timeSlot)
    setIsApplyAllDays(false)
  }

  const toggleUpdateSlot = (day, slot, slotIndex) => {
    timeSlot.days = day
    timeSlot.data = { ...slot }
    setIsAddSlot(false)
    setIsUpdateSlot(!isUpdateSlot)
    setTimeSlot(timeSlot)
    setSelectedTimeSlotIndex(slotIndex)
  }

  const addTimeSlot = () => {
    const data = {
      openTime: Number(timeSlot.data['openTime']),
      closeTime: Number(timeSlot.data['closeTime'])
    }

    if (data.openTime === data.closeTime) {
      setErrMsg('store.errMsg.openTimeAndCloseTimeShouldNotBeSame', props.intl)
    } else if (data.closeTime < data.openTime) {
      setErrMsg('store.errMsg.closeTimeShouldGreaterThemOpenTime', props.intl)
    } else {
      let slotIndex = -1
      let isDuplicateSlot = false
      let isSlotOverlap = false
      const duplicateSlotArr = []

      selectedTimeGroup.timings.forEach((day, i) => {
        if (timeSlot.days.toLowerCase() === day.days[0].toLowerCase() || isApplyAllDays) {
          day.data.forEach((slot, j) => {
            if (slot.openTime === data.openTime && slot.closeTime === data.closeTime) {
              duplicateSlotArr.push(day.days[0])
              isDuplicateSlot = true

              return
            }
            if ((data.openTime >= slot.openTime && data.openTime <= slot.closeTime) || (data.closeTime >= slot.openTime && data.closeTime <= slot.closeTime) || (data.openTime <= slot.openTime && data.closeTime >= slot.closeTime)) {
              isSlotOverlap = true
            }
          })
          slotIndex = i
        }
      })

      if (!isDuplicateSlot && !isSlotOverlap) {
        if (isApplyAllDays) {
          selectedTimeGroup.timings.forEach(day => {
            day.data.push({ ...data })
          })
        } else {
          selectedTimeGroup.timings[slotIndex].data.push(data)
        }
        const updatedTimeGroups = getUpdatedTimeGroups(timeGroups, selectedTimeGroup)

        setSelectedTimeGroup(selectedTimeGroup)
        setIsUpdateSlot(false)
        setIsAddSlot(false)
        setTimeGroups(updatedTimeGroups)
      } else if (isDuplicateSlot) {
        message.error(
          intlAlertMessage(
            { id: 'slotAlreadyExists', intl: props.intl }, { slot: duplicateSlotArr.map(val => val.toLowerCase()) }
          ))
      } else if (isSlotOverlap) {
        setErrMsg('store.errMsgOverlappingStore', props.intl)
      }
    }
  }

  const updateTimeSlot = () => {
    if (timeSlot.data['openTime'] === timeSlot.data['closeTime']) {
      setErrMsg('store.errMsg.openTimeAndCloseTimeShouldNotBeSame', props.intl)
    } else if (timeSlot.data['closeTime'] < timeSlot.data['openTime']) {
      setErrMsg('store.errMsg.closeTimeShouldGreaterThemOpenTime', props.intl)
    } else {
      const timeSlotStatus = checkForDuplicateAndOverlapTimeSlot(selectedTimeGroup, timeSlot, selectedTimeSlotIndex)

      if (!timeSlotStatus.isDuplicate && !timeSlotStatus.isOverlap) {
        const selectedTimeGroupWithModifiedTime = getTimeGroupWithModifiedTime(selectedTimeGroup, timeSlot, selectedTimeSlotIndex)

        setSelectedTimeGroup(selectedTimeGroupWithModifiedTime)
        closeAddSlotModal()
      } else if (timeSlotStatus.isDuplicate) {
        message.error(
          intlAlertMessage(
            { id: 'slotAlreadyExists', intl: props.intl },
            { slot: timeSlot.days.toLowerCase() }
          ))
      } else if (timeSlotStatus.isOverlap) {
        setErrMsg('store.errMsgOverlappingStore', props.intl)
      }
    }
  }

  const deleteTimeSlot = () => {

    if (timeSlot.data['id']) {
      timeSlotIdsToDelete.current.push(timeSlot.data['id'])
    }
    const timeGroupWithRemovedTimeSlot = getTimeGroupWithRemovedTime(selectedTimeGroup, timeSlot, selectedTimeSlotIndex)

    closeAddSlotModal()
    if (totalTimeSlot <= 1) {
      removeMenuTiming()
      setShowDeleteSlotModal(false)
    } else {
      setSelectedTimeGroup({ ...timeGroupWithRemovedTimeSlot })
      setShowDeleteSlotModal(false)
    }
  }

  const deleteAllTimeSlots = async () => {
    timeSlotIdsToDelete.current = getAllTimeSlotIds({ ...selectedTimeGroup })
    let removeMenuTimingsRes

    if (timeSlotIdsToDelete.current.length) {
      removeMenuTimingsRes = await removeMenuTimingById()
      if (!removeMenuTimingsRes) {
        setShowClearAllSlotModal(false)

        return
      }
    }
    if ((timeSlotIdsToDelete.current.length && Boolean(removeMenuTimingsRes)) || !timeSlotIdsToDelete.current.length) {
      selectedTimeGroup.timings = selectedTimeGroup.timings.map(time => {
        return {
          days: time.days,
          data: []
        }
      })
      savedTimeGroups.current = removeFromSavedTimeGroups([...savedTimeGroups.current], selectedTimeGroup.code)
      timeSlotIdsToDelete.current = []
    }
    setSelectedTimeGroup(selectedTimeGroup)
    setShowClearAllSlotModal(false)
    updateTimeGroups()
  }

  const saveTimings = async () => {

    setSavingTimings(true)
    if (isNewTimeGroup(savedTimeGroups.current, selectedTimeGroup.code)) {
      await createMenuTiming()
    } else {
      const modifiedTimings = getModifiedTimeSlots(selectedTimeGroup)

      if (modifiedTimings.length) {
        await updateMenuTiming(modifiedTimings)
      }
      const newTimings = getNewTimeSlots(selectedTimeGroup)

      if (newTimings.length) {
        await addMenuTiming(newTimings)
      }
      republishCatalog(props.client)
    }
    setSuccessMessage('menuTimings.savedSuccessfully', props.intl)
    updateTimeGroups()
  }

  const updateTimeGroups = () => {
    const updatedTimeGroups = getUpdatedTimeGroups(timeGroups, selectedTimeGroup)

    setTimeGroups(updatedTimeGroups)
    setSavingTimings(false)
  }

  const closeAddSlotModal = () => {
    setIsAddSlot(false)
    setIsUpdateSlot(false)
  }

  const onDeleteSlot = () => {
    let totalTimeSlot = 0

    selectedTimeGroup?.timings.map(elem => {
      totalTimeSlot += elem.data.length
    })
    setShowDeleteSlotModal(true)
    setTotalTimeSlot(totalTimeSlot)
  }

  const openTime = moment(getTimeSlotWithPaddingZero(timeSlot.data['openTime']), HH_MM_FORMAT)
  const closeTime = moment(getTimeSlotWithPaddingZero(timeSlot.data['closeTime']), HH_MM_FORMAT)

  let isNoSlot = true

  if (Boolean(selectedTimeGroup) && Boolean(selectedTimeGroup.timings)) {
    isNoSlot = isNoTimeSlot(selectedTimeGroup.timings)
  }
  
  return (
    <>
      <Col span={6} className="list-wrapper">
        <Title>
          <IntlMessages id="menuTiming.timeGroups" />
        </Title>
        <ItemBoxStyle>
          <AddItemStyle
            placeholder={intlAlertMessage({
              id: 'menuTiming.groupNamePlaceholderText',
              intl: props.intl
            })}
            value={timeSlotGroupName}
            onChange={e => setTimeSlotGroupName(e.target.value)} />
          <Button
            type="primary"
            disabled={!timeSlotGroupName.length}
            onClick={() => addTimeGroup()}
            loading={false}>
            <IntlMessages id="button.+add" />
          </Button>
        </ItemBoxStyle>
        <ScrollableListContainer>
          {fetchingTimeGroups && (
            <div className="divCenter" style={{ marginTop: '-100px' }}>
              <Spin size="default" />
            </div>
          )}
          {Boolean(timeGroups.length) &&
            timeGroups.map(timeGroup => (
              <GroupItemStyle
                key={timeGroup.code}
                style={
                  timeGroup.code === selectedTimeGroup?.code
                    ? {
                        backgroundColor: COLORS.BG_SHADE,
                        color: COLORS.PRIMARY,
                        padding: '10px 12px',
                        fontWeight: '600'
                      }
                    : {}
                }
                onClick={() => onGroupChange(timeGroup)}>
                {timeGroup.name}
              </GroupItemStyle>
            ))}
        </ScrollableListContainer>
      </Col>
      {!timeGroups.length ? (
        <EmptyTimeSlotsTable />
      ) : (
        <StyledContainer span={17}>
          <ClearAllSlotsContainer>
            <Title>{selectedTimeGroup?.name}</Title>
            <div>
              <Button
                disabled={isNoSlot}
                style={{ minWidth: '120px' }}
                onClick={() => setShowClearAllSlotModal(true)}
                size="small">
                <IntlMessages id="button.clearAllSlots" />
              </Button>
              <Button
                style={{ minWidth: '120px' }}
                onClick={() => setShowDeleteGroupModal(true)}
                size="small">
                <IntlMessages id="button.deleteGroup" />
              </Button>
            </div>
          </ClearAllSlotsContainer>
          <WeekTimeSlots
            dayWiseSlots={selectedTimeGroup?.timings || []}
            onToggleAddSlot={toggleAddSlot}
            onToggleUpdateSlot={toggleUpdateSlot}
          />
          <SaveButtonContainer>
            <Row>
              <Col span={23} style={{ padding: 0 }}>
                <Button
                  type="primary"
                  disabled={isNoSlot}
                  style={{ minWidth: '120px' }}
                  onClick={() => saveTimings()}
                  loading={savingTimings}>
                  <IntlMessages id="button.save" />
                </Button>
              </Col>
            </Row>
          </SaveButtonContainer>
        </StyledContainer>
      )}

      <SlotTimingModal
        isUpdateSlot={isUpdateSlot}
        onClose={() => {
          setIsAddSlot(false)
          setIsUpdateSlot(false)
        }}
        isAddSlot={isAddSlot}
        openTime={openTime}
        closeTime={closeTime}
        isApplyToAllDays={isApplyAllDays}
        onSlotOpenTimeChange={time => {
          timeSlot.data['openTime'] = Number(time.format(HH_MM_FORMAT))
          setTimeSlot({ ...timeSlot })
        }}
        onSlotCloseTimeChange={time => {
          timeSlot.data['closeTime'] = Number(time.format(HH_MM_FORMAT))
          setTimeSlot({ ...timeSlot })
        }}
        onApplyAllDaysChange={applyAllDayValue => {
          setIsApplyAllDays(applyAllDayValue)
        }}
        onAddTimeSlot={addTimeSlot}
        onUpdateTimeSlot={updateTimeSlot}
        onDeleteSlot={() => {
          onDeleteSlot()
        }}
      />

      <DeleteModal
        visible={showDeleteSlotModal}
        onCancel={() => setShowDeleteSlotModal(false)}
        onConfirm={() => deleteTimeSlot()}
        title={
          totalTimeSlot > 1 ? (
            <IntlMessages id="store.confirmationMsg.aboutDeleteSelectedSlot" />
          ) : (
            <IntlMessages id="menuTimings.deleteGroupConfirmationMsg" />
          )
        }
      />
      <DeleteModal
        visible={showClearAllSlotModal}
        onCancel={() => setShowClearAllSlotModal(false)}
        onConfirm={() => deleteAllTimeSlots()}
        title={
          <IntlMessages id="menuTimings.confirmationMsg.aboutDeleteAllSlot" />
        }
      />
      <DeleteModal
        visible={showDeleteGroupModal}
        onCancel={() => setShowDeleteGroupModal(false)}
        onConfirm={() => {
          removeMenuTiming()
        }}
        title={
          <IntlMessages id="menuTimings.confirmationMsg.aboutDeleteGroup" />
        }
      />
    </>
  )
}

const MenuTimingsComponent = compose(withRouter, withApollo)(MenuTimings)

export { MenuTimingsComponent }
