import Icon from '@ant-design/icons'
import { ICONS } from 'assets'
import React from 'react'

const DragIcon = props => (
  <Icon
    component={() => <img src={ICONS.dragIcon} alt="drag_icon" />}
    {...props}
  />
)

export default DragIcon
