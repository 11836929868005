import { CHANGE_PASSWORD, SEND_PASSWORD_RESET_LINK, SIGN_IN, USER_DATA } from 'services/query'

export const changePassword = async (client, payload) => {
  return client.mutate({
    mutation: CHANGE_PASSWORD,
    variables: payload,
    fetchPolicy: 'no-cache'
  })
}

export const sendResetPasswordLink = async (client, payload) => {
  return client.mutate({
    mutation: SEND_PASSWORD_RESET_LINK,
    variables: payload,
    fetchPolicy: 'no-cache'
  })
}

export const getUserData = (client, payload) => {
  return client.query({
    query: USER_DATA,
    variables: payload,
    fetchPolicy: 'no-cache'
  })
}

export const SignInUser = (client, payload) => {
  return client.mutate({
    mutation: SIGN_IN,
    variables: { input: payload },
    fetchPolicy: 'no-cache'
  })
}
