import { Col } from 'antd'
import styled from 'styled-components'
import { COLORS } from 'Utils/constants'

export const CardBlock = styled(Col)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `
export const DeliveryPartnersImgStyles = styled.img`
  height: 14px;
  width: 70px;
`
export const RadiusBarDotStyle = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: ${COLORS.BORDER};
`
export const DeliveryFleetRadioButtonStyle = styled.div`
  display: flex;
`