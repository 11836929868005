import { Col, Divider, Row } from 'antd'
import { IntlMessages } from 'components'
import React from 'react'

import { HeaderText } from '../../../styles'

const MenuHeader = () => {
  return (
    <Row>
      <Col span={12} offset={6}>
        <Row>
          <Col span={16}>
            <HeaderText type="H1">
              <IntlMessages id="catalogs.menu" />
            </HeaderText>
          </Col>
          <Col span={8}></Col>
        </Row>
        <Divider />
      </Col>
    </Row>
  )
}

export default MenuHeader
