import { DownOutlined, UpOutlined } from '@ant-design/icons'
import React from 'react'
import styled from 'styled-components'
import { reorderArray } from 'Utils'

const UpDownArrowContainer = styled.span`
  position: fixed;
  background-color: #fff;
  width: 16px;
  height: 33px;
  display: none;
  z-index: 1;
  border: 1px solid #eee;
  color: #aaa;
  box-shadow: 1px 1px 1px 0px #b2b2b2;
  border-radius: 2px;
  .selected-item & {
    display: block;
  }
  .searched-item & {
    display: block;
  }
`

export interface MoveUpDownProps {
  array: Array<any>
  rowIndex: number
  onReorder: Function
  className: string
  style?: object
}

export const MoveUpDown: React.FC<MoveUpDownProps> = props => {
  return (
    <UpDownArrowContainer style={props.style} className={props.className}>

      <UpOutlined

        onClick={e => {
          e.stopPropagation()
          const { newArr, newIndex } = reorderArray(
            props.array,
            props.rowIndex,
            'up'
          )

          props.onReorder(newArr, newIndex)
        }}
      />
      <DownOutlined
        onClick={e => {
          e.stopPropagation()
          const { newArr, newIndex } = reorderArray(
            props.array,
            props.rowIndex,
            'down'
          )

          props.onReorder(newArr, newIndex)
        }}
      />
    </UpDownArrowContainer>
  )
}
