import { Form, Input as AntInput, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { COLORS } from 'Utils/constants'

export interface InputProps {
  /**
   * Input label
   */
  label?: string
  /**
   * Input Placeholder
   */
  placeholder?: string
  /**
   * Can be set to visible, hidden
   */
  type?: 'visible' | 'hidden'
  /**
   * Disabled state of Input
   */
  disabled?: boolean
  /**
   * Optional change handler
   */
  onChange?: any
  /**
   * Validation Status
   */
  validateStatus?: 'success' | 'warning' | 'error' | 'validating'
  /**
   * Validation Msg
   */
  help?: string

  style?: object

  value?: string
}

/*
 * Const buttonPadding = theme.variants('mode', 'size', {
 *     small: { padding: '0 4px' },
 *     default: { light: '0 8px' },
 *     large: { light: '0 12px' },
 * });
 */

const InputStyle = styled(AntInput)`
  width: 292px;
  margin-top: 5px;
  &:hover {
    border-color: ${COLORS.BORDER_DARK};
  }
  &:focus {
    border-color: ${COLORS.PRIMARY};
  }
`
const InputPassStyle = styled(AntInput.Password)`
  width: 292px;
  margin-top: 5px;
  &:hover {
    border-color: ${COLORS.BORDER_DARK};
  }
  &:focus {
    border-color: ${COLORS.PRIMARY};
  }
`
const LabelStyle = styled(Typography)`
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #00000099;
  opacity: 1;
`

export const Input: React.FC<InputProps> = ({
  disabled = false,
  label = 'Text Input',
  placeholder = 'Placeholder Text',
  validateStatus = 'success',
  help = '',
  ...props
}) => {
  if (props.type === 'visible') {
    return (
      <Form.Item
        validateStatus={disabled ? '' : validateStatus}
        help={disabled ? '' : help}>
        <LabelStyle>{label}</LabelStyle>
        <InputStyle
          placeholder={placeholder}
          disabled={disabled}
          value={props.value}
          focus={true}
          style={props.style}
          onChange={props.onChange}
        />
      </Form.Item>
    )
  } else if (props.type === 'hidden') {
    return (
      <Form.Item
        validateStatus={disabled ? '' : validateStatus}
        help={disabled ? '' : help}>
        <LabelStyle>{label}</LabelStyle>
        <InputPassStyle
          class="hidden-input"
          placeholder={placeholder}
          disabled={disabled}
          style={props.style}
          onChange={props.onChange}
        />
      </Form.Item>
    )
  }
}
