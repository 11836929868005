import { Checkbox } from 'antd'
import { CheckboxGroup } from 'components'
import { Panel } from 'components/simple/Collapse'
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc'
import { hideItemUpDownArrow, setItemUpDownArrowPosition } from 'Utils/menuUtils/customizeHelper'

import { StyledCheckbox } from '../../../Utils/menuUtils/styleConstants'
import DragIcon from '../components/DragIcon'

const CustomizationListItem = (props) => {
  const { cg, val, customizationGroup, onSortOptionsValue, setCustomizationGroups } = props

  const onOptionValueSortEnd = (e, optionValue, index) => {

    customizationGroup.map((customizationGroup, index) => {
      if (customizationGroup.value === optionValue.value) {
        const newCustomisationOptionValue = arrayMove(
          customizationGroup.customisations,
          e.oldIndex,
          e.newIndex
        )
	
        customizationGroup[index].customisations = newCustomisationOptionValue
        onSortOptionsValue(customizationGroup, newCustomisationOptionValue)
      }
    })
  }

  const onCustomizationGroupChange = (cgIndex, selectedValues) => {
    const newCustomizationGroups = [...customizationGroup]
    const selectedCustomizationGroup = { ...newCustomizationGroups[cgIndex] }
    const isSelected = selectedValues.includes(selectedCustomizationGroup.value)
    let customizations = [...selectedCustomizationGroup.customizations]

    customizations = customizations.map(cs => {
      cs.selected = isSelected

      return cs
    })
    newCustomizationGroups[cgIndex] = {
      ...selectedCustomizationGroup,
      selected: isSelected,
      indeterminate: false,
      customizations: [...customizations]
    }
    setCustomizationGroups(newCustomizationGroups)
  }

  return (
    <Panel
			key={val}
			style={{ borderColor: '#f7f2f2' }}
			{...props}
			header={
  			<div
					style={{ display: 'flex', alignItems: 'center' }}
					onClick={() => {
						hideItemUpDownArrow(customizationGroup)
						setItemUpDownArrowPosition(val)
					}}>
    				<CheckboxGroup
						onChange={selectedValues => {
							onCustomizationGroupChange(val, selectedValues)
							setItemUpDownArrowPosition(val)
							hideItemUpDownArrow(customizationGroup)
						}}
						name={`${cg.label}-val`}
						value={cg.selected ? [cg.value] : []}>
      <span className="dragIcon">
        <DragIcon />
      </span>

      <Checkbox
							value={cg.value}
							indeterminate={cg.indeterminate}
							onClick={e => {
								e.stopPropagation()
							}}>
        {cg.label}
      </Checkbox>
    </CheckboxGroup>
  </div>
			}>
      <SortableOptionValueList
				cg={cg}
				val={val}
				distance={1}
				onSortEnd={e => onOptionValueSortEnd(e, cg, val)}
				style={{ zIndex: 99999999 }}
				helperClass="sortableHelper"
				customizationGroups={customizationGroup}
				setCustomizationGroups={setCustomizationGroups}
			/>
    </Panel>
  )
}

export const SortableCustomizationListItem = SortableElement(CustomizationListItem)


const OptionValueItem = prop => {
  const { c, elem } = prop

  return (
    <div key={`customization-${elem}`}>
      <StyledCheckbox
				key={`customization-cb-${elem}`}
				value={c.value}
			>
        {c.label}
      </StyledCheckbox>
    </div>
  )
}
const SortableOptionValueItem = SortableElement(OptionValueItem)
const OptionValueList = props => {
  const { cg, val, customizationGroups, setCustomizationGroups } = props

  const onCustomizationChange = (cgIndex, selectedValues) => {
    const newCustomizationGroups = [...customizationGroups]
    const selectedCustomizationGroup = { ...newCustomizationGroups[cgIndex] }

    selectedCustomizationGroup.customizations = selectedCustomizationGroup.customizations.map(
      cs => {
        const newCs = { ...cs }

        newCs.selected = selectedValues.includes(cs.value)

        return newCs
      }
    )
    selectedCustomizationGroup.selected =
      selectedValues.length === selectedCustomizationGroup.customizations.length
    selectedCustomizationGroup.indeterminate =
      Boolean(selectedValues.length) &&
      selectedValues.length < selectedCustomizationGroup.customizations.length

    selectedCustomizationGroup.customizations = selectedCustomizationGroup.customizations.map(
      cs => {
        const newCs = { ...cs }

        newCs.selected = selectedValues.includes(cs.value)

        return newCs
      }
    )
    selectedCustomizationGroup.selected =
      selectedValues.length === selectedCustomizationGroup.customizations.length
    selectedCustomizationGroup.indeterminate =
      Boolean(selectedValues.length) &&
      selectedValues.length < selectedCustomizationGroup.customizations.length

    newCustomizationGroups[cgIndex] = selectedCustomizationGroup
    setCustomizationGroups(newCustomizationGroups)
  }	

  return (
    <CheckboxGroup
			name={cg.label}
			onChange={selectedValues => onCustomizationChange(val, selectedValues)}
			value={cg.customizations
				.filter(cs => cs.selected)
				.map(cus => cus.value)}>
      {cg.customizations.map((c, cIndex) => (
        <SortableOptionValueItem
					key={cIndex}
					c={c}
					index={cIndex}
					elem={cIndex}
					distance={1}
					style={{ zIndex: 99999999 }}
				/>
			))}
    </CheckboxGroup>
  )
}
const SortableOptionValueList = SortableContainer(OptionValueList)