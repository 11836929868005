import { RightOutlined } from '@ant-design/icons'
import { Col, List, Row } from 'antd'
import React from 'react'

import DragIcon from './DragIcon'

const CategoryItem = props => {
  const { categoryDetails, category, onCategorySelection } = props

  return (
    <Row
      className={
        categoryDetails && categoryDetails['id'] === category['id']
          ? 'selected-item'
          : ''
      }
      onClick={() => {
        onCategorySelection(category)
      }}>
      <Col span={18} style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '50px' }}>
          <DragIcon className="dragIcon rightIcon" />
        </div>
        <List.Item>{`${category['name']}`}</List.Item>
      </Col>
      <Col span={4}>
        <span className="rightIcon">
          <RightOutlined />
        </span>
      </Col>
    </Row>
  )
}

export default CategoryItem
