import { message } from 'antd'
import { FormInstance } from 'antd/es/form'
import React, { createRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getUserDetails, intlAlertMessage, setUserDetails } from 'Utils'
import { ADMIN_PASSWORD_REGEX } from 'Utils/constants'

import { changePassword } from '../../../services/userService'

const useResetPassword = (props) => {
  const history = useHistory()
  const { intl } = props
  const [loading, setLoading] = useState(false)
  const [confirmDirty, setConfirmDirty] = useState(false)
  const formRef = createRef<FormInstance>()

  const handleSubmit = async (values: any) => {
    const { currentPassword: oldPassword, password: newPassword } = values

    try {
      setLoading(true)
      const data = await changePassword(props.client, {
        oldPassword,
        newPassword
      })

      if (data?.data.updatePassword.updated) {
        message.success(intlAlertMessage({ id: 'passwordUpdated', intl }))
        let userDetails = getUserDetails()

        userDetails = { ...userDetails, resetPassword: false }
        setUserDetails(userDetails)

        history.push('/orderx/onboarding')
        setLoading(false)
      }
    } catch (err) {
      console.error('Change password error')
      setLoading(false)

    }
  }
  const handleConfirmBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target


    setConfirmDirty(confirmDirty || Boolean(value))
  }


  const VerifyPassword = (_, value: any) => {

    if (value && value !== formRef.current.getFieldValue('password')) {
      return Promise.reject(new Error(intlAlertMessage({ id: 'updatepassword.errMsg.newPass&confirmPassShouldBeSame', intl })))
    }

    return Promise.resolve()
  }

  const validateNewPassword = (_, value: any) => {

    if (value && !ADMIN_PASSWORD_REGEX.test(String(value))) {
      return Promise.reject(
        new Error(intlAlertMessage({
          id: 'validationForPassword',
          intl
        }))
      )
    } else if (value && formRef.current.getFieldValue('currentPassword') && value === formRef.current.getFieldValue('currentPassword')) {
      return Promise.reject(new Error(intlAlertMessage({ id: 'userInfo.errMsg.currentAndNewPasswordCannotBeSame', intl })))
    }

    return Promise.resolve()
  }



  return { formRef, handleConfirmBlur, VerifyPassword, validateNewPassword, loading, setLoading, handleSubmit }
}

export default useResetPassword