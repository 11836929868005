import { CaretDownOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'
import {
  Button,
  IntlMessages,
  Modal,
  TextButton,
  Title
} from 'components'
import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import {
  arrayMove,
  SortableContainer,
} from 'react-sortable-hoc'
import { onSortCustomisation, onSortOptionValue } from 'services/productService'
import { compareArrayValues, intlAlertMessage } from 'Utils'
import {
  COLORS,
  MENU_TABS,
  PRODUCT_TYPE_ENUM,
  PRODUCT_VARIANT_TYPE_ENUM
} from 'Utils/constants'
import { getWcoreOrganizationId } from 'Utils/localStorageHandlers/getter'

import {
  getCustomisationGroupsWithSelections,
  getSelectedCutomisationGroups,
  getUpdatedPriceData,
  getValidatedPrices,
  prepareOriginalPrices,
  republishCatalog,
  selectedCustomizationValidateSelection,
  setSuccessMessage
} from '../../../Utils/menuUtils/commonFunc'
import {
  setItemUpDownArrowPosition
} from '../../../Utils/menuUtils/customizeHelper'
import {
  ButtonsContainer,
  StyledCollapse
} from '../../../Utils/menuUtils/styleConstants'
import SelectedCustomizations from '../components/selectedCustomisations'
import { SortableCustomizationListItem } from './SortableCustomizationListItem'

const ProductCustomisation = (props) => {
  const { options, productRelationships, productVariantPrices, newProductVariantPrices, onTabChange } = props
  const customizationGroupsWithSelection = getCustomisationGroupsWithSelections(
    options,
    productRelationships
  )
  const [option, setOptions] = useState(options)
  const [showCustomizeGroupPopup, setShowCustomizeGroupPopup] = useState(false)
  const [customizationGroups, setCustomizationGroups] = useState(customizationGroupsWithSelection)
  const [previousCustomisationGroups, setPreviousCustomisationGroups] = useState([])
  const [selectedCustomizationGroups, setSelectedCustomizationGroups] = useState(getSelectedCutomisationGroups(customizationGroupsWithSelection))

  const addSelectedGroup = () => {
    const selectedGroup = getSelectedCutomisationGroups(customizationGroups)

    setShowCustomizeGroupPopup(false)
    setSelectedCustomizationGroups(selectedGroup)
    props.onProductRelationChange(PRODUCT_TYPE_ENUM.VARIANT, selectedGroup)
  }


  const updateProductPriceData = (variantType, productPriceData, updatedProductPriceData) => {
    const { productVariantPrices, onProductVariantPriceChange, newProductVariantPrices } = props

    if (variantType === PRODUCT_VARIANT_TYPE_ENUM.OLD) {
      const updatedPricesIndex = productVariantPrices.findIndex(e => {
        return (
          e.productId === productPriceData.productId ||
          compareArrayValues(e.productId, productPriceData.productId)
        )
      })

      productVariantPrices.splice(
        updatedPricesIndex,
        1,
        updatedProductPriceData
      )
      onProductVariantPriceChange(
        productVariantPrices,
        PRODUCT_VARIANT_TYPE_ENUM.OLD
      )
    } else {
      const updatedPricesIndex = newProductVariantPrices.findIndex(e => {
        return (
          e.productId === productPriceData.productId ||
          compareArrayValues(e.productId, productPriceData.productId)
        )
      })

      newProductVariantPrices.splice(
        updatedPricesIndex,
        1,
        updatedProductPriceData
      )
      onProductVariantPriceChange(
        newProductVariantPrices,
        PRODUCT_VARIANT_TYPE_ENUM.NEW
      )
    }
    const selectedGroup = getSelectedCutomisationGroups(customizationGroups)

    setSelectedCustomizationGroups([...selectedGroup])
  }

  const updateProductBasePrice = (productId, value) => {
    if (isNaN(Math.round(value))) return
    const { variantType, productPriceData } = getUpdatedPriceData(productId, productVariantPrices, newProductVariantPrices)
    const updatedProductPriceData = {
      ...productPriceData,
      priceValue: Math.round(value) === 0 ? 1 : Math.round(value),
      priceValueChanged: true
    }

    updateProductPriceData(variantType, productPriceData, updatedProductPriceData)
  }

  const updateProductDealPrice = (productId, value) => {
    if (isNaN(Math.round(value))) return
    const { variantType, productPriceData } = getUpdatedPriceData(productId, productVariantPrices, newProductVariantPrices)
    const updatedProductPriceData = {
      ...productPriceData,
      dealPrice: Math.round(value)
    }

    updateProductPriceData(variantType, productPriceData, updatedProductPriceData)
  }

  const updateProductPackagingCharge = (productId, value) => {
    const newPackagingCharge = Math.round(value)

    if (isNaN(newPackagingCharge)) return
    const { variantType, productPriceData } = getUpdatedPriceData(productId, productVariantPrices, newProductVariantPrices)

    if (productPriceData.chargeValue === newPackagingCharge) {
      return
    }
    const updatedProductPackagingChargeData = {
      ...productPriceData,
      chargeValue: newPackagingCharge,
      chargeValueChanged: true
    }

    updateProductPriceData(variantType, productPriceData, updatedProductPackagingChargeData)
  }

  const validateAndUpdatePrices = (productId, priceType) => {
    const { newProductVariantPrices, originalProductVariantPrices } = props
    const { variantType, productPriceData } = getUpdatedPriceData(productId, productVariantPrices, newProductVariantPrices)
    const products = newProductVariantPrices.map(newProductVariantPrice => {
      const { productId, tag } = newProductVariantPrice

      return { id: productId, tag }
    })
    let originalNewProductVariantPrices = prepareOriginalPrices(products)

    originalNewProductVariantPrices = originalNewProductVariantPrices.map(
      originalNewProductVariantPrice => {
        return {
          ...originalNewProductVariantPrice,
          priceValue: null,
          discountValue: null,
          chargeValue: null
        }
      }
    )
    const updatedProductPriceData = getValidatedPrices(
      variantType === PRODUCT_VARIANT_TYPE_ENUM.OLD
        ? [...originalProductVariantPrices]
        : [...originalNewProductVariantPrices],
      productPriceData,
      priceType
    )

    updateProductPriceData(variantType, productPriceData, updatedProductPriceData)
  }

  const launchCustomizationsGroupPopup = () => {
    const previousCustomisationGroups = customizationGroups.map(addonGroup => {
      return { ...addonGroup }
    })

    setPreviousCustomisationGroups(previousCustomisationGroups)
    setShowCustomizeGroupPopup(true)
  }

  const closeCustomizationsGroupPopup = () => {
    const customizationGroups = previousCustomisationGroups.map(
      previousAddonGroup => {
        return { ...previousAddonGroup }
      }
    )

    setShowCustomizeGroupPopup(false)
    setCustomizationGroups(customizationGroups)
  }

  const updateProductTag = (productId, value) => {
    const { variantType, productPriceData } = getUpdatedPriceData(productId, productVariantPrices, newProductVariantPrices)

    if (productPriceData.tag === value) {
      return
    }
    const updatedProductPriceData = {
      ...productPriceData,
      tag: value
    }

    updateProductPriceData(variantType, productPriceData, updatedProductPriceData)
  }

  const onSortEnd = e => {
    const newCustomisationGroups = arrayMove(customizationGroups, e.oldIndex, e.newIndex)

    setCustomizationGroups(newCustomisationGroups)
    setOptions(newCustomisationGroups)
    onSortCustomizations()
  }

  const onSortCustomizations = async () => {
    const org_id = getWcoreOrganizationId()
    const customizationSortSeq = customizationGroups.map((c, index) => {
      return {
        id: c.value,
        sortSeq: index
      }
    })

    try {
      const customizationInput = {
        organizationId: org_id.toString(),
        optionSeq: customizationSortSeq
      }
      const res = await onSortCustomisation(props.client,
        customizationInput
      )
      const customizationGroupsWithSelection = getCustomisationGroupsWithSelections(
        res.data.updateOptionSortSeq,
        productRelationships
      )

      setOptions(res.data.updateOptionSortSeq)
      setCustomizationGroups(customizationGroupsWithSelection)
      setPreviousCustomisationGroups(customizationGroupsWithSelection)
      setSuccessMessage('category.successMsg.OptionReorderSuccessfully', props.intl)
    } catch (error) {
      console.log(error)
    }
  }

  const onSortOptionsValue = async (option, optionValue) => {
    const optionValueSortSeq = optionValue.map((option, index) => {
      return {
        id: option.productId,
        sortSeq: index
      }
    })

    try {
      const optionValueInput = {
        optionId: option.value,
        optionValueSeq: optionValueSortSeq
      }

      await onSortOptionValue(props.client, optionValueInput)
      setSuccessMessage('category.successMsg.OptionReorderSuccessfully', props.intl)
      setCustomizationGroups(customizationGroups)
      republishCatalog(props.client)
    } catch (error) {
      console.log(error)
    }
  }

  const CustomisationList = () => {
    return (
      <StyledCollapse
        bordered={false}
        expandIcon={({ isActive }) => {
          return <CaretDownOutlined style={{ color: COLORS.BORDER_DARK }} rotate={isActive ? 180 : 0} />
        }}
        expandIconPosition={'right'}
        onChange={key => {
          setItemUpDownArrowPosition(key)
        }}>
        {customizationGroups.map((cg, val) => (
          <SortableCustomizationListItem
            key={val}
            cg={cg}
            val={val}
            distance={1}
            index={val}
            style={{ zIndex: 99999999 }}
            customizationGroup={customizationGroups}
            onSortOptionsValue={onSortOptionsValue}
            setCustomizationGroups={(val) => setCustomizationGroups(val)}
          />
        ))}
      </StyledCollapse>
    )
  }
  const SortableCustomisationList = SortableContainer(CustomisationList)

  return (
    <>
      <Row className="mt-2">
        <Col span={20}>
          <Title
            level="h5"
            background={true}
            style={{
              color: COLORS.DARK_TEXT,
              marginBottom: 0,
              fontSize: '15px'
            }}>
            <IntlMessages id="customization" />
          </Title>
        </Col>
        <Col span={4} className="no-pd-left">
          <Button
            type="secondary"
            style={{ width: '100%', height: '41px', marginBottom: 0 }}
            onClick={() => launchCustomizationsGroupPopup()}>
            <IntlMessages id="button.+add" />
          </Button>
        </Col>
      </Row>
      {selectedCustomizationGroups.length ? (
        <SelectedCustomizations
          customisationGroups={selectedCustomizationGroups}
          productVariantPrices={productVariantPrices}
          newProductVariantPrices={newProductVariantPrices}
          onBasePriceChange={updateProductBasePrice}
          onDealPriceChange={updateProductDealPrice}
          onPackagingChargeChange={updateProductPackagingCharge}
          onTagChange={updateProductTag}
          validateAndUpdatePrices={validateAndUpdatePrices}
        />
      ) : null}
      <Modal
        title={<IntlMessages id="customisationGroups" />}
        visible={showCustomizeGroupPopup}
        onClose={() => closeCustomizationsGroupPopup()}
        subTitle={<IntlMessages id="customisationMaxGroups" />}>
        <SortableCustomisationList
          onSortEnd={onSortEnd}
          distance={1}
          helperClass="sortableHelper"
        />
        <ButtonsContainer>
          <TextButton
            label={intlAlertMessage({
              id: 'button.+createNew',
              intl: props.intl
            })}
            onClick={() => onTabChange(MENU_TABS.CUSTOMIZATIONS)}
          />
          <Button
            type="primary"
            onClick={addSelectedGroup}
            style={{ width: '100px', marginBottom: 0 }}
            disabled={!selectedCustomizationValidateSelection(customizationGroups)}>
            <IntlMessages id="add" />
          </Button>
        </ButtonsContainer>
      </Modal>
    </>
  )
}

export default injectIntl(ProductCustomisation)
