import { Button } from 'antd'
import { IntlMessages } from 'components'
import React from 'react'

export const Buttons = ({ key, title, onclick }) => {
  return (
    <Button
      key={key}
      type={title === 'leave' ? 'primary' : 'default'}
      danger={title === 'leave'}
      className={title === 'leave' ? 'leave-btn' : 'stay-btn'}
      size="large"
      onClick={() => {
        onclick()
      }}>
      <IntlMessages id={title} />
    </Button>
  )
}
