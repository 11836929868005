import 'antd/dist/antd.css'
import './index.css'

import { ApolloClient, ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { App } from 'components'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { configureClient, configurePmsClient } from './appApollo/client'
import { ErrorBoundary } from './components'
const { Suspense } = React
// Const App = lazy(() => import('./pages/App'));
const initSentry = () => {
  if (process.env.REACT_APP_SENTRY_ENV === 'development' && process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
      /*
       * BeforeSend(event, hint) {
       *   // Check if it is an exception, and if so, show the report dialog
       *   if (event.exception) {
       *     Sentry.showReportDialog({ eventId: event.event_id });
       *   }
       *   return event;
       * },
       */
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: process.env.SENTRY_RELEASE,
      environment: process.env.REACT_APP_SENTRY_ENV,
      denyUrls: ['https://help.peppo.co.in'],
      autoSessionTracking: true,
      sampleRate: 1,
      integrations: [new Integrations.BrowserTracing()],
      /*
       * We recommend adjusting this value in production, or using tracesSampler
       * for finer control
       */
      tracesSampleRate: 1
    })
    console.log('Sentry Activated')
  } else {
    console.log('Sentry not activated')
  }
}
const theme = {
  mode: 'light'
}

initSentry()

export const orderxClient = configureClient()
export const pmsClient = configurePmsClient()
class OrderxApp extends React.Component<{}, { client: ApolloClient<any> }> {
  constructor(props) {
    super(props)
    this.state = {
      client: null
    }
  }

  componentDidMount() {
    configureClient(client => {
      this.setState({
        client
      })
    })
  }

  render() {
    // Return <div>Consol Application</div>
    const { client } = this.state

    return (
      client && (
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <ApolloProvider client={client}>
              <BrowserRouter>
                <Suspense fallback={<div></div>}>
                  <Switch>
                    <Route path="/" component={App} />
                  </Switch>
                </Suspense>
              </BrowserRouter>
            </ApolloProvider>
          </ErrorBoundary>
        </ThemeProvider>
      )
    )
  }
}

export default Sentry.withProfiler(OrderxApp)

ReactDOM.render(<OrderxApp />, document.getElementById('root'))

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 * serviceWorker.unregister();
 */
