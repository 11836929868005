// Import theme from 'styled-theming';
import { Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { COLORS } from 'Utils/constants'

const { Title: AntTitle } = Typography

export interface TitleProps {
  /**
   * How large should the Title be?
   */
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  /**
   * Apply custom css
   */
  style?: object
  /**
   * To have default background color and padding
   */
  background?: boolean
}

const StyledTitle = styled(AntTitle)`
    &.ant-typography {
      font-family: Roboto;
      letter-spacing: 0px;
    }
    &.title-h1 {
      font-size: 32px;
      font-weight: 300;
      color: ${COLORS.DARK_TEXT};
    }
    &.title-h2 {
      font-size: 24px;
      font-weight: 700;
      color: ${COLORS.DARK_TEXT};
    }
    &.title-h3 {
      font-size: 22px;
      font-weight: 300;
      color: ${COLORS.DARK_TEXT};
    }
    &.title-h4 {
      font-size: 20px;
      font-weight: 700;
      color: ${COLORS.DARK_TEXT};
    }
    &.ant-typography.subtitle {
      font-size: 16px;
      font-weight: normal;
      color: ${COLORS.NORMAL_TEXT};
    }
    &.ant-typography.subtitle2 {
      font-size: 14px;
      font-weight: 700;
      color: ${COLORS.NORMAL_TEXT};
    }
  `
const StyledTitleContainer = styled.div`
    padding: 10px 16px;
    background-color: ${props => props.bgColor || COLORS.BG_SHADE};
  `

/**
 * Peppo Title component to provide title in different level
 */
export const Title: React.FC<TitleProps> = ({ level = 'h6', background = false, ...props }) => {
  const getLevel = level => {
    if (level === 'h1') {
      return 1
    } else if (level === 'h2') {
      return 2
    } else if (level === 'h3') {
      return 3
    } else if (level === 'h4') {
      return 4
    }
  }

  let titleComponent = (
    <StyledTitle level={getLevel(level)} {...props} className={`title-${level}`}>
      {props.children}
    </StyledTitle>
  )

  if (level === 'h5') {
    titleComponent = (
      <StyledTitle level={4} {...props} className={'subtitle'}>
        {props.children}
      </StyledTitle>
    )
  } else if (level === 'h6') {
    titleComponent = (
      <StyledTitle level={4} {...props} className={'subtitle2'}>
        {props.children}
      </StyledTitle>
    )
  }
  if (background) {
    return <StyledTitleContainer bgColor={props.style ? props.style['backgroundColor'] : ''}>{titleComponent}</StyledTitleContainer>
  }

  return titleComponent
}
