import { CheckCircleFilled } from '@ant-design/icons'
import { Divider, InputNumber } from 'antd'
import { Button, Input, IntlMessages, Modal, Text, Title } from 'components'
import { useState } from 'react'
import { createStorePOSConfiguration } from 'services/posService'
import styled from 'styled-components'
import { intlAlertMessage, setErrMsg } from 'Utils'
import {
  COLORS,
  DUMMY_MENU_SHARING_ID,
  PHONE_NUMBER_REGEX,
  POS_PROVIDERS,
  SEARCH_POS_ID_HELP_URL
} from 'Utils/constants'
import { FONTWEIGHT } from 'Utils/constants/styles'
import {
  getPosData,
  getPosEnableStores,
  getUserData
} from 'Utils/localStorageHandlers/getter'
import { setPosData } from 'Utils/localStorageHandlers/setter'

import POS_IMAGES from '../assests/image'

const POSServices = [
  {
    name: 'PetPooja',
    id: 1,
    img: POS_IMAGES.petpooja,
    posProvider: 'PETPOOJA'
  },
  {
    name: 'Urban Piper',
    id: 2,
    img: POS_IMAGES.urban_piper,
    posProvider: 'URBANPIPER'
  },
  { name: 'Others', id: 3, posProvider: 'OTHER' }
]
const ProviderBoxStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const POSServicesImageStyle = styled.div`
  height: 42px;
  width: 101px;
  border-radius: 4px;
  text-align: center;
  padding: 2%;
  margin-top: 8px;
`
const FooterStyle = styled.div`
  display: flex;
  justify-content: flex-end;
`

const POSProviders = ({
  isPOSModalVisible,
  onClose,
  intl,
  onSubmit,
  petpoojaPosId,
  onPosFormSubmit
}) => {
  const posData = getPosData()
  const posEnabledStores = getPosEnableStores()

  const petPoojaPosID = posEnabledStores?.length
    ? posEnabledStores[0]?.config?.posReferenceId
    : posData?.posId || petpoojaPosId
  const { organization, store } = getUserData()

  const [selectedPosService, setSelectedPosService] = useState(POSServices[0])
  const [posId, setPosId] = useState(petPoojaPosID || '')
  const [posName, setPosName] = useState('')
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const setPosDetails = () => {
    if (selectedPosService.posProvider === POS_PROVIDERS.URBANPIPER) {
      const data = {
        posProvider: selectedPosService.posProvider,
        data: {
          name,
          phoneNumber
        }
      }

      setPosData(data)
    } else if (selectedPosService.posProvider === POS_PROVIDERS.PETPOOJA) {
      if (posId.length < 5) {
        setErrMsg('pos.ValidPosIDErrMsg', intl)
      }else{
       
        const data = {
          posProvider: selectedPosService.posProvider,
          posId,
          data: {
            storeName: '',
            menuSharingCode: ''
          }
        }

        setPosData(data)
        onSubmit(posId)
      }
    } else if (!PHONE_NUMBER_REGEX.test(phoneNumber)) {
      setErrMsg('store.validMobileNum', intl)
  
    }else{
      const data = {
        posProvider: selectedPosService.posProvider,
        data: {
          posName,
          name,
          phoneNumber
        }
      }

      setPosData(data)

    }
  }

  const onPosSubmit = async () => {
    setIsLoading(true)
    try {
      const payload = {
        externalStoreId: DUMMY_MENU_SHARING_ID,
        organizationId: organization.id,
        storeId: store[0].id,
        posProvider: selectedPosService.posProvider,
        config: {
          orgName: organization.name,
          storeName: store[0].name,
          phoneNumber,
          name
        }
      }

      const res = await createStorePOSConfiguration(payload)
      
      setIsLoading(false)
      onPosFormSubmit()
    } catch (error) {
      console.warn(error)
      setIsLoading(true)
      onPosFormSubmit()
    }
  }
  const validatePhone = value => {
    if (value?.toString().length > 10){
      setErrMsg('pos.invalidPhoneNumber', intl)
    }else{
      setPhoneNumber(value)
    }
  }
  
  const disabled = () => {
    let isDisabled = true

    if (selectedPosService.posProvider === POS_PROVIDERS.PETPOOJA) {
      if (posId) {
        isDisabled = false
      }
    } else if(selectedPosService.posProvider === POS_PROVIDERS.URBANPIPER){
      if (name && phoneNumber) {
        if (phoneNumber.toString().length >= 10) {
          isDisabled = false
        }
      }
    } else if (posName && phoneNumber && name){
      if (phoneNumber.toString().length >= 10) {
        isDisabled = false
      }
    }

    return isDisabled
  }

  const posProviderHandler = (elem) => {
    setPhoneNumber('') 
    setName('')
    setSelectedPosService(elem) 
  }

  return (
    <Modal
      title={null}
      visible={isPOSModalVisible}
      onClose={onClose}
      width={608}>
      <Title level="h4" style={{ fontSize: '18px', marginTop: '-21px' }}>
        <IntlMessages id="store.integratePOSWithPeppo" />
      </Title>
      <ProviderBoxStyle>
        <div style={{ width: '334px' }}>
          <Text level="caption">
            <IntlMessages id="store.integratePOSSubTitle" />
          </Text>
          <div style={{ marginTop: '20px' }}>
            <Text level="body-2" style={{ color: COLORS.DARK_TEXT }}>
              <IntlMessages id="store.POSSelection" />
            </Text>
            <ProviderBoxStyle>
              {POSServices?.map((elem, key) => (
                <>
                  <POSServicesImageStyle
                    key={key}
                    onClick={() => posEnabledStores?.length && elem.posProvider === POS_PROVIDERS.PETPOOJA && posId.length ? posProviderHandler(elem)
                     : !posEnabledStores?.length ? posProviderHandler(elem) :null}
                    style={{
                      border: `1px solid ${
                        selectedPosService.posProvider === elem.posProvider
                          ? COLORS.PRIMARY
                          : COLORS.BORDER
                      }`,
                      backgroundColor: (elem.posProvider !== POS_PROVIDERS.PETPOOJA && posId.length) ? COLORS.DISABLED: COLORS.BG_WHITE,
                      cursor: elem.posProvider !== POS_PROVIDERS.PETPOOJA && posId.length ? 'not-allowed' : 'pointer'
                    }}>
                    {elem.img ? (
                      <img
                        src={elem.img}
                        alt="POS"
                        style={{ objectFit: 'contain' }}
                      />
                    ) : (
                      <div style={{ paddingTop: '3%' }}>
                        <Text level="body-1" style={{ paddingTop: '10%' }}>
                          {elem.name}
                        </Text>
                      </div>
                    )}
                    <div style={{ marginTop: '-39px', marginLeft: '72px' }}>
                      {selectedPosService.id === elem.id && (
                        <CheckCircleFilled
                          style={{ fontSize: '10px', color: COLORS.PRIMARY }}
                        />
                      )}
                    </div>
                  </POSServicesImageStyle>
                </>
              ))}
            </ProviderBoxStyle>
          </div>
          {selectedPosService.posProvider === POS_PROVIDERS.PETPOOJA ? (
            <div>
              <ProviderBoxStyle
                style={{
                  height: '20px',
                  marginTop: '24px'
                }}>
                <Text level="body-2">
                  <IntlMessages id="store.referenceNo" />
                </Text>
                <Button
                  type="link"
                  style={{
                    fontWeight: FONTWEIGHT.FONT_MEDIUM,
                    fontSize: '10px',
                    marginTop: '3%',
                    padding: '0'
                  }}>
                  <a href={SEARCH_POS_ID_HELP_URL} target={'_blank'} rel="noreferrer">
                    <IntlMessages id="store.don'tKnowId" />
                  </a>
                </Button>
              </ProviderBoxStyle>
              <div style={{ paddingLeft: '5%' }}>
                <Input
                  style={{ width: '95%' }}
                  type="visible"
                  label={null}
                  onChange={e => setPosId(e.target.value)}
                  value={posId}
                  placeholder={intlAlertMessage({
                    id: 'store.enterPOSId',
                    intl
                  })}
                />
              </div>
            </div>
          ) : null}
        </div>
        <div style={{ width: '30%', alignSelf: 'flex-start' }}>
          <img
            src={POS_IMAGES.cashier_icon}
            alt="cashier logo"
            height={'122px'}
            width={'122px'}
          />
        </div>
      </ProviderBoxStyle>
      {selectedPosService.posProvider === POS_PROVIDERS.URBANPIPER ? (
        <>
          <div style={{ width: '100%', display: 'flex', marginTop: '28px' }}>
            <div style={{ width: '45%', marginLeft: '3%' }}>
              <Text
                level="body-2"
                style={{ color: COLORS.DARK_TEXT, marginLeft: '-15px' }}>
                <IntlMessages id="appModule.name" />
              </Text>
              <Input
                style={{ width: '95%' }}
                type="visible"
                label={null}
                onChange={e => setName(e.target.value)}
                placeholder={intlAlertMessage({
                  id: 'appModule.name',
                  intl
                })}
              />
            </div>
            <div style={{ width: '45%', marginLeft: '6%' }}>
              <Text
                level="body-2"
                style={{ color: COLORS.DARK_TEXT, marginLeft: '-15px' }}>
                <IntlMessages id="customer.phoneNumber" />
              </Text>
              <InputNumber
                style={{ width: '100%', marginLeft: '-15px', marginTop: '5px' }}
                type="visible"
                value={phoneNumber}
                onChange={value => {
                  phoneNumber?.toString().length < 10 && validatePhone(value)
                }}
                placeholder={intlAlertMessage({
                  id: 'customer.phoneNumber',
                  intl
                })}
              />
            </div>
            
          </div>
          <Text level='caption' style={{ color: COLORS.ERROR_DARK }}>
            <IntlMessages id="pos.posAssistSupportMsg"/>
          </Text>
        </>
      ) : selectedPosService.posProvider !== POS_PROVIDERS.PETPOOJA ? (
        <div style={{ width: '100%', marginTop: '28px' }}>
          <div style={{ marginLeft: '3%' }}>
            <Text
              level="body-2"
              style={{ color: COLORS.DARK_TEXT, marginLeft: '-15px' }}>
              <IntlMessages id="pos.posName" />
            </Text>
            <Input
              style={{ width: '47%' }}
              type="visible"
              label={null}
              onChange={e => setPosName(e.target.value)}
              placeholder={intlAlertMessage({
                id: 'pos.posName',
                intl
              })}
            />
          </div>
          <div style={{ width: '100%', display: 'flex', marginTop: '16px' }}>
            <div style={{ width: '45%', marginLeft: '3%' }}>
              <Text
                level="body-2"
                style={{ color: COLORS.DARK_TEXT, marginLeft: '-15px' }}>
                <IntlMessages id="appModule.name" />
              </Text>
              <Input
                style={{ width: '95%' }}
                type="visible"
                label={null}
                onChange={e => setName(e.target.value)}
                placeholder={intlAlertMessage({
                  id: 'appModule.name',
                  intl
                })}
              />
            </div>
            <div style={{ width: '45%', marginLeft: '6%' }}>
              <Text
                level="body-2"
                style={{ color: COLORS.DARK_TEXT, marginLeft: '-15px' }}>
                <IntlMessages id="customer.phoneNumber" />
              </Text>
              <InputNumber
                style={{ width: '100%', marginLeft: '-15px', marginTop: '5px' }}
                type="visible"
                value={phoneNumber}
                onChange={value => {
                  phoneNumber?.toString().length < 10 && validatePhone(value)
                }}
                placeholder={intlAlertMessage({
                  id: 'customer.phoneNumber',
                  intl
                })}
              />
            </div>
          </div>
          <Text level='caption' style={{ color: COLORS.ERROR_DARK }}>
            <IntlMessages id="pos.posAssistSupportMsg"/>
          </Text>
        </div>
      ) : null}
      <Divider />
      <FooterStyle>
        <Button
          type="primary"
          style={{ width: '96px' }}
          disabled={disabled()}
          loading={isLoading}
          onClick={() =>
            selectedPosService.posProvider === POS_PROVIDERS.PETPOOJA
              ? setPosDetails()
              : onPosSubmit()
          }>
          <IntlMessages
            id={
              selectedPosService.posProvider === POS_PROVIDERS.PETPOOJA
                ? 'next'
                : 'button.submit'
            }
          />
        </Button>
      </FooterStyle>
    </Modal>
  )
}

export default POSProviders
