// Import theme from 'styled-theming';
import { Text } from 'components'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { getTimeSlotWithPaddingZero } from 'Utils'

const StyledContainer = styled.div`
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #9f9f9f;
  opacity: 1;
  margin: 5px 0;
  text-align: center;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
  &: hover {
    background-color: #00946e52;
    border-color: #00946e;
    color: #00946e;
  }
`

export interface TimeSlotTypes {
  /**
   * Day
   */
  day: any
  /**
   * Slot
   */
  slot: any
  /**
   * Slot
   */
  slotIndex: number
  /**
   * Change handler
   */
  onSlotChange: any
  /**
   * Apply custom css
   */
  style?: object
}

/**
 * Peppo TimeSlot component to display time range
 */
export const TimeSlot: React.FC<TimeSlotTypes> = ({ ...props }) => {
  const { day, slotIndex, slot, onSlotChange } = props

  return (
    <StyledContainer
      key={slotIndex}
      onClick={() => {
        onSlotChange(day.days[0], slot, slotIndex)
      }}>
      <div>
        <Text level={'caption'}>
          {moment(getTimeSlotWithPaddingZero(slot.openTime), 'HH:mm').format(
            'hh:mm a'
          )}
        </Text>{' '}
        -{' '}
        <Text level={'caption'}>
          {moment(getTimeSlotWithPaddingZero(slot.closeTime), 'HH:mm').format(
            'hh:mm a'
          )}
        </Text>
      </div>
    </StyledContainer>
  )
}
