import { Button, Col, Row, Select, Table } from 'antd'
import { IntlMessages } from 'components'
import React from 'react'
import { HeaderText } from 'styles'
import { intlAlertMessage } from 'Utils'

const { Option } = Select
const columns = [
  {
    title: 'Store Format',
    dataIndex: 'StoreFormat'
  },
  {
    title: 'Tax Type',
    dataIndex: 'TaxType',
    render: text => (
      <div>
        <IntlMessages id="taxType" />: {text}
      </div>
    )
  },
  {
    title: 'Percentage',
    dataIndex: 'Percentage',
    render: text => (
      <div>
        <IntlMessages id="percentage" />: {text}
      </div>
    )
  },
  {
    title: '',
    dataIndex: 'EditAction',
    render: () => (
      <Button type="link">
        <IntlMessages id="store.edit" />
      </Button>
    ),
    width: 75
  },
  {
    title: '',
    dataIndex: 'DeleteAction',
    render: () => (
      <Button type="link">
        <IntlMessages id="remove" />
      </Button>
    ),
    width: 75
  }
]
const data = [
  {
    key: '1',
    StoreFormat: 'Dine in',
    TaxType: 'SGST',
    Percentage: '5%'
  },
  {
    key: '2',
    StoreFormat: 'Delivery',
    TaxType: 'SGST',
    Percentage: '5%'
  }
]
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
  }
  /*
   *   GetCheckboxProps: record => ({
   *     disabled: record.name === 'Disabled User', // Column configuration not to be checked
   *     name: record.name,
   *   }),
   */
}

function StoreProperties(props) {
  return (
    <Col className={'agentSectionContainer'} span={20}>
      <HeaderText>
        <IntlMessages id="store.storeProperties" />
      </HeaderText>
      <Table
        className={'storePropertyTable'}
        columns={columns}
        dataSource={data}
        size="small"
        pagination={false}
        showHeader={false}
        rowSelection={{
          type: 'radio',
          ...rowSelection
        }}
        /*
         * TableLayout={undefined}
         * title={() => 'Store Format'}
         */
      />
      <Row className={'flexWrapper justifyContentSpaceBetween'}>
        <Col span={12} className="agentsInputWrapper">
          <div className="InputLabel">
            <IntlMessages id="store.modeOfServices" />
            <span className="requiredFieldRedColor">*</span>
          </div>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder={intlAlertMessage({
              id: 'store.pleaseSelect',
              intl: props.intl
            })}
            defaultValue={['a10', 'c12']}>
            <Option key="x" value={'a10'}>
              <IntlMessages id="store.a10" />
            </Option>
          </Select>
        </Col>
        <Col span={12} className="agentsInputWrapper">
          <div className="InputLabel">
            <IntlMessages id="store.sources" />
            <span className="requiredFieldRedColor">*</span>
          </div>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder={intlAlertMessage({
              id: 'store.pleaseSelect',
              intl: props.intl
            })}
            defaultValue={['a10', 'c12']}>
            <Option key="x" value={'a10'}>
              <IntlMessages id="store.a10" />
            </Option>
          </Select>
        </Col>
      </Row>
    </Col>
  )
}

export default StoreProperties
