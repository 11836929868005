import '../index.css'

import { message, Table } from 'antd'
import { Button as ConsoleButton, IntlMessages, Text } from 'components'
import Loader from 'components/simple/loader'
import moment from 'moment'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { getDeliveryDiscount, intlAlertMessage } from 'Utils'
import { COLORS, NOTE_TYPE, ORDER_STATUS, ORDER_TYPE } from 'Utils/constants'
import { getUserData } from 'Utils/localStorageHandlers/getter'
import { columnHeaders, getAllOrdersInput } from 'Utils/OrderUtils'

const OrderDetail = React.lazy(() => import('../../../components/complex/orderDetail'))

interface IOrderTableProps {
  pickUpOrder?: any
  data?: any
  loading?: boolean
  error?: any
  fetchMore?: any
  refetch?: any
  filter?: string
  isManager?: boolean
  intl: any
}

interface IOrderTableState {
  tableData: any[]
  viewOrder: boolean
  viewOrderData: any
  refetchData: boolean
  loadingTable: boolean
}

class OrderTable extends Component<IOrderTableProps, IOrderTableState> {
  // NotificationManager: any;
  constructor(props: Readonly<IOrderTableProps>) {
    super(props)
    this.state = {
      tableData: [],
      viewOrder: false,
      viewOrderData: null,
      refetchData: false,
      loadingTable: false
    }
  }

  static getDerivedStateFromProps(nextProps: IOrderTableProps, prevState: IOrderTableState) {
    const nextState = {} as IOrderTableState

    if (prevState.tableData !== nextProps.data.data) {
      nextState.tableData = nextProps.data.data
    }

    return nextState
  }

  /*
   * ComponentDidMount = () => {
   *   this.notificationManager = FbNotificationManager.getInstance();
   *   this.notificationManager.addListener(
   *     LISTENER_TYPE.ORDER_REFRESH,
   *     message => {
   *       this.props.refetch();
   *     }
   *   );
   * };
   */

  /*
   * ComponentWillUnmount = () => {
   *   this.notificationManager.removeListener(LISTENER_TYPE.ORDER_REFRESH);
   * };
   */

  formAction = order => {
    if (order.orderStatus === ORDER_STATUS.ORDER_CREATED && order.orderType === ORDER_TYPE.MOBILE_DELIVERY) {
      return (
        <ConsoleButton type={'primary'} onClick={this.viewOrderData.bind(this, order)} disabled={order.isLoadingStatus}>
          <IntlMessages id="orderTable.viewOrder" />
        </ConsoleButton>
      )
    } else if (order.orderType === ORDER_TYPE.MOBILE_PICKUP && order.orderStatus === ORDER_STATUS.ORDER_CREATED) {
      return (
        <ConsoleButton
          type={'primary'}
          style={{
            backgroundColor: COLORS.SECONDARY,
            borderColor: COLORS.SECONDARY
          }}
          onClick={this.viewOrderData.bind(this, order)}
          disabled={order.isLoadingStatus}>
          <IntlMessages id="orderTable.viewOrder" />
        </ConsoleButton>
      )
    }

    return (
      <ConsoleButton disabled={order.isLoadingStatus} type={'default'} style={{ borderColor: COLORS.PRIMARY, color: COLORS.PRIMARY }} onClick={this.viewOrderData.bind(this, order)} ghost>
        <IntlMessages id="orderTable.viewOrder" />
      </ConsoleButton>
    )
  }

  getRATNotification = order => {
    const isLogAvailable = order.orderDelivery[0]?.externalDeliveryDetails.bpp_task_state_log && order.orderDelivery[0]?.externalDeliveryDetails.bpp_task_state_log.length

    if (isLogAvailable) {
      const lastDeliveryLog = order.orderDelivery[0]?.externalDeliveryDetails?.bpp_task_state_log
      const RATCalc = moment.duration(moment(order.createdTime).diff(moment(lastDeliveryLog[lastDeliveryLog.length - 1].time)))

      return RATCalc.asMinutes()
    }
  }

  getDeliveryPersonDetails = order => {
    /* eslint-disable no-dupe-else-if */ // TODO: remove this eslint disable 
    if (order.orderStatus === ORDER_STATUS.ORDER_CREATED || order.orderStatus === ORDER_STATUS.ORDER_REJECTED) {
      return <IntlMessages id="orderTable.notAssigned" />
    } else if (order.orderDelivery.length && order?.orderDelivery?.[0].internalDeliveryStaff) {
      const { name, phone } = order.orderDelivery[0].internalDeliveryStaff

      return {
        name,
        phone: phone.slice(2)
      }
    } else if (order.orderDelivery.length && order?.orderDelivery?.[0].externalDeliveryDetails && order?.orderDelivery?.[0].externalDeliveryDetails.bpp_task_agent) {
      const { name, phones } = order.orderDelivery[0].externalDeliveryDetails.bpp_task_agent

      if (order.orderDelivery[0].deliveryOrderStatus === ORDER_STATUS.DELIVERY_CANCELLED) {
        return <IntlMessages id="newOrder.deliveryCancelledSupportMessage" />
      }

      return {
        name: name.given_name,
        phone: phones[0]
      }
    } else if (order.orderStatus === ORDER_STATUS.ORDER_ACCEPTED) {
      return <IntlMessages id="orderTable.lookingForRider" />
    } else if (this.getRATNotification(order) > 3 && order.orderStatus === ORDER_STATUS.ORDER_CREATED) {
      return <IntlMessages id="newOrder.ratCancellationSupportMessage" />
    }

    return <IntlMessages id="orderTable.notAssigned" />
  }

  buildColumnData = orders => {
    const columnOrders = orders?.map((order) => {
      const actions = this.formAction(order)
      const deliveryDiscount = getDeliveryDiscount(order)
      const itemCount = order?.product.length

      return {
        time: moment(order?.createdTime)
          .utc()
          .format('hh:mm A'),
        assignedPerson:
          order?.orderType === ORDER_TYPE.MOBILE_PICKUP ? (
            <Text level="body-2" style={{ color: COLORS.SECONDARY }}>
              <IntlMessages id="self-pickup" />
            </Text>
          ) : (
            this.getDeliveryPersonDetails(order)
          ),
        Bill: deliveryDiscount
          ? Math.ceil(order?.totalOrderAmountNonRoundOff - deliveryDiscount)
          : Math.ceil(order?.totalOrderAmountNonRoundOff),
        customerDetails:
          order?.orderStatus !== ORDER_STATUS.ORDER_CREATED
            ? {
              name: order?.customer?.person?.firstName || null,
              phoneNumber: order?.customer?.person?.phoneNumber
                .split(':')
                .pop()
                .slice(2)
            }
            : null,
        Status: order?.orderStatus,
        Action: actions,
        orderStatus: order?.orderStatus,
        orderId: order?.orderId,
        itemCount,
        orderDelivery: order?.orderDelivery,
        orderType: order?.orderType
      }
    })

    return columnOrders
  }

  loadMoreData = async (pageNumber: number, pageSize: number) => {
    const { fetchMore, isManager } = this.props
    const userData = getUserData()
    const stores = userData ? userData.store : []

    if (isManager) {
      getAllOrdersInput(pageNumber, pageSize)['storeId'] = stores[0].id
    }
    await fetchMore({
      variables: getAllOrdersInput(pageNumber, pageSize),
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev

        return fetchMoreResult
      }
    })
  }

  closeModal = () => {
    const { viewOrderData, tableData } = this.state
    const orderIndex = tableData.findIndex(order => order.id === viewOrderData.id)

    // eslint-disable-next-line prefer-const
    let tableDataCopy = [ ...tableData ]

    tableDataCopy.splice(orderIndex, 1, { ...tableDataCopy[orderIndex], isLoadingStatus: true })

    this.props.refetch()
    this.setState({
      tableData : tableDataCopy,
      viewOrder: false
    })
  }

  onCancelClick = () => {
    this.setState({
      viewOrder: false
    })
  }

  processOrderAcceptance = async (orderId: string) => {
    const { pickUpOrder } = this.props

    try {
      await pickUpOrder({
        variables: {
          id: orderId
        }
      })
      message.success(
        intlAlertMessage({
          id: 'orders.orderMarkedForPickup',
          intl: this.props.intl
        })
      )
      this.setState({
        refetchData: true
      })
      this.setState({
        refetchData: false
      })
    } catch (error) {
      console.log(error)
    }
  }

  viewOrderData = order => {
    const orderItems = order.product
    const totalAmount = order.totalOrderAmountNonRoundOff
    const deliveryDiscountCharge = getDeliveryDiscount(order)
    const specialInstructionsNote = order?.notes.find(
      note => note.noteType === NOTE_TYPE.SPECIAL_INSTRUCTION_STORE
    )
    const viewOrderData = {
      id: order.id,
      orderId: order.orderId,
      orderItems,
      totalAmount,
      customer: order.customer,
      allLineItemsTotalPrices: order.allLineItemsTotalPrices,
      orderDeliveryCharges: order.orderDeliveryCharges,
      allLineItemsTotalTaxes: order.allLineItemsTotalTaxes,
      orderLevelTaxes: order.orderLevelTaxes,
      allLineItemsTotalCharges: order.allLineItemsTotalCharges,
      orderLevelCharges: order.orderLevelCharges,
      store: order.store,
      shippingAddress: order.shippingAddress,
      orderStatus: order.orderStatus,
      orderDelivery: order.orderDelivery,
      createdTime: order.createdTime,
      deliveryDiscountCharge,
      note: specialInstructionsNote,
      orderType: order.orderType
    }

    this.setState({
      viewOrder: true,
      viewOrderData
    })
  }

  showPreviousOrders = () => {
    const { push } = useHistory()

    push('/orderx/order-history')
  }

  tableChangeHandler = async (pagination) => {
    this.setState({ loadingTable: true })
    await this.loadMoreData(pagination.current, pagination.pageSize)
    this.setState({ loadingTable: false })
  }

  getRowClassNames = (record: any) => {
    const { tableData } = this.state
    let classes = ''

    tableData.forEach(val => {
      classes = record.orderStatus === ORDER_STATUS.ORDER_CREATED ? 'light-red-bg-color' : record.orderStatus === ORDER_STATUS.ORDER_ACCEPTED || record.orderStatus === ORDER_STATUS.ORDER_DELIVERY_FOUND || record.orderStatus === ORDER_STATUS.ORDER_SHIPPED ? 'light-yellow-bg-color' : ''
      if (record && record.orderDelivery.length && record.orderDelivery[0].externalDeliveryDetails) {
        classes += ' peppo-delivery-indicator'
      } else if (val.orderId === record.orderId && val.orderType === ORDER_TYPE.MOBILE_PICKUP) {
        classes += ' self-pickup-indicator'
      }

    })

    return classes
  }

  render() {
    const { data, error, loading, filter } = this.props
    const { viewOrder, viewOrderData, loadingTable, tableData } = this.state

    if (loading) {
      return <Loader intl={this.props.intl} />
    }
    if (error) return `Error! ${error.message}`
    let emptyText = <IntlMessages id="orderTable.waitingFor1stOrder" />

    const orders = filter ? tableData.filter(order => order.orderId.indexOf(filter) !== -1 || (order.shippingAddress && order.shippingAddress.contactNumber && order.shippingAddress.contactNumber.indexOf(filter) !== -1)) : tableData
    const columnsData = this.buildColumnData(orders)

    if (filter && columnsData.length === 0) {
      emptyText = <IntlMessages id="orderTable.searchedOrderNotFound" />
    }

    return (
      <>
        <Table
          className="orderx-table"
          rowClassName={(record: any) => {
            return this.getRowClassNames(record)
          }}
          rowKey={(r, i) => `row-${i}`}
          dataSource={columnsData}
          columns={columnHeaders}
          onChange={this.tableChangeHandler}
          locale={{ emptyText }}
          loading={loadingTable}
          pagination={{
            total: data.paginationInfo.totalItems,
            pageSize: data.paginationInfo.perPage,
            current: data.paginationInfo.page
          }}
        />
        {viewOrder && <OrderDetail viewOrder={viewOrder} viewOrderData={viewOrderData} closeModal={this.closeModal} onCancelClick={this.onCancelClick} intl={this.props.intl} key={viewOrderData?.id} />}
      </>
    )
  }
}

export default injectIntl(OrderTable)

