import { LockOutlined } from '@ant-design/icons'
import { Mutation } from '@apollo/client/react/components'
import { Button, Col, Form, Input, message, Row } from 'antd'
import { FormInstance } from 'antd/es/form'
import { IMAGES } from 'assets'
import { IntlMessages } from 'components'
import { History } from 'history'
import { decode } from 'jsonwebtoken'
import * as compose from 'lodash/flowRight'
import moment from 'moment'
import * as React from 'react'
// Import { Mutation, MutationFunc } from 'react-apollo'
import { injectIntl } from 'react-intl'
import { intlAlertMessage } from 'Utils'
import { ADMIN_PASSWORD_REGEX, COLORS } from 'Utils/constants'
import { ROUTES } from 'Utils/constants/routes'

import { RESET_PASSWORD } from '../../../services/query'
interface IProps {
  history?: History
  intl: any
}

interface IState {
  confirmDirty: boolean
  token: string
  email: string
}

class ResetPassword extends React.Component<IProps, IState> {
  formRef: any

  constructor(props) {
    super(props)
    const params = new URLSearchParams(this.props.history.location.search)
    const token = params.has('token') ? params.get('token') : ''
    let isTokenExpired = true
    let receivedEmail = ''

    if (token) {
      const { exp, email } = decode(token) as any
      const expTime = moment.unix(exp)

      isTokenExpired = expTime.isBefore(moment())
      receivedEmail = email
    }

    if (isTokenExpired) {
      this.props.history.replace({
        pathname: ROUTES.FORGOT_PASSWORD_PAGE,
        state: { isLinkExpire: true }
      })
    }
    this.state = {
      confirmDirty: false,
      token: token ? token : '',
      email: receivedEmail
    }
    this.formRef = React.createRef<FormInstance>()
  }

  goToLogin = () => {
    this.props.history.replace(ROUTES.LOGIN_PAGE)
  }

  hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field])
  }

  handleConfirmBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target

    this.setState({ confirmDirty: this.state.confirmDirty || Boolean(value) })
  }

  compareToFirstPassword = (_, value: any) => {

    if (value && value !== this.formRef.current.getFieldValue('password')) {
      return Promise.reject(new Error(intlAlertMessage({ id: 'updatepassword.errMsg.newPass&confirmPassShouldBeSame', intl: this.props.intl })))
    }

    return Promise.resolve()
  }

  validateToNextPassword = (_, value: any) => {

    if (value && !ADMIN_PASSWORD_REGEX.test(String(value))) {
      return Promise.reject(
        new Error(intlAlertMessage({
          id: 'validationForPassword',
          intl: this.props.intl
        }))
      )
    }

    return Promise.resolve()
  }

  render() {
    const { token, email } = this.state
    // Only show error after a field is touched.

    return (
      <div
        style={{ background: '#00946E 0% 0% no-repeat padding-box' }}
        className="gx-app-change-pw-wrap">
        <div className="gx-app-change-pw-container">
          <div className="peppo-white-logo gx-text-center">
            <img src={IMAGES.peppo_logo_white} alt="Peppo white logo" />
          </div>
          <div className="gx-app-change-pw-main-content">
            <div className="gx-app-change-pw-content">
              <Row style={{ marginBottom: '20px' }}>
                <h2 style={{ marginBottom: 0, color: COLORS.DARK_TEXT }}>
                  <IntlMessages id="resetYourPassword" />
                </h2>
                <div style={{ fontSize: '13px' }}>
                  {intlAlertMessage(
                    { id: 'enterNewPassword', intl: this.props.intl },
                    { email }
                  )}
                </div>
              </Row>
              <Mutation mutation={RESET_PASSWORD} fetchPolicy="no-cache">
                {(resetPassword, { loading, error, data }: any) => (
                  <Form
                    ref={this.formRef}
                    onFinish={async values => {
                      try {
                        const { password } = values
                        const data = await resetPassword({
                          variables: {
                            password,
                            token
                          }
                        })

                        if (data?.data?.resetPassword?.updated) {
                          message.success(
                            intlAlertMessage({
                              id: 'passwordUpdated',
                              intl: this.props.intl
                            })
                          )
                          this.goToLogin()
                        }
                      } catch (err) {
                        console.error('Reset password error', err)
                      }
                    }}
                    className="login-form">
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: intlAlertMessage({
                            id: 'pleaseInputYourPassword',
                            intl: this.props.intl
                          })
                        },
                        {
                          validator: this.validateToNextPassword
                        }
                      ]}
                      hasFeedback>
                      <Input
                        prefix={
                          <LockOutlined

                            style={{ color: COLORS.DARK_TEXT }}
                          />
                        }
                        type="password"
                        placeholder={intlAlertMessage({
                          id: 'newPassword',
                          intl: this.props.intl
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      name="confirm"
                      rules={[
                        {
                          required: true,
                          message: intlAlertMessage({
                            id: 'pleaseConfirmYourPassword',
                            intl: this.props.intl
                          })
                        },
                        {
                          validator: this.compareToFirstPassword
                        }
                      ]}
                      hasFeedback>
                      <Input.Password
                        prefix={
                          <LockOutlined style={{ color: COLORS.DARK_TEXT }} />
                        }
                        onBlur={this.handleConfirmBlur}
                        placeholder={intlAlertMessage({
                          id: 'confirmPassword',
                          intl: this.props.intl
                        })}
                      />
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'center' }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        className="ant-btn-blue"
                        style={{ marginTop: 0, marginBottom: 0, width: 200 }}>
                        <IntlMessages id="menu.submit" />
                      </Button>
                    </Form.Item>
                  </Form>
                )}
              </Mutation>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(ResetPassword)
