import './styles.css'

import { ApolloProviderProps } from '@apollo/client/react/context'
import { withApollo } from '@apollo/client/react/hoc'
import { Button, Col, Divider, Modal, Radio, Row } from 'antd'
import { IntlMessages } from 'components'
import React from 'react'
import { COLORS } from 'Utils/constants'

interface iProps extends ApolloProviderProps<any> {
  title: any
  isVisible: boolean
  rejectOptions: string[]
  onCancel: any
  onSave: any
  buttonText: any
}
interface iState {
  selectedReason: string
}

const RadioGroup = Radio.Group

class RejectPopup extends React.Component<iProps, iState> {
  constructor(props: iProps) {
    super(props)
    // Let selectedReason = this.props.rejectOptions && this.props.rejectOptions.length ? this.props.rejectOptions[0] : ""
    this.state = {
      selectedReason: ''
    }
  }

  onOptionChange = e => {
    this.setState({ selectedReason: e.target.value })
  }

  render() {
    const { selectedReason } = this.state
    const {
      rejectOptions,
      title,
      isVisible,
      onCancel,
      onSave,
      buttonText
    } = this.props

    return (
      <Modal
        className="reject-modal"
        width={440}
        visible={isVisible}
        title={title}
        onCancel={() => {
          onCancel()
        }}
        footer={[
          <Button
            style={{ width: '100%', fontWeight: 500 }}
            type="primary"
            danger
            key="confirmCancel"
            disabled={!selectedReason}
            onClick={() => {
              onSave(selectedReason)
            }}>
            {buttonText}
          </Button>
        ]}>
        <Row>
          <Col span={24}>
            <div
              style={{
                color: COLORS.NORMAL_TEXT,
                padding: '0 24px 0 24px',
                marginBottom: '10px'
              }}>
              <IntlMessages id="console.message.confirmationForCustomerTransparency" />
            </div>
            <Divider />
            <RadioGroup
              onChange={e => this.onOptionChange(e)}
              value={selectedReason}
              style={{ width: '100%' }}>
              {' '}
              {rejectOptions.map((option, i) => (
                <div key={i}>
                  <div
                    style={{
                      fontWeight: 500,
                      color: COLORS.DARK_TEXT,
                      padding: '0 24px 0 24px'
                    }}>
                    <Radio value={option} key={i}>
                      {option}
                    </Radio>
                  </div>

                  <Divider />
                </div>
              ))}
            </RadioGroup>
          </Col>
        </Row>
      </Modal>
    )
  }
}
export default RejectPopup
