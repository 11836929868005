import { decode } from 'jsonwebtoken'
import { getUserDetails, intlAlertMessage } from 'Utils'
import { ASSOCIATION, MINI_URL_REGEX, SUPPORTED_FILE_TYPES } from 'Utils/constants'
import animationData from 'Utils/constants/animation.json'

import { getAuthToken } from './localStorageHandlers/getter'

export const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}


export const setOnboarding = () => {
  localStorage.setItem('isOnboarding', JSON.stringify(true))
}

export const getUserData = () => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  const roles = userData ? userData.roles : []
  const stores = userData ? userData.store : []
  const catalogs = userData ? userData.catalogs : []

  return { userData, roles, stores, catalogs }
}

export const blockCopyPasteAccNumber = () => {
  setTimeout(() => {
    const accInput: HTMLElement = document.querySelector('#add-bank-account_accountNumber')
    const confirmAcInput: HTMLElement = document.querySelector('#add-bank-account_confirmAccountNumber')

    accInput.oncut = () => false
    accInput.oncopy = () => false
    accInput.onpaste = () => false
    confirmAcInput.oncut = () => false
    confirmAcInput.oncopy = () => false
    confirmAcInput.onpaste = () => false
  }, 1000)
}

export const getUploadApiConfig = () => {
  const token = getAuthToken()
  const { id, org_id } = decode(token) as any

  return { organizationId: org_id, token, userId: id }
}

export const getSupportedFileTypes = fileTypeList => {
  let fileTypes = ''

  fileTypeList.forEach((type, i) => {
    if (i !== fileTypeList.length - 1 && i !== fileTypeList.length - 2) {
      fileTypes += `${type}, `
    } else if (i === fileTypeList.length - 2) {
      fileTypes += type
    } else {
      fileTypes += ` and ${type}`
    }
  })

  return fileTypes
}

export const isGpayDone = (associations: Array<any>) => {
  return Boolean(getGpayDetails(associations)?.merchantId)
}

export const getGpayDetails = (associations: Array<any>) => {
  if (!associations) return
  const spotObj = associations.find(association => association.orgId === ASSOCIATION.SPOT && association.type === ASSOCIATION.CHANNEL)

  return spotObj?.terms
}

export const getContact = (contacts: Array<any>) => {
  if (!contacts) return
  const contactObj = contacts.find(contact => contact.type === 'OFFICE')

  return contactObj
}

export const isBankAccountAlreadySaved = (savedBankDetails, newBankDetails) => {
  return savedBankDetails.accountNumber === newBankDetails.bank_account_number && savedBankDetails.beneficiaryName === newBankDetails.beneficiaryName && savedBankDetails.ifscCode === newBankDetails.ifsc_code && savedBankDetails.name === newBankDetails.name
}

export const updateGpayDetailsForm = organization => {
  const { name, contact } = organization
  const gpayDetails = getGpayDetails(organization.associations)
  const { gstNumber, legalBusinessName, panNumber, vpa } = gpayDetails
  const orgContact = getContact(contact)
  const userDetails = getUserDetails()
  const storeDetails = userDetails && userDetails.store && userDetails.store[0]
  const inputValue = {
    aggregator_type: 'SPOT_PARTNER',
    merchant_relationships: {
      merchant_relationships: [
        {
          merchant_external_id: 'BCR2DN6TTPOMVT3L'
        }
      ]
    },
    merchant_name: {
      display_name: name,
      registered_name: legalBusinessName
    },
    merchant_address: {
      address_data: {
        region_code: 'IN',
        recipient: legalBusinessName,
        address_line: [storeDetails?.addressLine1, storeDetails?.addressLine2],
        locality: storeDetails?.city,
        administrative_area: storeDetails?.state,
        postal_code: storeDetails?.pinCode
      }
    },
    merchant_contact: {
      phone: orgContact ? `+${orgContact.phone}` : '',
      website_url: 'https://peppo.co.in/',
      support_url: 'https://peppo.co.in/',
      support_email: 'help@pepp.co.in',
      support_phone: orgContact ? `+${orgContact.phone}` : ''
    },
    merchant_vpa: {
      vpa_info: [
        {
          vpa
        }
      ]
    },
    merchant_category: {
      mcc: '5812',
      category: 'Eating Places and Restaurants'
    },
    merchant_tax_info: {
      pan: panNumber,
      gstin: gstNumber
    }
  }

  document.getElementById('displayName')['value'] = inputValue.merchant_name.display_name
  document.getElementById('legalName')['value'] = inputValue.merchant_name.registered_name

  // Same as legalName
  document.getElementById('legalName')['value'] = inputValue.merchant_address.address_data.recipient
  document.getElementById('addressline1')['value'] = inputValue.merchant_address.address_data.address_line[0]
  document.getElementById('addressline2')['value'] = inputValue.merchant_address.address_data.address_line[1]
  document.getElementById('locality')['value'] = inputValue.merchant_address.address_data.locality
  document.getElementById('admin_area')['value'] = inputValue.merchant_address.address_data.administrative_area
  document.getElementById('postal_code')['value'] = inputValue.merchant_address.address_data.postal_code

  document.getElementById('phone')['value'] = inputValue.merchant_contact.phone

  // Use restaurant phone
  document.getElementById('phone')['value'] = inputValue.merchant_contact.support_phone

  document.getElementById('vpa')['value'] = inputValue.merchant_vpa.vpa_info[0].vpa

  document.getElementById('pan')['value'] = inputValue.merchant_tax_info.pan
  document.getElementById('gstin')['value'] = inputValue.merchant_tax_info.gstin

  console.log(inputValue)
  document.getElementById('prefilled_info')['value'] = JSON.stringify(inputValue)
}

export const openCreateWindowWithPost = history => {
  const inputValue = {
    aggregator_type: 'SPOT_PARTNER',
    merchant_relationships: {
      merchant_relationships: [
        {
          merchant_external_id: 'BCR2DN6TTPOMVT3L'
        }
      ]
    },
    merchant_name: {
      display_name: 'public name/display name',
      registered_name: 'legal business name'
    },
    merchant_address: {
      address_data: {
        region_code: 'IN',
        recipient: 'Google India',
        address_line: ['4th Floor, Tower E, RMZ Infinity, No. 3', 'Swami Vivekananda Rd, Sadanandanagar, Bennigana Halli'],
        locality: 'Bengaluru',
        administrative_area: 'Karnataka',
        postal_code: '560016'
      }
    },
    merchant_contact: {
      phone: '+91 80672 18000',
      website_url: 'https://peppo.co.in/',
      support_url: 'https://peppo.co.in/',
      support_email: 'help@pepp.co.in',
      support_phone: '+91 80672 18000'
    },
    merchant_vpa: {
      vpa_info: [
        {
          vpa: 'myvpainfo@hdfc'
        }
      ]
    },
    merchant_category: {
      mcc: '5812',
      category: 'Eating Places and Restaurants'
    },
    merchant_tax_info: {
      pan: 'ABCDE1234F',
      gstin: '12ABCDE1234F1ZD'
    }
  }
  const f = document.getElementById('TheFormCreate') as HTMLFormElement

  inputValue.merchant_name.display_name = document.getElementById('displayName')['value']
  inputValue.merchant_name.registered_name = document.getElementById('legalName')['value']

  // Same as legalName
  inputValue.merchant_address.address_data.recipient = document.getElementById('legalName')['value']
  inputValue.merchant_address.address_data.address_line = [document.getElementById('addressline1')['value'], document.getElementById('addressline2')['value']]
  inputValue.merchant_address.address_data.locality = document.getElementById('locality')['value']
  inputValue.merchant_address.address_data.administrative_area = document.getElementById('admin_area')['value']
  inputValue.merchant_address.address_data.postal_code = document.getElementById('postal_code')['value']

  inputValue.merchant_contact.phone = document.getElementById('phone')['value']

  // Use restaurant phone
  inputValue.merchant_contact.support_phone = document.getElementById('phone')['value']

  inputValue.merchant_vpa.vpa_info[0].vpa = document.getElementById('vpa')['value']
  inputValue.merchant_tax_info.pan = document.getElementById('pan')['value']
  inputValue.merchant_tax_info.gstin = document.getElementById('gstin')['value']

  console.log(inputValue)
  document.getElementById('prefilled_info')['value'] = JSON.stringify(inputValue)

  window.open('', 'TheWindow')
  f.submit()
  history.replace({
    pathname: '/orderx/onboarding/gpay-merchant'
  })
}

export const MiniUrlValidationRules = [
  { label: 'startWithAlphabets', isValid: false, id: 2 },
  { label: 'spaceNotAllowed', isValid: false, id: 1 },
  { label: 'specialCharactersNotAllowed', isValid: false, id: 3 },
  { label: 'minThreeCharacters', isValid: false, id: 4 },
  { label: 'maxTwelveCharacters', isValid: false, id: 5 }
]

export const miniUrlValidation = val => {
  const temp = val

  if (temp.length >= 3) {
    MiniUrlValidationRules.forEach(val => {
      if (val.id === 4) val.isValid = true
    })
  } else {
    MiniUrlValidationRules.forEach(val => {
      if (val.id === 4) val.isValid = false
    })
  }
  if (temp.length <= 12 && temp.length >= 3) {
    MiniUrlValidationRules.forEach(val => {
      if (val.id === 5) val.isValid = true
    })
  } else if (temp.length > 12) {
    MiniUrlValidationRules.filter(val => (val.id === 5 ? (val.isValid = false) : val.isValid))
  }
  if (val.length > 0 && isNaN(temp[0])) {
    MiniUrlValidationRules.forEach(val => {
      if (val.id === 2) val.isValid = true
    })
  } else if (!isNaN(temp[0])) {
    MiniUrlValidationRules.forEach(val => {
      if (val.id === 2) val.isValid = false
    })
  }
  if (MINI_URL_REGEX.test(temp)) {
    MiniUrlValidationRules.forEach(val => {
      if (val.id === 3) val.isValid = true
    })
  } else if (!MINI_URL_REGEX.test(temp)) {
    MiniUrlValidationRules.forEach(val => {
      if (val.id === 3) val.isValid = false
    })
  }
}

export const isSupportedFileType = (file) => {
  const fileTypeList = SUPPORTED_FILE_TYPES
  const ext = file.name
    .split('.')
    .pop()
    .toLowerCase()

  if (fileTypeList && fileTypeList.indexOf(ext) !== -1) {
    return false
  }

}