import { Button, Modal } from 'antd'
import { IntlMessages } from 'components'

const ConfirmChangesModal = ({ showConfirmModal, onCancel, onLeaveBtnAction, onAction }) => {
  return (
    <Modal
    visible={showConfirmModal}
    key="item-form-modal"
    title={<IntlMessages id="category.confirmationMsg.leaveThisPage" />}
    onCancel={onCancel}
    footer={[
      <div key="footer">
        <Button
            key="stay"
            type="default"
            size="large"
            onClick={onLeaveBtnAction}>
          <IntlMessages id="stay" />
        </Button>
        <Button
            key="leave"
            type="primary"
            danger
            size="large"
            onClick={onAction}>
          <IntlMessages id="leave" />
        </Button>
      </div>
    ]}>
      <div>
        <IntlMessages id="category.infoMsg.changesMayNotSave" />.
      </div>
    </Modal>
  )
}

export default ConfirmChangesModal