import { UserOutlined } from '@ant-design/icons'
import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { graphql } from '@apollo/client/react/hoc'
import { Form, Input } from 'antd'
import { IMAGES } from 'assets'
import { Button, IntlMessages, Switch } from 'components'
import { History } from 'history'
import * as compose from 'lodash/flowRight'
import React from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { intlAlertMessage } from 'Utils'
import { ROUTES } from 'Utils/constants/routes'
import { COLORS } from 'Utils/constants/styles'

import { SIGN_IN_CLIENT } from '../../../services/query'
import useSignIn from './useSignIn'

interface IProps {
  history?: History
  routeQuery?: any
  intl: any
}

const NormalLoginForm = (props: IProps) => {
  const { isRememberMeChecked, setIsRememberMeChecked, handleSubmit } = useSignIn(props)

  return (
    <div style={{ background: COLORS.PRIMARY }} className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={IMAGES.login} alt="Delivery_Boy" />
              {/* https://via.placeholder.com/272x395 */}
            </div>
            <div className="gx-app-logo-wid"></div>
          </div>
          <div className="gx-app-login-content">
            <div className="gx-app-logo">
              <img
                alt="example"
                src={IMAGES.peppo_logo_transparent}
                style={{
                  width: 100,
                  marginLeft: '-21px',
                  marginBottom: '10px'
                }}
              />
            </div>
            <Mutation mutation={SIGN_IN_CLIENT} fetchPolicy="no-cache">
              {(signIn: any, { loading, error, data }: any) => (
                <Form
                  // OnSubmit is throwing error. so using onSubmitCapture
                  onSubmitCapture={e => handleSubmit(e, signIn)}
                  className="login-form">
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="signIn.typeUsername" />
                      }
                    ]}>
                    <Input
                      prefix={
                        <UserOutlined style={{ color: COLORS.DARK_TEXT }} />
                      }
                      placeholder={intlAlertMessage({
                        id: 'usernameOrEmail',
                        intl: props.intl
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: <IntlMessages id="signIn.typePassword" />
                      }
                    ]}>
                    <Input.Password
                      prefix={
                        <UserOutlined style={{ color: COLORS.DARK_TEXT }} />
                      }
                      placeholder={intlAlertMessage({
                        id: 'appModule.password',
                        intl: props.intl
                      })}
                    />
                  </Form.Item>
                  <Form.Item name="remember">
                    <Switch variant='toggle1' checked={isRememberMeChecked} onClick={() => setIsRememberMeChecked(!isRememberMeChecked)} />
                    <span style={{ paddingLeft: 5 }}>
                      <IntlMessages id="rememberMe" />
                    </span>
                    <Link className="link-text" to={ROUTES.FORGOT_PASSWORD_PAGE}>
                      <IntlMessages id="app.userAuth.forgotPassword" />?
                    </Link>
                    <br />
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        style={{
                          marginTop: 10,
                          width: '200px',
                        }}
                      >
                        <IntlMessages id="login" />
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              )}
            </Mutation>
          </div>
        </div>
      </div>
    </div>
  )

}

const GET_REDIRECT_ROUTE = gql`
  query redirectRoute {
    redirectRoute @client
  }
`

export default injectIntl(
  compose(
    graphql(GET_REDIRECT_ROUTE, {
      name: 'routeQuery'
    })
  )(NormalLoginForm)
)
