import {
  Button,
  Col,
  Input,
  InputNumber,
  Modal,
  Table,
} from 'antd'
import { Button as PeppoButton, IntlMessages, Switch, Title } from 'components'
import { orderxClient } from 'index'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import * as storeService from 'services/storeService'
import { setErrMsg } from 'Utils'
import { COLORS, PHONE_NUMBER_REGEX, ROLE_TYPE, STATUS } from 'Utils/constants'
import { setIsUnsavedStoreDetails } from 'Utils/localStorageHandlers/setter'
import { columnHeaders, formatMobileNumber, getTenDigitNumber } from 'Utils/storeUtils'

export default function ManageDeliveryAgent({ client, agents, setAgents }) {
  const intl = useIntl()
  const [showAddEditAgentModal, setShowAddEditAgentModal] = useState(false)
  const [showDeleteAgentModal, setShowDeleteAgentModal] = useState(false)
  const [agentDetails, setAgentDetails] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)

  function toggleAddEditAgentModal() {
    setShowAddEditAgentModal(!showAddEditAgentModal)
  }

  function toggleDeleteAgentModal() {
    setShowDeleteAgentModal(!showDeleteAgentModal)
  }

  function onAddEditAgentClick(index = null, isDelete = false) {
    let currentAgentDetails = {
      id: '',
      staffRole: ROLE_TYPE.DELIVERY,
      name: '',
      phoneNumber: '',
      status: STATUS.ACTIVE,
      index,
      isNewAgent: true
    }

    if (index !== null) {
      const { id, staff_role, name, phone, status } = agents[index]

      currentAgentDetails = {
        ...currentAgentDetails,
        id,
        staffRole: staff_role,
        name,
        phoneNumber: getTenDigitNumber(phone),
        status,
        index,
        isNewAgent: false
      }
    }
    setAgentDetails(currentAgentDetails)
    isDelete ? toggleDeleteAgentModal() : toggleAddEditAgentModal()
  }

  function setAgentDetailsByPropertyName({ property, value }) {
    setAgentDetails({ ...agentDetails, [property]: value })
  }

  async function handleAddUpdateAgent() {
    const { id, staffRole: staff_role, name, phoneNumber: phone, status, index } = agentDetails

    if (name === '' || phone === '') {
      setErrMsg('category.errMsg.fillAllTheFields', intl)

      return
    } else if (!PHONE_NUMBER_REGEX.test(phone)) {
      setErrMsg('store.validMobileNum', intl)

      return
    }
    const updatedAgentDetails = {
      id,
      staff_role,
      name,
      phone: formatMobileNumber(`${phone}`),
      status,
      index
    }

    let updatedListOfAgents

    if (index !== null) {
      const editStaffInput = {
        id,
        name,
        phone: updatedAgentDetails.phone
      }

      id && await storeService.editStaff(orderxClient, editStaffInput)
      updatedListOfAgents = agents.map((agent, agentIndex) => {
        if (agentIndex === index) return updatedAgentDetails

        return agent
      })
    }
    else {
      updatedListOfAgents = agents.concat(updatedAgentDetails)
      setIsUnsavedStoreDetails(true)
    }
    setAgents(updatedListOfAgents)
    toggleAddEditAgentModal()
  }

  const handleDeleteAgent = async () => {
    const editStaffInput = {
      id: agentDetails.id,
      enabled: false,
    }

    try {
      await storeService.editStaff(orderxClient, editStaffInput)
      const updatedListOfAgents = agents.filter(agent => agent.id !== agentDetails.id)

      setAgents(updatedListOfAgents)
      toggleDeleteAgentModal()
    }
    catch (err) {
      console.log('Error deleting delivery agent', err)
    }
  }

  async function handleAgentStatusChange(isActive, agentIndex) {
    const deliveryAgentStatusChangeInput = {
      id: agents[agentIndex].id
    }

    try {
      if (deliveryAgentStatusChangeInput.id) {
        isActive
          ? await storeService.activeDeliveryAgent(client, deliveryAgentStatusChangeInput)
          : await storeService.inactiveStaffMember(client, deliveryAgentStatusChangeInput)
      }
      const updatedListOfAgents = agents.map((agent, index) => {
        if (agentIndex === index) return { ...agent, status: isActive ? STATUS.ACTIVE : STATUS.INACTIVE }

        return agent
      })

      setAgents(updatedListOfAgents)
    } catch (error) {
      console.log(error)
    }
  }

  const deleteButtonFormAction = (index) => {
    return (
      <PeppoButton
        type="link"
        style={{ color: COLORS.DARK_TEXT }}
        onClick={() => onAddEditAgentClick(index, true)}>
        <IntlMessages id="button.delete" />
      </PeppoButton>
    )
  }

  const editButtonFormAction = editIndex => {
    return (
      <PeppoButton
        type="link"
        onClick={() => onAddEditAgentClick(editIndex)}>
        <IntlMessages id="store.edit" />
      </PeppoButton>
    )
  }

  const deliveryAgentStatusAction = (status, index) => {
    const isActive = status === STATUS.ACTIVE

    return (
      <Switch
        checked={isActive}
        onChange={status => {
          handleAgentStatusChange(status, index)
        }}
      />
    )
  }

  const buildDeliveryAgentColumnData = () => {

    const columnOrders = agents.map((agent, index) => {

      const editButtonAction = editButtonFormAction(index)
      const deleteButtonAction = deleteButtonFormAction(index)
      const activeStatusAction = deliveryAgentStatusAction(agent.status, index)

      return {
        deleteIndex: index,
        name: agent.name,
        phone: agent.phone,
        activeStatusAction,
        editButtonAction,
        deleteButtonAction,
      }
    })

    return columnOrders
  }
  const columnsData = buildDeliveryAgentColumnData()


  return (
    <>
      {columnsData && columnsData.length > 0 && (
        <div className={'deliveryAgentTableWrapper'}>
          <Table
            className="orderx-table"
            rowKey={(record, index) => `agent-row-${record.phone}`}
            dataSource={columnsData}
            columns={columnHeaders}
            pagination={false}
            scroll={{
              x: 0,
              y: 208
            }}
          />
        </div>
      )}
      <PeppoButton type='secondary' onClick={() => onAddEditAgentClick()}>
        <IntlMessages id="store.addAgentTitle" />
      </PeppoButton>

      <Modal
        visible={showAddEditAgentModal}
        title={
          <Title level='h4'>
            {agentDetails.isNewAgent ? <IntlMessages id="store.addNewAgents" /> : <IntlMessages id="store.updateAgents" />}
          </Title>
        }
        footer={null}
        onCancel={toggleAddEditAgentModal}
        >
        <div className="InputLabel">
          <IntlMessages id="appModule.name" />
        </div>
        <Input
          size="large"
          placeholder={intl.formatMessage({ id: 'store.enterAgentName' })}
          value={agentDetails.name}
          style={{ border: `1px solid ${COLORS.BORDER}` }}
          onChange={e => {
            setAgentDetailsByPropertyName({ property: 'name', value: e.target.value })
          }}
        />
        <div className="InputLabel" style={{ marginTop: '12px' }}>
          <IntlMessages id="customer.phoneNumber" />
        </div>
        <InputNumber
          addonBefore="+91"
          size="large"
          controls={false}
          value={agentDetails?.phoneNumber}
          placeholder={intl.formatMessage({ id: 'store.enterAgentsPhoneNumber' })}
          style={{
            borderRadius: '5px',
            width: '100%'
          }}
          maxLength={10}
          onChange={value => {
            setAgentDetailsByPropertyName({ property: 'phoneNumber', value })
          }}
        />
        <Col>
          <PeppoButton
            loading={isLoading}
            type='primary'
            style={{ margin: '15px 0 0 0', padding: '0 30px' }}
            onClick={handleAddUpdateAgent}>
            {agentDetails.isNewAgent ? <IntlMessages id="store.addAgent" /> : <IntlMessages id="store.updateAgent" />}
          </PeppoButton>
        </Col>
      </Modal>

      <Modal
        visible={showDeleteAgentModal}
        title={
          <IntlMessages id="store.confirmationMsg.areYouSureYouWantToDelete" />
        }
        onCancel={toggleDeleteAgentModal}
        footer={[
          <Button
            key="rejectDelete"
            onClick={toggleDeleteAgentModal}>
            <IntlMessages id="newOrder.Cancel" />
          </Button>,
          <Button
            type="primary"
            danger
            key="confirmDelete"
            onClick={handleDeleteAgent}>
            <IntlMessages id="button.delete" />
          </Button>
        ]}></Modal>
    </>
  )
}