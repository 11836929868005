const Colors = {
  Primary: '#2E5CE6',
  'P-Hover': '#244FD1',
  'P-Disabled': '#B1BBD9',
  Secondary: '#CCD9FF',
  'S-Hover': '#B8C9FB',
  'S-Disabled': '#E7EBF5',
  Icon: '#95A1B1',
  'Sidebar-Bg': '#000638',
  'Dark-Text': '#1A1A1A',
  'Normal-Text': '#808080',
  'Light-Text': '#B3B3B3',
  Border: '#D9D9D9',
  Bg: '#F5F5F7',
  'Light-Bg': '#FAFAFA',
  'Bg-White': '#FFFFFF',
  Success: '#3ECE85',
  Alert: '##FFBE3D',
  Error: '#FF5D7D'
}

export default Colors
