import { Avatar as AntAvatar } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { AVATAR_BACKGROUND_COLORS, COLORS } from 'Utils/constants'

export interface AvatarProps {
  /**
   * Apply custom css
   */
  style?: object
  label?: string
}


const Color = AVATAR_BACKGROUND_COLORS[Math.floor(Math.random() * AVATAR_BACKGROUND_COLORS.length)]
const StyledAvatar = styled(AntAvatar)`
  &.ant-avatar {
    font-size: 14px;
    background-color: ${Color};
    color: ${COLORS.BG_WHITE};
  }
`

/**
 * Peppo Avatar component
 */
export const Avatar: React.FC<AvatarProps> = ({ ...props }) => {
  const label = props?.label.trim().split(' ')
  const username = label[0][0] + (label.length > 1 ? label[label.length - 1][0] : '')

  return <StyledAvatar {...props}>{username}</StyledAvatar>
}
