import { Query } from '@apollo/client/react/components'
import { Select } from 'antd'
import { IntlMessages } from 'components'
import React from 'react'
import { GET_STORE_STAFF } from 'services/query'
import { COLORS } from 'Utils/constants'

const { Option } = Select

const StoreStaff = props => {
  const { viewOrderData, hideActions, isAccept, isOrderHistory, selectedDeliveryStaff } = props

  return (
    <Query
      query={GET_STORE_STAFF}
      variables={{
        storeId: viewOrderData.store.id,
        pageOptions: { page: 1, pageSize: 100 }
      }}
      fetchPolicy="cache-and-network">
      {({ loading, error, data }: any) => {
        if (loading) {
          return (
            <div>
              <IntlMessages id="newOrder.loadingDeliveryStaff" />
              ...
            </div>
          )
        } else if (error) {
          console.log(error)

          return (
            <div>
              <IntlMessages id="newOrder.errMassages.errorFetching" />
            </div>
          )
        } else if (data) {
          const totalData = data.getStaffMembers.data
          const deliveryStaff = []

          totalData.forEach(element => {
            if (element.status === 'ACTIVE' && element.staffRole === 'DELIVERY') {
              deliveryStaff.push(element)
            }
          })

          return (
            <div style={{ marginBottom: '20px' }}>
              <div
                style={{
                  fontSize: '11px',
                  color: COLORS.DARK_TEXT,
                  marginBottom: '5px'
                }}>
                {' '}
                <IntlMessages id="newOrder.chooseDeliveryPerson" />
              </div>

              <Select
                style={{ width: '100%' }}
                onChange={e => props.handleDeliverystaff(e)}
                value={selectedDeliveryStaff}
                disabled={hideActions || isAccept || isOrderHistory}>
                {deliveryStaff.map((element, index) => {
                  return (
                    <Option key={index} value={element.id}>
                      {element.name}
                    </Option>
                  )
                })}
              </Select>
            </div>
          )
        }

        return (
          <span>
            <IntlMessages id="newOrder.announcement.deliveryStaffNotPresent" />
          </span>
        )
      }}
    </Query>
  )
}

export default StoreStaff
