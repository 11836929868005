import '../../style.css'

import { ArrowLeftOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { Input, Tooltip } from 'antd'
import { ClientManager } from 'appApollo/client'
import { IMAGES } from 'assets'
import { Button, Card, IntlMessages, Text, Title } from 'components'
import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { getBlackListNames } from 'request'
import { checkURLAvailability, updatePMSOrganization } from 'services'
import { getOrgDetails, intlAlertMessage, isAdmin, setOrgDetails } from 'Utils'
import {
  COLORS,
  ENVIRONMENT,
  ORG_STATUS,
  PWA_DOMAIN_NAME
} from 'Utils/constants'
import {
  miniUrlValidation,
  MiniUrlValidationRules
} from 'Utils/onboardingUtils'

import { TooltipComponent } from '../Tooltip'
import * as Styles from './styles'

const MiniUrlPage = props => {
  const pmsClient = ClientManager.getInstance().pms
  const organization = getOrgDetails()
  const history = useHistory()
  const [isURLAvailable, setIsURLAvailable] = useState(false)
  const [isURLUsed, setIsURLUsed] = useState(false)
  const [savingData, setSavingData] = useState(false)
  const [checkingAvailability, setCheckingAvailability] = useState(false)
  const [isValidURL, setIsValidURL] = useState(false)
  const [isError, setIsError] = useState(false)
  const [miniUrl, setMiniUrl] = useState('')
  const [blackListKeywords, setBlackListKeywords] = useState([])

  useEffect(() => {
    getBlackListKeyNames()

    if (
      !isAdmin() ||
      (isAdmin() &&
        organization &&
        organization.onboardingStatus !== ORG_STATUS.INITIATED)
    ) {
      history.push({ pathname: '/orderx/orders' })
    }
  })

  const getBlackListKeyNames = async () => {
    const data = await getBlackListNames()

    setBlackListKeywords(data.Sheet1)
  }

  const onChangeHandler = e => {
    setMiniUrl(e.target.value?.trim().toLowerCase() || '')
    setIsURLAvailable(false)
    setIsURLUsed(false)
    setIsError(false)

    if (!e.target.value.trim()) {
      MiniUrlValidationRules.forEach(val => (val.isValid = false))
    } else {
      MiniUrlValidationRules.forEach(val => {
        if (val.id === 1) val.isValid = true
      })
    }
    miniUrlValidation(e.target.value.trim())
    isValidName(e.target.value.trim().toLowerCase())
  }

  const isValidName = val => {
    let isValidURL = false

    blackListKeywords.forEach(keyword => {
      const isValid = val.includes(keyword.name.toLowerCase().trim())

      if (isValid) {
        isValidURL = true
      }
    })
    setIsValidURL(isValidURL)
    setIsError(false)
  }

  const goBackToOnboarding = () => {
    localStorage.removeItem('isOnboarding')
    history.push({
      pathname: '/orderx/onboarding',
      state: {
        isOnboarding: false,
        fromStoreOrCatalog: true
      }
    })
  }

  const checkAvailability = async () => {
    try {
      setCheckingAvailability(true)
      const res = await checkURLAvailability(pmsClient, miniUrl)

      setIsURLAvailable(Boolean(res?.data?.isCodeUnique?.success))
      setIsURLUsed(!res?.data?.isCodeUnique?.success)
      setCheckingAvailability(false)
      setIsError(false)
    } catch (error) {
      setIsURLAvailable(false)
      setIsURLUsed(false)
    }
  }

  const saveMiniUrl = async () => {
    const { id } = getOrgDetails()

    setSavingData(true)
    try {
      const saveMiniUrlInput = {
        id,
        code: miniUrl
      }
      const updateRes = await updatePMSOrganization(pmsClient, saveMiniUrlInput)

      if (updateRes?.data?.updateOrganization) {
        setOrgDetails(updateRes.data.updateOrganization.organization)
        goBackToOnboarding()
      }
      setSavingData(false)
    } catch (err) {
      setSavingData(false)
      console.log('Save MiniApp Url  error', err)
    }
  }

  const errMsg = () => {
    if (isValidURL && !isError) {
      setIsError(true)
    }
  }

  const isValid = MiniUrlValidationRules.filter(val => val.isValid === false)

  if (isValid) {
    errMsg()
  }
  const env = process.env.REACT_APP_SENTRY_ENV

  return (
    <Styles.Container>
      <Styles.Header>
        <Styles.BackButton onClick={() => goBackToOnboarding()}>
          <ArrowLeftOutlined
            style={{
              fontSize: '15px',
              fontWeight: 'bold',
              color: COLORS.NORMAL_TEXT,
              padding: '0px 3% 0 1%'
            }}
          />
          <Title level="h2">
            <IntlMessages id="onboarding.createMini" />
          </Title>
        </Styles.BackButton>
      </Styles.Header>
      <Styles.CardBlock>
        <Card>
          <Title level="h5">
            <IntlMessages id="onboarding.createUniqueUrl" />
          </Title>
          <Text level={'body-2'}>
            <IntlMessages id="onboarding.createMiniInfo" />
          </Text>
          <Styles.LinkBoxStyle>
            <Text level={'body-2'}>
              <IntlMessages id="https://" />
            </Text>
            <Styles.InputBox>
              <Tooltip
                placement={'bottomLeft'}
                title={<TooltipComponent intl={props.intl} />}
                overlayClassName="custom-tooltip">
                <Input
                  placeholder={intlAlertMessage({
                    id: 'tamak',
                    intl: props.intl
                  })}
                  onChange={e => onChangeHandler(e)}
                  value={miniUrl}
                />
                {isError && (
                  <Text level="caption" style={{ color: COLORS.ERROR }}>
                    <IntlMessages id="onbaording.miniUrl.errMsg" />
                  </Text>
                )}
              </Tooltip>
            </Styles.InputBox>
            <Text level={'body-2'}>{PWA_DOMAIN_NAME[env]}</Text>
            <Styles.BtnStyle>
              <Button
                loading={checkingAvailability}
                ghost={false}
                onClick={() => checkAvailability()}
                disabled={!(isValid.length === 0 && !isValidURL)}
                size="default"
                type="secondary"
                style={{ marginBottom: 0 }}>
                <IntlMessages id="onboarding.checkAvailability" />
              </Button>
            </Styles.BtnStyle>
          </Styles.LinkBoxStyle>
          {isURLAvailable && (
            <Text level={'overline'} style={{ color: COLORS.SUCCESS }}>
              <IntlMessages id="onboarding.availableURL" />
            </Text>
          )}
          {isURLUsed && (
            <div>
              <Text level={'overline'} style={{ color: COLORS.ERROR_DARK }}>
                <IntlMessages id="sorry" />, {miniUrl}{' '}
              </Text>
              <Text level={'overline'} style={{ color: COLORS.ERROR_DARK }}>
                <IntlMessages id="onboarding.usedURL" />
              </Text>
            </div>
          )}
          <div>
            <ExclamationCircleFilled
              style={{
                color: COLORS.ALERT,
                paddingRight: '8px',
                paddingTop: '5px'
              }}
            />
            <Text level={'caption'}>
              <IntlMessages id="onboarding.nameCannotChangeLater" />
            </Text>
          </div>
        </Card>
        <Styles.SubmitButtonStyle>
          <Button
            ghost={false}
            disabled={!isURLAvailable}
            onClick={() => {
              saveMiniUrl()
            }}
            size="default"
            type="primary"
            loading={savingData}>
            <IntlMessages id="button.submit" />
          </Button>
        </Styles.SubmitButtonStyle>
      </Styles.CardBlock>
      <Styles.ImageBlock>
        <img src={IMAGES.sample_restaurant_img} alt="Pwa app" />
      </Styles.ImageBlock>
    </Styles.Container>
  )
}

export default injectIntl(MiniUrlPage)
