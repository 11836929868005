import { Form, Input } from 'antd'
import { FormInstance } from 'antd/es/form'
import { Button, IntlMessages, Text } from 'components'
import * as React from 'react'
import styled from 'styled-components'
import { intlAlertMessage } from 'Utils'
import { COLORS } from 'Utils/constants'

interface MenuLinkFormProps {
  onSubmit: any
  processing: boolean
  intl: any
  processingError: boolean
  onLinkChange: any
  disable: boolean
  onMenuSave?: any
}

interface MenuLinkFormState {}

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .ant-row {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
`
const FormContainer = styled.div`
  margin-top: 20px;
  .ant-form-explain {
    color: ${COLORS.ERROR};
    font-family: Roboto;
    font-weight: normal;
  }
`

class MenuLinkForm extends React.Component<
  MenuLinkFormProps,
  MenuLinkFormState
> {
  formRef: any

  constructor(props) {
    super(props)
    this.formRef = React.createRef<FormInstance>()
  }

  render() {
    const { disable, processing, processingError } = this.props

    return (
      <FormContainer>
        <Form ref={this.formRef} onFinish={this.props.onSubmit}>
          <Text level="body-2">
            <IntlMessages id="onboarding.menuLinkCard.text.menuLink" />
          </Text>
          <StyledRow>
            <div style={{ flexGrow: 16 }}>
              <Form.Item
                name="url"
                rules={[
                  {
                    required: true,
                    message: (
                      <IntlMessages id="error.onboarding.menuLinkCard.url.required" />
                    )
                  },
                  {
                    type: 'url',
                    message: (
                      <IntlMessages id="error.onboarding.menuLinkCard.url.invalid" />
                    )
                  }
                ]}>
                <Input
                  placeholder={intlAlertMessage({
                    id: 'onboarding.menuLinkCard.text.placeholder',
                    intl: this.props.intl
                  })}
                  onChange={() => this.props.onLinkChange()}
                  disabled={disable}
                />
              </Form.Item>
              {processingError && (
                <Text level="caption" style={{ color: COLORS.ERROR }}>
                  <IntlMessages id="error.onboarding.menuScrappingError" />
                </Text>
              )}
            </div>

            <div style={{ flexGrow: 3, textAlign: 'right' }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginBottom: 0, width: 100.38, marginTop: 3 }}
                loading={processing}>
                <IntlMessages id={'button.save'} />{' '}
              </Button>
            </div>
          </StyledRow>
        </Form>
      </FormContainer>
    )
  }
}

export default MenuLinkForm
