import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Dropdown as antdDropdown, Menu, Typography } from 'antd'
import { IntlMessages } from 'components/complex'
import React, { ReactElement, useState } from 'react'
import styled from 'styled-components'
import { COLORS } from 'Utils/constants'

export interface DropdownProps {
  /**
   * Apply custom css
   */
  style?: object

  data?: any

  label?: ReactElement

  type?: 'withTitle' | 'withoutTitle'

  size: 'default' | 'small'

  isButtonAvailable?: boolean
  buttonLabel?: string
  onButtonClick?: any
  onChangeItem?: any
  selectedItem: string
  isDisabled?: boolean
}

const StyledDropdown = styled(antdDropdown)``
const StyledInputBox = styled.div`
  border: 1px solid ${COLORS.BORDER};
  padding: 5px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${COLORS.DARK_TEXT};
  border-radius: 5px;
  font: normal normal normal 14px/19px Roboto;
  letter-spacing: 0px;
  cursor: pointer;
  &:hover {
    border-color: ${COLORS.BORDER_DARK};
  }
  &.box-default {
    width: 303px;
    height: 48px;
  }
  &.box-small {
    width: 291px;
    height: 36px;
  }
`

const LabelStyle = styled(Typography)`
  text-align: left;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0px;
  color: #00000099;
  opacity: 1;
`
const MenuStyle = styled(Menu)`
  &.ant-dropdown-menu-item-active {
    background-color: ${COLORS.ERROR};
    &:hover {
      background-color: ${COLORS.BG_SHADE};
    }
  }
`

/**
 * Peppo Dropdown component
 */
export const Dropdown: React.FC<DropdownProps> = ({
  type,
  data,
  label,
  size,
  isButtonAvailable,
  buttonLabel,
  onButtonClick,
  selectedItem,
  ...props
}) => {
  const [isOpen, setOpen] = useState(false)
  const menu = (
    <MenuStyle>
      {data?.map(item => (
        <MenuStyle.Item
          key={item.code}
          onClick={() => {
            setOpen(!isOpen)
            props.onChangeItem(item)
          }}>
          {item.name}
        </MenuStyle.Item>
      ))}
      {isButtonAvailable ? (
        <MenuStyle.Item
          onClick={
            onButtonClick
              ? () => {
                onButtonClick()
              }
              : null
          }
          style={{ color: COLORS.PRIMARY }}>
          {buttonLabel || ''}
        </MenuStyle.Item>
      ) : null}
    </MenuStyle>
  )

  return (
    <div>
      {type === 'withTitle' ? <LabelStyle>{label}</LabelStyle> : null}
      <StyledDropdown
        disabled={props.isDisabled}
        overlay={menu}
        onVisibleChange={() => setOpen(!isOpen)}
        visible={isOpen}>
        <StyledInputBox
          className={`box-${size}`}
          onClick={e => e.preventDefault()}
          style={
            props.isDisabled
              ? {
                color: COLORS.DISABLED,
                backgroundColor: COLORS.DISABLED,
                cursor: 'not-allowed'
              }
              : {}
          }>
          <span>
            {selectedItem && selectedItem['name']
              ? selectedItem['name']
              : <IntlMessages id="profile.chooseFromTheList" />}
          </span>
          {isOpen ? (
            <UpOutlined style={{ fontSize: '13px' }} />
          ) : (
            <DownOutlined style={{ fontSize: '13px' }} />
          )}
        </StyledInputBox>
      </StyledDropdown>
    </div>
  )
}
