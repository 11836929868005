import { Spin } from 'antd'
import { IntlMessages } from 'components'
import React from 'react'
import { COLORS } from 'Utils/constants'

import { MenuStyle } from '../../../Utils/menuUtils/styleConstants'

const Menu = props => (
  <MenuStyle style={{ height: '50vh', overflow: 'scroll' }}>
    <MenuStyle.Item
      style={{ color: COLORS.PRIMARY }}
      onClick={() => {
        props.onMenuItemClickHandler()
      }}>
      <IntlMessages id="button.+createNewGroup" />
    </MenuStyle.Item>
    {props.isCatagoriesLoading ? (
      <MenuStyle.Item>
        <div className="divCenter" style={{ marginTop: '5px' }}>
          <Spin size="default" />
        </div>
      </MenuStyle.Item>
    ) : null}
    {props.customizationGroupData?.map((item, index) => (
      <MenuStyle.Item
        key={index}
        onClick={() => {
          props.getProducts(item, false)
        }}>
        {item.name}
      </MenuStyle.Item>
    ))}
  </MenuStyle>
)

export default Menu
