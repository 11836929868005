import { COLORS } from './colors'

/* eslint-disable */
export const ORDER_STATUS = {
  ORDER_CREATED: 'ORDER_CREATED',
  ORDER_ACCEPTED: 'ORDER_ACCEPTED',
  ORDER_DELIVERY_FOUND: 'ORDER_DELIVERY_FOUND',
  ORDER_SHIPPED: 'ORDER_SHIPPED',
  ORDER_CANCELLED: 'ORDER_CANCELLED',
  ORDER_REJECTED: 'ORDER_REJECTED',
  ORDER_DELIVERED: 'ORDER_DELIVERED',
  ORDER_COMPLETE: 'ORDER_COMPLETE',
  EN_ROUTE_TO_DROP: 'EN-ROUTE-TO-DROP',
  ASSIGNED_AGENT: 'ASSIGNED-AGENT',
  SEARCHING_FOR_FMD_AGENT: 'SEARCHING-FOR-FMD-AGENT',
  AT_PICKUP_LOCATION: 'AT-PICKUP-LOCATION',
  DELIVERY_CANCELLED: 'CANCELLED',
  ORDER_READY: 'ORDER_READY',
  ORDER_PICKED: 'ORDER_PICKED'
}

export const CONSOLE_ORDER_STATUS = {
  ORDER_CREATED: 'New',
  ORDER_ACCEPTED: 'Preparing',
  ORDER_DELIVERY_FOUND: 'Preparing',
  ORDER_SHIPPED: 'Delivery In Progress',
  ORDER_CANCELLED: 'Cancelled',
  ORDER_REJECTED: 'Rejected',
  ORDER_DELIVERED: 'Delivered',
  ORDER_COMPLETE: 'Completed',
  ORDER_READY: 'Ready For Pickup',
  ORDER_PICKED: 'Delivered'
}

export const CONSOLE_STORE_STATUS = {
  ACTIVE: 'OPEN',
  INACTIVE: 'CLOSE',
}

export const ORDER_HISTORY_STATUS = {
  ORDER_DELIVERED: 'ORDER_DELIVERED',
  ORDER_DELIVERY_FOUND: 'ORDER_DELIVERY_FOUND',
  ORDER_ACCEPTED: 'ORDER_ACCEPTED',
  ORDER_COMPLETE: 'ORDER_COMPLETE',
}

export const CONSOLE_MENU_CATEGORY_STATUS = {
  ACTIVE: 'YES',
  INACTIVE: 'NO',
}

export const NOTE_TYPE = {
  SPECIAL_INSTRUCTION_STORE: "SPECIAL_INSTRUCTION_STORE"
}

export const CONSOLE_RIDER_BUSY_STATUS = {
  ACTIVE: 'YES',
  INACTIVE: 'NO',
}

export enum STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
}

export const ORG_STATUS = {
  ORDER_READY: 'ORDER-READY',
  INITIATED: 'INITIATED',
  ACTIVE: 'ACTIVE',
}

export const ASSOCIATION = {
  CHANNEL: 'CHANNEL',
  SPOT: 'SPOT',
}

export const ONBOARDING_ROUTES = [
  '/orderx/onboarding',
  '/orderx/onboarding/create-mini-url',
  '/orderx/onboarding/gpay-registration',
  '/orderx/onboarding/gpay-merchant',
  '/orderx/store',
  '/orderx/menu-list',
]

export const LISTENER_TYPE = {
  NEW_ORDER: 'NEW_ORDER',
  ORDER_REFRESH: 'ORDER_REFRESH',
  MENU_UPLOAD_UPDATE: 'MENU_UPLOAD_UPDATE',
}

export const NOTIFICATION_TYPE = {
  MENU_UPLOAD_UPDATE: 'menu_upload_update',
  NEW_ORDER: 'NEW_ORDER',
  ORDER_UPDATE: 'ORDER_UPDATE',
}

export const ENVIRONMENT = {
  DEVELOPMENT: 'development',
  MASTER: 'master',
  PRODUCTION: 'production',
}

export const ORG_CONSTANT = {
  STOREFORMAT: 'Default',
  CATALOG: 'Default',
  CHARGE: 'packaging',
  STRIKEOUT_DISCOUNT_CODE: 'strikeout_discount',
  STRIKEOUT_DISCOUNT_NAME: 'Strikeout Discount',
}

export enum DISCOUNT_VALUE_TYPE_ENUM {
  ABSOLUTE = 'ABSOLUTE',
  PERCENTAGE = 'PERCENTAGE',
}

export const WINDOW_EVENTS = {
  BEFORE_UNLOAD: 'beforeunload',
}

export const ACTIONS = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
}

export const PRICE_TYPE = {
  DEAL: 'DEAL',
  BASE: 'BASE',
  PACKAGING_CHARGE: 'PACKAGING_CHARGE'
}

export enum PRODUCT_TYPE_ENUM {
  REGULAR = 'REGULAR',
  VARIANT = 'VARIANT',
  FIXED_BUNDLE = 'FIXED_BUNDLE',
  DYNAMIC_BUNDLE = 'DYNAMIC_BUNDLE',
  PRODUCT = 'PRODUCT',
  ADDON = 'ADDON',
  COMBO = 'COMBO',
  PRODUCT_ALTERNATIVES = 'PRODUCT_ALTERNATIVES',
}

export enum PRODUCT_RELATIONSHIP_TYPE_ENUM {
  PRODUCT_VARIANT = 'PRODUCT_VARIANT',
  PRODUCT_ADDONS = 'PRODUCT_ADDONS',
  PRODUCT_COMBO = 'PRODUCT_COMBO',
}

export enum PRODUCT_TAG_ENUM {
  VEG = 'veg',
  NON_VEG = 'non-veg',
  MUST_TRY = 'must_try'
}
export enum TAG_TYPE_ENUM {
  PROPERTY = 'PROPERTY',
  INFO = 'INFO'
}

export enum PRODUCT_VARIANT_TYPE_ENUM {
  NEW = 'NEW',
  OLD = 'OLD',
}

export const MENU_TABS = {
  PRODUCTS: 'products',
  CATERGORIES: 'categories', //TODO: remove after Refine code merged
  CATEGORIES: 'categories',
  ADDONS: 'addons',
  CUSTOMIZATIONS: 'customizations',
  MENU_TIMINGS: 'menu-timings',
}

export const ORDER_TYPE = {
  MOBILE_DELIVERY: "MOBILE_DELIVERY",
  MOBILE_PICKUP: "MOBILE_PICKUP"
}

export const ORDER_HISTORY_TABS = {
  SELF_PICKUP: 'self-pickup',
  DELIVERY: 'delivery'
}

export const DAYS_ENUM = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const WEEK_DAYS_SORTER = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
}

export const DEFAULT_TIME_SLOT = {
  OPEN: '0001',
  CLOSE: '2359',
}

export const PWA_DOMAIN_NAME ={
  [ENVIRONMENT.PRODUCTION]: ".online-order.link",
  [ENVIRONMENT.DEVELOPMENT]: ".refinex.in",
  [ENVIRONMENT.MASTER]: ".getwalkin.in",
}
export const DATE_FORMAT = 'DD MMM YYYY'
export const YEAR_TO_DATE_FORMAT = 'YYYY-MM-DD'
export const HOUR_TO_MINUTE_TIME_FORMAT = 'hh:mm A'
export const HH_MM_FORMAT = 'HHmm'
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss'
export const DEFAULT_START_DATE = '1970-01-01'

export const START_TIME = { hours: 0, minutes: 0, seconds: 0 }
export const END_TIME = { hours: 23, minutes: 59, seconds: 59 }

export const MAX_ITEMS_PER_PAGE = 10
export const DEFAULT_PAGE_NUMBER = 1

export const MAX_ITEMS_PER_PAGE_ORDER_HISTORY = 50
export const MAX_MUST_TRY_TAG_COUNT = 5
export const DUMMY_MENU_SHARING_ID = "x456123b"

export const SUPPORTED_FILE_TYPES = ['xls', 'csv', 'xlsx']

export const USER_NAME = {
  ADMIN: 'Admin',
  STORE_MANAGER: 'Store Manager'
}

export const ROLE_TYPE = {
  ADMIN: 'ADMIN',
  STORE_MANAGER: 'STORE_MANAGER',
  MANAGER: 'MANAGER',
  DELIVERY: 'DELIVERY'
}
export const FILE_SYSTEM_TYPES = {
  CLOUDINARY: "CLOUDINARY"
  }


export const AVATAR_BACKGROUND_COLORS = [
  COLORS.ERROR_DARK,
  COLORS.PRIMARY,
  COLORS.SUCCESS,
  COLORS.SECONDARY,
  COLORS.PRIMARY_SHADE
]

export const RUPEE = '₹'

export const PROMOTION_MS_ENDPOINTS = {
  REDEMPTIONS: '/organization/v1/redemptions'
}

export const POS_API_ENDPOINTS = {
  GET_POS_CONFIGURATION: '/get-pos-configuration',
  GET_ORGANIZATIONS: '/get-organizations',
  CREATE_POS_CONFIGURATION: '/create-pos-configuration',
  UPDATE_POS_CONFIGURATION: '/update-pos-configuration'
}

export enum PROMOTIONS_API_STATUS {
  ALL_OK = 'ALL_OK'
}

export const PROMOTIONS_ERROR_CODE = {
  NOT_AUTHORIZED: 'SYS0009'
}

export enum LOGO_TYPE {
  PEPPO = 'PEPPO',
  PEPPO_BMS = 'PEPPO_BMS'
}

export enum VIEW_TYPES {
  PEPPO_ONLY = 'PEPPO_ONLY',
  BMS_DEALS_ONLY = 'BMS_DEALS_ONLY',
  PEPPO_BMS = 'PEPPO_BMS'
}

export enum POS_PROVIDERS {
  URBANPIPER = 'URBANPIPER',
  PETPOOJA = 'PETPOOJA'
}

export enum CHANNELS {
  PEPPO_PWA = 'PEPPO_PWA',
  SPOT = 'SPOT',
  BOT = 'BOT',
}

export enum CATEGORY_FILTER {
  ALL = 'ALL',
  LISTABLE = 'LISTABLE',
  NOT_LISTABLE = 'NOT_LISTABLE',
}

export enum REDEMPTION_STATUS {
  PENDING = 'PENDING',
  REDEEMED = 'REDEEMED',
  FAILED = 'FAILED',
  EXPIRED = 'EXPIRED'
}

export enum POS_STATUS {
  IN_PROGRESS = 'IN_PROGRESS',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export * from './regex'
export * from './langConstant'
export * from './urls'
export * from './config'
export * from './colors'
export * from './themeSetting'
