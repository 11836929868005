import { gql } from '@apollo/client'

export const GET_RESTAURANTS_ORDERS = gql`
         # Write your query or mutation here
         query GET_RESTAURANTS_ORDERS(
           $customerId: ID
           $storeId: ID
           $organizationId: ID!
           $statusCode: [Int]
           $orderStatus: [String]
           $dateStart: DateTime
           $dateEnd: DateTime
           $pageOptions: PageOptions
           $sortOptions: SortOptions
         ) {
           orders(
             storeId: $storeId
             organizationId: $organizationId
             statusCode: $statusCode
             orderStatus: $orderStatus
             dateStart: $dateStart
             dateEnd: $dateEnd
             pageOptions: $pageOptions
             sortOptions: $sortOptions
             customerId: $customerId
           ) {
             data {
               id
               orderId
               totalOrderAmount
               totalOrderAmountNonRoundOff
               orderDeliveryCharges
               allLineItemsTotalTaxes
               allLineItemsTotalAmount
               allLineItemsTotalPrices
               orderLevelTaxes
               allLineItemsTotalCharges
               orderLevelCharges
               customer {
                 id
                 firstName
                 lastName
                 person {
                   firstName
                   lastName
                   phoneNumber
                   gender
                 }
               }
               store {
                 id
                 name
               }
               channel {
                 id
                 name
               }
               orderDelivery {
                 id
                 internalDeliveryStaff {
                   id
                   name
                   phone
                 }
                 externalDeliveryDetails
                 deliveryPartner {
                   id
                   name
                 }
                 deliveryType {
                   id
                   name
                 }
                 deliveryStatus
                 deliveryOrderStatus
               }

               orderStatus
               orderType
               product {
                 quantity
                 product {
                   id
                   code
                   name
                 }
                 lineTotalAmount
                 lineTotalCharge
                 lineTotalTax
                 lineTotalProductPrices
                 productPrice
                 children {
                   quantity
                   lineTotalAmount
                   lineTotalCharge
                   lineTotalTax
                   lineTotalProductPrices
                   productPrice
                   productDiscountPrice
                   lineItemDiscountValue
                   product {
                     id
                     code
                     name
                     productType
                   }
                 }
               }
               notes {
                 id
                 note
                 noteType
               }
               shippingAddress {
                 id
                 name
                 addressLine1
                 addressLine2
                 city
                 state
                 country
                 contactNumber
                 addressTitle
                 addressType
                 geoLocation
                 zip
               }
               createdTime
               orderPayments {
                 paymentType {
                   name
                   code
                 }
                 externalOrderDetails
               }
             }
             paginationInfo {
               totalPages
               totalItems
               page
               perPage
               hasNextPage
               hasPreviousPage
             }
           }
         }
       `

export const SIGN_IN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      jwt
    }
  }
`

export const SIGN_IN_CLIENT = gql`
  mutation signIn($input: SignInInput!) {
    signIn(input: $input) @client
  }
`

export const CHANGE_PASSWORD = gql`
  mutation updatePassword($oldPassword: String, $newPassword: String) {
    updatePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      updated
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      updated
    }
  }
`

export const PROCESS_ORDER_ACCEPTANCE = gql`
  mutation processStoreOrderAcceptance(
    $id: ID!
    $deliveryStaffId: ID
    $deliveryTypeCode: DELIVERY_TYPE
    $deliveryPartnerCode: String
  ) {
    processStoreOrderAcceptance(
      id: $id
      deliveryStaffId: $deliveryStaffId
      deliveryTypeCode: $deliveryTypeCode
      deliveryPartnerCode: $deliveryPartnerCode
    ) {
      id
      customer {
        id
        firstName
        lastName
      }
    }
  }
`
export const ORDER_READY = gql`
  mutation orderReady(
    $id: ID!
  ) {
    orderReady(
      id: $id
    ) {
      id
      customer {
        id
        firstName
        lastName
      }
    }
  }
`
export const ORDER_PICKED = gql`
  mutation orderPicked(
    $id: ID!
  ) {
    orderPicked(
      id: $id
    ) {
      id
      customer {
        id
        firstName
        lastName
      }
    }
  }
`
export const PROCESS_ORDER_STORE_REJECT = gql`
  mutation processStoreOrderReject($id: ID!, $note: String) {
    processStoreOrderReject(id: $id, note: $note) {
      id
      customer {
        id
        firstName
        lastName
      }
      store {
        id
      }
    }
  }
`

export const PROCESS_ORDER_CANCEL = gql`
  mutation cancelOrder($id: ID!, $note: OrderNoteInput) {
    cancelOrder(id: $id, note: $note) {
      id
      customer {
        id
        firstName
        lastName
      }
    }
  }
`

export const PROCESS_DELIVERY_ACCEPTANCE = gql`
  mutation processDeliveryAcceptance($id: ID!) {
    processDeliveryAcceptance(id: $id) {
      id
      customer {
        id
        firstName
        lastName
      }
    }
  }
`

export const DELIVER_ORDER = gql`
  mutation deliverOrder($id: ID!) {
    deliverOrder(id: $id) {
      id
      customer {
        id
        firstName
        lastName
      }
    }
  }
`

export const SHIPPED_ORDER = gql`
  mutation shipOrder($id: ID!) {
    shipOrder(id: $id) {
      id
      customer {
        id
        firstName
        lastName
      }
    }
  }
`

export const GET_ORDER_HISTORY = gql`
         query(
           $organizationId: ID!
           $storeId: ID
           $orderStatus: [String]
           $dateStart: DateTime
           $dateEnd: DateTime
           $pageOptions: PageOptions
         ) {
           orders(
             storeId: $storeId
             organizationId: $organizationId
             orderStatus: $orderStatus
             dateStart: $dateStart
             dateEnd: $dateEnd
             pageOptions: $pageOptions
           ) {
             data {
               id
               orderId
               totalOrderAmount
               totalOrderAmountNonRoundOff
               orderDeliveryCharges
               allLineItemsTotalTaxes
               allLineItemsTotalAmount
               allLineItemsTotalPrices
               orderLevelTaxes
               allLineItemsTotalCharges
               orderLevelCharges
               customer {
                 id
                 firstName
                 lastName
                 person {
                   firstName
                   lastName
                   phoneNumber
                   gender
                 }
               }
               store {
                 id
                 name
               }
               channel {
                 id
                 name
               }
               orderDelivery {
                 id
                 internalDeliveryStaff {
                   id
                   name
                   phone
                 }
                 externalDeliveryDetails
                 deliveryPartner {
                   id
                   name
                 }
                 deliveryType {
                   id
                   name
                 }
                 deliveryStatus
               }
               orderStatus
               orderType
               product {
                 quantity
                 product {
                   id
                   code
                   name
                 }
                 lineTotalAmount
                 lineTotalCharge
                 lineTotalTax
                 lineTotalProductPrices
                 productPrice
                 children {
                   quantity
                   lineTotalAmount
                   lineTotalCharge
                   lineTotalTax
                   lineTotalProductPrices
                   productPrice
                   productDiscountPrice
                   lineItemDiscountValue
                   product {
                     id
                     code
                     name
                     productType
                   }
                 }
               }
               notes {
                 id
                 note
                 noteType
               }
               shippingAddress {
                 id
                 name
                 addressLine1
                 addressLine2
                 city
                 state
                 country
                 contactNumber
                 addressTitle
                 addressType
                 geoLocation
                 zip
               }
               createdTime
               orderPayments {
                 paymentType {
                   name
                   code
                 }
                 externalOrderDetails
               }
             }
             paginationInfo {
               totalPages
               totalItems
               page
               perPage
               hasNextPage
               hasPreviousPage
             }
           }
         }
       `

export const GET_TAX_VALUES = gql`
  query productTaxTypeValues(
    $filter: ProductTaxTypeValueFilter
    $pageOptions: PageOptions
  ) {
    productTaxTypeValues(filter: $filter, pageOptions: $pageOptions) {
      data {
        id
        taxValue
        taxLevel {
          id
          name
          taxTypeCode
        }
        product {
          id
        }
      }
    }
  }
`

export const GET_CHARGE_VALUES = gql`
  query productChargeValues(
    $filter: ProductValueFilter
    $pageOptions: PageOptions!
  ) {
    productChargeValues(filter: $filter, pageOptions: $pageOptions) {
      data {
        product {
          id
        }
        id
        chargeValue
        channel {
          id
          name
        }
        storeFormat {
          id
        }
      }
    }
  }
`
export const GET_NEW_ORDERS = gql`
  subscription onOrderCreated($orgId: String!){
    orderCreated (orgId: $orgId)  {
      id
      orderId
      orderStatus
    }
  }
`

export const GET_ORDER_COUNT = gql`
  query GET_ORDER_COUNT(
    $storeId: ID
    $organizationId: ID!
    $statusCode: [Int]
    $orderStatus: [String]
    $dateStart: DateTime
    $dateEnd: DateTime
    $pageOptions: PageOptions
    $sortOptions: SortOptions
  ) {
    orders(
      storeId: $storeId
      organizationId: $organizationId
      statusCode: $statusCode
      orderStatus: $orderStatus
      dateStart: $dateStart
      dateEnd: $dateEnd
      pageOptions: $pageOptions
      sortOptions: $sortOptions
    ) {
      data {
        id
        product {
          quantity
          product {
            id
            name
          }
        }
        totalOrderAmount
      }
      paginationInfo {
        totalItems
      }
    }
  }
`

export const FILESYSTEMS = gql`
  query($orgId: ID!, $status: String) {
    fileSystems(organizationId: $orgId, status: $status) {
      data {
        id
        name
        description
        fileSystemType
        status
        enabled
        accessType
      }
    }
  }
`

export const GET_STORE_STAFF = gql`
  query(
    $storeId: ID!
    $pageOptions: PageOptions!
    $staffRole: ENUM_STAFF_ROLE
  ) {
    getStaffMembers(
      storeId: $storeId
      pageOptions: $pageOptions
      staffRole: $staffRole
    ) {
      data {
        id
        name
        phone
        staffRole
        status
      }
    }
  }
`

export const USER_DATA = gql`
  query userData($id: ID!, $organizationId: String!) {
    user(id: $id, organizationId: $organizationId) {
      firstName
      lastName
      email
      id
      resetPassword
      # permissionMap(types:[UI])
      roles {
        id
        name
        # policies { id effect permission resource type }
      }
      organization {
        id
        name
        store {
          id
          name
        }
        bankAccount {
          id
          name
          accountNumber
          beneficiaryName
          ifscCode
          accountType
        }
      }
      store {
        id
        name
        enable
        code
        storeFormats {
          id
          name
          storeFormatCode
          status
        }
        channels {
          id
          name
          channelCode
        }
        staff {
          staffRole
          status
        }
        addressLine1
        addressLine2
        city
        state
        pinCode
      }
    }
  }
`

export const GET_CHANNEL = gql`
  query channels {
    channels {
      data {
        id
        name
        channelCode
        organization {
          id
        }
        chargeTypes {
          id
          name
          chargeTypeCode
        }
      }
    }
  }
`

export const GET_STORE_FORMAT = gql`
  query storeFormats {
    storeFormats(status: ACTIVE) {
      data {
        id
        name
        storeFormatCode
        taxTypes {
          id
          name
          taxTypeCode
          description
          status
        }
      }
    }
  }
`

export const GET_TAGS = gql`
  query getTags($filter: TagsFilter) {
    getTags(filter: $filter) {
      data {
        id
        tagName
        code
        active
      }
    }
  }
`

export const GET_DELIVERY_AREA = gql`
  query getStoreDeliverArea($storeId: ID!) {
    getStoreDeliverArea(storeId: $storeId) {
      id
      area
      pincode
      areaType
    }
  }
`

export const GET_BANK_DETAILS = gql`
  query($filter: BankAccountsFilter) {
    getBankAccounts(filter: $filter) {
      data {
        id
        name
        phone
        email
        accountNumber
        ifscCode
        accountType
        verified
      }
    }
  }
`

export const PROCESS_STORE_ENABLE = gql`
  mutation processStoreEnable($input: EnableStoreInput) {
    enableStore(input: $input) {
      id
      name
      email
      enable
      addressLine1
      addressLine2
    }
  }
`

export const PROCESS_STORE_DISABLE = gql`
  mutation processStoreDisable($input: DisableStoreInput) {
    disableStore(input: $input) {
      id
      name
      enable
      email
      addressLine1
      addressLine2
    }
  }
`

export const GET_PARTNERS = gql`
  query getPartners($filter: PartnersFilterInput) {
    getPartners(filter: $filter) {
      id
      name
      partnerType
      code
    }
  }
`

export const GET_STORE_CHARGE = gql`
  query getStoreCharges($id: ID) {
    getStoreCharges(storeId: $id) {
      data {
        id
        chargeType
        chargeValue
        chargeValueType
      }
    }
  }
`

export const GET_CHARGE_TYPES = gql`
  query chargeTypes {
    chargeTypes(input: { chargeTypeCode: [] }) {
      id
      name
      chargeTypeCode
    }
  }
`

export const GET_TAX_TYPES = gql`
  query taxTypes($organizationId: ID) {
    taxTypes(organizationId: $organizationId) {
      data {
        id
        name
        taxTypeCode
      }
    }
  }
`

export const CREATE_CHARGE_TYPE = gql`
  mutation createChargeType($input: ChargeTypeCreateInput) {
    createChargeType(input: $input) {
      id
      name
      chargeTypeCode
    }
  }
`

export const CREATE_TAX_TYPE = gql`
  mutation createTaxType($input: TaxTypeInput) {
    createTaxType(input: $input) {
      id
      name
      taxTypeCode
    }
  }
`

export const ADD_TAX_FOR_CATALOG = gql`
  mutation createProductTaxValueForCatalog(
    $input: ProductTaxTypeValueForCatalogInput
  ) {
    createProductTaxValueForCatalog(input: $input) {
      id
    }
  }
`

export const ADD_CHARGE_FOR_CATALOG = gql`
  mutation createProductChargeValueForCatalog(
    $input: ProductChargeValueForCatalogInput
  ) {
    createProductChargeValueForCatalog(input: $input) {
      id
    }
  }
`

export const UPDATE_TAX_FOR_CATALOG = gql`
  mutation updateProductTaxValueForCatalog(
    $input: ProductTaxTypeValueUpdateForCatalogInput
  ) {
    updateProductTaxValueForCatalog(input: $input) {
      id
    }
  }
`

export const UPDATE_CHARGE_FOR_CATALOG = gql`
  mutation updateProductChargeForCatalog(
    $input: ProductChargeValueUpdateForCatalogInput
  ) {
    updateProductChargeForCatalog(input: $input) {
      id
      chargeType {
        id
        name
        chargeTypeCode
      }
      product {
        id
        name
      }
      chargeValue
    }
  }
`

export const REMOVE_TAX_FOR_FORMAT_CHANNEL = gql`
  mutation removeProductTaxValues($input: RemoveProductTaxValues) {
    removeProductTaxValues(input: $input) {
      id
    }
  }
`

export const REMOVE_CHARGE_FOR_FORMAT_CHANNEL = gql`
  mutation removeProductChargeValues($input: RemoveProductChargeValues) {
    removeProductChargeValues(input: $input) {
      id
    }
  }
`

export const PRODUCT_AVAILABILITY_CHECK = gql`
  mutation storeProductAvailablity($input: productAvailablityInput!) {
    storeProductAvailablity(input: $input) {
      product {
        id
        code
        name
        description
        imageUrl
        listable
      }
      inventoryAvailable
    }
  }
`

export const STORE_PRODUCTS_AVAILABILITY = gql`
  mutation storeProductsAvailablity($input: storeProductsInput!) {
    storeProductsAvailablity(input: $input) {
      product {
        id
        code
        name
        description
        imageUrl
        listable
      }
      inventoryAvailable
    }
  }
`

export const GET_ORG_INFO = gql`
  query organization($id: ID!) {
    organization(id: $id) {
      id
      name
      addressLine1
      addressLine2
      city
      state
      pinCode
      country
      status
      phoneNumber
      businessType
      brandLogo
      bankAccount {
        id
        name
        beneficiaryName
        ifscCode
        accountNumber
        accountType
      }
    }
  }
`

export const UPDATE_ORG = gql`
  mutation updateOrganization($organization: UpdateOrganizationInput!) {
    updateOrganization(organization: $organization) {
      id
      name
      addressLine1
      addressLine2
      city
      state
      pinCode
      country
      status
      phoneNumber
      businessType
      brandLogo
      bankAccount {
        id
        name
        beneficiaryName
        ifscCode
        accountNumber
        accountType
      }
    }
  }
`

export const GET_LEGAL_ORG_DOC = gql`
  query getLegalOrganizationDocuments($organizationId: ID!) {
    getLegalOrganizationDocuments(organizationId: $organizationId) {
      id
      legalDocumentValue
      legalDocumentInfo
      legalDocumentUrl
      legalDocumentType
    }
  }
`

export const MAKE_All_STORE_STAFF_BUSY_STATUS = gql`
  mutation makeAllStoreStaffBusyStatus($input: UpdateStoreStaffStatus) {
    makeAllStoreStaffBusyStatus(input: $input) {
      id
      name
      busy
    }
  }
`

export const GET_STORE_STAFF_BUSY_STATUS = gql`
  query getStoreStaffBusyStatus($input: StoreStaffStatus) {
    getStoreStaffBusyStatus(input: $input)
  }
`

export const SEND_PASSWORD_RESET_LINK = gql`
  mutation sendPasswordResetLink($email: String) {
    sendPasswordResetLink(email: $email) {
      sentLink
    }
  }
`

/*
 * Export const ADD_DEVICE_INFO = gql`
 *   mutation createUpdateDeviceInfo($input: UserDeviceInput) {
 *     createUpdateDeviceInfo(input: $input) {
 *       fcmToken
 *       deviceId
 *       os
 *       osVersion
 *       user {
 *         email
 *         firstName
 *       }
 *     }
 *   }
 * `;
 */

export * from './addons'
export * from './menu'
export * from './menuTimings'
export * from './store'
