import { gql } from '@apollo/client'
export const GET_OPTIONS = gql`
  query options {
    options {
      id
      name
      optionValues {
        id
        value
      }
    }
  }
`

export const CREATE_OPTION = gql`
  mutation createOption($input: OptionInput) {
    createOption(input: $input) {
      id
      name
    }
  }
`

export const CREATE_OPTION_VALUE = gql`
  mutation createOptionValue($input: OptionValueInput) {
    createOptionValue(input: $input) {
      id
      value
      option {
        id
        name
      }
    }
  }
`
export const UPDATE_OPTION_VALUE = gql`
  mutation updateOptionValue($input: UpdateOptionValueInput) {
    updateOptionValue(input: $input) {
      id
      value
      option {
        id
        name
      }
    }
  }
`

export const CREATE_PRODUCT_RELATIONSHIPS = gql`
  mutation createProductRelationships($input: [ProductRelationshipInput]) {
    createProductRelationships(input: $input) {
      id
      parent {
        id
        name
      }
      child {
        id
        name
      }
      parentType
      childType
      relationship
      config
    }
  }
`

export const UPDATE_PRODUCT_RELATIONSHIPS = gql`
  mutation updateProductRelationShips(
    $input: [ProductRelationshipUpdateInput]
  ) {
    updateProductRelationShips(input: $input) {
      id
      parent {
        id
        name
      }
      child {
        id
        name
      }
      parentType
      childType
      relationship
      config
    }
  }
`

export const REMOVE_PRODUCT_RELATIONSHIPS = gql`
  mutation removeProductRelationShip($input: [RemoveRelationShipInput]) {
    removeProductRelationShip(input: $input) {
      id
      parent {
        id
        name
      }
      child {
        id
        name
      }
      parentType
      childType
      relationship
      config
    }
  }
`
