import { Button, Input, message } from 'antd'
import {
  Button as PeppoButton,
  Card,
  IntlMessages,
  Text,
  Title
} from 'components'
import POS_IMAGES from 'pages/stores/assests/image'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { getViewType } from 'Utils'
import { COLORS, GEOLOCATION_REGEX, POS_STATUS, VIEW_TYPES } from 'Utils/constants'
import { FONTWEIGHT } from 'Utils/constants/styles'
import { setIsUnsavedStoreDetails } from 'Utils/localStorageHandlers/setter'
import { getCityStateCountryZip } from 'Utils/storeUtils'

import { CardBlock } from '../styles'
import StoreLocation from './storeLocation'

export default function BasicInfo({ useStoreData }) {
  const history = useHistory()
  const [storeData, setStoreData] = useStoreData()
  const intl = useIntl()
  const [storeName, setStoreName] = useState('')
  const posConfigurationDetail = storeData().posConfigurationDetails
  const [storeLocation, setStoreLocation] = useState({
    searchedLocation: '',
    userInputLocation: '',
    storeGeoLocation: '',
    address_components: []
  })

  const [showLocationModal, setShowLocationModal] = useState(false)

  function toggleLocationPopup() {
    setShowLocationModal(!showLocationModal)
  }

  const handleNameChange = e => {
    setStoreName(e.target.value)
    setIsUnsavedStoreDetails(true)
  }

  function onLocationChange(location) {
    const {
      storeLocation: storeGeoLocation,
      searchedLocationText: searchedLocation,
      streetAddressText: userInputLocation,
      address_components
    } = location

    setStoreLocation({
      searchedLocation,
      userInputLocation,
      storeGeoLocation,
      address_components
    })
    setIsUnsavedStoreDetails(true)
    toggleLocationPopup()
  }

  const getSearchedLocation = latLng => {
    let searchedLocation = ''
    const geoCoder = new google.maps.Geocoder()

    geoCoder.geocode(
      {
        location: latLng
      },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          searchedLocation = results[0]?.formatted_address
        }
      }
    )

    return searchedLocation
  }

  useEffect(() => {
    if (storeData()?.store) {
      const store = storeData().store

      setStoreName(store.name)
      let center
      let searchedLocation
      const { geoLocation, addressLine2, addressLine1 } = store

      if (geoLocation) {
        const [latLng1, latLng2, lat, lng] = geoLocation.match(
          GEOLOCATION_REGEX
        )

        if (lat && lng) center = { lat: Number(lat), lng: Number(lng) }

        if (!addressLine2) {
          const newLatLng = new google.maps.LatLng(center.lat, center.lng)

          searchedLocation = getSearchedLocation(newLatLng)
        }
      }
      setStoreLocation({
        ...storeLocation,
        searchedLocation: addressLine2 || searchedLocation,
        storeGeoLocation: center,
        userInputLocation: addressLine1
      })
    }
  }, [])

  useEffect(() => {
    if (storeName || storeLocation.userInputLocation) {
      const {
        storeGeoLocation,
        searchedLocation,
        userInputLocation,
        address_components
      } = storeLocation
      const address = getCityStateCountryZip(
        address_components,
        searchedLocation
      )
      const prevStore = storeData()?.store || {}
      const store = {
        ...prevStore,
        name: storeName,
        geoLocation: storeGeoLocation,
        addressLine2: searchedLocation,
        addressLine1: userInputLocation,
        ...address
      }

      setStoreData({ store })
    }
  }, [storeName, storeLocation])

  const viewType = getViewType()

  const IsBMSView = viewType === VIEW_TYPES.BMS_DEALS_ONLY

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '12px 0',
          width: '100%'
        }}>
        <Card style={{ width: !IsBMSView && posConfigurationDetail ? '70%' : '100%' }}>
          <Title level="h4" style={{ marginBottom: '24px' }}>
            <IntlMessages id="profile.basicInfo" />
          </Title>
          <CardBlock className="agentsInputWrapper">
            <Title level="h5">
              <IntlMessages id="store.enterYourStore’sName" />
            </Title>
            <Input
              placeholder={intl.formatMessage({ id: 'appModule.name' })}
              style={{ border: `1px solid ${COLORS.BORDER}`, width: '60%' }}
              value={storeName}
              onChange={handleNameChange}
            />
            <div style={{ width: '4%' }} />
          </CardBlock>
          <CardBlock>
            <Title level="h5">
              <IntlMessages id="store.enterYourStore’sLocation" />
            </Title>
            {storeLocation.searchedLocation &&
            storeLocation.userInputLocation ? (
              <>
                <Title
                  level="h5"
                  style={{
                    width: '60%',
                    marginTop: 0
                  }}>
                  {storeLocation.userInputLocation}
                  {','} {storeLocation.searchedLocation}
                </Title>
                <PeppoButton
                  type="link"
                  onClick={toggleLocationPopup}
                  style={{ fontWeight: FONTWEIGHT.FONT_BOLD }}>
                  <IntlMessages id="store.change" />
                </PeppoButton>
              </>
            ) : (
              <>
                <Button
                  className="store-location-btn"
                  onClick={toggleLocationPopup}
                  style={{
                    border: `1px solid ${COLORS.BORDER}`,
                    width: '60%'
                  }}>
                  <IntlMessages id="store.clickToSetYourStoreLocation" />
                </Button>
                <div style={{ width: '4%' }} />
              </>
            )}
          </CardBlock>
        </Card>
        {!IsBMSView && posConfigurationDetail ? (
          <Card style={{ width: '25%' }}>
            <CardBlock>
              <div>
                <Title
                  level="h4"
                  style={{ fontWeight: '500', marginBottom: '-5px' }}>
                  <IntlMessages id="pos.posIntegration" />
                </Title>
                <Text
                  level="body-2"
                  style={{
                    fontSize: '13px',
                    color:
                      posConfigurationDetail.status === POS_STATUS.IN_PROGRESS
                        ? COLORS.SECONDARY
                        : COLORS.SUCCESS
                  }}>
                  {posConfigurationDetail.status === POS_STATUS.IN_PROGRESS ? (
                    <IntlMessages id="pending" />
                  ) : (
                    posConfigurationDetail.status
                  )}
                </Text>
              </div>
              <img
                src={POS_IMAGES.cashier_icon}
                height="42px"
                width={'42px'}
                alt="cashier"
              />
            </CardBlock>
            <div style={{ margin: '9px 0 11px 0' }}>
              <CardBlock>
                <Text level="caption">
                  <IntlMessages id="appModule.name" />
                </Text>
                <Text
                  level="body-2"
                  style={{ color: COLORS.DARK_TEXT, fontSize: '15px' }}>
                  {posConfigurationDetail?.posProvider}
                </Text>
              </CardBlock>
              <CardBlock>
                <Text level="caption">
                  <IntlMessages id="pos.refNo" />
                </Text>
                <Text
                  level="body-2"
                  style={{ color: COLORS.DARK_TEXT, fontSize: '15px' }}>
                  {posConfigurationDetail?.config?.posReferenceId}
                </Text>
              </CardBlock>
              <CardBlock>
                <Text level="caption">
                  <IntlMessages id="store.menuSharingCode" />
                </Text>
                <Text
                  level="body-2"
                  style={{ color: COLORS.DARK_TEXT, fontSize: '15px' }}>
                  {posConfigurationDetail?.storeIdentifier}
                </Text>
              </CardBlock>
            </div>
            <PeppoButton
              type="dark"
              size="small"
              style={{
                color: COLORS.PRIMARY,
                borderColor: COLORS.PRIMARY,
                width: '88px'
              }}
              onClick={() => {
                history.push({ pathname: '/orderx/store-list' })
              }}>
              <IntlMessages id="pos.configure" />
            </PeppoButton>
          </Card>
        ) : null}
      </div>
      {showLocationModal && (
        <StoreLocation
          searchedLocationText={storeLocation.searchedLocation}
          streetAddressText={storeLocation.userInputLocation}
          storeGeoLocation={storeLocation.storeGeoLocation}
          intl={intl}
          onLocationChange={onLocationChange}
          toggleLocationPopup={toggleLocationPopup}
        />
      )}
    </>
  )
}
