import { Col, Row } from 'antd'
import { IntlMessages } from 'components'
import React from 'react'

const CatalogsNotFound = ({ catalogs }) => {
  return (
    !catalogs.length && (
      <Row>
        <Col span={12} offset={6}>
          <IntlMessages id="catalogs.noMenuFound" />
        </Col>
      </Row>
    )
  )
}

export default CatalogsNotFound
