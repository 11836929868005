import {
  message,
  Row,
  Slider,
} from 'antd'
import { IMAGES } from 'assets'
import { Card, IntlMessages, Text as PeppoText, Title } from 'components'
import { orderxClient, pmsClient } from 'index'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import * as pmsService from 'services/pmsServices'
import * as storeService from 'services/storeService'
import { STATUS } from 'Utils/constants'
import { getWcoreOrganizationId } from 'Utils/localStorageHandlers/getter'
import { setIsUnsavedStoreDetails } from 'Utils/localStorageHandlers/setter'

import { CardBlock, DeliveryPartnersImgStyles, RadiusBarDotStyle } from '../styles'
import DeliveryDiscount from './deliveryDiscount'

const defaultDeliveryDiscount = 10
const defaultDeliveryDiscountStatus = { YES: STATUS.ACTIVE, NO: STATUS.INACTIVE }

export default function PartnerDeliveryService({ storeId, useStoreData }) {
  const [storeData, setStoreData] = useStoreData()
  const [serviceabilityRadius, setServiceabilityRadius] = useState(8)
  const [deliveryDiscount, setDeliveryDiscount] = useState(defaultDeliveryDiscount)
  const [deliveryDiscountStatus, setDeliveryDiscountStatus] = useState(defaultDeliveryDiscountStatus.NO)
  const intl = useIntl()
  const wcoreOrganizationId = getWcoreOrganizationId()

  const getDeliveryDiscount = async (val, status) => {
    const newStatus = status ? defaultDeliveryDiscountStatus.YES : defaultDeliveryDiscountStatus.NO

    if (val && val >= 3 && val <= 90) {
      setDeliveryDiscount(val)
      setDeliveryDiscountStatus(newStatus)
      setIsUnsavedStoreDetails(true)
    } else {
      message.error(
        intl.formatMessage({ id: 'store.deliveryDiscount.ErrMsg' })
      )
    }
  }

  const stateInitializer = (deliveryDiscount = defaultDeliveryDiscount, deliveryDiscountStatus = STATUS.INACTIVE, serviceabilityRadius = 8) => {
    setDeliveryDiscount(deliveryDiscount)
    setDeliveryDiscountStatus(deliveryDiscountStatus)
    setServiceabilityRadius(serviceabilityRadius)
  }

  async function getPartnerDeliveryServiceData() {
    const payload = {
      storeId,
      organizationId: wcoreOrganizationId,
    }

    try {
      const promises = [
        pmsService.getPMSStore(pmsClient, { wcoreStoreId: payload.storeId }),
        storeService.getStoreServiceAreas(orderxClient, payload)
      ]
      const [pmsStore, serviceAreas] = await Promise.all(promises)
      const { discounts } = pmsStore.data.store
      const [serviceableArea] = serviceAreas.data.getStoreServiceAreas
      const radius = Number(serviceableArea.area) / 1000
      const status = discounts[0].status === null || discounts[0].status === STATUS.PENDING ? defaultDeliveryDiscountStatus.NO : discounts[0].status

      setStoreData({ serviceableArea })

      stateInitializer(discounts[0].discountValue, status, radius)

    }
    catch (err) {
      console.log('Error fetching store data', err)
    }
  }

  useEffect(() => {
    if (!storeData()?.deliveryDiscount && !storeData()?.serviceableArea && storeId) {
      getPartnerDeliveryServiceData()
    }
    else {
      const { value, status } = storeData()?.deliveryDiscount || {}
      const serviceabilityRadius = storeData()?.serviceabilityRadius

      stateInitializer(value, status, serviceabilityRadius)
    }
  }, [])

  useEffect(() => {

    setStoreData({
      deliveryDiscount: {
        value: deliveryDiscount,
        status: deliveryDiscountStatus,
      },
      serviceabilityRadius
    })
  }, [deliveryDiscount, deliveryDiscountStatus, serviceabilityRadius])

  return (
    <Card style={{ margin: '12px 0' }}>
      <CardBlock>
        <Title level='h4'>
          <IntlMessages id="store.partnerDeliveryServices" />
        </Title>
        <CardBlock style={{ width: '22%' }}>
          <PeppoText level='caption'>
            <IntlMessages id="store.partners" />:
          </PeppoText>
          <DeliveryPartnersImgStyles src={IMAGES.dunzo} alt='dunzo' />
          <DeliveryPartnersImgStyles src={IMAGES.shadow_fax} alt='shadow_fax' />
          <DeliveryPartnersImgStyles src={IMAGES.wefast} alt='wefast' />
        </CardBlock>
      </CardBlock>
      <Row>
        <div className="fleet-check-text">
          <Title level="h5" style={{ marginBottom: '0px' }}>
            <IntlMessages id="store.serviceabilityAreaTitle" />
          </Title>
          <PeppoText level="caption">
            ({<IntlMessages id="deliveryRadius" />})
          </PeppoText>
        </div>
        <div
          style={{
            width: '71%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <div className="delivery-radius-container">
            <span className="dot-container">
              {[...Array(5)].map((val, key) => (
                <RadiusBarDotStyle key={key} />
              ))}
            </span>
            <Slider
              min={3}
              max={50}
              onChange={(value) => {
                setServiceabilityRadius(value)
                setIsUnsavedStoreDetails(true)
              }}
              value={serviceabilityRadius}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '-7px'
              }}>
              <PeppoText level="overline">3 km</PeppoText>
              <PeppoText level="overline">50 km</PeppoText>
            </div>
          </div>
          <div className="radius-value">{serviceabilityRadius}</div>
        </div>
      </Row>
      <DeliveryDiscount
        getDeliveryDiscount={(val, status) =>
          getDeliveryDiscount(val, status)
        }
        deliveryDiscount={deliveryDiscount}
        deliveryDiscountStatus={deliveryDiscountStatus}
        intl={intl}
      />
    </Card>
  )
}