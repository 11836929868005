import { withApollo } from '@apollo/client/react/hoc'
import { Row } from 'antd'
import { IntlMessages, ResetPasswordForm } from 'components'
import { History } from 'history'
import * as compose from 'lodash/flowRight'
import React from 'react'
import { injectIntl } from 'react-intl'
import { COLORS } from 'Utils/constants/styles'

import useResetPassword from './useResetPassword'

interface IProps {
  client?: unknown
  history?: History
  intl: any
}


const InitialResetPassword = (props: IProps) => {


  const { intl } = props
  const { loading, handleSubmit } = useResetPassword(props)

  return (
    <div
      style={{ background: `${COLORS.PRIMARY_MEDIUM} 0% 0% no-repeat padding-box` }}
      className="gx-app-change-pw-wrap">
      <div className="gx-app-change-pw-container">
        <div className="gx-app-change-pw-main-content">
          <div className="gx-app-change-pw-content">
            <Row style={{ marginBottom: '20px' }}>
              <h2 style={{ marginBottom: 0, color: COLORS.DARK_TEXT }}>
                <IntlMessages id="welcomeOnboard" />
              </h2>
              <div style={{ fontSize: '13px' }}>
                <IntlMessages id="store.setYourPassword" />.
              </div>
            </Row>
            <ResetPasswordForm
              onSubmit={handleSubmit}
              intl={intl}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  )

}

export default injectIntl(compose(withApollo)(InitialResetPassword))
