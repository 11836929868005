import { gql } from '@apollo/client'

export const ADD_BULK_STORE_OPEN_TIMING = gql`
  mutation addBulkStoreOpenTiming($input: AddBulkStoreOpeningTimings) {
    addBulkStoreOpenTiming(input: $input) {
      days
      data {
        id
        openTime
        closeTime
      }
    }
  }
`

export const ADD_DELIVERY_AREA = gql`
  mutation addStoreDelivery($input: AddStoreDeliveryInput) {
    addStoreDelivery(input: $input) {
      id
    }
  }
`

export const ADD_STAFF_MEMBERS_TO_STORE = gql`
  mutation addStaffMembersToStore($input: StaffMembersForStoreInput) {
    addStaffMembersToStore(input: $input) {
      id
      name
      staff {
        id
        name
        phone
        staffRole
      }
    }
  }
`

export const ADD_STAFF_MEMBER_TO_STORE = gql`
  mutation addStaffMemberToStore($input: StaffForStoreInput) {
    addStaffMemberToStore(input: $input) {
      id
      name
    }
  }
`

export const ADD_STORE_CHARGES = gql`
  mutation createStoreCharges($input: StoreChargeInput) {
    createStoreCharges(input: $input) {
      id
    }
  }
`

export const ADD_USER_TO_ORGANISATION = gql`
  mutation addUserToOrganization(
    $userData: UserCreateInput!
    $organization_id: ID!
    $role_id: ID
  ) {
    addUserToOrganization(
      userData: $userData
      organization_id: $organization_id
      role_id: $role_id
    ) {
      id
    }
  }
`

export const ADD_USER_TO_STORE = gql`
  mutation linkUserToStore($input: LinkUserStoreInput) {
    linkUserToStore(input: $input) {
      id
      email
      firstName
      lastName
    }
  }
`

export const CHECK_USERNAME_AVAILABILITY = gql`
  query checkUserNameAvailability($userName: String!) {
    checkUserNameAvailability(userName: $userName)
  }
`

export const CREATE_BULK_STAFF_MEMBERS = gql`
  mutation addBulkStaffMembers($input: [addStaffInput]) {
    addBulkStaffMembers(input: $input) {
      id
      name
    }
  }
`

export const CREATE_STAFF = gql`
  mutation addStaff($input: addStaffInput) {
    addStaff(input: $input) {
      id
      name
      phone
      status
      whatsappSupport
      store {
        id
      }
    }
  }
`

export const CREATE_STORE = gql`
  mutation createStore($input: CreateStoreInput!) {
    createStore(input: $input) {
      id
      code
      name
      addressLine1
      addressLine2
      city
      state
      pinCode
      storeFormats {
        id
        name
        storeFormatCode
        status
      }
      channels {
        id
        name
        channelCode
      }
    }
  }
`

export const GET_ALL_STORES = gql`
  query stores($input: StoreSearchInput!, $pageOptions: PageOptions!) {
    stores(input: $input, pageOptions: $pageOptions) {
      data {
        id
        name
        STATUS
        enable
        externalStoreId
        code
        extend
        geoLocation
        state
        city
        addressLine1
        addressLine2
        pinCode
        country
        wifi
        email
      }
    }
  }
`

export const INACTIVE_DELIVERY_AGENT = gql`
  mutation inactiveStaffMember($input: UpdateStaffInput) {
    inactiveStaffMember(input: $input) {
      id
    }
  }
`

export const ACTIVE_DELIVERY_AGENT = gql`
  mutation activeStaffMamber($input: UpdateStaffInput) {
    activeStaffMamber(input: $input) {
      id
      name
      phone
      staffRole
    }
  }
`

export const REMOVE_DELIVERY_AREA = gql`
  mutation removeStoreDelivery($input: RemoveStoreDelivery) {
    removeStoreDelivery(input: $input) {
      id
      area
      pincode
      areaType
    }
  }
`

export const REMOVE_STORE_TIMINGS = gql`
  mutation removeStoreOpenTiming($input: RemoveStoreOpenTimingInput) {
    removeStoreOpenTiming(input: $input) {
      id
      days
      openTime
      closeTime
    }
  }
`

export const ROLES_LIST = gql`
  query roles($input: RoleQueryInput!) {
    roles(input: $input) {
      id
      name
    }
  }
`

export const STORE = gql`
  query store($id: ID!) {
    store(id: $id) {
      id
      name
      STATUS
      enable
      code
      geoLocation
      addressLine1
      addressLine2
      state
      city
      pinCode
      country
      storeFormats {
        id
        name
        description
        storeFormatCode
        status
      }
      channels {
        id
        name
        channelCode
      }
      staff {
        id
        email
        name
        phone
        staffRole
        status
        whatsappSupport
        enabled
      }
      users {
        id
        firstName
        email
        status
        roles {
          id
          name
        }
      }
    }
  }
`

export const STORE_TIMINGS = gql`
  query storeOpenTimings($storeId: ID!, $pageOptions: PageOptions!) {
    storeOpenTimings(storeId: $storeId, pageOptions: $pageOptions) {
      data {
        days
        data {
          id
          openTime
          closeTime
        }
      }
    }
  }
`

export const UPDATE_DELIVERY_AGENT = gql`
  mutation editStaff($input: EditStaffInput) {
    editStaff(input: $input) {
      id
      name
      phone
      status
      whatsappSupport
      store {
        id
      }
    }
  }
`
export const EDIT_STAFF = gql`
  mutation editStaff($input: EditStaffInput) {
    editStaff(input: $input) {
      id
      name
      phone
      status
      whatsappSupport
      store {
        id
      }
    }
  }
`

export const UPDATE_DELIVERY_AREA = gql`
  mutation updateStoreDelivery($input: UpdateStoreDeliveryInput) {
    updateStoreDelivery(input: $input) {
      id
    }
  }
`

export const UPDATE_STORE_SERVICE_AREA = gql`
  mutation updateStoreServiceArea($input: UpdateStoreServiceAreaInput!) {
    updateStoreServiceArea(input: $input) {
      store {
        id
      }
      id
      areaType
      status
      area
    }
  }
`

export const UPDATE_STORE = gql`
  mutation updateStore($input: UpdateStoreInput!) {
    updateStore(input: $input) {
      id
      name
      STATUS
      externalStoreId
      code
      extend
      state
      city
      geoLocation
      addressLine1
      addressLine2
      pinCode
      country
      wifi
      email
    }
  }
`

export const UPDATE_STORE_CHARGES = gql`
  mutation updateStoreCharges($id: ID!, $input: updateStoreChargeInput) {
    updateStoreCharges(id: $id, input: $input) {
      id
    }
  }
`

export const UPDATE_USER_PASSWORD = gql`
  mutation updateUserPassword($userId: String, $password: String) {
    updateUserPassword(userId: $userId, password: $password) {
      updated
    }
  }
`

export const GET_STORE_SERVICE_AREAS = gql`
  query getStoreServiceAreas($storeId: ID!, $organizationId: ID!) {
    getStoreServiceAreas(storeId: $storeId, organizationId: $organizationId) {
      store {
        id
      }
      id
      areaType
      status
      area
    }
  }
`

export const REMOVE_STORE_SLOTS_TIMINGS = gql`
  mutation removeStoreOpenTimings($input: RemoveStoreOpenTimingsInput) {
    removeStoreOpenTimings(input: $input) {
      id
    }
  }
`
export const GET_STORE_NAMES = gql`
  query stores($input: StoreSearchInput!, $pageOptions: PageOptions!) {
    stores(input: $input, pageOptions: $pageOptions) {
      data {
        id
        name
      }
    }
  }
`