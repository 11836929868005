import { Modal } from 'antd'
import { IntlMessages } from 'components'
import React from 'react'

import { Buttons } from './modalButton'

export const ConfirmationModal = props => {
  return (
    <Modal
      visible={props.isUnsavedChanges}
      className="confirm-tab-modal"
      key="item-form-modal"
      title={<IntlMessages id="category.confirmationMsg.leaveThisPage" />}
      onCancel={() => props.onClick()}
      footer={[
        <div key="footer">
          <Buttons key={'leave'} title={'leave'} onclick={() => props.goToTargetLocation()} />
          <Buttons key={'stay'} title={'stay'} onclick={() => props.stayAction()} />
        </div>
      ]}>
      <div>
        <IntlMessages id="category.infoMsg.changesMayNotSave" />
      </div>
    </Modal>
  )
}
