import './styles.css'

import SearchOutlined from '@ant-design/icons/SearchOutlined'
import { ApolloProviderProps } from '@apollo/client/react/context'
import { withApollo } from '@apollo/client/react/hoc'
import { AutoComplete, Input } from 'antd'
import React from 'react'
import { injectIntl } from 'react-intl'
import { intlAlertMessage } from 'Utils'

interface iProps extends ApolloProviderProps<any> {
  dataSource: any[]
  onSelect: (value) => void
  width?: number
  placeholder?: string
  intl: any
}

interface iState {
  dataSource: any[]
}

class AutoCompleteSearch extends React.Component<iProps, iState> {
  constructor(props: iProps) {
    super(props)
    this.state = {
      dataSource: this.props.dataSource
    }
  }

  /*
   * Static getDerivedStateFromProps(nextProps, prevState) {
   * debugger;
   *   if (nextProps.dataSource !== prevState.dataSource) {
   *     return { dataSource: nextProps.dataSource };
   *   }
   *   else return null;
   * }
   * componentDidUpdate(prevProps, prevState) {
   *   debugger;
   *   if (prevProps.dataSource !== this.props.dataSource) {
   *     //Perform some operation here
   *     this.setState({ dataSource: this.props.dataSource });
   *   }
   * }
   */

  handleSearch = (value: string) => {
    // Debugger;
    if (!value) {
      this.setState({ dataSource: [] })
    } else {
      const updatedDataSource = this.props.dataSource.filter(d =>
        d.toLowerCase().includes(value.toLowerCase())
      )

      if (!updatedDataSource.length) {
        updatedDataSource.push(intlAlertMessage({ id: 'item.noItemsFound', intl: this.props.intl }))
      }
      this.setState({ dataSource: updatedDataSource })
    }
    this.props.onSelect(value)
  }

  render() {
    const { onSelect, width, placeholder } = this.props
    const { dataSource } = this.state

    return (
      <div className="auto-complete-search-wrapper">
        <AutoComplete
          dataSource={dataSource}
          style={{ width: width ? width : 200 }}
          onSelect={onSelect}
          onSearch={this.handleSearch}
          allowClear={true}>
          <Input
            placeholder={
              placeholder
                ? placeholder
                : intlAlertMessage({
                  id: 'component.searchAnyItem',
                  intl: this.props.intl
                })
            }
            suffix={<SearchOutlined className="certain-category-icon" />}
          />
        </AutoComplete>
      </div>
    )
  }
}
export default injectIntl(AutoCompleteSearch)
