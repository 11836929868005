import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { withApollo } from '@apollo/client/react/hoc'
import { Checkbox, Col, Dropdown, Input, InputNumber, Radio, Spin } from 'antd'
import { Button, FileUpload, IntlMessages, Modal, Text, Title } from 'components'
import * as compose from 'lodash/flowRight'
import React, { useEffect, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { addTaxes, createCategory, createProduct, createProductDiscountValue, createProductPrice, getAllProductCategory, getPriceValues, getProductDiscountValue, getProducts, getProductsByCategory, updateDiscountForProducts, updateProduct, updateProductPrice } from 'services'
import { intlAlertMessage, randomString } from 'Utils'
import { CHANNELS, COLORS, DISCOUNT_VALUE_TYPE_ENUM, ORG_STATUS, PRICE_TYPE, PRODUCT_TAG_ENUM, PRODUCT_TYPE_ENUM } from 'Utils/constants'
import { getDiscountTypes, getFileSystemId, getWcoreOrganizationId } from 'Utils/localStorageHandlers/getter'

import { getItemCode, priceFormatter, republishCatalog, setSuccessMessage, setWarningMessage } from '../../../Utils/menuUtils/commonFunc'
import { prepareFormatsChannels, updateFormatsChannels } from '../../../Utils/menuUtils/productsHelper'
import { AddItemStyle, AddonBox, AddonContainerStyles, AddonPriceContainer, GroupItemStyle, ImageContainerStyles, ItemBoxStyle, StyledInputBox } from '../../../Utils/menuUtils/styleConstants'
import Menu from '../components/AddonMenuItem'

const Addons = (props) => {
  const history = useHistory()
  const org_id = getWcoreOrganizationId()
  const { catalog } = props
  const fileSystemId = getFileSystemId()

  const [isOpen, setIsOpen] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [customizationName, setCustomizationName] = useState('')
  const [showUploader, setShowUploader] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const [customizationGroupData, setCustomizationGroupData] = useState([])
  const [productsList, setProductsList] = useState([])
  const [productItemName, setProductItemName] = useState('')
  const [selectedProductItem, setSelectedProductItem] = useState(null)
  const [selectedItemName, setSelectedItemName] = useState('')
  const [selectedProductItemTagCode, setSelectedProductItemTagCode] = useState('')
  const [basePrice, setBasePrice] = useState(0)
  const [dealPrice, setDealPrice] = useState(0)
  const [discountValue, setDiscountValue] = useState(0)
  const [isNoExtraCostEnabled, setIsNoExtraCostEnabled] = useState(false)
  const [productDiscountValueIds, setProductDiscountValueIds] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [updateProductLoading, setUpdateProductLoading] = useState(false)
  const [isProductsLoading, setIsProductsLoading] = useState(false)
  const [isCatagoriesLoading, setIsCatagoriesLoading] = useState(false)
  const [formatsChannels, setFormatChannels] = useState(prepareFormatsChannels(props))
  const strikeOutDiscountType = getDiscountTypes()

  useEffect(() => {
    if (catalog) {
      getCategory(catalog, false, '')
    }
  }, [])


  const getCategory = async (catalog, isNewCategoryAdded, customizationName) => {
    try {
      setIsCatagoriesLoading(true)
      const res = await getAllProductCategory(props.client, { id: catalog })

      if (res?.data) {
        const newCategoryList = res?.data?.categories.filter(val => val.productType === PRODUCT_TYPE_ENUM.ADDON) || null
        const newSelectedCategory = newCategoryList.find(elem => elem.code === getItemCode(customizationName))

        getProductsByCategoryId(isNewCategoryAdded ? newSelectedCategory : newCategoryList[0], false)
        setCustomizationGroupData(newCategoryList)
        setIsCatagoriesLoading(false)
        setSelectedCategory(isNewCategoryAdded ? newSelectedCategory : newCategoryList[0])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = async () => {
    setCustomizationName('')
    setIsModalOpen(false)
    setUpdateProductLoading(true)
    setSelectedProductItemTagCode('')
    setSelectedItemName('')

    const createCategoryInput = {
      name: customizationName,
      description: '',
      status: ORG_STATUS.ACTIVE,
      catalogId: catalog,
      organizationId: org_id,
      code: getItemCode(customizationName),
      productType: PRODUCT_TYPE_ENUM.ADDON
    }
    const categoryRes = await createCategory(props.client, createCategoryInput)

    if (categoryRes) {
      setSuccessMessage('category.message.ItemCreatedSuccessfully', props.intl)
      setUpdateProductLoading(false)
    }
    getCategory(catalog, true, customizationName)
  }

  const showImageUpload = () => {
    if (!fileSystemId) {
      setWarningMessage('category.warnMsg.imgMissingforFilesystem', props.intl)
    } else {
      setShowUploader(true)
    }
  }

  const getProductsByCategoryId = async (category, isNewProductAdded) => {
    setSelectedCategory(category)
    setIsOpen(false)
    setIsProductsLoading(true)

    try {
      const res = await getProductsByCategory(props.client, category.id)

      if (res?.data) {

        const data = res.data.productCategoriesByCategoryId

        setProductsList(data)
        onSelectedItem(isNewProductAdded ? data[data.length - 1] : data[0])
        getDiscountValue(isNewProductAdded ? data[data.length - 1]?.product : data[0]?.product)
        setIsProductsLoading(false)
      }
    } catch (error) {
      setIsProductsLoading(false)
    }
  }

  const updateAddonProduct = async () => {
    setUpdateProductLoading(true)
    const extendObj: any = {
      extend_non_veg: Boolean(selectedProductItemTagCode && selectedProductItemTagCode.toLowerCase() === PRODUCT_TAG_ENUM.NON_VEG),
      extend_veg: Boolean(selectedProductItemTagCode && selectedProductItemTagCode.toLowerCase() === PRODUCT_TAG_ENUM.VEG)
    }
    const updateProductInput = {
      id: selectedProductItem?.id,
      name: selectedItemName,
      productType: PRODUCT_TYPE_ENUM.ADDON,
      organizationId: org_id,
      imageUrl,
      extend: extendObj,
      listable: true
    }

    try {
      const res = await updateProduct(props.client, updateProductInput)
      const productIndex = productsList.findIndex(elem => elem.product.id === selectedProductItem?.id)
      const productListCopy = [...productsList]

      productListCopy.splice(productIndex, 1, { product: res?.data?.updateProduct })
      props.onAddonsChange()
      updatePriceAndCharge(res?.data?.updateProduct)
      setUpdateProductLoading(false)
      setProductsList(productListCopy)
    } catch (error) {
      console.log(error)
    }
  }

  const updatePriceAndCharge = async selectedProductItem => {
    try {
      formatsChannels?.forEach(async formatChannel => {
        if (formatChannel.productPriceValueId) {
          const updatePriceInput = {
            productPriceValueId: formatChannel.productPriceValueId,
            storeFormat: formatChannel.storeFormat.id,
            channel: formatChannel.channel.id,
            productPrice: isNoExtraCostEnabled ? 0 : basePrice
          }

          await updateProductPrice(props.client, updatePriceInput)
        } else {
          const createPriceInput = {
            productId: selectedProductItem?.id,
            storeFormat: formatChannel.storeFormat.id,
            channel: formatChannel.channel.id,
            productPrice: isNoExtraCostEnabled ? 0 : basePrice
          }

          await createProductPrice(props.client, createPriceInput)
          createAddonDiscountValue(createPriceInput)
        }
      })
      const productIndex = productsList.findIndex(elem => elem.product.id === selectedProductItem?.id)

      const productListCopy = [...productsList]
      const { productPriceValues } = productListCopy[productIndex].product.productPrices
      const newPriceIndex = productPriceValues?.findIndex((elem) => elem?.channel?.name === CHANNELS.PEPPO_PWA)
      const productPriceValuesCopy = [...productPriceValues]

      productPriceValuesCopy[newPriceIndex] = { ...productPriceValuesCopy[newPriceIndex], basePrice }
      productPriceValuesCopy[newPriceIndex] = { ...productPriceValuesCopy[newPriceIndex], priceValue: dealPrice }

      productListCopy.splice(productIndex, 1, { product: { ...productListCopy[productIndex].product, productPrices: { productPriceValues: productPriceValuesCopy, productDiscountValues: productListCopy[productIndex].product.productPrices.productDiscountValues, productChargeValues: productListCopy[productIndex].product.productPrices.productChargeValues } } })

      setSuccessMessage('category.successMsg.itemUpdatedSuccessfully', props.intl)
      if (productDiscountValueIds?.length) {
        await updateProductDiscountValue()
      }
      republishCatalog(props.client)
      setProductsList(productListCopy)
    } catch (error) {
      console.log(error)
    }
  }

  const updateProductDiscountValue = async () => {
    try {
      const payload = []

      productDiscountValueIds.forEach(elem => {
        payload.push({
          productDiscountValueId: elem,
          discountValue: isNoExtraCostEnabled ? 0 : Math.round(discountValue)
        })
      })
      const productDiscountValueIdArr = []
      
      if (productDiscountValueIds.length) {
        const updateRes = await updateDiscountForProducts(props.client, payload)
        
        updateRes.data?.updateDiscountValueForProducts.forEach(elem => {
          productDiscountValueIdArr.push(elem.id)
        })
        setProductDiscountValueIds(productDiscountValueIdArr)
      }
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const createAddonDiscountValue = async productDetails => {
    const productDiscountValueIdArr = []

    try {
      const discountInput = {
        productId: productDetails.productId,
        storeFormat: productDetails.storeFormat,
        channel: productDetails.channel,
        discountValue: isNoExtraCostEnabled ? 0 : Math.round(discountValue),
        discountType: strikeOutDiscountType[0].id,
        discountValueType: DISCOUNT_VALUE_TYPE_ENUM.ABSOLUTE
      }
      const updateRes = await createProductDiscountValue(props.client, discountInput)

      productDiscountValueIdArr.push(updateRes?.data?.createDiscountValueForProduct?.id)
      setProductDiscountValueIds(productDiscountValueIdArr)
      getProductsByCategoryId(selectedCategory, true)
    } catch (error) {
      console.log(error, 'error')
    }
  }

  const getDiscountValue = product => {

    // eslint-disable-next-line no-unsafe-optional-chaining
    const { productDiscountValues } = product?.productPrices
    const productDiscountValueIdArr = []

    productDiscountValues.forEach(elem => productDiscountValueIdArr.push(elem.id))
    setProductDiscountValueIds(productDiscountValueIdArr)
  }

  const createAddon = async () => {
    const createProductInput = {
      productType: PRODUCT_TYPE_ENUM.ADDON,
      status: ORG_STATUS.ACTIVE,
      organizationId: org_id,
      categoryIds: [selectedCategory['id']],
      listable: true,
      name: productItemName,
      code: getItemCode(productItemName)
    }

    try {
      setIsLoading(true)
      const res = await createProduct(props.client, createProductInput)

      if (res?.data) {
        const { createProduct } = res.data

        await addTaxes(props.client, createProduct.id)

        setIsLoading(false)
        setProductItemName('')
        setSuccessMessage('category.successMsg.itemUpdatedSuccessfully', props.intl)
        getProductsByCategoryId(selectedCategory, true)
        props.onAddonsChange()
        republishCatalog(props.client)
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  const onPriceValueChanged = (dealPrice, basePrice, fieldName) => {
    if (dealPrice < 0 || basePrice < 0) {
      return
    }
    const discountValue = basePrice - dealPrice

    if (isNaN(dealPrice)) {
      setDealPrice(0)
      setDiscountValue(basePrice)
    }
    if (dealPrice && dealPrice < basePrice) {
      setDealPrice(dealPrice)
      setDiscountValue(discountValue)
      setBasePrice(basePrice)
    } else if (dealPrice >= basePrice) {
      if (fieldName === PRICE_TYPE.DEAL) {
        setDealPrice(dealPrice)
        setDiscountValue(0)
        setBasePrice(dealPrice)
      } else {
        setDealPrice(basePrice)
        setDiscountValue(0)
        setBasePrice(basePrice)
      }
    }
  }

  const onSelectedItem = item => {
    const { product } = item

    setIsNoExtraCostEnabled(false)
    setSelectedProductItem(product)
    setSelectedItemName(product?.name || '')
    setImageUrl(product?.imageUrl || '')
    setSelectedProductItemTagCode(product?.extend ? (product?.extend?.extend_non_veg ? PRODUCT_TAG_ENUM.NON_VEG : PRODUCT_TAG_ENUM.VEG) : '')
    getProductPrices(product)
    getDiscountValue(product)
  }

  const getProductPrices = (product) => {
    const { productPrices } = product
    const updateStoreFormat = updateFormatsChannels(productPrices?.productPriceValues || [], productPrices?.productChargeValues || [], { formatsChannels })
    const { priceValue, basePrice } = getPriceForPWAChannel(productPrices?.productPriceValues || [])
    const { discountValue } = getPriceForPWAChannel(productPrices?.productDiscountValues || [])

    setFormatChannels(updateStoreFormat)
    setBasePrice(basePrice || 0)
    setDealPrice(priceValue || 0)
  }

  const getPriceForPWAChannel = (arr) => {
    return arr.find(elem =>
      elem?.channel?.name === CHANNELS.PEPPO_PWA
    ) || {}
  }

  const onMenuItemClickHandler = () => {
    setIsModalOpen(true)
    setIsOpen(!isOpen)
  }

  const onDealPriceHandler = value => {
    setDealPrice(value < 0 ? 0 : value)
  }

  const onNoExtraCostInputHandler = () => {
    setIsNoExtraCostEnabled(!isNoExtraCostEnabled)
    setDealPrice(null)
    setBasePrice(null)
  }

  return (
    <>
      <AddonContainerStyles span={6}>
        <Title level="h6">
          <IntlMessages id="addons/Combos" />
        </Title>
        <div>
          <Dropdown
            overlay={
              <Menu
                onMenuItemClickHandler={onMenuItemClickHandler}
                customizationGroupData={customizationGroupData}
                isCatagoriesLoading={isCatagoriesLoading}
                getProducts={(category, isNewProductAdded) => getProductsByCategoryId(category, isNewProductAdded)}
              />
            }
            onVisibleChange={() => setIsOpen(!isOpen)}
            visible={isOpen}>
            <StyledInputBox
              className={'box-default'}
              onClick={e => e.preventDefault()}>
              <span>
                {selectedCategory ? (
                  selectedCategory['name']
                ) : (
                  <IntlMessages id="addons/combo.selectAndCreateAnyGroup" />
                )}
              </span>
              {isOpen ? (
                <UpOutlined style={{ fontSize: '13px' }} />
              ) : (
                <DownOutlined style={{ fontSize: '13px' }} />
              )}
            </StyledInputBox>
          </Dropdown>
          {isProductsLoading ? (
            <div className="divCenter" style={{ marginTop: '-100px' }}>
              <Spin size="default" />
            </div>
          ) : null}
          {selectedCategory && productsList.length ? (
            productsList?.map((item, index) => (
              <GroupItemStyle
                key={index}
                style={
                  item?.product?.id === selectedProductItem?.id
                    ? {
                      backgroundColor: COLORS.BG_SHADE,
                      color: COLORS.PRIMARY,
                      padding: '10px 12px',
                      fontWeight: '600'
                    }
                    : {}
                }
                onClick={() => {
                  onSelectedItem(item)
                }}>
                {item?.product?.name || ''}
              </GroupItemStyle>
            ))
          ) : (
            <div>
              <IntlMessages id="item.noItemsFound" />
            </div>
          )}
          {selectedCategory ? (
            <ItemBoxStyle>
              <AddItemStyle
                placeholder={intlAlertMessage({
                  id: 'item.enterName',
                  intl: props.intl
                })}
                name="productItemName"
                value={productItemName}
                onChange={e => setProductItemName(e.target.value)} />
              <Button
                type="primary"
                disabled={!productItemName}
                onClick={() => createAddon()}
                loading={isLoading}>
                <IntlMessages id="button.+add" />
              </Button>
            </ItemBoxStyle>
          ) : null}
        </div>
      </AddonContainerStyles>
      {selectedCategory && productsList.length ? (
        <Col span={12} style={{ marginLeft: '20px' }}>
          <ImageContainerStyles>
            <div style={{ display: 'flex' }}>
              {imageUrl === null || imageUrl.length < 20 ? (
                <div
                  style={{ marginRight: '20px' }}
                  className="noImage"
                  onClick={() => showImageUpload()}>
                  <span>
                    <IntlMessages id="category.message.clickHereToAttachTheImage" />
                  </span>
                </div>
              ) : (
                <img
                  style={{ marginRight: '20px' }}
                  src={imageUrl}
                  className="detailImage"
                  onClick={() => showImageUpload()}
                  alt="Category"
                />
              )}
              <AddonBox>
                <div>
                  <div>
                    <IntlMessages id="menu.itemName" />
                  </div>
                  <Input
                    style={{ width: '330px', marginTop: '5px' }}
                    placeholder={intlAlertMessage({
                      id: 'cheeseBurst',
                      intl: props.intl
                    })}
                    value={selectedItemName}
                    onChange={e => setSelectedItemName(e.target.value)}
                  />
                </div>
                <Radio.Group
                  style={{ marginTop: '15px' }}
                  value={selectedProductItemTagCode}
                  onChange={e => setSelectedProductItemTagCode(e.target.value)}>
                  <Radio value={PRODUCT_TAG_ENUM.VEG} className="veg">
                    <Text level="body-2" style={{ color: COLORS.PRIMARY }}>
                      <IntlMessages id="addons/combo.Veg" />
                    </Text>
                  </Radio>
                  <Radio value={PRODUCT_TAG_ENUM.NON_VEG} className="non-veg">
                    <Text level="body-2" style={{ color: COLORS.ERROR_DARK }}>
                      <IntlMessages id="addons/combo.nonveg" />
                    </Text>
                  </Radio>
                </Radio.Group>
              </AddonBox>
            </div>
            <AddonPriceContainer>
              <div style={{ marginRight: '20px' }}>
                <div>
                  <Text level="caption">
                    <IntlMessages id="menu.basePrice" />
                  </Text>
                </div>
                <InputNumber
                  disabled={isNoExtraCostEnabled}
                  formatter={priceFormatter}
                  value={Math.round(basePrice)}
                  min={0}
                  onChange={value => setBasePrice(value)}
                  onBlur={() => onPriceValueChanged(dealPrice, basePrice, PRICE_TYPE.BASE)}
                />
              </div>
              <div style={{ marginRight: '20px' }}>
                <div>
                  <Text level="caption">
                    <IntlMessages id="menu.dealPrice" />
                  </Text>
                </div>
                <InputNumber
                  disabled={isNoExtraCostEnabled}
                  formatter={priceFormatter}
                  value={Math.round(dealPrice)}
                  min={0}
                  onChange={value => onDealPriceHandler(value)}
                  onBlur={() => onPriceValueChanged(dealPrice, basePrice, PRICE_TYPE.DEAL)}
                />
              </div>
              <Checkbox
                style={{ marginTop: '15px' }}
                checked={isNoExtraCostEnabled}
                onChange={() => onNoExtraCostInputHandler()}>
                <IntlMessages id="addons/combo.noExtraCost" />
              </Checkbox>
            </AddonPriceContainer>
          </ImageContainerStyles>

          <Button
            type="primary"
            disabled={!(selectedProductItemTagCode && selectedItemName && ((basePrice && dealPrice) || isNoExtraCostEnabled) && productsList.length)}
            loading={updateProductLoading}
            style={{ minWidth: '88px', height: '40px', marginTop: '12px' }}
            onClick={() => updateAddonProduct()}>
            <IntlMessages id="update" />
          </Button>
        </Col>
      ) : null}
      <Modal
        visible={isModalOpen}
        title=""
        width={450}
        onClose={() => setIsModalOpen(false)}>
        <Title
          level="h4"
          style={{ marginTop: '-8px', color: COLORS.DARK_TEXT, fontWeight: '500' }}>
          <IntlMessages id="addons/combo.name" />
        </Title>
        <Text level="caption" style={{ marginBottom: '15px' }}>
          <IntlMessages id="addons/combo.enterName" />
        </Text>
        <Input
          placeholder={intlAlertMessage({
            id: 'addons/combo.enterName',
            intl: props.intl
          })}
          value={customizationName}
          style={{ marginTop: '5px' }}
          onChange={e => setCustomizationName(e.target.value)}
        />
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="primary"
            disabled={!customizationName}
            style={{ height: '35px', width: '154px', marginTop: '16px' }}
            onClick={() => onSubmit()}>
            <IntlMessages id="button.submit" />
          </Button>
        </div>
      </Modal>
      <FileUpload
        key={randomString(4)}
        title={<IntlMessages id="uploadImage" />}
        isVisible={showUploader}
        fileSystemId={fileSystemId}
        onSave={(data) => {
          setShowUploader(false)
          setImageUrl(data)
        }}
        onCancel={() => setShowUploader(false)}
        client={props.client}
        allowedSizeInMB={1}
        singleFile={true}
        imgDimension={'400x400 px'}
        fileSize={'1 MB'}
        imgProportion={'Square'}
        fileTypeList={['jpg', 'jpeg', 'png']}>
        {props.children}
      </FileUpload>
    </>
  )
}
const AddonsComponent = compose(withRouter)(withApollo(Addons))

export default AddonsComponent
