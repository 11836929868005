import { Collapse as AntdCollapse } from 'antd'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

const { Panel: AntdPanel } = AntdCollapse

export interface PanelProps {
  /**
   * If true, panel cannot be opened or closed
   */
  disabled?: string[] | string | number[] | number
  /**
   * Key of the initial active panel
   */
  defaultActiveKey?: string[] | string | number[] | number
  /**
   * Toggles rendering of the border around the collapse block
   */
  bordered?: boolean
  /**
   * If true, Panel renders as Accordion
   */
  accordion?: boolean
  /**
   * Callback function executed when active panel is changed
   */
  onChange?: () => void
  /**
   * Allow to customize collapse icon
   */
  expandIcon?: (panelProps) => ReactNode
  /**
   * Set expand icon position: left, right
   */
  expandIconPosition?: 'left' | 'right'
}

const StyledPanel = styled(AntdPanel)``

export const Panel: React.FC<PanelProps> = ({ ...props }) => {
  return <StyledPanel {...props}>{props.children}</StyledPanel>
}
