import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'
import { Layout } from 'antd'
import { History } from 'history'
import * as compose from 'lodash/flowRight'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Auxiliary } from 'Utils'

// Import '../../styles/styles.css';
import SidebarLogo from '../SidebarContent/sidebarLogo'
import UserInfo from '../UserInfo'

const { Header } = Layout

interface iProps extends RouteComponentProps {
  width: number
  navCollapsed: boolean
  navStyle: string
  switchLanguage: any
  toggleCollapsedSideNav: any
  locale: string
  history: History
}

interface iState {
  searchText: string
}

class Topbar extends React.Component<iProps, iState> {
  state = {
    searchText: ''
  }

  render() {
    // Const { width, navCollapsed, navStyle } = this.props

    return (
      <Auxiliary>
        <Header className="OrderX-Topbar">
          <SidebarLogo />
          <ul className="gx-header-notifications gx-ml-auto">
            <Auxiliary>
              <li
                className="gx-user-nav"
                onMouseOver={() => {
                  setTimeout(() => {
                    if (
                      !document.querySelector(
                        '.gx-site-logo.gx-onboarding-logo.gx-d-none'
                      )
                    ) {
                      document.querySelectorAll('.gx-onboarding-d-none')
                        .length &&
                        document
                          .querySelectorAll('.gx-onboarding-d-none')
                          .forEach(ele => {
                            ele.classList.add('gx-d-none')
                          })
                    } else {
                      document.querySelectorAll('.gx-onboarding-d-none')
                        .length &&
                        document
                          .querySelectorAll('.gx-onboarding-d-none')
                          .forEach(ele => {
                            ele.classList.remove('gx-d-none')
                          })
                    }
                  }, 100)
                }}
                key="user-info-container">
                <UserInfo />
              </li>
            </Auxiliary>
          </ul>
        </Header>
      </Auxiliary>
    )
  }
}

const mapStateToProps = ({ settings }: any) => {
  const { locale, navStyle, navCollapsed, width } = settings.settings

  return { locale, navStyle, navCollapsed, width }
}
/*
 * Export default connect(
 *   mapStateToProps,
 *   { toggleCollapsedSideNav, switchLanguage }
 * )(Topbar);
 */

const GET_SETTINGS = gql`
  query settings {
    settings @client {
      locale {
        locale
        name
        languageId
        icon
      }
      navStyle
      navCollapsed
      width
    }
  }
`
const TOGGLE_COLLAPSED_SIDE_NAV = gql`
  mutation toggleCollapsedSideNav($navCollapsed: Boolean) {
    toggleCollapsedSideNav(navCollapsed: $navCollapsed) @client
  }
`
const SWITCH_LANGUAGE = gql`
  mutation switchLanguage($locale: LocaleInput) {
    switchLanguage(locale: $locale) @client
  }
`

export default compose(
  withRouter,
  graphql(TOGGLE_COLLAPSED_SIDE_NAV, { name: 'toggleCollapsedSideNav' }),
  graphql(SWITCH_LANGUAGE, {
    name: 'switchLanguage'
  }) /* Graphql(GET_SETTINGS, { props: mapStateToProps, name: 'settings' })*/
)(Topbar)
