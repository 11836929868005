import { Divider, message } from 'antd'
import { Button, Input, IntlMessages, Modal, Text, Title } from 'components'
import { useEffect, useState } from 'react'
import { createStorePOSConfiguration } from 'services/posService'
import styled from 'styled-components'
import { intlAlertMessage, setErrMsg } from 'Utils'
import { COLORS, MENU_SHARING_CODE_HELP_URL } from 'Utils/constants'
import {
  getPosData,
  getPosEnableStores,
  getUserData
} from 'Utils/localStorageHandlers/getter'

const ProviderBoxStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const FooterStyle = styled.div`
  display: flex;
  justify-content: flex-end;
`

const POSMenuSharingCodeModal = ({
  onSubmit,
  onClose,
  intl,
  dataSource,
  onBackBtnClicked,
  getPosStatus
}) => {
  const posData = getPosData()
  const posEnabledStores = getPosEnableStores()
  const [storeData, setStoreData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { organization } = getUserData()

  const posId = posEnabledStores.length
    ? posEnabledStores[0]?.config?.posReferenceId
    : posData.posId || ''

  const onPosIntegrateHandler = async () => {
    try {
      storeData.forEach(async store => {
        const isEnabled = posEnabledStores.filter(pos => store.storeId === pos.storeId)

        if (store?.externalStoreId?.val?.length < 5) {
          setErrMsg('pos.menuSharingCodeWarnMsg', intl)

          return
        }
        if (store.externalStoreId && !isEnabled[0]?.storeIdentifier) {

          setIsLoading(true)
          const payload = {
            external_store_id: store.externalStoreId?.val,
            organizationId: organization.id,
            store_id: store.storeId,
            pos_provider: posData.posProvider,
            config: {
              organization: organization.name,
              store: store.storeName,
              lastMenuSyncTime: '',
              lastMenuSyncStatus: '',
              posReferenceId: posId
            }
          }

          const response = await createStorePOSConfiguration(payload)

          if (response?.status === 200) {
            onSubmit()
          } else {
            setErrMsg('newOrder.somethingWetWrongMsg', intl)
          }
          setIsLoading(false)
        }
      })
    } catch (error) {
      setIsLoading(false)
      console.warn(error)
    }
  }

  useEffect(() => {
    init()
  }, [])

  const init = () => {
    const data = []

    dataSource?.forEach(elem => {
      const isEnabled = posEnabledStores.filter(pos => elem.key === pos.storeId)

      data.push({
        storeName: elem.storeName,
        storeId: elem.key,
        externalStoreId: isEnabled[0]?.storeIdentifier || '',
        posStatus: isEnabled[0]?.status || '-',
        isExternalStoreIdExist: Boolean(isEnabled[0]?.storeIdentifier)
      })
    })

    setStoreData(data)
  }

  const onChangeHandler = (storeId, val) => {
    const storeDataCopy = storeData
    const index = storeDataCopy.findIndex(e => e.storeId === storeId)

    storeDataCopy[index].externalStoreId = { ...storeDataCopy[index].externalStoreId, val }
    setStoreData(storeDataCopy)
  }

  return (
    <Modal title={null} visible={true} onClose={() => onClose()} width={649}>
      <Title level="h4" style={{ fontSize: '18px', marginTop: '-21px' }}>
        <IntlMessages id="store.integratePOSWithPeppo" />
      </Title>
      <ProviderBoxStyle>
        <div style={{ width: '100%', height: 'auto' }}>
          <div>
            <ProviderBoxStyle
              style={{
                height: '20px',
                marginTop: '24px'
              }}>
              <Text level="caption">
                <IntlMessages id="store.petpoojaId" /> {posId}
              </Text>
              <Button
                type="link"
                style={{
                  fontWeight: 500,
                  fontSize: '10px',
                  marginTop: '3%',
                  padding: '0'
                }}>
                <a href={MENU_SHARING_CODE_HELP_URL} target={'_blank'} rel="noreferrer">
                  <IntlMessages id="store.don'tKnowMenuSharingCode" />
                </a>
              </Button>
            </ProviderBoxStyle>
            <Divider />
            <div>
              <div style={{ display: 'flex' }}>
                <Text
                  level="caption"
                  style={{
                    fontWeight: 'bold',
                    color: COLORS.GRAY_TEXT,
                    width: '180px'

                  }}>
                  <IntlMessages id="sidebarContent.stores" />
                </Text>
                <Text
                  level="caption"
                  style={{
                    fontWeight: 'bold',
                    color: COLORS.GRAY_TEXT,
                    width: '85px'
                  }}>
                  <IntlMessages id="pos.posStatus" />
                </Text>
                <Text
                  level="caption"
                  style={{
                    fontWeight: 'bold',
                    color: COLORS.GRAY_TEXT,
                    width: '334px'
                  }}>
                  <IntlMessages id="store.menuSharingCode" />
                </Text>
              </div>
              {storeData?.map(elem => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '40px'
                  }}
                  key={elem.storeId}>
                  <Text
                    level="caption"
                    style={{
                      fontWeight: 'bold',
                      color: COLORS.GRAY_TEXT,
                      width: '180px'
                    }}>
                    {elem.storeName}
                  </Text>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '40px'
                  }}>
                    <Text
                      level="caption"
                      style={{
                        color: COLORS.GRAY_TEXT,
                        width: '100px'
                      }}>
                      {getPosStatus(elem.posStatus)}
                    </Text>
                    {elem.isExternalStoreIdExist ? (
                      <Input
                        style={{ width: '334px', marginBottom: '0' }}
                        type="visible"
                        label={null}
                        value={elem?.externalStoreId}
                        disabled={elem.externalStoreId.length}
                        onChange={e =>
                          onChangeHandler(elem.storeId, e.target.value)
                        }
                        placeholder={intlAlertMessage({
                          id: 'pos.enterMenuSharingCode',
                          intl
                        })}
                      />
                    ) : (
                      <Input
                        style={{ width: '334px', marginBottom: '0' }}
                        type="visible"
                        label={null}
                        disabled={elem.externalStoreId.length}
                        onChange={e =>
                          onChangeHandler(elem.storeId, e.target.value)
                        }
                        placeholder={intlAlertMessage({
                          id: 'pos.enterMenuSharingCode',
                          intl
                        })}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </ProviderBoxStyle>
      <Divider />
      <FooterStyle>
        <Button
          type="link"
          style={{ width: '96px', fontWeight: '600' }}
          onClick={() => onBackBtnClicked()}
          disabled={false}>
          <IntlMessages id="store.back" />
        </Button>
        <Button
          type="primary"
          style={{ width: '96px' }}
          disabled={!storeData.length}
          loading={isLoading}
          onClick={() => onPosIntegrateHandler()}>
          <IntlMessages id="button.submit" />
        </Button>
      </FooterStyle>
    </Modal>
  )
}

export default POSMenuSharingCodeModal
