import styled from 'styled-components'
import { COLORS } from 'Utils/constants'

export const Header = styled.div`
    display: flex;
    align-items: baseline;
    align-self: center;
    -webkit-align-self: initial;
    margin: 0 0;
  `
export const LinkBoxStyle = styled.div`
    display: flex;
    align-items: baseline;
    padding: 30px 0 0 0;
  `
export const InputBox = styled.div`
    padding: 0 5px;
    width: 30%;
  `
export const BtnStyle = styled.div`
    padding-left: 25px;
  `
export const SubmitButtonStyle = styled.div`
    margin: 2% 0;
  `
export const ImageBlock = styled.div`
    width: 20%;
    float: right;
  `
export const CardBlock = styled.div`
    float: left;
    width: 53%;
    margin-left: 5%;
  `
export const Container = styled.div`
    margin: 37px 244px 0 154px;
    @media (min-width: 768px && max-width: 1024px) {
      margin: 37px 50px 0 50px;
    }
    @media (max-width: 728px) {
      margin: 37px 0 0 0;
    }
  `
export const TooltipContainer = styled.div`
    background-color: ${COLORS.BG_WHITE};
    width: 180px;
    margin: 7px 0;
  `
export const BackButton = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: ${COLORS.NORMAL_TEXT};
  padding: 0px 5% 0 1%;
  cursor: pointer;
  width: 50%;
  display: contents;
`
export const TooltipCon = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
  `
