import { Switch as AntSwitch } from 'antd'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import theme from 'styled-theming'
import { COLORS } from 'Utils/constants'

export interface SwitchProps {
  title?: string
  /**
   * Can be set to toggle1, toggle2˙
   */
  variant?: 'toggle1' | 'toggle2'
  /**
   * Determine whether the Switch is checked˙
   */
  checked?: boolean
  /**
   * Disabled state of switch
   */
  disabled?: boolean
  /**
   * Set the loading status of switch
   */
  loading?: boolean
  /**
   * Trigger when clicked
   */
  onClick?: (checked: boolean, event: Event) => void
  /**
   * Trigger when the checked state is changing
   */
  onChange?: (checked: boolean, event: Event) => void
  /**
   * Content to be shown when the state is checked
   */
  checkedChildren?: string | ReactNode
  /**
   * Content to be shown when the state is unchecked
   */
  unCheckedChildren?: string | ReactNode
  /**
   * Apply custom css
   */
  style?: object
}

const checkedBgColor = theme.variants('mode', 'variant', {
  toggle1: { light: COLORS.PRIMARY },
  toggle2: { light: COLORS.PRIMARY }
})
const unCheckedBgColor = theme.variants('mode', 'variant', {
  toggle2: { light: COLORS.ERROR }
})
const StyledSwitch = styled(AntSwitch)`
  &.ant-switch {
      min-width: 35px;
      height: 17px;
      line-height: 13px;
      background-color: ${unCheckedBgColor};
      &::after{
          width: 13px;
          height: 13px;
          top: 1px;
      }
      .ant-switch-loading-icon {
        color: ${unCheckedBgColor};
      }
  }

  &.ant-switch-checked {
    background-color: ${checkedBgColor};
    &::after{
        width: 13px;
        height: 13px;
        top: 1px;
    }
  }

  &.ant-switch.switch-toggle2 {
    &::after{
        width: 15px;
        height: 15px;
        top: 0.5px;
        left: 0;
    }
  }

  &.ant-switch-checked.switch-toggle2 {
    &::after{
        margin-left: -0.5px;
        left: 100%;
    }
  }

  &.ant-switch-checked {
    .ant-switch-inner {
        margin-left: 6px;
        text-transform: uppercase;
        margin-right: 20px;
    }
  }

  .ant-switch-inner {
    margin-right: 6px;
    margin-left: 20px;
    text-transform: uppercase;
  }

  .ant-switch-loading-icon {
      width: 12px;
      height: 12px
      top: 2px;
      font-size: 11px;
  }

  &.ant-switch-checked.ant-switch-loading {
    .ant-switch-loading-icon {
        color: ${checkedBgColor};
    }
  }
  
`

/**
 * Peppo Switch selector to represent the switching between two states or on-off state
 */
export const Switch: React.FC<SwitchProps> = ({ variant = 'toggle1', disabled = false, loading = false, ...props }) => {
  return (
    <StyledSwitch variant={variant} className={`switch-${variant}`} disabled={disabled} loading={loading} {...props}>
      {props.children}
    </StyledSwitch>
  )
}
