import { Collapse, Divider,Input } from 'antd'
import { Tag, Text } from 'components'
import Styled from 'styled-components'
import { COLORS } from 'Utils/constants'
const { Panel } = Collapse


export const SelectedAddonsContainerStyles = Styled.div`
  background-color: ${COLORS.BG_WHITE};
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  width: 225px;
  height: 236px;
  box-shadow: 0px 1px 2px #00000029;
  margin: 15px 30px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const MaxAddonInputStyles = Styled(Input)`
  width: 32px;
  height: 23px;
  margin: 0px 5px;
  padding: 4px;
`
export const TitleStyles = Styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${COLORS.BORDER};
  padding: 5px;
`
export const FooterStyle = Styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${COLORS.BORDER};
  padding: 4px 0px;
`
export const FooterStyles = Styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const PanelLabel = Styled(props => <Text {...props}/>)`
    font-size: 13px;
    color: ${COLORS.DARK_TEXT};
  `
export const GroupTag = Styled(props => <Tag {...props}/> )``
export const DividerWithoutMargin = Styled(Divider)`
    margin: 0;
  `