/* eslint-disable camelcase */
import { message } from 'antd'
import { sessionExpired } from 'i18n/locales/en_US.json'
import {
  BLACKLIST_KEYWORDS,
  PROMOTIONS_API_STATUS,
  PROMOTIONS_ERROR_CODE
} from 'Utils/constants'
import { getAuthToken } from 'Utils/localStorageHandlers/getter'

const baseUrl = process.env.REACT_APP_PROMOTION_URL
const posBaseUrl = process.env.REACT_APP_POS_URL
const pmsBaseUrl = process.env.REACT_APP_REST_PMS_URL

const config = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'wcore-api-key': process.env.REACT_APP_WCORE_API_KEY
  }
}

async function posClient(endpoint: string, payload: any, method: string) {
  const token = getAuthToken()
  const response = await fetch(`${posBaseUrl}${endpoint}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(payload)
  })

  return response
}
async function getPosClient(endpoint: string, method: string) {
  const token = getAuthToken()
  const response = await fetch(`${posBaseUrl}${endpoint}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  })
  const { data } = await response.json()

  return data
}

async function getMetabaseUrl(question_id) {
  const token = getAuthToken()

  try {
    const response = await fetch(`${pmsBaseUrl}/get-metabase-signed-url`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`
      },
      body: JSON.stringify(
        question_id
      )
    })

    const data = await response.json()

    return data
  } catch (error) {
    console.log(error)
  }
}

async function promotionClient(endpoint: string, payload: any) {
  const token = getAuthToken()
  const { headers, ...rest } = config
  const response = await fetch(`${baseUrl}${endpoint}`, {
    ...rest,
    headers: { ...headers, Authorization: `Bearer ${token}` },
    body: JSON.stringify(payload)
  })

  const { data, meta } = await response.json()

  if (meta.status !== PROMOTIONS_API_STATUS.ALL_OK && meta.errors.length) {
    const [error] = meta.errors

    if (error.error_code === PROMOTIONS_ERROR_CODE.NOT_AUTHORIZED) {
      message.warn(sessionExpired)
      setTimeout(() => {
        localStorage.clear()
        sessionStorage.clear()
        location.reload()
      }, 1000)
    }
  }

  return data
}

async function promotionClientBlob(endpoint: string, payload: any) {
  const token = getAuthToken()
  const { headers, ...rest } = config
  const response = await fetch(`${baseUrl}${endpoint}`, {
    ...rest,
    headers: { ...headers, Authorization: `Bearer ${token}` },
    body: JSON.stringify(payload)
  })

  const blob = await response.blob()

  return blob
}

async function getBlackListNames() {
  const URL = BLACKLIST_KEYWORDS
  const response = await fetch(URL)

  return response.json()
}

export {
  posClient,
  getPosClient,
  promotionClient,
  getBlackListNames,
  getMetabaseUrl,
  promotionClientBlob
}
