/* eslint-disable */
export const IMAGES = {
  current_location: require('./images/currentLocation.svg').default,
  login: require('./images/login.png').default,
  mail_sent: require('./images/mailSent.svg').default,
  map_marker: require('./images/mapMarker.svg').default,
  peppo_logo_transparent: require('./images/peppoLogoTransparent.png').default,
  peppo_logo_white: require('./images/peppoLogoWhite.svg').default,
  sample_restaurant_img: require('./images/sample-img.png').default,
  merchant_id_img: require('./images/merchant-id.png').default,
  download_img: require('./images/download.png').default,
  banner_img: require('./images/pop-up-banner.png').default,
  dunzo: require('./images/dunzo.png').default,
  shadow_fax: require('./images/shadow-fax.png').default,
  wefast: require('./images/wefast.png').default,
  peppo_bms_logo: require('./images/peppo_bms.png').default,
  menu_disabled_screen: require('./images/menu-disabled.png').default
}

export const SOUNDS = {
  new_order_alarm: require('./sounds/newOrderAlarm.mp3').default,
}

export const ICONS = {
  store: require('./icons/store.svg').default,
  menu: require('./icons/menu.svg').default,
  activeOrder: require('./icons/activeOrder.svg').default,
  orderHistory: require('./icons/orderHistory.svg').default,
  report: require('./icons/report.svg').default,
  filter: require('./icons/filter.svg').default,
  slide: require('./icons/ham_slide.svg').default,
  dragIcon: require('./icons/drag_pin.svg').default,
  selfPickupIcon: require('./icons/self_pickup.svg').default,
  whatsapp: require('./icons/whatsapp.svg').default,
  refresh: require('./icons/refresh.svg').default,
  download: require('./icons/download.svg').default,
}
