/* eslint-disable indent */
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Col, Divider, Row } from 'antd'
import { Button, IntlMessages, Title } from 'components'
import Loader from 'components/simple/loader'
import { orderxClient, pmsClient } from 'index'
import * as compose from 'lodash/flowRight'
import React from 'react'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import * as menuService from 'services/menuService'
import * as pmsService from 'services/pmsServices'
import { getPosConfiguration } from 'services/posService'
import * as storeService from 'services/storeService'
import {
  getOrgDetails,
  getUserDetails,
  getViewType,
  setErrMsg,
  setUserDetails
} from 'Utils'
import {
  ORG_CONSTANT,
  ORG_STATUS,
  PASSWORD_REGEX,
  PHONE_NUMBER_REGEX,
  RADIUS,
  ROLE_TYPE,
  STATUS,
  STORE_HELP_URL,
  USER_NAME_REGEX,
  VIEW_TYPES
} from 'Utils/constants'
import {
  getChannelsData,
  getIsUnsavedStoreDetails,
  getWcoreOrganizationId
} from 'Utils/localStorageHandlers/getter'
import { setIsUnsavedStoreDetails } from 'Utils/localStorageHandlers/setter'
import { StoreChargeTypeEnum } from 'Utils/storeUtils'

import {
  BasicInfo,
  ConfigureDeliveryFleet,
  ConfirmationModal,
  StoreManager,
  StoreTimings
} from './components'

type Props = {
  history: any
  location: any
  match: any
  intl: any
}

type State = {
  isLoading: boolean
  saveInProgress: boolean
  isUnsavedChanges: boolean
}

class NewStore extends React.Component<Props, State> {
  wcoreOrganizationId: string

  storeId: string

  isOnboarding: boolean

  viewType: string

  currentStoreName: string

  storeCode: string

  refData: any

  channels: any[]

  catalogCode: string

  unblock: Function

  targetLocation: any

  storeNamesInUse: string[]

  managerRoleId: string

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      saveInProgress: false,
      isUnsavedChanges: false
    }
    this.refData = React.createRef()
    this.viewType = getViewType()
    this.channels = getChannelsData()
  }

  storeData = () => {
    return this.refData?.current || {}
  }

  setStoreData = data => {
    this.refData.current = {
      ...this.storeData(),
      ...data
    }
  }

  useStoreData = () => {
    return [this.storeData, this.setStoreData]
  }

  componentDidMount() {
    this.init()
    if (this.storeId) {
      this.handleStoreDataFetch()
    } else {
      this.getCatalogs() // To get catalogCode, required for createStore API
      this.getManagerRoleId()
    }
    this.getStoreNames()
  }

  init() {
    const orgDetails = getOrgDetails()

    this.wcoreOrganizationId = getWcoreOrganizationId()
    this.storeId = this.props.match?.params?.id || null
    this.isOnboarding = Boolean(
      orgDetails && orgDetails.onboardingStatus === ORG_STATUS.INITIATED
    )

    this.unblock = this.props.history.block(targetLocation => {
      this.targetLocation = targetLocation
      const isUnsavedChanges = getIsUnsavedStoreDetails()

      if (isUnsavedChanges) {
        this.setState({ isUnsavedChanges })

        return false
      }
    })
  }

  handleStoreDataFetch = () => {
    switch (this.viewType) {
      case VIEW_TYPES.PEPPO_ONLY: {
        this.fetchAllStoreData(this.storeId, this.wcoreOrganizationId)
        break
      }
      case VIEW_TYPES.BMS_DEALS_ONLY: {
        this.fetchBMSStoreData(this.storeId, this.wcoreOrganizationId)
        break
      }
      default: {
        this.fetchAllStoreData(this.storeId, this.wcoreOrganizationId)
      }
    }
  }

  async fetchAllStoreData(storeId, wcoreOrganizationId) {
    const payload = {
      storeId,
      organizationId: wcoreOrganizationId
    }

    this.setState({ isLoading: true })
    try {
      const promises = [
        storeService.getStore(orderxClient, payload.storeId),
        storeService.getStoreTimings(orderxClient, payload.storeId),
        process.env.REACT_APP_SENTRY_ENV !== 'development' &&
          (await getPosConfiguration(wcoreOrganizationId, storeId))
      ]
      const details = await Promise.all(promises)

      this.stateInitializer(details)
    } catch (err) {
      console.log('Error fetching store data', err)
    } finally {
      this.setState({ isLoading: false })
    }
  }

  async fetchBMSStoreData(storeId, wcoreOrganizationId) {
    const payload = {
      storeId,
      organizationId: wcoreOrganizationId
    }

    this.setState({ isLoading: true })
    try {
      const promises = [storeService.getStore(orderxClient, payload.storeId)]
      const [store] = await Promise.all(promises)

      this.stateInitializer([store, {}, {}])
    } catch (err) {
      console.log('Error fetching store data', err)
    } finally {
      this.setState({ isLoading: false })
    }
  }

  getCatalogs = async () => {
    try {
      const catalogsResponse = await menuService.getCatalogue(
        orderxClient,
        this.wcoreOrganizationId
      )
      const { catalogs } = catalogsResponse.data
      const catalog = catalogs[0]

      this.catalogCode = catalog?.catalogCode || ''
    } catch (err) {
      console.log('Err fetching catalogs', err)
    }
  }

  getManagerRoleId = async () => {
    try {
      const response = await storeService.getRoles(orderxClient, {
        name: ROLE_TYPE.MANAGER,
        organizationId: this.wcoreOrganizationId
      })

      this.managerRoleId = response?.data.roles[0].id
    } catch (err) {
      console.log('Error fetching manager role id', err)
    }
  }

  getStoreNames = async () => {
    try {
      const response = await storeService.getStoreNames(
        orderxClient,
        this.wcoreOrganizationId
      )

      this.storeNamesInUse = response.data.stores.data
        .filter(store => store.id !== this.storeId)
        .map(store => store.name)
    } catch (err) {
      console.log('Error fetching store names', err)
    }
  }

  stateInitializer = details => {
    const [store, storeTimings, posDetails] = details

    this.setStoreData({
      store: store?.data?.store,
      storeTimings: storeTimings?.data?.storeOpenTimings,
      posConfigurationDetails: posDetails
    })

    this.currentStoreName = this.storeData().store.name
    console.log(this.storeData())
  }

  createManagerUser = storeId => {
    const { storeManager } = this.storeData()
    const { name, password, phone, whatsappSupport } = storeManager
    const addUserToOrganizationInput = {
      organization_id: this.wcoreOrganizationId,
      role_id: this.managerRoleId,
      userData: {
        userName: name,
        firstName: name,
        password
      }
    }
    const addStaffInput = {
      staff_role: ROLE_TYPE.STORE_MANAGER,
      name,
      phone,
      whatsappSupport
    }

    return [
      storeService
        .addUserToOrganization(orderxClient, addUserToOrganizationInput)
        .then(addUserToOrganizationResponse => {
          const userId =
            addUserToOrganizationResponse.data.addUserToOrganization.id

          storeService.addUserToStore(orderxClient, userId, storeId)
        }),
      storeService
        .createStaff(orderxClient, addStaffInput)
        .then(addStaffResponse => {
          const addStaffMemberToStoreInput = {
            staffMemberId: addStaffResponse.data.addStaff.id,
            storeId
          }

          storeService.addStaffMemberToStore(
            orderxClient,
            addStaffMemberToStoreInput
          )
        })
    ]
  }

  addUpdateStoreTimings = storeId => {
    const { storeTimings } = this.storeData()
    const newTimings = storeTimings.filter(({ days, data }) => {
      const newSlots = data.filter(slot => !slot.id)

      if (newSlots.length) {
        return {
          days,
          data: newSlots
        }
      }
    })

    if (!newTimings.length) return
    const addStoreTimingInput = {
      organizationId: this.wcoreOrganizationId,
      storeId,
      storeTimings: newTimings
    }

    return storeService.addBulkStoreOpenTiming(
      orderxClient,
      addStoreTimingInput
    )
  }

  addUpdateDeliveryCharge = storeId => {
    const { deliveryChargeValue, deliveryCharge } = this.storeData()
    const deliveryChargeId = deliveryCharge?.id || ''

    if (!this.storeId || (deliveryChargeValue !== 0 && !deliveryChargeValue)) {
      return
    }
    const deliveryChargeInput = {
      chargeType: StoreChargeTypeEnum.DELIVERY_CHARGE,
      chargeValueType: 'ABSOLUTE',
      storeId,
      chargeValue: parseFloat(deliveryChargeValue.toString())
    }

    return storeService.storeCharges(
      orderxClient,
      deliveryChargeId,
      deliveryChargeInput
    )
  }

  addDeliveryAgents = async storeId => {
    const { deliveryAgents } = this.storeData()

    const staffDetails = deliveryAgents
      .filter(agent => !agent.id)
      .map(agent => {
        return {
          staff_role: agent.staff_role,
          name: agent.name,
          phone: agent.phone
        }
      })

    if (!staffDetails.length) return
    const createBulkStaffResponse = await storeService.createBulkStaffMembers(
      orderxClient,
      staffDetails
    )
    const addStaffMembersToStoreInput = {
      storeId,
      staffMemberIds: createBulkStaffResponse.data.addBulkStaffMembers.map(
        staff => staff.id
      )
    }

    return storeService.addStaffMembersToStore(
      orderxClient,
      addStaffMembersToStoreInput
    )
  }

  addUpdateDeliveryDiscountStatusAndPercentage = storeId => {
    const { deliveryDiscount, store } = this.storeData()
    const createPMSStoreInput = {
      wcoreStoreId: storeId,
      name: store.name,
      code: store.code,
      discounts: [
        {
          code: 'DDIS',
          discountValue: deliveryDiscount?.value,
          appliedOn: 'Delivery',
          description: '',
          status: deliveryDiscount?.status
        }
      ]
    }

    return pmsService.createUpdatePMSStore(pmsClient, createPMSStoreInput)
  }

  updateManagerPassword = () => {
    const { storeManager, store } = this.storeData()
    const managerUser = store.users.find(
      user => user.roles[0].name === ROLE_TYPE.MANAGER
    )

    if (!storeManager?.password) return
    const updateUserPasswordInput = {
      userId: managerUser.id,
      password: storeManager.password
    }

    return storeService.updateUserPassword(
      orderxClient,
      updateUserPasswordInput
    )
  }

  updateStoreManager = () => {
    const { storeManager } = this.storeData()
    const editStaffInput = {
      id: storeManager.id,
      phone: storeManager.phone,
      staff_role: ROLE_TYPE.STORE_MANAGER,
      whatsappSupport: storeManager.whatsappSupport
    }

    return storeService.editStaff(orderxClient, editStaffInput)
  }

  updateServiceableRadius = () => {
    const { serviceableArea, serviceabilityRadius } = this.storeData()
    const updateStoreServiceAreaInput = {
      id: serviceableArea.id,
      organizationId: this.wcoreOrganizationId,
      serviceAreaValue: (serviceabilityRadius * 1000).toString(),
      serviceAreaType: RADIUS
    }

    return storeService.updateStoreServiceArea(
      orderxClient,
      updateStoreServiceAreaInput
    )
  }

  addUpdateDeliveryArea = storeId => {
    const { mapData, deliveryArea } = this.storeData()

    if (!mapData) return
    const addUpdateDeliveryAreaInput = {
      organizationId: this.wcoreOrganizationId,
      deliveryAreaType: 'GEO_AREA',
      deliveryAreaValue: JSON.stringify(mapData.polygonGeoJSON.area)
    }

    if (deliveryArea?.id) {
      const updateDeliveryAreaInput = {
        ...addUpdateDeliveryAreaInput,
        id: deliveryArea.id
      }

      return storeService.updateDeliveryArea(
        orderxClient,
        updateDeliveryAreaInput
      )
    }

    const addDeliveryAreaInput = {
      ...addUpdateDeliveryAreaInput,
      storeId
    }

    return storeService.addDeliveryArea(orderxClient, addDeliveryAreaInput)
  }

  removeDeliveryArea = () => {
    const { deliveryArea } = this.storeData()

    if (!deliveryArea?.id) return
    const removeDeliveryAreaInput = {
      deliveryAreaId: deliveryArea.id,
      storeId: this.storeId,
      organizationId: this.wcoreOrganizationId
    }

    return storeService.removeDeliveryArea(
      orderxClient,
      removeDeliveryAreaInput
    )
  }

  bulkInactiveDeliveryAgents = () => {
    const { deliveryAgents } = this.storeData()
    const agentList = deliveryAgents.filter(
      agent => agent.status !== STATUS.INACTIVE
    )

    if (!agentList.length) return []

    return agentList.map(agent =>
      storeService.inactiveStaffMember(orderxClient, { id: agent.id })
    )
  }

  handleCreateUpdateStore = (storedId = null) => {
    const { store, serviceabilityRadius = 8 } = this.storeData()
    const {
      name,
      geoLocation,
      addressLine1,
      addressLine2,
      country,
      state,
      pinCode,
      city
    } = store
    const createUpdateStoreInput = {
      latitude: `${geoLocation.lat}`,
      longitude: `${geoLocation.lng}`,
      organizationId: this.wcoreOrganizationId,
      name,
      addressLine1,
      addressLine2,
      country,
      state,
      pinCode,
      city
    }

    if (storedId) {
      const updateStoreInput = {
        ...createUpdateStoreInput,
        id: this.storeId
      }

      return storeService.updateStore(orderxClient, updateStoreInput)
    }
    const addServiceArea = {
      serviceAreaValue: (Number(serviceabilityRadius) * 1000).toString(),
      serviceAreaType: RADIUS
    }

    const createStoreInput = {
      ...createUpdateStoreInput,
      ...addServiceArea,
      organizationId: this.wcoreOrganizationId,
      storeFormatCode: ORG_CONSTANT.STOREFORMAT,
      catalogCode: this.catalogCode,
      channelCodes: this.channels.map(channel => channel.channelCode)
    }

    const createStoreInputWithCatalogue = {
      ...createStoreInput,
      catalogCode: this.catalogCode
    }

    const payload = this.catalogCode
      ? createStoreInputWithCatalogue
      : createStoreInput

    return storeService.createStore(orderxClient, payload)
  }

  handleCreateStore = async () => {
    const { internalFleetStatus } = this.storeData()

    const createStoreResponse = await this.handleCreateUpdateStore()
    const storeResponse = createStoreResponse?.data?.createStore
    const userDetails = getUserDetails()

    userDetails.store = [storeResponse]
    setUserDetails(userDetails)
    this.setStoreData({ store: storeResponse })
    const storeId = storeResponse.id
    const promises = [
      this.addUpdateStoreTimings(storeId),
      ...this.createManagerUser(storeId),
      this.addUpdateDeliveryDiscountStatusAndPercentage(storeId)
    ]

    if (internalFleetStatus === STATUS.ACTIVE) {
      promises.push(
        ...[
          this.addDeliveryAgents(storeId),
          this.addUpdateDeliveryCharge(storeId),
          this.addUpdateDeliveryArea(storeId)
        ]
      )
    }

    try {
      await Promise.all(promises)
      this.handleSubmitSuccess()
    } catch (err) {
      setErrMsg('errorCreateStore', this.props.intl)
      this.setState({ saveInProgress: false })
    }
  }

  handleUpdateStore = async () => {
    const { internalFleetStatus } = this.storeData()
    const promises = [
      this.handleCreateUpdateStore(this.storeId),
      this.addUpdateStoreTimings(this.storeId),
      this.updateStoreManager(),
      this.updateManagerPassword()
    ]

    if (internalFleetStatus === STATUS.ACTIVE) {
      promises.push(
        ...[
          this.addDeliveryAgents(this.storeId),
          this.addUpdateDeliveryCharge(this.storeId),
          this.addUpdateDeliveryArea(this.storeId)
        ]
      )
    } else {
      promises.push(
        ...[
          this.updateServiceableRadius(),
          this.addUpdateDeliveryDiscountStatusAndPercentage(this.storeId),
          this.removeDeliveryArea(),
          ...this.bulkInactiveDeliveryAgents()
        ]
      )
    }
    try {
      await Promise.all(promises)
      this.handleSubmitSuccess()
    } catch (err) {
      setErrMsg('errorUpdateStore', this.props.intl)
      this.setState({ saveInProgress: false })
    }
  }

  handleBMSUpdateStore = async () => {
    if (!this.isBasicInfoValid()) return
    const isValidFields = await this.isStoreManagerFieldsValid()

    if (!isValidFields) return

    this.setState({ saveInProgress: true })
    const promises = [
      this.handleCreateUpdateStore(this.storeId),
      this.updateStoreManager(),
      this.updateManagerPassword()
    ]

    try {
      await Promise.all(promises)
      this.handleSubmitSuccess()
    } catch (err) {
      setErrMsg('errorUpdateStore', this.props.intl)
      this.setState({ saveInProgress: false })
    }
  }

  isStoreNameUnique = storeName => {
    const storeNameIndex = this.storeNamesInUse.findIndex(
      name => name.toLowerCase() === storeName.toLowerCase()
    )

    return storeNameIndex === -1
  }

  checkUserNameAvailability = async userName => {
    try {
      const response = await storeService.checkUserName(orderxClient, {
        userName
      })

      return response.data.checkUserNameAvailability
    } catch (err) {
      return false
    }
  }

  handleValidations = async () => {
    const {
      storeTimings,
      internalFleetStatus,
      deliveryAgents,
      deliveryArea,
      mapData,
      deliveryCharge,
      deliveryChargeValue
    } = this.storeData()

    if (!this.isBasicInfoValid()) {
      return false
    }

    // Start Store Timings Validation
    let slotCount = 0

    storeTimings?.forEach(day => {
      slotCount += day.data.length
    })
    if (slotCount === 0) {
      setErrMsg('store.oneTimeSlotRequired', this.props.intl)

      return false
    }
    // End Store Timings Validation

    // Start Store Manager Fields Validation
    const isValidFields = await this.isStoreManagerFieldsValid()

    if (!isValidFields) return false

    // End Store Manager Fields Validation

    // Start Configure Fleet Fields Validation
    if (!internalFleetStatus) {
      setErrMsg('store.selectDeliveryFleet', this.props.intl)

      return false
    }
    if (internalFleetStatus === STATUS.ACTIVE) {
      if (!deliveryAgents?.length) {
        setErrMsg('store.errMsg.oneDeliveryAgentNeeded', this.props.intl)

        return false
      }
      if (
        !deliveryAgents?.filter(agent => agent.status === STATUS.ACTIVE).length
      ) {
        setErrMsg('store.errMsg.markOneDeliveryAgentActive', this.props.intl)

        return false
      }
      if (
        deliveryChargeValue !== 0 &&
        !deliveryChargeValue &&
        !deliveryCharge
      ) {
        setErrMsg('store.errMsg.enterDeliveryCharge', this.props.intl)

        return false
      }
      if (!(mapData?.polygonArray.length > 1) && !deliveryArea) {
        setErrMsg('store.errMsg.defineDeliveryArea', this.props.intl)

        return false
      }
    }
    // End Configure Fleet Fields Validation

    return true
  }

  isBasicInfoValid = () => {
    const { store } = this.storeData()

    if (!store?.name || !store?.geoLocation?.lat || !store?.geoLocation?.lng) {
      setErrMsg('category.errMsg.fillAllTheFields', this.props.intl)

      return false
    }

    if (!store?.addressLine1 && !store?.addressLine2) {
      setErrMsg('store.errMsg.setYourStoreLocationFirst', this.props.intl)

      return false
    }

    if (!store?.city || !store?.country || !store?.pinCode || !store?.state) {
      setErrMsg('store.errMsg.enterValidAddress', this.props.intl)

      return false
    }
    if (!this.isStoreNameUnique(store.name)) {
      setErrMsg('store.storeNameIsUnique', this.props.intl)

      return false
    }

    return true
  }

  isStoreManagerFieldsValid = async () => {
    const { storeManager } = this.storeData()

    if (!PHONE_NUMBER_REGEX.test(storeManager?.phone.toLowerCase())) {
      setErrMsg('store.validMobileNum', this.props.intl)

      return false
    }
    if (!USER_NAME_REGEX.test(storeManager?.name.toLowerCase())) {
      setErrMsg('store.errMsg.enterValidUserName', this.props.intl)

      return false
    }
    if (
      (storeManager.isEditPassword || !this.storeId) &&
      !PASSWORD_REGEX.test(storeManager.password)
    ) {
      setErrMsg('store.errMsg.enterValidPassword', this.props.intl)

      return false
    }
    if (!this.storeId) {
      const isUserNameAvailable = await this.checkUserNameAvailability(
        storeManager.name
      )

      if (!isUserNameAvailable) {
        setErrMsg('store.errMsg.duplicateUserName', this.props.intl)

        return false
      }
    }

    return true
  }

  onPressBack = () => {
    this.props.history.goBack()
  }

  handleSubmit = async () => {
    console.log(this.storeData())
    if (this.viewType === VIEW_TYPES.BMS_DEALS_ONLY) {
      this.handleBMSUpdateStore()

      return
    }
    const areAllFormFieldsValid = await this.handleValidations()

    if (!areAllFormFieldsValid) return
    this.setState({ saveInProgress: true })
    if (this.storeId) {
      this.handleUpdateStore()
    } else {
      this.handleCreateStore()
    }
  }

  handleSubmitSuccess = () => {
    this.setState({ saveInProgress: false })
    setIsUnsavedStoreDetails(false)
    this.onPressBack()
  }

  toggleConfirmationModal = () => {
    this.setState({ isUnsavedChanges: false })
  }

  goToTargetLocation = () => {
    setIsUnsavedStoreDetails(false)
    this.toggleConfirmationModal()
    if (this.unblock) this.unblock()
    this.props.history.push({ pathname: this.targetLocation.pathname })
  }

  renderView = () => {
    switch (this.viewType) {
      case VIEW_TYPES.PEPPO_ONLY: {
        return (
          <>
            <BasicInfo useStoreData={this.useStoreData} />
            <StoreTimings
              useStoreData={this.useStoreData}
              storeId={this.storeId}
            />
            <StoreManager
              useStoreData={this.useStoreData}
              storeId={this.storeId}
            />
            <ConfigureDeliveryFleet
              useStoreData={this.useStoreData}
              storeId={this.storeId}
            />
          </>
        )
      }
      case VIEW_TYPES.BMS_DEALS_ONLY: {
        return (
          <>
            <BasicInfo useStoreData={this.useStoreData} />
            <StoreManager
              useStoreData={this.useStoreData}
              storeId={this.storeId}
            />
          </>
        )
      }
    }
  }

  render() {
    if (this.state.isLoading) {
      return <Loader intl={this.props.intl} />
    }

    return (
      <div className="main">
        <Row>
          <Col span={22}>
            <Title level="h4" style={{ margin: '0 25px' }}>
              <ArrowLeftOutlined
                className="backIcon"
                onClick={this.onPressBack}
              />
              {this.currentStoreName || (
                <IntlMessages id="store.createNewStore" />
              )}
            </Title>
          </Col>
          <Col span={2}>
            <a
              className="menu-help"
              href={STORE_HELP_URL}
              target="_blank"
              rel="noopener noreferrer">
              <IntlMessages id="menu.help" />
            </a>
          </Col>
        </Row>
        <Divider />
        <Row style={{ margin: '12px 140px', boxSizing: 'border-box' }}>
          {this.renderView()}
          <Button
            type="primary"
            loading={this.state.saveInProgress}
            style={{ marginTop: '12px' }}
            onClick={this.handleSubmit}>
            <IntlMessages id="store.saveChanges" />
          </Button>
        </Row>
        <ConfirmationModal
          isUnsavedChanges={this.state.isUnsavedChanges}
          onClick={this.toggleConfirmationModal}
          goToTargetLocation={this.goToTargetLocation}
          stayAction={this.toggleConfirmationModal}
        />
      </div>
    )
  }
}

export default injectIntl(compose(withRouter)(NewStore))
