import { gql } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'
import { Layout } from 'antd'
import { ErrorBoundary, Topbar } from 'components'
import { Location } from 'history'
import * as compose from 'lodash/flowRight'
import * as React from 'react'
import { injectIntl } from 'react-intl'

import OrderXRoutes from '../../../routes/index'
import Sidebar from '../Sidebar'
const { Content } = Layout

interface IState {}

interface IProps {
  updateWindowWidth?: any
  location?: Location
  intl?: any
}
export class MainApp extends React.Component<IProps, IState> {
  componentDidMount() {
    window.addEventListener('resize', () => {
      this.props.updateWindowWidth({ variables: { width: window.innerWidth } })
      const isOnboarding = JSON.parse(localStorage.getItem('isOnboarding'))
      const screeName = this.props.location.pathname.split('/')[2]

      if (screeName !== 'onboarding' && !isOnboarding) {
        if (typeof document.querySelector('.gx-app-sidebar') !== 'undefined') {
          document.querySelector('.gx-app-sidebar').classList.remove('gx-d-none')
        }
      }
    })
  }

  render() {
    const { intl } = this.props

    return (
      <Layout className="gx-app-layout">
        <Topbar />
        <Layout>
          <Sidebar />
          <Layout>
            <Content className={'gx-layout-content'}>
              <ErrorBoundary>
                <OrderXRoutes intl={intl} />
              </ErrorBoundary>
            </Content>
            {/* <Footer>  <div className="gx-layout-footer-content">Copyright First Walkin Technologies © 2019</div> </Footer> */}
          </Layout>
        </Layout>
      </Layout>
    )
  }
}

const UPDATE_WINDOW_WIDTH = gql`
  mutation updateWindowWidth($width: Int) {
    updateWindowWidth(width: $width) @client
  }
`

export default injectIntl(compose(graphql(UPDATE_WINDOW_WIDTH, { name: 'updateWindowWidth' }))(MainApp))
