import { Dropdown, IntlMessages } from 'components'
import React from 'react'

import { StyledCheckBox, StyledCheckBoxContainer,StyleMenuDropdown } from '../../../Utils/menuUtils/styleConstants'

export interface MenuTimingsDropdownProps {
  selectedMenuTiming?: any
  onChangeItem?: any
  isButtonAvailable?: boolean
  buttonLabel?: string
  onButtonClick?: any
  data?: any
  isMenuTimingEnable: boolean
  onMenuTimingHandler: any
}
export const MenuTimingsDropdown: React.FC<MenuTimingsDropdownProps> = props => {
  return (
    <StyleMenuDropdown>
      <StyledCheckBoxContainer>
        <StyledCheckBox
          name="menuTimeSlot"
          checked={props?.isMenuTimingEnable}
          onChange={() => {
            props.onMenuTimingHandler()
            props.isMenuTimingEnable && props.onChangeItem('')
          }}
        />
      </StyledCheckBoxContainer>
      <Dropdown
        type="withTitle"
        data={props?.data}
        label={<IntlMessages id="menuTiming.customTime" />}
        size="small"
        isDisabled={!props.isMenuTimingEnable}
        isButtonAvailable={props.isButtonAvailable}
        buttonLabel={props.buttonLabel}
        onButtonClick={() => props.onButtonClick()}
        onChangeItem={item => {
          props.onChangeItem(item)
        }}
        selectedItem={props?.selectedMenuTiming}
      />
    </StyleMenuDropdown>
  )
}
