import './style.css'

import { Icon } from '@ant-design/compatible'
import { ApolloClient, gql, useSubscription } from '@apollo/client'
import { ApolloProviderProps } from '@apollo/client/react/context'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { Badge, Menu } from 'antd'
import { configureClient } from 'appApollo/client'
import { ICONS, SOUNDS } from 'assets'
import { IntlMessages } from 'components'
import { History } from 'history'
import * as compose from 'lodash/flowRight'
import React, { useEffect, useState } from 'react'
import { RouteChildrenProps } from 'react-router'
import { Link, withRouter } from 'react-router-dom'
import { getOrderCount } from 'services'
import { GET_NEW_ORDERS } from 'services/query'
import { Auxiliary, getUserDetails, getViewType, isAdmin, isRestaurantLive, showSideBar } from 'Utils'
import { ORDER_STATUS, VIEW_TYPES } from 'Utils/constants'
import { getUserData, getWcoreOrganizationId } from 'Utils/localStorageHandlers/getter'
import { setSubscriptionNotification } from 'Utils/localStorageHandlers/setter'

interface SidebarContentProps extends RouteChildrenProps, ApolloProviderProps<any> {
  pathname: any
  history: History
  isCollapsed: boolean
}

const effect = new Audio(SOUNDS.new_order_alarm)

effect.muted = true
effect.loop = true

const getNewOrderSubscription = () => {
  const org_Id = getWcoreOrganizationId()
  const { data, loading, error } = useSubscription(
    GET_NEW_ORDERS,
    { variables: { orgId: org_Id } }
  )

  if (data !== undefined) {
    return data
  }
}

const SidebarContent = (props: SidebarContentProps, client: ApolloClient<any>) => {
  const isAdminUser = isAdmin()
  const viewType = getViewType()
  const isBMSDealsOnlyView = viewType === VIEW_TYPES.BMS_DEALS_ONLY

  const newOrderResponse = !isBMSDealsOnlyView && getNewOrderSubscription()

  const [orderHash, setOrderHash] = useState({})
  const [totalItems, setTotalItems] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [orderXClient, setOrderXClint] = useState(null)
  const [isNewOrderAvailable, setIsNewOrderAvailable ]= useState(null)


  const playSoundAlert = () => {
    effect.play()
    effect.muted = false
  }

  const showNotification = (totalItems) => {
    const hashData = orderHash
    const totalData = totalItems.data
    let playBufferCheck = false

    totalData?.forEach(async element => {
      const propertyName = element.id

      if (orderHash[propertyName]) {
        if (isPlaying === false && playBufferCheck === false) {
          playBufferCheck = true
          setIsPlaying(true)
          playSoundAlert()
        }
      } else {
        const args = {
          duration: 10000,
          key: element.id,
          message: <IntlMessages id="sidebarContent.youHaveNewOrder" />,
          description: `Bill Value : \u20B9 ${element?.totalOrderAmount}`,
          onClose: () => {
          // Effect.pause();
          },
          onClick: () => {
            props.history.push('/orderx/orders', {})
          }
        }

        hashData[element.id] = args

        if (isPlaying === false && playBufferCheck === false) {
        // Console.log('Initiate Play');
          playBufferCheck = true
          setOrderHash(hashData)
          setIsPlaying(true)
          // Notification.open(args);
          playSoundAlert()
        } else {
        // Notification.open(args);
          setOrderHash(hashData)
        }
      }
    })
  }
  const orderDataTimer = (client) => {
    setInterval(() => {
      onOrderDataHandler(client)
    }, 10000)
  }

  useEffect(() => {

    configureClient(client => {
      setOrderXClint(client)
      !isBMSDealsOnlyView && orderDataTimer(client)
    })
    if (!isAdminUser || isRestaurantLive()) {
      localStorage.removeItem('isOnboarding')
      showSideBar()
    }
    
  }, [])

  

  const closeOldNotifications = (totalData) => {
    const allNotificationIds = Object.keys(orderHash)

    totalData?.forEach((element, index) => {
      if (allNotificationIds.indexOf(element.id) !== -1) {
        allNotificationIds.splice(index, 1)
      }
    })
  }

  

  const onOrderDataHandler = async (orderXClient) => {
    if (isAdminUser && (!isRestaurantLive() || getUserDetails().resetPassword)) {
      return
    }
    const org_id = getWcoreOrganizationId()
    const getOrderCountInput = {
      orderStatus: [ORDER_STATUS.ORDER_CREATED],
      organizationId: org_id
    }

    if (!isAdminUser) {
      const userData = getUserData()

      getOrderCountInput['storeId'] = userData.store[0].id
    }
    try {
      const response = await getOrderCount(orderXClient, getOrderCountInput)
      const data = response.data.orders
      const totalItems = data ? data.paginationInfo.totalItems : 0

      setTotalItems(totalItems)
      setIsPlaying(totalItems > 0 ? (isPlaying ? isPlaying : false) : false)
      setIsNewOrderAvailable(newOrderResponse)
      closeOldNotifications(data.data)

      if (totalItems > 0) {
        showNotification(data)
      } else {
        effect.pause()
        effect.muted = true
      }
        
    } catch (err) {
      console.error(err)
    }
  }

  if(!isBMSDealsOnlyView && newOrderResponse !== isNewOrderAvailable){
    onOrderDataHandler(orderXClient) 
    setSubscriptionNotification(newOrderResponse)
  }

  const orderCountBadge = () => {
    return <Badge count={totalItems} overflowCount={20} />
  }

  const menuListItem = () => {
    return (
      <Menu.Item key="menu-list">
        <Link to={'/orderx/menu-list'}>
          <Icon style={{ fontSize: '18px' }} component={() => <img src={ICONS.menu} alt="menu" />} />
          <span className="sideMenuText">
            <IntlMessages id="sidebar.navigation.menu" />
          </span>
        </Link>
      </Menu.Item>
    )
  }


  const ordersItem = () => {
    const { isCollapsed } = props

    return (
      <Menu.Item key="orders" >
        <Link to="/orderx/orders">
          <Icon style={{ fontSize: '18px' }} component={() => <img src={ICONS.activeOrder} alt="active_orders" />} />
          <span className="sideMenuText">
            <IntlMessages id="orders.todaysOrders" />
            {!isCollapsed && orderCountBadge()}
          </span>
        </Link>
      </Menu.Item>
    )
  }

  const { pathname, isCollapsed } = props
  const selectedKeys = pathname.substr(1)
  const defaultOpenKeys = selectedKeys.split('/')[1]

  return (
    <Auxiliary>
      <div
        style={{ height: '100%' }}
        className={`OrderX-Sidebar ${isAdminUser ? 'set-badge-admin' : 'set-badge'}
        ${isCollapsed && 'sidebar-collapsed'}`}>
        {!isBMSDealsOnlyView && isCollapsed && <div className="custom-badge">{orderCountBadge()}</div>}
        <Menu style={{ height: '100%' }} className={`${isCollapsed && 'menu-collapsed'}`} defaultOpenKeys={[defaultOpenKeys]} selectedKeys={[defaultOpenKeys]} theme={'dark'} mode="inline">
          {isAdminUser && (
            <Menu.Item key="store-list">
              <Link to={'/orderx/store-list'}>
                <Icon style={{ fontSize: '18px' }} component={() => <img src={ICONS.store} alt="store" />} />
                <span className="sideMenuText">
                  <IntlMessages id="sidebarContent.stores" />
                </span>
              </Link>
            </Menu.Item>
          )}
          {!isBMSDealsOnlyView && (isAdminUser ? (
            <>{menuListItem()}
              {ordersItem()}</>) : (<>
                {ordersItem()}
                {menuListItem()}
              </>
          ))
          }
          {!isBMSDealsOnlyView && <Menu.Item key="order-history">
            <Link to="/orderx/order-history">
              <Icon style={{ fontSize: '18px' }} component={() => <img src={ICONS.orderHistory} alt="order_history" />} />
              <span className="sideMenuText">
                <IntlMessages id="OrderHistory" />
              </span>
            </Link>
          </Menu.Item>}
          {(isAdminUser || isBMSDealsOnlyView) && (
            <Menu.Item key="reports">
              <Link to="/orderx/reports">
                <Icon component={() => <img src={ICONS.report} alt="report" />} style={{ fontSize: '18px' }} />
                <span className="sideMenuText">
                  <IntlMessages id={isBMSDealsOnlyView ? 'bmsDeals' : 'dataRangeReports'} />
                </span>
              </Link>
            </Menu.Item>
          )}
        </Menu>
      </div>
    </Auxiliary>
  )
}

SidebarContent.propTypes = {}

const mapStateToProps = ({ ownProps }: any) => {
  const { pathname } = ownProps.location

  return { pathname }
}
const GET_SETTINGS = gql`
    query settings {
      settings @client {
        pathname
      }
    }
  `

export default compose(
  withRouter,
  graphql(GET_SETTINGS, {
    props: mapStateToProps,
    name: 'settings'
  }),
  withApollo
)(SidebarContent)
