import { Radio as AntRadio } from 'antd'
import React from 'react'
import styled from 'styled-components'
import theme from 'styled-theming'
import { COLORS } from 'Utils/constants'

import { RadioButton, RadioButtonProps } from './index'

export interface RadioGroupProps {
  /**
   * The name property of all input[type="radio"] children˙
   */
  name: string
  /**
   * Used for setting the currently selected value.
   */
  value?: any
  /**
   * Set children optional
   */
  options?: Array<RadioButtonProps>
  /**
   * Disable all radio buttons
   */
  disabled?: boolean
  /**
   * The callback function that is triggered when the state changes.
   */
  onChange?: (event: Event) => void
}

const selectedBgColor = theme('mode', {
  light: COLORS.PRIMARY
})
const StyledRadioGroup = styled(AntRadio.Group)`
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: ${selectedBgColor};
      &:hover {
        background-color: ${selectedBgColor};
      }
    }
  `

/**
 * Peppo Radio group to wrap a group of Radio used to select a single state from multiple options
 */
export const RadioGroup: React.FC<RadioGroupProps> = ({ disabled = false, ...props }) => {
  if (props.options && props.options.length) {
    const properties = { ...props }

    delete properties.options

    return (
      <StyledRadioGroup disabled={disabled} buttonStyle={'solid'} {...properties}>
        {props.options.map((option, optionIndex) => {
          return <RadioButton key={optionIndex} {...option} />
        })}
      </StyledRadioGroup>
    )
  }

  return (
    <StyledRadioGroup buttonStyle={'solid'} disabled={disabled} {...props}>
      {props.children}
    </StyledRadioGroup>
  )
}
