import { ADD_CHARGES_FOR_PRODUCT, ADD_TAGS_TO_PRODUCT, ADD_TAXES_FOR_PRODUCT, CREATE_CHARGE_FOR_PRODUCT, CREATE_DISCOUNT_FOR_PRODUCTS, CREATE_MENU_DISCOUNT_VALUE, CREATE_PRICE_FOR_PRODUCT, CREATE_PRICE_FOR_PRODUCTS, CREATE_PRODUCT, GET_ALL_PRODUCTS, GET_CHARGE_VALUES, GET_DISCOUNT_VALUES, GET_PRODUCT_BY_CATEGORY, GET_PRODUCT_CHARGE_VALUES, GET_PRODUCT_PRICE_VALUES, GET_PRODUCT_TAX_VALUES, MENU_DISCOUNT_VALUE, PRODUCT_BY_CATEGORY_ID, REMOVE_CHARGE_FOR_PRODUCT, REMOVE_TAGS_FROM_PRODUCT, UPDATE_CHARGE_FOR_PRODUCT, UPDATE_CHARGE_FOR_PRODUCTS, UPDATE_DISCOUNT_FOR_PRODUCTS, UPDATE_OPTION_SORT_SEQUENCE, UPDATE_OPTION_VALUE_SORT_SEQ, UPDATE_PRICE_FOR_PRODUCT, UPDATE_PRICE_FOR_PRODUCTS, UPDATE_PRODUCT, UPDATE_PRODUCT_SORT_SEQUENCE } from 'services/query'

export const addTaxes = async (client, productId) => {
  const addTaxesInput = {
    productId
  }

  return client
    .mutate({
      mutation: ADD_TAXES_FOR_PRODUCT,
      variables: { input: addTaxesInput },
      fetchPolicy: 'no-cache'
    })
    .catch(err => {
      console.error('Add taxes for product error:', err)
    })
}

export const addCharges = async (client, productId) => {
  const addChargesInput = {
    productId
  }

  return client
    .mutate({
      mutation: ADD_CHARGES_FOR_PRODUCT,
      variables: { input: addChargesInput },
      fetchPolicy: 'no-cache'
    })
    .catch(err => {
      console.error('Add charges for product error:', err)
    })
}

export const updateProductSortSeq = (client, updateProductSortSeqInput) => {
  return client
    .mutate({
      mutation: UPDATE_PRODUCT_SORT_SEQUENCE,
      variables: { input: updateProductSortSeqInput },
      fetchPolicy: 'no-cache'
    })
    .catch(err => {
      console.warn('Update item sort seq', err)
    })
}

export const updatePriceForProducts = (client, ProductPriceValueUpdateInput) => {
  return client
    .mutate({
      mutation: UPDATE_PRICE_FOR_PRODUCTS,
      variables: { input: ProductPriceValueUpdateInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.error('Update prices for product error', e)
    })
}

export const createPriceForProducts = (client, ProductPriceValueInput) => {
  return client
    .mutate({
      mutation: CREATE_PRICE_FOR_PRODUCTS,
      variables: { input: ProductPriceValueInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.error('create price values for product error', e)
    })
}

export const updateDiscountForProducts = (client, ProductDiscountValueUpdateInput) => {
  return client
    .mutate({
      mutation: UPDATE_DISCOUNT_FOR_PRODUCTS,
      variables: { input: ProductDiscountValueUpdateInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.error('Update discount values for product error', e)
    })
}

export const updateChargeForProducts = (client, ProductChargeValueUpdateInput) => {
  return client
    .mutate({
      mutation: UPDATE_CHARGE_FOR_PRODUCTS,
      variables: { input: ProductChargeValueUpdateInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.error('Update packaging charge for product error', e)
    })
}

export const createDiscountForProducts = (client, ProductDiscountValueInput) => {
  return client
    .mutate({
      mutation: CREATE_DISCOUNT_FOR_PRODUCTS,
      variables: { input: ProductDiscountValueInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.error('create discount values for product error', e)
    })
}

export const getProductsByCategory = (client, categoryId) => {
  return client.query({
    query: GET_PRODUCT_BY_CATEGORY,
    variables: {
      categoryId
    },
    fetchPolicy: 'network-only'
  })
}

export const getProductsByCategoryId = (client, categoryId) => {
  return client.query({
    query: PRODUCT_BY_CATEGORY_ID,
    variables: {
      categoryId
    },
    fetchPolicy: 'network-only'
  })
}

export const getDiscountValues = (client, discountValueInput) => {
  return client
    .query({
      query: GET_DISCOUNT_VALUES,
      variables: { filter: discountValueInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.error('Get discount values error', e)
    })
}

export const createChargeForProduct = (client, ProductChargeValueInput) => {
  return client
    .mutate({
      mutation: CREATE_CHARGE_FOR_PRODUCT,
      variables: { input: ProductChargeValueInput },
      fetchPolicy: 'no-cache'
    })
    .catch(error => {
      console.error('create charge values for product error', error)
    })
}

export const getProducts = (client, category, orgId) => {
  return client
    .query({
      query: GET_ALL_PRODUCTS,
      variables: {
        input: { categoryId: category.id, organizationId: orgId }
      },
      fetchPolicy: 'network-only'
    })
    .catch(e => {
      console.log(e, 'get product error')
    })
}

export const updateProduct = (client, updateProductInput) => {
  return client
    .mutate({
      mutation: UPDATE_PRODUCT,
      variables: { input: updateProductInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e, 'update product error')
    })
}

export const updateProductPrice = (client, updatePriceInput) => {
  return client
    .mutate({
      mutation: UPDATE_PRICE_FOR_PRODUCT,
      variables: { input: updatePriceInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e, 'update product price error')
    })
}

export const createProductPrice = (client, createPriceInput) => {
  return client
    .mutate({
      mutation: CREATE_PRICE_FOR_PRODUCT,
      variables: { input: createPriceInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e, 'create product price error')
    })
}

export const createProductDiscountValue = (client, discountInput) => {
  return client
    .mutate({
      mutation: CREATE_MENU_DISCOUNT_VALUE,
      variables: {
        input: discountInput
      },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e, 'create product discount error')
    })
}

export const getProductDiscountValue = (client, payload) => {
  return client
    .query({
      query: MENU_DISCOUNT_VALUE,
      variables: {
        filter: payload
      },
      fetchPolicy: 'network-only'
    })
    .catch(e => {
      console.log(e, 'get product discount error')
    })
}

export const createProduct = (client, createProductInput) => {
  return client
    .mutate({
      mutation: CREATE_PRODUCT,
      variables: { input: createProductInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e, 'create product error')
    })
}

export const getPriceValues = (client, payload) => {
  return client
    .query({
      query: GET_PRODUCT_PRICE_VALUES,
      variables: {
        filter: payload
      },
      fetchPolicy: 'network-only'
    })
    .catch(e => {
      console.log(e, 'get product prices error')
    })
}

export const getChargeValues = (client, payload) => {
  return client.query({
    query: GET_CHARGE_VALUES,
    variables: {
      filter: payload,
      pageOptions: { page: 1, pageSize: 1000 }
    },
    fetchPolicy: 'network-only'
  })
} 

export const addProductTags = (client, payload) => {
  return client.mutate({
    mutation: ADD_TAGS_TO_PRODUCT,
    variables: { input: payload },
    fetchPolicy: 'no-cache'
  })
}

export const removeProductTags = (client, payload) => {
  return client.mutate({
    mutation: REMOVE_TAGS_FROM_PRODUCT,
    variables: { input: payload },
    fetchPolicy: 'no-cache'
  })
}

export const updateProductCharge = (client, payload) => {
  return client.mutate({
    mutation: UPDATE_CHARGE_FOR_PRODUCT,
    variables: { input: payload },
    fetchPolicy: 'no-cache'
  })
}

export const removeProductCharge = (client, payload) => {
  return client.mutate({
    mutation: REMOVE_CHARGE_FOR_PRODUCT,
    variables: payload,
    fetchPolicy: 'no-cache'
  })
}

export const onSortCustomisation = (client, payload) => {
  return client.mutate({
    mutation: UPDATE_OPTION_SORT_SEQUENCE,
    variables: { input: payload },
    fetchPolicy: 'no-cache'
  })
}

export const onSortOptionValue = (client, payload) => {
  return client.mutate({
    mutation: UPDATE_OPTION_VALUE_SORT_SEQ,
    variables: { input: payload },
    fetchPolicy: 'no-cache'
  })
}

export const getProductTaxValues = (client, payload) => {
  return client.query({
    query: GET_PRODUCT_TAX_VALUES,
    variables: { filter: payload },
    fetchPolicy: 'no-cache',
  })
}

export const getProductChargeValues = (client, payload) => {
  return client.query({
    query: GET_PRODUCT_CHARGE_VALUES,
    variables: {
      filter: payload,
      pageOptions: {
        page: 1,
        pageSize: 100
      }
    },
    fetchPolicy: 'no-cache',
  })
}
