import { CaretDownOutlined } from '@ant-design/icons'
import { Col, Collapse, InputNumber, Row } from 'antd'
import { IntlMessages, Switch, Text } from 'components'
import React from 'react'
import { injectIntl } from 'react-intl'
import {
  COLORS,
  DEAL_PRICE_REGEX,
  PRICE_TYPE,
  PRODUCT_TAG_ENUM
} from 'Utils/constants'

import {
  getNestedCustomizationGroups,
  getPriceDataWithProductId
} from '../../../Utils/menuUtils/commonFunc'
import { StyledCollapse } from '../../../Utils/menuUtils/styleConstants'
import {
  DividerWithoutMargin,
  GroupTag,
  PanelLabel
} from '../customize_new/styles'
const { Panel } = Collapse

const SelectedCustomizations = props => {
  const {
    customisationGroups,
    productVariantPrices,
    newProductVariantPrices,
    onBasePriceChange,
    onDealPriceChange,
    onPackagingChargeChange,
    validateAndUpdatePrices,
    onTagChange
  } = props

  const formProductTag = productId => {
    let productPriceData = getPriceDataWithProductId(
      [...productVariantPrices],
      productId
    )

    if (!productPriceData) {
      productPriceData = getPriceDataWithProductId(
        [...newProductVariantPrices],
        productId
      )
    }
    const tag = productPriceData?.tag

    return (
      <Switch
        checked={tag === PRODUCT_TAG_ENUM.VEG}
        variant="toggle2"
        onClick={(checked, event) => {
          onTagChange(
            productId,
            checked ? PRODUCT_TAG_ENUM.VEG : PRODUCT_TAG_ENUM.NON_VEG
          )
        }}
      />
    )
  }

  const formProductBasePrice = productId => {
    let productPriceData = getPriceDataWithProductId(
      [...productVariantPrices],
      productId
    )

    if (!productPriceData) {
      productPriceData = getPriceDataWithProductId(
        [...newProductVariantPrices],
        productId
      )
    }
    const price = productPriceData?.priceValue || null

    return (
      <InputNumber
        size="middle"
        value={price}
        min={1}
        onChange={value => {
          onBasePriceChange(productId, value)
        }}
        formatter={value => `₹ ${value}`.replace(DEAL_PRICE_REGEX, ',')}
        onBlur={() => {
          validateAndUpdatePrices(productId, PRICE_TYPE.BASE)
        }}
      />
    )
  }

  const formProductDealPrice = productId => {
    let productPriceData = getPriceDataWithProductId(
      [...productVariantPrices],
      productId
    )

    if (!productPriceData) {
      productPriceData = getPriceDataWithProductId(
        [...newProductVariantPrices],
        productId
      )
    }
    const price = productPriceData?.dealPrice || 0

    return (
      <InputNumber
        size="middle"
        value={price}
        min={0}
        onChange={value => {
          onDealPriceChange(productId, value)
        }}
        formatter={value => `₹ ${value}`.replace(DEAL_PRICE_REGEX, ',')}
        onBlur={() => {
          validateAndUpdatePrices(productId, PRICE_TYPE.DEAL)
        }}
      />
    )
  }

  const formProductPackagingCharge = productId => {
    let productPriceData = getPriceDataWithProductId(
      [...productVariantPrices],
      productId
    )

    if (!productPriceData) {
      productPriceData = getPriceDataWithProductId(
        [...newProductVariantPrices],
        productId
      )
    }
    const packagingCharge = productPriceData?.chargeValue || null

    return (
      <InputNumber
        size="middle"
        value={packagingCharge}
        min={0}
        onChange={value => {
          onPackagingChargeChange(productId, value)
        }}
        formatter={value => `₹ ${value}`.replace(DEAL_PRICE_REGEX, ',')}
      />
    )
  }

  const { nestedCustomizationGroups } = getNestedCustomizationGroups(
    customisationGroups
  )

  return (
    <>
      <Row className="mt-1">
        <Col span={9}>
          <Text level="caption" style={{ paddingLeft: '16px' }}>
            <IntlMessages id="groups" />
          </Text>
        </Col>
        <Col span={3}>
          <Text level="caption">
            <IntlMessages id="vegItem?" />
          </Text>
        </Col>
        <Col span={4}>
          <Text level="caption">
            <IntlMessages id="menu.basePrice" />
          </Text>
          <span className="requiredFieldRedColor">*</span>
        </Col>
        <Col span={4}>
          <Text level="caption">
            <IntlMessages id="menu.dealPrice" />
          </Text>
          <span className="requiredFieldRedColor">*</span>
        </Col>
        <Col span={4}>
          <Text level="caption">
            <IntlMessages id="menu.packagingCharge" />
          </Text>
        </Col>
      </Row>
      {nestedCustomizationGroups?.customizations?.map((nCG, index) => {
        if (nCG?.nestedCustomizations) {
          return (
            <div key={`collapse-wrapper-${index}`}>
              <Row>
                <Col span={24}>
                  <StyledCollapse
                    bordered={false}
                    expandIcon={({ isActive }) => (
                      <CaretDownOutlined
                        style={{ color: COLORS.BORDER_DARK }}
                        rotate={isActive ? 180 : 0}
                      />
                    )}
                    expandIconPosition={'left'}>
                    <Panel
                      key={`customization-panel-${index}`}
                      style={{ borderBottom: 0 }}
                      header={
                        <div>
                          <PanelLabel
                            level="body-2"
                            style={{
                              fontSize: '13px',
                              color: COLORS.DARK_TEXT
                            }}>
                            {nCG.label}
                          </PanelLabel>
                          <GroupTag
                            label={nestedCustomizationGroups.groupLabel}
                            style={{
                              marginLeft: '10px'
                            }}
                          />
                        </div>
                      }>
                      {nCG.nestedCustomizations?.customizations?.map(
                        (innerNCG, innerIndex) => {
                          if (innerNCG.nestedCustomizations) {
                            return (
                              <>
                                <StyledCollapse
                                  bordered={false}
                                  expandIcon={({ isActive }) => (
                                    <CaretDownOutlined
                                      style={{ color: COLORS.BORDER_DARK }}
                                      rotate={isActive ? 180 : 0}
                                    />
                                  )}
                                  expandIconPosition={'left'}
                                  key={`panel-wrapper-${innerIndex}`}>
                                  <Panel
                                    style={{ borderBottom: 0 }}
                                    key={`inner-customization-panel-${innerIndex}`}
                                    header={
                                      <div>
                                        <PanelLabel
                                          level="body-2"
                                          style={{
                                            fontSize: '13px',
                                            color: COLORS.DARK_TEXT
                                          }}>
                                          {innerNCG.label}
                                        </PanelLabel>
                                        <GroupTag
                                          label={
                                            nCG.nestedCustomizations.groupLabel
                                          }
                                          style={{
                                            marginLeft: '10px'
                                          }}
                                        />
                                      </div>
                                    }>
                                    {innerNCG.nestedCustomizations?.customizations.map(
                                      (customization, innerMostIndex) => {
                                        return (
                                          <>
                                            <Row
                                              className="mt-1"
                                              key={`panel-content-wrapper-${innerMostIndex}`}>
                                              <Col span={9}>
                                                <div
                                                  style={{
                                                    marginLeft: '16px'
                                                  }}>
                                                  <PanelLabel
                                                    level="body-2"
                                                    style={{
                                                      fontSize: '13px',
                                                      color: COLORS.DARK_TEXT
                                                    }}>
                                                    {customization.label}
                                                  </PanelLabel>
                                                  <GroupTag
                                                    label={
                                                      innerNCG
                                                        .nestedCustomizations
                                                        .groupLabel
                                                    }
                                                    style={{
                                                      marginLeft: '10px'
                                                    }}
                                                  />
                                                </div>
                                              </Col>
                                              <Col span={3}>
                                                {formProductTag(
                                                  customization.productId
                                                )}
                                              </Col>
                                              <Col span={4}>
                                                {formProductBasePrice(
                                                  customization.productId
                                                )}
                                              </Col>
                                              <Col span={4}>
                                                {formProductDealPrice(
                                                  customization.productId
                                                )}
                                              </Col>
                                              <Col span={4}>
                                                {formProductPackagingCharge(
                                                  customization.productId
                                                )}
                                              </Col>
                                            </Row>
                                          </>
                                        )
                                      }
                                    )}
                                  </Panel>
                                </StyledCollapse>
                                <DividerWithoutMargin />
                              </>
                            )
                          }

                          return (
                            <>
                              <Row className="mt-1">
                                <Col span={9}>
                                  <div style={{ marginLeft: '16px' }}>
                                    <PanelLabel
                                      level="body-2"
                                      style={{
                                        fontSize: '13px',
                                        color: COLORS.DARK_TEXT
                                      }}>
                                      {innerNCG.label}
                                    </PanelLabel>
                                    <GroupTag
                                      label={
                                        nCG.nestedCustomizations.groupLabel
                                      }
                                      style={{ marginLeft: '10px' }}
                                    />
                                  </div>
                                </Col>
                                <Col span={3}>
                                  {formProductTag(innerNCG.productId)}
                                </Col>
                                <Col span={4}>
                                  {formProductBasePrice(innerNCG.productId)}
                                </Col>
                                <Col span={4}>
                                  {formProductDealPrice(innerNCG.productId)}
                                </Col>
                                <Col span={4}>
                                  {formProductPackagingCharge(
                                    innerNCG.productId
                                  )}
                                </Col>
                              </Row>
                            </>
                          )
                        }
                      )}
                    </Panel>
                  </StyledCollapse>
                </Col>
              </Row>
              <Row key={`collapse-divider-${index}`}>
                <Col span={24}>
                  <DividerWithoutMargin />
                </Col>
              </Row>
            </div>
          )
        }

        return (
          <div key={`customisation-wrapper-${index}`}>
            <Row className="mt-1">
              <Col span={9}>
                <div style={{ marginLeft: '16px' }}>
                  <PanelLabel
                    level="body-2"
                    style={{
                      fontSize: '13px',
                      color: COLORS.DARK_TEXT
                    }}>
                    {nCG.label}
                  </PanelLabel>
                  <GroupTag
                    label={nestedCustomizationGroups.groupLabel}
                    style={{ marginLeft: '10px' }}
                  />
                </div>
              </Col>
              <Col span={3}>{formProductTag(nCG.productId)}</Col>
              <Col span={4}>{formProductBasePrice(nCG.productId)}</Col>
              <Col span={4}>{formProductDealPrice(nCG.productId)}</Col>
              <Col span={4}>{formProductPackagingCharge(nCG.productId)}</Col>
            </Row>
          </div>
        )
      })}
    </>
  )
}

export default injectIntl(SelectedCustomizations)
