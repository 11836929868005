import { Spin } from 'antd'
import React from 'react'
import { intlAlertMessage } from 'Utils'

const Loader = props => {
  return (
    <div className="justifyContent-center">
      <Spin
        size="large"
        tip={intlAlertMessage({
          id: 'orderTable.message.consoleIsLoading',
          intl: props.intl
        })}
      />
    </div>
  )
}

export default Loader
