import { CloseOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { CheckboxGroup, IntlMessages, Modal, Text } from 'components'
import React, { useState } from 'react'
import { COLORS } from 'Utils/constants'
import { ValidateMinMaxAddonValue } from 'Utils/menuUtils'

import { StyledCheckbox } from '../../../Utils/menuUtils/styleConstants'
import {
  FooterStyle,
  FooterStyles,
  MaxAddonInputStyles,
  SelectedAddonsContainerStyles,
  TitleStyles
} from '../customize_new/styles'
const VISIBLE_ADDON_COUNT = 4

const formCheckboxGroup = (
  addonGroupId,
  selectedAddonGroups,
  onAddonChange
) => {
  const selectedAddonGroup = selectedAddonGroups.find(
    selectedAddonGroup => selectedAddonGroup.value === addonGroupId
  )

  return (
    <CheckboxGroup
      name={selectedAddonGroup.label}
      onChange={selectedValues => {
        onAddonChange(addonGroupId, selectedValues, true)
      }}
      value={selectedAddonGroup.customisations
        .filter(cs => cs.selected)
        .map(cus => cus.value)}>
      {selectedAddonGroup.customisations.map((c, cIndex) => (
        <div key={`customization-${cIndex}`}>
          <StyledCheckbox key={`customization-cb-${cIndex}`} value={c.value}>
            {c.label}
          </StyledCheckbox>
        </div>
      ))}
    </CheckboxGroup>
  )
}

const SelectedAddons = props => {
  const { onAddonChange, onMinMaxChange, selectedAddonGroups } = props
  const [showAddonGroupPopup, setShowAddonGroupPopup] = useState(false)
  const [selectedAddonGroupIndex, setSelectedAddonGroupIndex] = useState(null)

  const validateMinMaxValue = selectedAddonGroup => {
    const { minValue, maxValue } = ValidateMinMaxAddonValue(selectedAddonGroup)

    onMinMaxChange(selectedAddonGroup.value, minValue, maxValue, true)
  }

  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {selectedAddonGroups.map((selectedAddonGroup, index) => (
          <SelectedAddonsContainerStyles key={selectedAddonGroup.value}>
            <div>
              <TitleStyles>
                <Text level="button">{selectedAddonGroup.label}</Text>
                <CloseOutlined
                  onClick={() =>
                    onAddonChange(selectedAddonGroup.value, [], true)
                  }
                />
              </TitleStyles>
              <div style={{ height: '120px', overflow: 'hidden' }}>
                {formCheckboxGroup(
                  selectedAddonGroup.value,
                  selectedAddonGroups,
                  onAddonChange
                )}
              </div>
            </div>
            <div>
              {selectedAddonGroup?.customisations.length >
                VISIBLE_ADDON_COUNT && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px 8px',
                    alignItems: 'center'
                  }}>
                  <Text level="caption">{`+ ${selectedAddonGroup?.customisations
                    .length - VISIBLE_ADDON_COUNT} More`}</Text>
                  <div
                    style={{ color: COLORS.PRIMARY, cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedAddonGroupIndex(index)
                      setShowAddonGroupPopup(true)
                    }}>
                    <IntlMessages id="view.all" />
                  </div>
                </div>
              )}
              <FooterStyle>
                <FooterStyles>
                  <Text level="caption" style={{ padding: '0px 5px' }}>
                    <IntlMessages id="minChoice" />
                  </Text>
                  <Input
                    placeholder=""
                    value={selectedAddonGroup.customisations[0].config.min}
                    style={{ width: '32px', height: '23px', padding: '4px' }}
                    onChange={e => {
                      onMinMaxChange(
                        selectedAddonGroup.value,
                        e.target.value,
                        selectedAddonGroup.customisations[0].config.max,
                        false
                      )
                    }}
                    onBlur={() => validateMinMaxValue(selectedAddonGroup)}
                  />
                </FooterStyles>
                <FooterStyles>
                  <Text level="caption">
                    <IntlMessages id="maxChoice" />
                  </Text>
                  <MaxAddonInputStyles
                    value={selectedAddonGroup.customisations[0].config.max}
                    onChange={e => {
                      onMinMaxChange(
                        selectedAddonGroup.value,
                        selectedAddonGroup.customisations[0].config.min,
                        e.target.value,
                        false
                      )
                    }}
                    onBlur={() => validateMinMaxValue(selectedAddonGroup)}
                  />
                </FooterStyles>
              </FooterStyle>
            </div>
          </SelectedAddonsContainerStyles>
        ))}
      </div>
      {selectedAddonGroupIndex && (
        <Modal
          title={selectedAddonGroups[selectedAddonGroupIndex].label}
          visible={showAddonGroupPopup}
          onClose={() => {
            setShowAddonGroupPopup(false)
            setSelectedAddonGroupIndex(null)
          }}>
          {formCheckboxGroup(
            selectedAddonGroups[selectedAddonGroupIndex].value,
            selectedAddonGroups,
            onAddonChange
          )}
        </Modal>
      )}
    </>
  )
}

export default SelectedAddons
