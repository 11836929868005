export const FIREBASE_CONFIG_DEV_MASTER = {
  apiKey: 'AIzaSyBQKbJdgvEIbGb-pOY0xE6X5qdkfcnY65Q',
  authDomain: 'peppo-d7844.firebaseapp.com',
  databaseURL: 'https://peppo-d7844.firebaseio.com',
  projectId: 'peppo-d7844',
  storageBucket: 'peppo-d7844.appspot.com',
  messagingSenderId: '561646205977',
  appId: '1:561646205977:web:5197e4ebee332b8c163e88',
  measurementId: 'G-X0N5KZ56X7'
}

/*
 * Export const FCM_VAPIDKEY_DEV_MASTER =
 *   'BEolzu0-BMbQndP08GPMOVCoS3EOVrOM-DPJsiJjlMIvxKlsq3SI47jEEMharv9rIftM08Xy-zbQxZZptszhhwY'; //Old Key
 */
export const FCM_VAPIDKEY_DEV_MASTER = 'BMYWZOxsW6jJn8L5b57AVjQ76lyz8NdqSZPK8ymxMXCFa7sJ-WSmuK0anLLAGFvMxKOxD-JU4WNYJ9MOKWKlSVg' // New Key

export const FIREBASE_CONFIG_PROD = {
  apiKey: 'AIzaSyBVulr4uGwpavKEsqS3oJ5IG17DHvuWY2I',
  authDomain: 'partner-ms-prod.firebaseapp.com',
  projectId: 'partner-ms-prod',
  storageBucket: 'partner-ms-prod.appspot.com',
  messagingSenderId: '1086059056624',
  appId: '1:1086059056624:web:65d8c19eedc3651a6a6135',
  measurementId: 'G-PVF718GL2G'
}

export const FCM_VAPIDKEY_PROD = 'BCaVq1LoY3E7lPdO1attCQREG1JxiIXz2WM19a06PC1WKBv2ofPBFRZ_iymn4i1zymEgEtMrafRaERMH2hYOFC4'

export const CANVA_APIKEY_DEV_MASTER = 'Oiv9tn6xtzBKJS1YMvytiDBj'
export const CANVA_APIKEY_PROD = 'JTUFB7nw9gSjRTjoWN7ppWwH'

export const DEFAULT_CONSOLE_VIEW_CONFIG = {
  store_management: true,
  menu_management: true,
  order_management: true,
  order_history: true,
  pay_direct_transactions: false,
  reports: {
    food_order_settlements: true,
    pay_direct_settlements: false
  }
}
