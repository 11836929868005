import '../index.css'

import { ArrowLeftOutlined } from '@ant-design/icons'
import { withApollo } from '@apollo/client/react/hoc'
import { Col, Menu, Row } from 'antd'
import { IntlMessages } from 'components'
import * as compose from 'lodash/flowRight'
import React, { useEffect, useRef, useState } from 'react'
import { injectIntl } from 'react-intl'
import { useHistory, withRouter } from 'react-router-dom'
import { getCategoriesWithProducts, getOptions } from 'services'
import { CATEGORY_HELP_URL, ITEMS_HELP_URL, MENU_TABS } from 'Utils/constants'

import HeaderText from '../../../styles/Typography/Headers'
import ConfirmChangesModal from '../components/ConfirmChangesModal'
import AddonsComponent from './addons'
import { CategoriesComponent } from './categories'
import { CustomizationComponent } from './customization'
import { MenuTimingsComponent } from './menuTimings'
import { ProductsComponent } from './products'

const Customize = (props) => {
  const history = useHistory()
  const isCategoryClicked = useRef(false)
  const isProductClicked = useRef(false)
  const catalogId = props.match?.params?.id || null
  const isFormDataChange = useRef(false)
  const unblock = useRef(null)
  const targetLocation = useRef(null)

  const [selectedMenu, setSelectedMenu] = useState(MENU_TABS.CATEGORIES)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [categoriesWithProducts, setCategoriesWithProducts] = useState([])
  const [options, setOptions] = useState([])

  useEffect(() => {
    getCatalogCategories()
    getAllOptions()
  }, [])

  const getCatalogCategories = async () => {
    const categoriesWithProductsRes = await getCategoriesWithProducts(props.client, catalogId).catch(error => {
      console.error('Get catalog categories error', error)
    })
    const categories = categoriesWithProductsRes?.data?.catalog?.categories

    setCategoriesWithProducts(categories || [])
  }

  const getAllOptions = async () => {
    const optionsRes = await getOptions(props.client)
    const options = optionsRes?.data?.options

    setOptions(options || [])
  }

  const handleItemWarningModalCancel = () => {
    setSelectedMenu(isCategoryClicked.current ? MENU_TABS.PRODUCTS : isProductClicked ? MENU_TABS.CATEGORIES : selectedMenu)
    setShowConfirmModal(false)
  }

  const goToTargetLocation = () => {
    isFormDataChange.current = false
    if (unblock.current) unblock.current()
    if (!isCategoryClicked.current && !isProductClicked) {
      const urlDetails = targetLocation.current.pathname.split('/')

      if (urlDetails[urlDetails.length - 1] === 'detail') {
        history.push({
          pathname: `/orderx/menu-list/${catalogId}/detail`,
        })
      } else {
        history.push({ pathname: targetLocation.current.pathname })
      }
    } else if (isCategoryClicked.current) {
      isCategoryClicked.current = false
      setSelectedMenu(MENU_TABS.CATEGORIES)
      setShowConfirmModal(false)
    } else {
      isProductClicked.current = false
      setSelectedMenu(MENU_TABS.PRODUCTS)
      setShowConfirmModal(false)
    }
  }

  const onFormDataChange = (isChange, isUnblock) => {
    isFormDataChange.current = isChange
    unblock.current = isUnblock
  }

  const onTargetLocationChange = targetLocation => {
    targetLocation.current = targetLocation
    isFormDataChange.current && setShowConfirmModal(true)
  }

  return (
    <div>
      <Row className="customize-header-container">
        <Col span={7}>
          <HeaderText type="H1">
            <ArrowLeftOutlined
              className="backIcon"
              onClick={() => history.push({
                pathname: `/orderx/menu-list/${catalogId}/detail`,
              })}
            />
            <IntlMessages id="menu.customizeMenu" />
          </HeaderText>
        </Col>
        <Col span={15}>
          <Menu
            mode="horizontal"
            selectable={true}
            selectedKeys={[selectedMenu]}
            className="customize-menu"
            onSelect={({ key }) => {
              if (isFormDataChange.current && key === MENU_TABS.CATEGORIES) {
                setShowConfirmModal(true)
                setSelectedMenu(MENU_TABS.PRODUCTS)
                isCategoryClicked.current = true
              } else if (
                isFormDataChange.current && key === MENU_TABS.PRODUCTS
              ) {
                setShowConfirmModal(true)
                setSelectedMenu(MENU_TABS.CATEGORIES)
                isProductClicked.current = true
              } else {
                setSelectedMenu(key)
                isCategoryClicked.current = false
                isProductClicked.current = false
              }
            }}>
            <Menu.Item
              key={MENU_TABS.CATEGORIES}
              className={
                selectedMenu === MENU_TABS.CATEGORIES ? 'active' : ''
              }>
              <IntlMessages id="menu.categories" />
            </Menu.Item>
            <Menu.Item
              key={MENU_TABS.PRODUCTS}
              className={selectedMenu === MENU_TABS.PRODUCTS ? 'active' : ''}>
              <IntlMessages id="menu.items" />
            </Menu.Item>
            <Menu.Item
              key={MENU_TABS.CUSTOMIZATIONS}
              className={
                selectedMenu === MENU_TABS.CUSTOMIZATIONS ? 'active' : ''
              }>
              <IntlMessages id="customization" />
            </Menu.Item>
            <Menu.Item
              key={MENU_TABS.ADDONS}
              className={selectedMenu === MENU_TABS.ADDONS ? 'active' : ''}>
              <IntlMessages id="addons/Combos" />
            </Menu.Item>
            <Menu.Item
              key={MENU_TABS.MENU_TIMINGS}
              className={
                selectedMenu === MENU_TABS.MENU_TIMINGS ? 'active' : ''
              }>
              <IntlMessages id="menu.menuTimings" />
            </Menu.Item>
          </Menu>
        </Col>
        <Col span={1} style={{ marginTop: 11 }}>
          <a
            className="menu-help"
            href={
              selectedMenu === MENU_TABS.CATEGORIES
                ? CATEGORY_HELP_URL
                : ITEMS_HELP_URL
            }
            target="_blank"
            rel="noopener noreferrer">
            <IntlMessages id="menu.help" />?
          </a>
        </Col>
      </Row>
      <Row className="mt-2" style={{ marginLeft: '8px', width: '100%' }}>
        {selectedMenu === MENU_TABS.CATEGORIES && (
          <CategoriesComponent
            onCategoryChange={() => getCatalogCategories()}
            onTargetLocationChange={onTargetLocationChange}
            onFormDataChange={onFormDataChange}
            onTabChange={(tabName) => setSelectedMenu(tabName)}
            intl={props.intl}
            catalogId={catalogId}
          />
        )}
        {selectedMenu === MENU_TABS.ADDONS && (
          <AddonsComponent
            intl={props.intl}
            onAddonsChange={getCatalogCategories}
            catalog={catalogId}
          />
        )}
        {selectedMenu === MENU_TABS.CUSTOMIZATIONS && (
          <CustomizationComponent
            intl={props.intl}
            onOptionsChange={getAllOptions}
          />
        )}
        {selectedMenu === MENU_TABS.PRODUCTS && (
          <ProductsComponent
            onTargetLocationChange={onTargetLocationChange}
            onFormDataChange={onFormDataChange}
            categoriesWithProducts={categoriesWithProducts}
            options={options}
            intl={props.intl}
            onTabChange={(tabName) => setSelectedMenu(tabName)}
            onAddonSeqChange={getCatalogCategories}
            catalogId={catalogId}
          />
        )}
        {selectedMenu === MENU_TABS.MENU_TIMINGS && (
          <MenuTimingsComponent intl={props.intl} />
        )}
      </Row>
      <ConfirmChangesModal showConfirmModal={showConfirmModal} onCancel={() => handleItemWarningModalCancel()} onLeaveBtnAction={() => handleItemWarningModalCancel()} onAction={() => goToTargetLocation()} />
    </div>
  )
}

const CustomizeComponent = injectIntl(compose(withRouter, withApollo)(Customize))

export default CustomizeComponent