import './style.css'

import { withApollo } from '@apollo/client/react/hoc'
import { Col, Row } from 'antd'
import { ClientManager } from 'appApollo/client'
import { IntlMessages, Title } from 'components'
import Loader from 'components/simple/loader'
import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { getAllChannels, getCatalogue, getStoreFormat } from 'services'
import {
  getOrgDetails,
  getUserDetails,
  isAdmin,
  setOrgDetails,
  setUserDetails
} from 'Utils'
import { ORG_STATUS } from 'Utils/constants'
import { setChannels, setStoreFormats } from 'Utils/localStorageHandlers/setter'
import { getUploadApiConfig, getUserData } from 'Utils/onboardingUtils'

import {
  BankAccountCard,
  BusinessLogoCard,
  MenuCard,
  MiniAppUrlCard,
  OutletInfoCard,
  YourMenuCard
} from './components'
import OnboardingHeader from './components/OnboardingHeader'

const Onboarding = props => {
  const history = useHistory
  const { client, intl } = props
  const pmsClient = ClientManager.getInstance().pms
  const [isFetching, setIsFetching] = useState(true)
  const [catalogs, setCatalogs] = useState([])
  const [organization, setOrganization] = useState(getOrgDetails())

  useEffect(() => {
    if (
      !isAdmin() ||
      (isAdmin() &&
        organization &&
        organization.onboardingStatus !== ORG_STATUS.INITIATED)
    ) {
      history.push({ pathname: '/orderx/orders' })
    }
    const userDetails = getUserDetails()

    if (!userDetails?.catalogs || !userDetails?.catalogs.length) {
      getCatalogs(true)
    } else {
      initOnboarding()
      setCatalogs(userDetails?.catalogs)
    }
  }, [])

  const getCatalogs = async isInitOnboarding => {
    const userDetails = getUserDetails()
    const { organizationId } = getUploadApiConfig()

    try {
      const catalogsResponse = await getCatalogue(props.client, organizationId)
      const { catalogs } = catalogsResponse.data

      userDetails.catalogs = catalogs
      setCatalogs(catalogs)
      isInitOnboarding && initOnboarding()
      setUserDetails(userDetails)
    } catch (err) {
      console.error('Get catalogs error', err)
    }
  }

  const initOnboarding = () => {
    const { stores, catalogs } = getUserData()
    const isStoreCreated = stores && stores.length > 0
    const isMenuVerified =
      catalogs && catalogs.length > 0 && catalogs[0].listable

    if (!isMenuVerified || !isStoreCreated) {
      getChannels()
    } else {
      setIsFetching(false)
    }
  }

  const getChannels = async () => {
    try {
      const response = await getAllChannels(props.client)
      const channels = response.data.channels.data

      getStoreFormats()
      setChannels(channels)
    } catch (err) {
      console.error('Get channels error', err)
    }
  }

  const getStoreFormats = async () => {
    try {
      const response = await getStoreFormat(props.client)
      const { data } = response.data.storeFormats

      setStoreFormats(data)
      setIsFetching(false)
    } catch (err) {
      console.error('Get Store formats error', err)
    }
  }

  const setOrganizationDetails = val => {
    setOrganization(val)
    setOrgDetails(val)
  }

  if (isFetching) {
    return <Loader intl={intl} />
  }

  return (
    <div className="onboarding-container">
      <OnboardingHeader />
      <div className="gx-main-content-wrapper">
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 18, offset: 3 }}
            lg={{ span: 12, offset: 6 }}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '-20px'
            }}>
            <Title level="h4">
              <IntlMessages id="onboarding.welcomeMsg" />
            </Title>
            <div className="onboard-text">
              <IntlMessages id="onboarding.requiredInfoMsg" />
            </div>
          </Col>
        </Row>
        <BusinessLogoCard
          pmsClient={pmsClient}
          client={client}
          setOrganizationDetails={e => setOrganizationDetails(e)}
        />
        <MenuCard
          brandLogo={organization?.orgLogo}
          getCatalogs={val => getCatalogs(val)}
          isCatalogCreated={Boolean(catalogs?.length) || false}
        />
        <OutletInfoCard />
        <YourMenuCard />
        <MiniAppUrlCard />
        <BankAccountCard client={pmsClient} />
      </div>
    </div>
  )
}

// @ts-ignore
export default injectIntl(withApollo(Onboarding))
