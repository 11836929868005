import { Button, Card, IntlMessages, Text, TextButton, Title } from 'components'
import React from 'react'
import Lottie from 'react-lottie'
import styled from 'styled-components'
import { intlAlertMessage } from 'Utils'
import { COLORS } from 'Utils/constants'
import { defaultOptions } from 'Utils/onboardingUtils'

interface CommonOnboardingCardProps {
  intl: any
  title: string
  pending: boolean
  description: string
  buttonText: string
  helpUrl?: string
  onButtonClick: any
  disable?: boolean
  miniAppUrl?: string
  merchantId?: string
  required?: boolean
  copyToClipboard?: any
}
interface CommonOnboardingCardState {}

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `
const ButtonStyle = styled.div`
    margin-left: -7px;
  `
const Status = styled.span`
    color: ${props => (props.pending ? COLORS.ERROR : COLORS.PRIMARY)};
    font-size: 13px;
    line-height: 31px;
  `
const StatusContainer = styled.div`
    display: flex;
  `

class CommonOnboardingCard extends React.Component<CommonOnboardingCardProps, CommonOnboardingCardState> {
  render() {
    const { title, pending, description, buttonText, helpUrl, onButtonClick, disable, miniAppUrl, merchantId, required } = this.props

    return (
      <Card>
        <Header>
          <Title level={'h3'}>
            <IntlMessages id={title} />
            {required && <span style={{ marginLeft: '3px' }}>*</span>}
          </Title>
          <StatusContainer>
            <Status pending={pending}>
              <Text
                level="caption"
                style={{
                  color: pending ? COLORS.ERROR : COLORS.PRIMARY,
                  fontSize: 11
                }}>
                {pending ? (
                  <span>
                    *<IntlMessages id={'pending'} />
                  </span>
                ) : (
                  <IntlMessages id={'completed'} />
                )}
              </Text>
            </Status>
            {!pending && <Lottie options={defaultOptions} height={31} width={31} style={{ marginLeft: 8 }} />}
          </StatusContainer>
        </Header>
        <div style={{ width: '70%', marginTop: '10px' }}>
          <Text level={'body-2'}>
            {miniAppUrl && miniAppUrl.length ? (
              <span>
                <IntlMessages id="onboarding.createMiniUrlSuccess1" /> <br />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Text level={'body-1'} style={{ color: COLORS.DARK_TEXT }}>
                    {miniAppUrl}
                  </Text>
                  <Button type="link" onClick={() => this.props.copyToClipboard()} style={{ marginBottom: 0 }}>
                    <IntlMessages id="copyLink" />
                  </Button>
                </div>
                <br />
                <Text level={'body-2'}>
                  <IntlMessages id="onboarding.createMiniUrlSuccess2" />
                </Text>
              </span>
            ) : (
              <span>
                <IntlMessages id={description} /> {merchantId}
              </span>
            )}
          </Text>
          {helpUrl && (
            <Button type="link" style={{ marginBottom: 0, lineHeight: '33px', paddingLeft: 0 }}>
              <a href={helpUrl} target="_blank" rel="noopener noreferrer" style={{ paddingLeft: '5px' }}>
                <IntlMessages id="learnMore" />
              </a>
            </Button>
          )}
        </div>
        {pending && (
          <ButtonStyle>
            <TextButton
              label={intlAlertMessage({
                id: buttonText,
                intl: this.props.intl
              })}
              onClick={() => onButtonClick()}
              size="default"
              type="primary"
              disabled={disable}
            />
          </ButtonStyle>
        )}
      </Card>
    )
  }
}
export default CommonOnboardingCard
