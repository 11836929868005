import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, message, Modal, Row, TimePicker, Tooltip, Typography } from 'antd'
import { Button as PeppoButton, Card, IntlMessages, Text as PeppoText, Title } from 'components'
import { orderxClient as client } from 'index'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import * as storeService from 'services/storeService'
import { setErrMsg } from 'Utils'
import { COLORS, DEFAULT_TIME_SLOT, HH_MM_FORMAT } from 'Utils/constants'
import { getWcoreOrganizationId } from 'Utils/localStorageHandlers/getter'
import { setIsUnsavedStoreDetails } from 'Utils/localStorageHandlers/setter'
import { padWithZeroes } from 'Utils/storeUtils'
const { Text } = Typography

enum DAYS {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY'
}

const getDefaultSlotData = () => ({
  [DAYS.MONDAY]: [],
  [DAYS.TUESDAY]: [],
  [DAYS.WEDNESDAY]: [],
  [DAYS.THURSDAY]: [],
  [DAYS.FRIDAY]: [],
  [DAYS.SATURDAY]: [],
  [DAYS.SUNDAY]: []
})

const parseDayName = (dayString) => JSON.parse(dayString)[0]

function StoreTimings({ useStoreData, storeId }) {
  const [storeData, setStoreData] = useStoreData()
  const [storeTimings, setStoreTimings] = useState(getDefaultSlotData())
  const [showSlotModal, setShowSlotModal] = useState(false)
  const [currentSlotDetails, setCurrentSlotDetails] = useState<any>({})
  const [showDeleteSlotModal, setShowDeleteSlotModal] = useState(false)
  const [showClearAllSlotModal, setShowClearAllSlotModal] = useState(false)
  const intl = useIntl()
  const organizationId = getWcoreOrganizationId()

  let visibleCount = 0

  Object.values(storeTimings).forEach(slotData => {
    visibleCount += slotData?.length
  })

  function toggleSlotModal() {
    setShowSlotModal(!showSlotModal)
  }

  function toggleDeleteSlotModal() {
    setShowDeleteSlotModal(!showDeleteSlotModal)
  }

  function toggleClearAllSlotModal() {
    setShowClearAllSlotModal(!showClearAllSlotModal)
  }

  const showAddEditSlotModal = ({ day, slotIndex = null }) => {
    let slotDetails = {
      id: null,
      openTime: moment(padWithZeroes(DEFAULT_TIME_SLOT.OPEN), HH_MM_FORMAT),
      closeTime: moment(padWithZeroes(DEFAULT_TIME_SLOT.CLOSE), HH_MM_FORMAT),
      index: null,
      dayName: day,
      isNewSlot: true,
      applyToAllDays: false,
    }

    if (slotIndex !== null) {
      const slot = storeTimings[day][slotIndex]

      slotDetails = {
        ...slotDetails,
        id: slot.id,
        isNewSlot: false,
        openTime: moment(padWithZeroes(slot.openTime), HH_MM_FORMAT),
        closeTime: moment(padWithZeroes(slot.closeTime), HH_MM_FORMAT),
        index: slotIndex
      }
    }
    setCurrentSlotDetails(slotDetails)
    toggleSlotModal()
  }

  function updateCurrentSlotDetailsByPropertyName({ property, value }) {
    setCurrentSlotDetails({ ...currentSlotDetails, [property]: value })
  }

  async function removeAndAddSlot(slotId, slotData) {
    try {
      const removeSlotInput = {
        storeId,
        id: slotId,
        organizationId
      }
      const addSlotInput = {
        organizationId,
        storeId,
        storeTimings: [slotData]
      }

      await storeService.removeStoreTimings(client, removeSlotInput)
      await storeService.addBulkStoreOpenTiming(client, addSlotInput)

    }
    catch (err) {
      console.log('Error handleUpdateSlot', err)
    }
  }

  const checkDuplicateSlot = (slot, timings) => slot.openTime === timings.openTime && slot.closeTime === timings.closeTime

  const checkSlotOverlap = (slot, timings) => timings.openTime > slot.closeTime || timings.closeTime < slot.openTime

  const handleAddUpdateSlot = async () => {
    const { id, openTime, closeTime, applyToAllDays, dayName, index } = currentSlotDetails
    const timings = {
      openTime: Number(openTime.format(HH_MM_FORMAT)),
      closeTime: Number(closeTime.format(HH_MM_FORMAT))
    }

    let isDuplicateSlot = false
    let isSlotOverlap = false

    if (timings.openTime === timings.closeTime) {
      setErrMsg('store.errMsg.openTimeAndCloseTimeShouldNotBeSame', intl)

      return
    }
    if (timings.closeTime < timings.openTime) {
      setErrMsg('store.errMsg.closeTimeShouldGreaterThemOpenTime', intl)

      return
    }

    const duplicateSlotArr = []

    Object.keys(DAYS).forEach(day => {
      const slots = storeTimings[day]

      if (dayName === day || applyToAllDays) {
        slots.forEach((slot, slotIndex) => {
          if (slotIndex !== index) {
            isDuplicateSlot = checkDuplicateSlot(slot, timings)
            if (isDuplicateSlot) {
              duplicateSlotArr.push(day)

              return
            }
            isSlotOverlap = !checkSlotOverlap(slot, timings)
          }
        })
      }
    })

    if (!isDuplicateSlot && !isSlotOverlap) {
      let newSlots
      const timingsAppliedToAllDays = {}
      const existingSlots = storeTimings[dayName]

      if (index !== null) {

        id && await removeAndAddSlot(id, { days: dayName, data: [timings] })

        newSlots = existingSlots.map((slot, slotIndex) => {
          if (index === slotIndex) return timings

          return slot
        })
      } else {
        newSlots = existingSlots.concat(timings)
        if (applyToAllDays) {
          Object.entries(storeTimings).forEach(([day, slots]) => {
            if (dayName !== day) {
              timingsAppliedToAllDays[day] = slots.concat(timings)
            }
          })
        }
        setIsUnsavedStoreDetails(true)
      }

      const updatedStoreTimings = {
        ...storeTimings,
        ...timingsAppliedToAllDays,
        [dayName]: newSlots
      }

      toggleSlotModal()
      setStoreTimings(updatedStoreTimings)
    } else if (isDuplicateSlot) {
      message.error(intl.formatMessage({ id: 'slotAlreadyExists' }, { slot: duplicateSlotArr.map(val => `${val.charAt(0)}${val.toLowerCase().slice(1)} `) }))
    } else if (isSlotOverlap) {
      setErrMsg('store.errMsgOverlappingStore', intl)
    }
  }

  const onDeleteSlotClickHandler = async () => {
    const { id, dayName, index } = currentSlotDetails
    const existingSlots = storeTimings[dayName]

    if (id) {

      const removeSlotInput = {
        storeId,
        organizationId,
        id: existingSlots[index].id
      }

      await storeService.removeStoreSlotTimings(client, removeSlotInput)
    }
    const updatedStoreTimings = {
      ...storeTimings,
      [dayName]: existingSlots.filter((slot, slotIndex) => slotIndex !== index)
    }

    setStoreTimings(updatedStoreTimings)
    toggleDeleteSlotModal()
    toggleSlotModal()
  }

  const onClearSlotHandler = async () => {
    if (storeId) {
      const slotsIds = []

      Object.keys(DAYS).forEach(day => {
        storeTimings[day].filter(slot => slot?.id && slotsIds.push(slot.id))
      })
      const removeStoreTimingInput = {
        storeId,
        id: slotsIds,
        organizationId
      }

      if (slotsIds.length > 0) {
        try {
          await storeService.removeStoreSlotTimings(client, removeStoreTimingInput)
        } catch (err) {
          console.log(err)
        }
      }
    }
    setStoreTimings(getDefaultSlotData())
    toggleClearAllSlotModal()
  }

  useEffect(() => {
    if (storeData()?.storeTimings?.data) {
      const storeTimingsData = storeData().storeTimings.data

      if (storeTimingsData?.length) {
        const slotData = getDefaultSlotData()

        storeTimingsData.forEach(timings => {
          const day = parseDayName(timings.days)

          slotData[day] = timings.data
        })
        setStoreTimings(slotData)
      }
    }
  }, [])

  useEffect(() => {
    const formattedStoreTimings = Object.entries(storeTimings).map(([day, slots]) => ({ days: day, data: slots }))

    setStoreData({ storeTimings: formattedStoreTimings })
  }, [storeTimings])


  const AddUpdateSlotModalButton = () => {
    return (
      <Button
        key="createSlot"
        className={'createSlotBtn'}
        onClick={handleAddUpdateSlot}
        disabled={
          !currentSlotDetails.openTime || !currentSlotDetails.closeTime
        }>
        {currentSlotDetails.isNewSlot ? <IntlMessages id="create" /> : <IntlMessages id="update" />}
      </Button>
    )
  }

  const DeleteSotButton = () => {
    return (
      <Button
        type="primary"
        danger
        key="deleteSlot"
        className={'deleteSlotBtn'}
        onClick={toggleDeleteSlotModal}>
        <IntlMessages id="button.delete" />
      </Button>
    )
  }

  return (
    <>
      <Card style={{ margin: '12px 0' }}>
        <Row className={'flexWrapper'}>
          <Col span={24}>
            <Row>
              <Col
                span={24}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'baseline',
                  justifyContent: 'space-between'
                }}>
                <Title level='h4' style={{ marginBottom: '24px' }}>
                  <IntlMessages id="store.StoreOpen&CloseTime" />
                  <Tooltip
                    title={intl.formatMessage({ id: 'storeDeliveryTimings' })}
                    className="store-tooltip">
                    <ExclamationCircleOutlined className="store-tooltip-icon" />
                  </Tooltip>
                </Title>
                {visibleCount >= 2 && (
                  <div
                    onClick={toggleClearAllSlotModal}
                    style={{
                      color: COLORS.ERROR,
                      fontWeight: 'bold',
                      cursor: 'pointer'
                    }}>
                    Clear All
                  </div>
                )}
              </Col>
            </Row>
            <Row className={'daytimingswrapper'}>
              {Object.keys(DAYS).map(day => {
                return (
                  <Col
                    style={{
                      width: `${100 / 7}%`,
                      padding: '0 5px'
                    }}
                    key={day}>
                    <PeppoText level='body-1' style={{ color: COLORS.DARK_TEXT }}>{day}</PeppoText>
                    {storeTimings[day]?.length > 0 &&
                      storeTimings[day].map((slot, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => showAddEditSlotModal({ day, slotIndex: index })}
                            className={'showSlotTimeBtn'}>
                            <div>
                              <Text>
                                {moment(
                                  padWithZeroes(slot.openTime),
                                  HH_MM_FORMAT
                                ).format('hh:mm a')}
                              </Text>{' '}
                              -{' '}
                              <Text>
                                {moment(
                                  padWithZeroes(slot.closeTime),
                                  HH_MM_FORMAT
                                ).format('hh:mm a')}
                              </Text>
                            </div>
                          </div>
                        )
                      })}
                    <PeppoButton
                      type='secondary'
                      style={{ width: '100%' }}
                      onClick={() => showAddEditSlotModal({ day })}>
                      + <IntlMessages id="addSlot" />
                    </PeppoButton>
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>
      </Card>
      <Modal
        title={
          currentSlotDetails.isNewSlot ? (
            <IntlMessages id="addSlot" />
          ) : (
            <IntlMessages id="updateSlot" />
          )
        }
        visible={showSlotModal}
        className={'add-slot-modal-styles'}
        onCancel={toggleSlotModal}
        footer={
          currentSlotDetails.isNewSlot
            ? [<AddUpdateSlotModalButton key="add-update-btn-1" />]
            : [
              <AddUpdateSlotModalButton key="add-update-btn-2" />,
              <DeleteSotButton key="delete-btn-1" />
            ]
        }>
        <Col span={24}>
          <Row>
            <Col span={12}>
              <IntlMessages id="openTime" />
            </Col>
            <Col span={12}>
              <IntlMessages id="closeTime" />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <TimePicker
                use12Hours
                style={{ width: '100%' }}
                onChange={(time, timeString) => {
                  updateCurrentSlotDetailsByPropertyName({ property: 'openTime', value: time })
                }}
                format="hh:mm a"
                minuteStep={1}
                value={currentSlotDetails.openTime}
                size="large"
              />
            </Col>
            <Col span={12}>
              <TimePicker
                use12Hours
                style={{ width: '100%' }}
                onChange={(time, timeString) => {
                  updateCurrentSlotDetailsByPropertyName({ property: 'closeTime', value: time })
                }}
                format="hh:mm a"
                minuteStep={1}
                value={currentSlotDetails.closeTime}
                size="large"
              />
            </Col>
          </Row>
          {currentSlotDetails.isNewSlot && (
            <Row>
              <Col span={12}>
                <div>
                  <Checkbox
                    style={{ marginTop: '10px' }}
                    checked={currentSlotDetails.applyToAllDays}
                    onChange={() => updateCurrentSlotDetailsByPropertyName({ property: 'applyToAllDays', value: !currentSlotDetails.applyToAllDays })}>
                    {' '}
                    <IntlMessages id="store.applyToAllDays" />
                  </Checkbox>
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Modal>
      <Modal
        className={'delete-slot-modal'}
        visible={showDeleteSlotModal}
        title={<IntlMessages id="store.confirmationMsg.aboutDeleteSelectedSlot" />}
        onCancel={toggleDeleteSlotModal}
        footer={[
          <Button
            key="rejectDelete"
            onClick={toggleDeleteSlotModal}>
            <IntlMessages id="newOrder.Cancel" />
          </Button>,
          <Button
            type="primary"
            danger
            key="confirmDelete"
            onClick={onDeleteSlotClickHandler}>
            <IntlMessages id="button.delete" />
          </Button>
        ]}></Modal>
      <Modal
        width={450}
        style={{ fontSize: '20px' }}
        visible={showClearAllSlotModal}
        title={<IntlMessages id="sweetAlerts.areYouSure" />}
        onCancel={toggleClearAllSlotModal}
        footer={null}>
        <div style={{ marginTop: '-16px', fontSize: '13px' }}>
          <IntlMessages id="store.wouldYouLikeToRemoveAll" />
        </div>
        <Button
          style={{
            display: 'flex',
            justifySelf: 'flex-start',
            margin: '16px 0 0 0',
            borderRadius: '6px'
          }}
          onClick={onClearSlotHandler}>
          <IntlMessages id="store.yesContinue" />
        </Button>
        ,
      </Modal>
    </>
  )
}

export default StoreTimings