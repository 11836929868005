/* eslint-disable prefer-named-capture-group */

import { ApolloProviderProps } from '@apollo/client/react/context'
import { withApollo } from '@apollo/client/react/hoc'
import { Button, Col, Input, message,Modal, Row } from 'antd'
import { IMAGES } from 'assets'
import { IntlMessages } from 'components'
import * as compose from 'lodash/flowRight'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { intlAlertMessage } from 'Utils'
import { COLORS, PINCODE_REGEX } from 'Utils/constants'
import { getCityStateCountryZip } from 'Utils/storeUtils'


interface IStoreLocationProps extends ApolloProviderProps<any> {
  storeGeoLocation: any
  onLocationChange: any
  searchedLocationText: any
  streetAddressText: any
  toggleLocationPopup: any
  intl: any
}
interface IStoreLocationState {
  storeLocation: any
  searchedLocationText: any
  streetAddressText: any
  fetchingCurrentLocation: boolean
  address_components: any[]
  pinCode: any
}

class StoreLocation extends Component<IStoreLocationProps, IStoreLocationState> {
  map: google.maps.Map<HTMLElement>

  marker: google.maps.Marker

  addMarkerListener: google.maps.MapsEventListener

  searchInput: any

  geocoder = new google.maps.Geocoder()

  constructor(props: IStoreLocationProps) {
    super(props)
    this.searchInput = React.createRef<HTMLInputElement>()
    const { searchedLocationText, streetAddressText, storeGeoLocation } = this.props
    let center = { lat: 20.5937, lng: 78.9629 }
    let result

    if (storeGeoLocation) {
      center = storeGeoLocation
    }
    if (streetAddressText) {
      if (searchedLocationText.match(PINCODE_REGEX)) {
        result = searchedLocationText.match(PINCODE_REGEX)[searchedLocationText.match(PINCODE_REGEX).length - 1]
      }
    }
    this.state = {
      storeLocation: center,
      searchedLocationText,
      streetAddressText,
      fetchingCurrentLocation: false,
      address_components: [],
      pinCode: result
    }
  }

  componentDidMount() {
    const { storeLocation } = this.state

    if (storeLocation.lat && storeLocation.lng) {
      this.addMarker(new google.maps.LatLng(Number(storeLocation.lat), Number(storeLocation.lng)))
    }
    setTimeout(() => {
      this.initMap()
      document.querySelector('.ant-modal-wrap').addEventListener('scroll', () => {
        const topValue = document.querySelector('#place-search').getBoundingClientRect().top + document.querySelector('#place-search')['offsetHeight']

        document.querySelector('.pac-container')['style'].top = `${topValue}px`
      })
    }, 100)
  }

  componentWillUnmount = () => {
    if (document.querySelector('.pac-container')) {
      document.querySelector('.pac-container').remove()
    }
  }

  getCurrentLocation = () => {
    const success = (position) => {
      const { latitude } = position.coords
      const { longitude } = position.coords
      const center = { lat: latitude, lng: longitude }
      const newLatLng = new google.maps.LatLng(Number(center.lat), Number(center.lng))

      this.updateSearchedLocation(newLatLng)
      this.map.setCenter(newLatLng)
      this.addMarker(newLatLng)
      this.setState({ fetchingCurrentLocation: false })
    }

    const error = () => {
      message.error(
        intlAlertMessage({
          id: 'store.errMsg.unableToGetLocation',
          intl: this.props.intl
        })
      )
      this.setState({ fetchingCurrentLocation: false })
    }

    if (!navigator.geolocation) {
      message.error(
        intlAlertMessage({
          id: 'store.locationNotSupportedBrowser',
          intl: this.props.intl
        })
      )
      // Status.textContent = 'Geolocation is not supported by your browser';
    } else {
      this.setState({ fetchingCurrentLocation: true })
      navigator.geolocation.getCurrentPosition(success, error)
    }
  }

  addMarker = location => {
    const { storeLocation } = this.state

    this.marker && this.marker.setMap(null)
    this.marker = new google.maps.Marker({
      position: location,
      map: this.map
    })
    this.setState({
      storeLocation: {
        ...storeLocation,
        lat: location.lat(),
        lng: location.lng()
      }
    })
    /*
     * MapData.center = { lat: location.lat(), lng: location.lng() };
     * this.props.onDataChange('mapData', mapData);
     */
  }

  initMap = () => {
    const { storeLocation } = this.state
    const { lat } = storeLocation
    const { lng } = storeLocation

    this.map = new google.maps.Map(document.getElementById('locationMap'), {
      center: { lat, lng },
      zoom: 14,
      disableDefaultUI: true,
      zoomControl: true,
      fullscreenControl: true
    })

    /*
     * Var myControl = new MyControl(controlDiv);
     * this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
     *     this.renderCustomMapButton()
     * );
     */
    this.startAddMarkerListener()

    if (storeLocation.lat && storeLocation.lng) {
      this.addMarker(new google.maps.LatLng(Number(storeLocation.lat), Number(storeLocation.lng)))
    }
    this.initAutocomplete()
  }

  updateSearchedLocation = latLng => {

    this.geocoder.geocode(
      {
        location: latLng
      },
      (results, status) => {
        if (results) {
          const address = getCityStateCountryZip(results[0].address_components, results[0].formatted_address)
          const { pinCode } = address

          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              this.setState({
                searchedLocationText: results[0].formatted_address,
                address_components: results[0].address_components,
                pinCode
              })
            }
          }
        }
      }
    )
  }

  startAddMarkerListener = () => {
    this.addMarkerListener = this.map.addListener('click', event => {
      /*
       *  If (this.marker) {
       * removing existing marker
       */
      this.marker && this.marker.setMap(null)
      //
      this.updateSearchedLocation(event.latLng)
      /*
       * Geocoder.geocode(
       *     {
       *         location: event.latLng,
       *     },
       *     function (results, status) {
       *         if (status == google.maps.GeocoderStatus.OK) {
       *             if (results[0]) {
       *                 self.setState({
       *                     searchedLocationText: results[0].formatted_address,
       *                 });
       *             }
       *         }
       *     }
       * );
       */

      this.addMarker(event.latLng)
    })
  }

  stopAddMarkerListener = () => {
    this.addMarkerListener.remove()
  }

  initAutocomplete = () => {
    // Create the search box and link it to the UI element.
    const searchBox = new google.maps.places.SearchBox(this.searchInput.current.input)

    // Bias the SearchBox results towards current map's viewport.
    this.map.addListener('bounds_changed', () => {
      searchBox.setBounds(this.map.getBounds())
    })

    /*
     * Var markers = [];
     * Listen for the event fired when the user selects a prediction and retrieve
     * more details for that place.
     */
    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces()

      if (places.length === 0) {
        return
      }

      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds()

      places.forEach(({ geometry, address_components, formatted_address }) => {
        if (!geometry) {
          return
        }
        this.addMarker(geometry.location)
        const address = getCityStateCountryZip(address_components, formatted_address)
        const { pinCode } = address

        this.setState({
          searchedLocationText: formatted_address,
          address_components,
          pinCode
        })

        if (geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(geometry.viewport)
        } else {
          bounds.extend(geometry.location)
        }
      })
      this.map.fitBounds(bounds)
    })
  }

  validateAddress = () => {
    const { address_components, searchedLocationText } = this.state
    let isValid = true
    const address = getCityStateCountryZip(address_components, searchedLocationText)

    if (!address.city || !address.country || !address.pinCode || !address.state) {
      message.error(intlAlertMessage({ id: 'store.enterValidAdd', intl: this.props.intl }))
      isValid = false
    } else if (address && (address.pinCode.length !== 6 || isNaN(Number(address.pinCode)))) {
      message.error(
        intlAlertMessage({
          id: 'store.errMsg.validPincode',
          intl: this.props.intl
        })
      )
      isValid = false
    }

    return isValid
  }

  onPincodeHandler = (e: { target: { value: any } }) => {
    const temp = this.state.searchedLocationText

    if (!isNaN(e.target.value) && e.target.value.length <= 6) {
      if (temp.match(PINCODE_REGEX)) {
        const result = temp.match(PINCODE_REGEX)[temp.match(PINCODE_REGEX).length - 1]

        this.setState({
          pinCode: e.target.value,
          searchedLocationText: temp.replace(result, e.target.value)
        })
      } else {
        const position = temp.length - 7
        const output = [temp.slice(0, position), ' ', e.target.value, temp.slice(position)].join('')

        this.setState({
          pinCode: e.target.value,
          searchedLocationText: output
        })
      }
    }
  }

  render() {
    const { onLocationChange, toggleLocationPopup } = this.props
    const { searchedLocationText, streetAddressText, storeLocation, fetchingCurrentLocation, address_components, pinCode } = this.state

    return (
      <Modal
        width={680}
        footer={null}
        className="store-location-modal"
        onCancel={e => toggleLocationPopup(e)}
        visible={true}
        closable={true}>
        <Col span={24} style={{ marginLeft: '1px', marginRight: '1px' }}>
          <Row>
            <div id="locationMap" className="mapWrapper " />
          </Row>
          <Row className="mb-1">
            <Col span={24}>
              <div
                className="mb-1"
                style={{
                  fontSize: '15px',
                  color: COLORS.DARK_TEXT,
                  fontWeight: 500
                }}>
                <IntlMessages id="store.errMsg.setYourStoreLocationFirst" />
              </div>
              <div className="InputLabel">
                <IntlMessages id="store.searchLocation" />
              </div>
              <Input
                addonBefore={
                  <Button
                    shape="circle"
                    type="default"
                    style={{
                      marginBottom: 0,
                      border: 'none',
                      background: 'transparent',
                      cursor: 'default'
                    }}>
                    <img src={IMAGES.map_marker} alt="Map marker" />
                  </Button>
                }
                ref={this.searchInput}
                size="large"
                id="place-search"
                placeholder={intlAlertMessage({
                  id: 'stores.searchYourStore',
                  intl: this.props.intl
                })}
                value={searchedLocationText}
                onChange={e => {
                  this.setState({
                    searchedLocationText: e.target.value,
                    address_components: []
                  })
                }}
                addonAfter={
                  <Button
                    onClick={() => this.getCurrentLocation()}
                    shape="circle"
                    type="default"
                    style={{
                      marginBottom: 0,
                      border: 'none',
                      background: 'transparent'
                    }}
                    loading={fetchingCurrentLocation}>
                    <img
                      alt="Get current location"
                      src={IMAGES.current_location}
                      // Style={{ maxWidth: 35 }}
                    />
                  </Button>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '70%' }}>
                  <div className="InputLabel">
                    <IntlMessages id="store.errMsg.enterBuildingName" />
                  </div>
                  <Input
                    size="large"
                    id="street-name"
                    placeholder={intlAlertMessage({
                      id: 'stores.locationDetails',
                      intl: this.props.intl
                    })}
                    value={streetAddressText}
                    // Style={{ width: '70%',  }}
                    onChange={e => {
                      this.setState({ streetAddressText: e.target.value })
                    }}
                  />
                </div>
                <div>
                  <div className="InputLabel" style={{ marginLeft: '20px' }}>
                    <IntlMessages id="stores.PINCode" />
                  </div>
                  <Input
                    size="large"
                    id="street-name"
                    className="pincode-input-box"
                    style={{ borderColor: !pinCode && COLORS.ERROR_DARK }}
                    placeholder={intlAlertMessage({
                      id: 'stores.pincode',
                      intl: this.props.intl
                    })}
                    value={pinCode}
                    onChange={e => this.onPincodeHandler(e)}
                  />
                  {!pinCode && (
                    <div
                      className="pincode-warning-text"
                      style={{ color: COLORS.ERROR_DARK }}>
                      <IntlMessages id="store.errMsg.unableToFetchEnterPincode" />
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="btn-wrapper">
            <Button
              disabled={!searchedLocationText || !streetAddressText || !pinCode}
              onClick={() => {
                if (this.validateAddress()) {
                  onLocationChange({
                    storeLocation,
                    searchedLocationText,
                    streetAddressText,
                    address_components,
                    pinCode
                  })
                }
              }}
              className="submit-btn">
              <IntlMessages id="menu.submit" />
            </Button>
          </Row>
        </Col>
      </Modal>
    )
  }
}

export default compose(withRouter, withApollo)(StoreLocation)
