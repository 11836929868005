import { CREATE_OPTION, CREATE_OPTION_VALUE, CREATE_PRODUCT_RELATIONSHIPS, GET_OPTIONS, REMOVE_PRODUCT_RELATIONSHIPS, UPDATE_OPTION_VALUE, UPDATE_PRODUCT_RELATIONSHIPS } from 'services/query'

export const getOptions = client => {
  return client.query({
    query: GET_OPTIONS,
    fetchPolicy: 'no-cache'
  })
}

export const onCreateOption = (client, orgId, customizationName) => {
  return client.mutate({
    mutation: CREATE_OPTION,
    variables: {
      input: {
        organizationId: orgId,
        name: customizationName,
        description: ''
      }
    },
    fetchPolicy: 'no-cache'
  })
}

export const onOptionValueSubmit = (client, selectedCustomizationGroup, optionValueString) => {
  return client.mutate({
    mutation: CREATE_OPTION_VALUE,
    variables: {
      input: {
        optionId: selectedCustomizationGroup.id,
        value: optionValueString
      }
    }
  })
}

export const updateOptionValue = (client, selectedCustomizationGroupItem, selectedCustomizationGroup, optionValueName) => {
  return client.mutate({
    mutation: UPDATE_OPTION_VALUE,
    variables: {
      input: {
        id: selectedCustomizationGroupItem.id,
        optionId: selectedCustomizationGroup.id,
        value: optionValueName
      }
    }
  })
}

export const createProductRelationships = (client, ProductRelationshipInput) => {
  return client
    .mutate({
      mutation: CREATE_PRODUCT_RELATIONSHIPS,
      variables: { input: ProductRelationshipInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.error('create product relationship error', e)
    })
}

export const updateProductRelationships = (client, ProductRelationshipInput) => {
  return client
    .mutate({
      mutation: UPDATE_PRODUCT_RELATIONSHIPS,
      variables: { input: ProductRelationshipInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.error('update product relationship error', e)
    })
}

export const removeProductRelationships = (client, ProductRelationshipInput) => {
  return client
    .mutate({
      mutation: REMOVE_PRODUCT_RELATIONSHIPS,
      variables: { input: ProductRelationshipInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.error('remove product relationship error', e)
    })
}
