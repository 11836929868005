import { Checkbox, TimePicker } from 'antd'
import { Button, IntlMessages, Modal, Text } from 'components'
import React from 'react'
import styled from 'styled-components'

const SlotTimeBoxStyle = styled.div`
    display: flex;
    flex-direction: column;
  `
const SlotContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `
const SingleButtonContainerStyle = styled.div`
    display: flex;
    margin-top: 25px;
    justify-content: center;
  `

export interface SlotTimingModalProps {
  isApplyToAllDays?: boolean
  isUpdateSlot: boolean
  onAddTimeSlot: any
  onUpdateTimeSlot: any
  onClose: any
  isAddSlot: boolean
  openTime: any
  closeTime: any
  onSlotOpenTimeChange: any
  onSlotCloseTimeChange: any
  onApplyAllDaysChange?: any
  onDeleteSlot: any
}

export const SlotTimingModal: React.FC<SlotTimingModalProps> = ({ ...props }) => {
  const { isUpdateSlot, onClose, isAddSlot, openTime, closeTime, isApplyToAllDays, onSlotOpenTimeChange, onSlotCloseTimeChange, onApplyAllDaysChange, onAddTimeSlot, onUpdateTimeSlot, onDeleteSlot } = props

  return (
    <Modal
      title={isUpdateSlot ? <IntlMessages id="updateSlot" /> : <IntlMessages id="addSlot" />}
      visible={isAddSlot || isUpdateSlot}
      onClose={() => {
        onClose()
      }}
      width={380}>
      <SlotContainer>
        <SlotTimeBoxStyle>
          <Text level="body-2">
            <IntlMessages id="openTime" />
          </Text>
          <TimePicker use12Hours format="h:mm a" onChange={time => onSlotOpenTimeChange(time)} style={{ width: 170 }} minuteStep={1} value={openTime} />
        </SlotTimeBoxStyle>
        <SlotTimeBoxStyle>
          <Text level="body-2">
            <IntlMessages id="closeTime" />
          </Text>
          <TimePicker use12Hours format="h:mm a" onChange={time => onSlotCloseTimeChange(time)} style={{ width: 170 }} minuteStep={1} value={closeTime} />
        </SlotTimeBoxStyle>
      </SlotContainer>
      {!isUpdateSlot ? (
        <div>
          <Checkbox
            style={{ marginTop: '10px' }}
            checked={isApplyToAllDays}
            onChange={e => {
              onApplyAllDaysChange(e.target.checked)
            }}>
            {' '}
            <IntlMessages id="store.applyToAllDays" />
          </Checkbox>
        </div>
      ) : null}
      <SingleButtonContainerStyle>
        <Button
          type="primary"
          loading={false}
          style={{ width: '130px' }}
          disabled={!openTime || !closeTime}
          onClick={() => {
            isUpdateSlot ? onUpdateTimeSlot() : onAddTimeSlot()
          }}>
          {isUpdateSlot ? <IntlMessages id="update" /> : <IntlMessages id="create" />}
        </Button>
        {isUpdateSlot ? (
          <Button
            type="danger"
            loading={false}
            style={{ width: '130px' }}
            onClick={() => {
              onDeleteSlot()
            }}>
            <IntlMessages id="button.delete" />
          </Button>
        ) : null}
      </SingleButtonContainerStyle>
    </Modal>
  )
}
