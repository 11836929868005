import { withApollo } from '@apollo/client/react/hoc'
import { Button, Col } from 'antd'
import { IntlMessages } from 'components'
import * as compose from 'lodash/flowRight'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { arrayMove, SortableContainer } from 'react-sortable-hoc'
import {
  addMenuTimingsForCategory,
  createdCategory,
  getAllProductCategory,
  removeMenuTimingsForCategory as removeMenuTimingsForCategoryService,
  updateCategory as updateCategoryService,
  updateCategorySortSeq,
  updateMenuTimingsForCategory as updateMenuTimingsForCategoryService
} from 'services'
import { setInfoMessage } from 'Utils'
import { PRODUCT_TYPE_ENUM } from 'Utils/constants'
import { getWcoreOrganizationId } from 'Utils/localStorageHandlers/getter'

import { HeaderText } from '../../../styles/index'
import {
  getCategoryData,
  getUpdateCategorySortSeqInput,
  isCategoryNameExist,
  isMenuTimingNotAvailableForCategory,
  republishCatalog,
  setSuccessMessage
} from '../../../Utils/menuUtils'
import CategoriesList from '../components/CategoriesList'
import ConfirmChangesModal from '../components/ConfirmChangesModal'
import CategoryForm from './CategoryForm'

const Categories = props => {
  const history = useHistory()
  const org_id = getWcoreOrganizationId()
  const isFormDataChange = useRef(false)
  const categoryIndexToSelect = useRef(null)
  const unblock = useRef({})

  const [categories, setCategories] = useState([])
  const [isListed, setIsListed] = useState(true)
  const [loading, setLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [categoryForm, setCategoryForm] = useState({
    name: '',
    description: '',
    menuTiming: {},
    isMenuTimingEnable: false
  })
  const [categoryDetails, setCategoryDetails] = useState({})
  const [isFetchingCategory, setIsFetchingCategory] = useState(false)
  const [isOrder, setIsOrder] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  useEffect(() => {
    getAllCategoryByCatalog(props.catalogId)
    unblock.current = history.block(targetLocation => {
      props.onTargetLocationChange(targetLocation)
      if (isFormDataChange.current) {
        return false
      }
    })
  }, [])

  const getAllCategoryByCatalog = async catalogId => {
    setIsFetchingCategory(true)
    try {
      const allCategoriesResponse = await getAllProductCategory(props.client, {
        id: catalogId
      })

      const listedCategories = allCategoriesResponse?.data?.categories.filter(
        val => val.listable === true
      )

      const listedRegularCategories = listedCategories.filter(
        category => category.productType === PRODUCT_TYPE_ENUM.PRODUCT
      )

      setCategories(listedRegularCategories)
      setIsFetchingCategory(false)

      isFormDataChange.current = false
      onCategorySelection(listedRegularCategories[0])
    } catch (err) {
      console.warn('Category List Error:', err)
      setIsFetchingCategory(false)
    }
  }

  const onChange = (type: string, value: any) => {
    categoryForm[type] = value
    isFormDataChange.current = true
    props.onFormDataChange(true, unblock)

    setCategoryForm(categoryForm => ({
      ...categoryForm,
      categoryForm
    }))
  }

  const toggleNewCategory = () => {
    if (!categoryDetails['id'] && categoryForm['name']) {
      setInfoMessage('category.message.newCategoryInfo', props.intl)

      return
    }
    if (isFormDataChange.current) {
      setShowConfirmModal(true)
      categoryIndexToSelect.current = null

      return
    }
    categoryForm['name'] = ''
    categoryForm['menuTiming'] = {}
    categoryForm['isMenuTimingEnable'] = false
    isFormDataChange.current = false
    categoryIndexToSelect.current = null
    props.onFormDataChange(false, unblock.current)
    setCategoryDetails({})
    setCategoryForm(categoryForm)
    setIsListed(true)
    setIsEdit(false)
  }

  const updateListableProp = async () => {
    setIsListed(!isListed)
    if (isEdit) {
      try {
        const updateCategoryInput = {
          id: categoryDetails['id'],
          listable: !isListed,
          organizationId: org_id
        }
        const updateCategoryResponse = await updateCategoryService(
          props.client,
          updateCategoryInput
        )
        const newUpdateCategory = updateCategoryResponse.data.updateCategory
        const categoryIndex = categories.findIndex(
          category => category.id === newUpdateCategory.id
        )

        if (categoryIndex !== -1) {
          categories[categoryIndex] = {
            ...categories[categoryIndex],
            listable: newUpdateCategory.listable
          }
        }
        setCategories(categories)
        republishCatalog(props.client)
      } catch (err) {
        setIsListed(isListed)
      }
    }
  }

  const menuTimingUpdate = id => {
    if (categoryDetails['menuTimings'] && categoryForm['isMenuTimingEnable']) {
      updateMenuTimingsForCategory(id)
    } else if (
      !categoryForm['isMenuTimingEnable'] &&
      categoryDetails['menuTimings']
    ) {
      removeMenuTimingsForCategory(id)
    } else if (categoryForm['menuTiming']) {
      addMenuTimingForCategory(id)
    }
  }

  const createOrUpdateCategory = async () => {
    if (isMenuTimingNotAvailableForCategory(categoryForm, props.intl)) {
      return
    }
    if (
      isCategoryNameExist(categories, categoryForm, categoryDetails, props.intl)
    ) {
      return
    }
    setLoading(true)
    const categoryInput = {
      id: categoryDetails['id'],
      name: categoryForm['name'],
      description: categoryForm['description'],
      listable: isListed,
      organizationId: org_id
    }

    if (!isEdit) {
      onCreateNewCategory(categoryInput)
    } else {
      updateCategory(categoryInput)
    }
  }

  const updateCategory = async categoryInput => {
    try {
      const updateCategoryResponse = await updateCategoryService(
        props.client,
        categoryInput
      )
      const updatedCategory = updateCategoryResponse.data.updateCategory

      menuTimingUpdate(categoryInput.id)
      const newCategoryData = getCategoryData(updatedCategory)
      const categoryIndex = categories.findIndex(
        category => category.id === categoryInput.id
      )

      categories[categoryIndex] = {
        ...categories[categoryIndex],
        ...newCategoryData
      }
      setSuccessMessage(
        'category.successMsg.categoryUpdatedSuccessfully',
        props.intl
      )
      isFormDataChange.current = false
      props.onFormDataChange(false, unblock.current)
      setCategories(categories)
      setLoading(false)
      setCategoryDetails(newCategoryData)
      republishCatalog(props.client)
    } catch (err) {
      setLoading(false)
    }
  }

  const onCreateNewCategory = async categoryInput => {
    const createCategoryInput = {
      status: 'ACTIVE',
      catalogId: props.catalogId,
      code: categoryForm['name']
        .split(' ')
        .join('_')
        .toLowerCase()
    }

    try {
      const createCategoryResponse = await createdCategory(
        props.client,
        categoryInput,
        createCategoryInput
      )
      const newCategory = createCategoryResponse.data.createCategory

      if (categoryForm['isMenuTimingEnable'] && categoryForm['menuTiming']) {
        addMenuTimingForCategory(newCategory.id)
      }
      categories.unshift(newCategory)
      setSuccessMessage(
        'category.successMsg.categoryCreatedSuccessfully',
        props.intl
      )
      isFormDataChange.current = false
      props.onFormDataChange(false, unblock.current)
      setCategories(categories)
      onCategorySelection(newCategory)
      setLoading(false)
      setCategoryDetails(newCategory)
      updateCategorySortSequence()
    } catch (err) {
      setLoading(false)
      setCategoryDetails({})
    }
  }

  const updateCategorySortSequence = async () => {
    const updateCategorySortSeqInput = getUpdateCategorySortSeqInput(
      categories,
      org_id
    )

    try {
      await updateCategorySortSeq(props.client, updateCategorySortSeqInput)
      republishCatalog(props.client)
    } catch (err) {
      setCategoryDetails({})
    }
  }

  const updateMenuTimingsForCategory = async categoryId => {
    try {
      const menuTimingCode = categoryForm['menuTiming']['code']
      const updateMenuTimingRes = await updateMenuTimingsForCategoryService(
        props.client,
        categoryId,
        menuTimingCode
      )

      categoryDetails['menuTimings'] =
        updateMenuTimingRes?.data?.updateMenuTimingsForCategory?.menuTimings
      categories.find((category, i) => {
        if (category.id === categoryId) {
          categories[i].menuTimings =
            updateMenuTimingRes?.data?.updateMenuTimingsForCategory?.menuTimings
        }
      })
      /* eslint-disable-next-line require-atomic-updates */
      categoryForm['menuTiming'] =
        updateMenuTimingRes?.data?.updateMenuTimingsForCategory?.menuTimings ||
        {}
      setCategories(categories)
      setCategoryDetails(categoryDetails)
      setCategoryForm(categoryForm)
    } catch (error) {
      console.warn(error)
    }
  }

  const removeMenuTimingsForCategory = async categoryId => {
    try {
      const menuTimingCode = categoryDetails['menuTimings'].code

      if (categoryDetails['menuTimings']) {
        await removeMenuTimingsForCategoryService(
          props.client,
          categoryId,
          menuTimingCode
        )
        categories.find((category, i) => {
          if (category.id === categoryId) {
            categories[i].menuTimings = null
          }
        })
        setCategories(categories)
        setCategoryForm(categoryForm)
      } else {
        categoryForm['menuTiming'] = {}
        setCategoryForm(categoryForm)
      }
    } catch (error) {
      console.log('Error', error)
    }
  }

  const addMenuTimingForCategory = async categoryId => {
    try {
      const menuTimingCode = categoryForm['menuTiming']['code']
      const addMenuTimingRes = await addMenuTimingsForCategory(
        props.client,
        categoryId,
        menuTimingCode
      )

      categoryDetails['menuTimings'] =
        addMenuTimingRes.data.addMenuTimingsForCategory.menuTimings
      /* eslint-disable-next-line require-atomic-updates */
      categoryForm['menuTiming'] =
        addMenuTimingRes.data.addMenuTimingsForCategory.menuTimings || {}
      categories.find((category, i) => {
        if (category.id === categoryId) {
          categories[i].menuTimings =
            addMenuTimingRes.data.addMenuTimingsForCategory.menuTimings
        }
      })
      setCategories(categories)
      setCategoryForm(categoryForm)
      setCategoryDetails(categoryDetails)
    } catch (error) {
      console.log(error)
    }
  }

  const onCategorySelection = category => {
    if (isFormDataChange.current) {
      setShowConfirmModal(true)
      categoryIndexToSelect.current = categories.findIndex(
        c => c.id === category.id
      )

      return
    }
    categoryForm['name'] = category?.name
    categoryForm['menuTiming'] = category?.menuTimings
    categoryForm['isMenuTimingEnable'] = Boolean(category?.menuTimings)
    isFormDataChange.current = false
    categoryIndexToSelect.current = null
    props.onFormDataChange(false, unblock.current)
    setCategoryForm(categoryForm)
    setIsEdit(category.id ? true : isEdit)
    setIsListed(Boolean(category.listable))
    setCategoryDetails(category)
  }

  const updateReorderedCategory = async () => {
    setIsFetchingCategory(true)
    const updateCategorySortSeqInput = getUpdateCategorySortSeqInput(
      categories,
      org_id
    )

    try {
      await updateCategorySortSeq(props.client, updateCategorySortSeqInput)
      setSuccessMessage(
        'category.successMsg.categoryOrderedSuccessfully',
        props.intl
      )
      setIsFetchingCategory(false)
      setIsOrder(!isOrder)
      republishCatalog(props.client)
    } catch (err) {
      setIsFetchingCategory(false)
      setCategoryDetails({})
    }
  }

  const goToCategory = () => {
    setShowConfirmModal(false)
    isFormDataChange.current = false
    if (categoryIndexToSelect.current) {
      onCategorySelection(categories[categoryIndexToSelect.current])
    } else {
      toggleNewCategory()
    }
  }

  const onSortEnd = e => {
    const newCategories = arrayMove(categories, e.oldIndex, e.newIndex)

    onCategorySelection(categories[e.oldIndex])
    setCategories(newCategories)
    updateReorderedCategory()
  }

  const SortableCategoriesList = SortableContainer(CategoriesList)

  return (
    <>
      <Col span={6} className="list-wrapper">
        <HeaderText type="H5" margin="0 0 20px 0" color="#000">
          <IntlMessages id="menu.categories" />
        </HeaderText>
        <Button className="add-new-btn" onClick={() => toggleNewCategory()}>
          <IntlMessages id="category.addNewCategory" />
        </Button>
        <SortableCategoriesList
          onSortEnd={onSortEnd}
          distance={1}
          categories={categories}
          categoryDetails={categoryDetails}
          isFetchingCategory={isFetchingCategory}
          onCategorySelection={cate => onCategorySelection(cate)}
        />
      </Col>
      <CategoryForm
        isListed={isListed}
        categoryForm={categoryForm}
        onTabChange={props.onTabChange}
        isEdit={isEdit}
        loading={loading}
        updateListableProp={updateListableProp}
        onChange={(label, value) => onChange(label, value)}
        createOrUpdateCategory={() => createOrUpdateCategory()}
        client={props.client}
        intl={props.intl}
      />
      <ConfirmChangesModal
        showConfirmModal={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onLeaveBtnAction={() => setShowConfirmModal(false)}
        onAction={() => goToCategory()}
      />
    </>
  )
}

export const CategoriesComponent = compose(withApollo)(Categories)
