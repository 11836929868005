/* eslint-disable */
export const COLORS = {
    // TODO:  color BLACK_4 should be discuss with designed when we will enable notifications.
    // BLACK_4: 'rgb(30,30,30)',
    /*
     *Below color constants are used in reusable components, 
     *Above constant should be removed once reusable components are ready and 
     *below color constants should be used across app 
     */
    PRIMARY: '#1A8D5F',
    PRIMARY_MEDIUM: '#00946E',
    DARK_TEXT: '#000000DE',
    NORMAL_TEXT: '#00000099',
    GRAY_TEXT: '#00000061',
    BG_WHITE: '#FFFFFF',
    BG_LIGHT: '#F9FAFC',
    BG_SHADE: '#F3F7FA',
    BORDER: '#E6E6E6',
    BORDER_DARK: '#B2B2B2',
    BORDER_1: '#d0ced9',
    PRIMARY_DARK: '#117A50',
    PRIMARY_SHADE: '#D1E8DF',
    PRIMARY_LIGHT: '#E8F3EF',
    ALERT_SHADE: '#FFEDE6',
    SUCCESS: '#00B886',
    ALERT: '#FFC107',
    ERROR: '#E04F1A',
    ERROR_DARK: '#D1400B',
    DISABLED: '#F6F6F6',
    BOX_SHADOW: '#00000029',
    SECONDARY: '#F38200',
    TRANSPARENT: '#00000000'
  }


export const FONTWEIGHT = {
    FONT_LIGHT: '300',
    FONT_REGULAR: '400',
    FONT_MEDIUM: '500',
    FONT_BOLD: '600',
    FONT_BLACK: '900',
}