import { ADD_MENU_TIMINGS, ADD_MENU_TIMINGS_FOR_CATEGORY, ADD_MENU_TIMINGS_FOR_PRODUCT, CREATE_MENU_TIMINGS, GET_MENU_TIMINGS, REMOVE_MENU_TIMINGS, REMOVE_MENU_TIMINGS_BY_ID, REMOVE_MENU_TIMINGS_FOR_CATEGORY, REMOVE_MENU_TIMINGS_FOR_PRODUCT, UPDATE_MENU_TIMINGS, UPDATE_MENU_TIMINGS_FOR_CATEGORY, UPDATE_MENU_TIMINGS_FOR_PRODUCT } from 'services/query'

export const getMenuTimings = client => {
  return client.query({
    query: GET_MENU_TIMINGS,
    fetchPolicy: 'no-cache'
  })
}

export const createMenuTimings = (client, createMenuTimingsInput) => {
  return client.mutate({
    mutation: CREATE_MENU_TIMINGS,
    variables: {
      input: createMenuTimingsInput
    }
  })
}

export const addMenuTimings = (client, addMenuTimingsInput) => {
  return client.mutate({
    mutation: ADD_MENU_TIMINGS,
    variables: {
      input: addMenuTimingsInput
    }
  })
}

export const updateMenuTimings = (client, updateMenuTimingsInput) => {
  return client.mutate({
    mutation: UPDATE_MENU_TIMINGS,
    variables: {
      input: updateMenuTimingsInput
    }
  })
}

export const removeMenuTimings = (client, removeMenuTimingsInput) => {
  return client.mutate({
    mutation: REMOVE_MENU_TIMINGS,
    variables: {
      input: removeMenuTimingsInput
    }
  })
}

export const removeMenuTimingsById = (client, removeMenuTimingsByIdInput) => {
  return client.mutate({
    mutation: REMOVE_MENU_TIMINGS_BY_ID,
    variables: {
      input: removeMenuTimingsByIdInput
    }
  })
}

export const addMenuTimingsForCategory = (client, CategoryId, menuTimingsCode) => {
  return client.mutate({
    mutation: ADD_MENU_TIMINGS_FOR_CATEGORY,
    variables: {
      input: {
        category: CategoryId,
        code: menuTimingsCode
      }
    }
  })
}

export const removeMenuTimingsForCategory = (client, CategoryId, menuTimingsCode) => {
  return client.mutate({
    mutation: REMOVE_MENU_TIMINGS_FOR_CATEGORY,
    variables: {
      input: {
        category: CategoryId,
        code: menuTimingsCode
      }
    }
  })
}

export const updateMenuTimingsForCategory = (client, CategoryId, menuTimingsCode) => {
  return client.mutate({
    mutation: UPDATE_MENU_TIMINGS_FOR_CATEGORY,
    variables: {
      input: {
        category: CategoryId,
        code: menuTimingsCode
      }
    }
  })
}

export const addMenuTimingsForProduct = (client, productId, menuTimingsCode) => {
  return client.mutate({
    mutation: ADD_MENU_TIMINGS_FOR_PRODUCT,
    variables: {
      input: {
        product: productId,
        code: menuTimingsCode
      }
    }
  })
}

export const removeMenuTimingsForProduct = (client, productId, menuTimingsCode) => {
  return client.mutate({
    mutation: REMOVE_MENU_TIMINGS_FOR_PRODUCT,
    variables: {
      input: {
        product: productId,
        code: menuTimingsCode
      }
    }
  })
}

export const updateMenuTimingsForProduct = (client, productId, menuTimingsCode) => {
  return client.mutate({
    mutation: UPDATE_MENU_TIMINGS_FOR_PRODUCT,
    variables: {
      input: {
        product: productId,
        code: menuTimingsCode
      }
    }
  })
}
