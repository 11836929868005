import { ACTIVE_DELIVERY_AGENT, ADD_BULK_STORE_OPEN_TIMING, ADD_DELIVERY_AREA, ADD_STAFF_MEMBER_TO_STORE, ADD_STAFF_MEMBERS_TO_STORE, ADD_STORE_CHARGES, ADD_USER_TO_ORGANISATION, ADD_USER_TO_STORE, CHECK_USERNAME_AVAILABILITY, CREATE_BULK_STAFF_MEMBERS, CREATE_STAFF, CREATE_STORE, EDIT_STAFF, GET_ALL_STORES, GET_DELIVERY_AREA, GET_STORE_CHARGE, GET_STORE_NAMES,GET_STORE_SERVICE_AREAS, GET_STORE_STAFF, GET_STORE_STAFF_BUSY_STATUS, INACTIVE_DELIVERY_AGENT, PROCESS_STORE_DISABLE, PROCESS_STORE_ENABLE, REMOVE_DELIVERY_AREA, REMOVE_STORE_SLOTS_TIMINGS, REMOVE_STORE_TIMINGS, ROLES_LIST, STORE, STORE_INVENTORY, STORE_PRODUCTS_AVAILABILITY, STORE_TIMINGS, UPDATE_DELIVERY_AGENT, UPDATE_DELIVERY_AREA, UPDATE_STORE, UPDATE_STORE_CHARGES, UPDATE_STORE_SERVICE_AREA, UPDATE_USER_PASSWORD } from 'services/query'

export const getStore = (client, storeId) => {
  return client.query({
    query: STORE,
    variables: { id: storeId },
    fetchPolicy: 'network-only'
  })
}

export const onDeleteAgent = (client, removeDeliveryAgentInput) => {
  return client
    .mutate({
      mutation: INACTIVE_DELIVERY_AGENT,
      variables: { input: removeDeliveryAgentInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}

export const inactiveStaffMember = (client, removeDeliveryAgentInput) => {
  return client
    .mutate({
      mutation: INACTIVE_DELIVERY_AGENT,
      variables: { input: removeDeliveryAgentInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}

export const getRoles = (client, filter = {}) => {
  return client
    .query({
      query: ROLES_LIST,
      variables: { input: filter },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}

export const getStoreTimings = (client, storeId) => {
  return client
    .query({
      query: STORE_TIMINGS,
      variables: {
        storeId,
        pageOptions: { page: 1, pageSize: 100 }
      },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}
export const getAllStores = (client, orgId) => {
  return client
    .query({
      query: GET_ALL_STORES,
      variables: {
        input: { organizationId: orgId },
        pageOptions: { page: 1, pageSize: 100 }
      },
      fetchPolicy: 'network-only'
    })
    .catch(e => {
      console.log(e)
    })
}

export const getStoreNames = (client, organizationId) => {
  return client
    .query({
      query: GET_STORE_NAMES,
      variables: {
        input: { organizationId },
        pageOptions: { page: 1, pageSize: 100 }
      },
      fetchPolicy: 'network-only'
    })
    .catch(e => {
      console.log(e)
    })
}

export const createStore = (client, createStoreInput) => {
  return client
    .mutate({
      mutation: CREATE_STORE,
      variables: { input: createStoreInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}
export const addUserToOrganiser = (client, addUserToOrganisationInput) => {
  return client
    .mutate({
      mutation: ADD_USER_TO_ORGANISATION,
      variables: { ...addUserToOrganisationInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}

export const addUserToOrganization = (client, addUserToOrganizationInput) => {
  return client
    .mutate({
      mutation: ADD_USER_TO_ORGANISATION,
      variables: { ...addUserToOrganizationInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}

export const addUserToStore = (client, userId, storeId) => {
  return client
    .mutate({
      mutation: ADD_USER_TO_STORE,
      variables: { input: { userId, storeId } },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}
export const createStaff = (client, addStaffInput) => {
  return client
    .mutate({
      mutation: CREATE_STAFF,
      variables: { input: addStaffInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}
export const addDeliveryArea = (client, addDeliveryAreaInput) => {
  return client
    .mutate({
      mutation: ADD_DELIVERY_AREA,
      variables: { input: addDeliveryAreaInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}
export const updateStore = (client, updateStoreInput) => {
  return client
    .mutate({
      mutation: UPDATE_STORE,
      variables: { input: updateStoreInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}
export const updateDeliveryArea = (client, updateDeliveryAreaInput) => {
  return client
    .mutate({
      mutation: UPDATE_DELIVERY_AREA,
      variables: { input: updateDeliveryAreaInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}
export const addStaffMemberToStore = (client, addStaffMemberToStoreInput) => {
  return client
    .mutate({
      mutation: ADD_STAFF_MEMBER_TO_STORE,
      variables: { input: addStaffMemberToStoreInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}
export const updateDeliveryAgent = (client, editStaffInput) => {
  return client
    .mutate({
      mutation: UPDATE_DELIVERY_AGENT,
      variables: { input: editStaffInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}

export const editStaff = (client, editStaffInput) => {
  return client
    .mutate({
      mutation: EDIT_STAFF,
      variables: { input: editStaffInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}

export const updateUserPassword = (client, updateUserPasswordInput) => {
  return client
    .mutate({
      mutation: UPDATE_USER_PASSWORD,
      variables: updateUserPasswordInput,
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}
export const storeCharges = (client, deliveryChargeId, deliveryChargeInput) => {
  const { chargeType, ...updateChargeInput } = deliveryChargeInput

  return client
    .mutate({
      mutation: deliveryChargeId !== '' ? UPDATE_STORE_CHARGES : ADD_STORE_CHARGES,
      variables: deliveryChargeId !== '' ? { id: deliveryChargeId, input: updateChargeInput } : { input: deliveryChargeInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}
export const createBulkStaffMembers = (client, staffDetails) => {
  return client
    .mutate({
      mutation: CREATE_BULK_STAFF_MEMBERS,
      variables: { input: staffDetails },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}
export const addStaffMembersToStore = (client, addStaffMemmbersToStoreInput) => {
  return client
    .mutate({
      mutation: ADD_STAFF_MEMBERS_TO_STORE,
      variables: { input: addStaffMemmbersToStoreInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}

export const addBulkStoreOpenTiming = (client, addStoreTimingInput) => {
  return client
    .mutate({
      mutation: ADD_BULK_STORE_OPEN_TIMING,
      variables: { input: addStoreTimingInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}
export const removeDeliveryArea = (client, deliveryChargeInput) => {
  return client
    .mutate({
      mutation: REMOVE_DELIVERY_AREA,
      variables: {
        input: deliveryChargeInput
      },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}
export const removeStoreTimings = (client, removeStoreTimingInput) => {
  return client
    .mutate({
      mutation: REMOVE_STORE_TIMINGS,
      variables: { input: removeStoreTimingInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}
export async function checkUserName(client, storeFormDetails) {
  return await client
    .query({
      query: CHECK_USERNAME_AVAILABILITY,
      variables: {
        userName: storeFormDetails.userName
      },
      fetchPolicy: 'network-only'
    })
    .catch(e => {
      console.log(e)
    })
}
export const updateStoreServiceArea = (client, updateStoreServiceArea) => {
  return client.mutate({
    mutation: UPDATE_STORE_SERVICE_AREA,
    variables: { input: updateStoreServiceArea },
    fetchPolicy: 'no-cache'
  })
}

export const activeDeliveryAgent = (client, payload) => {
  return client.mutate({
    mutation: ACTIVE_DELIVERY_AGENT,
    variables: { input: payload },
    fetchPolicy: 'no-cache'
  })
}

export const getDeliveryArea = (client, storeId) => {
  return client.query({
    query: GET_DELIVERY_AREA,
    variables: { storeId },
    fetchPolicy: 'no-cache'
  })
}

export const getStoreCharge = (client, id) => {
  return client.query({
    query: GET_STORE_CHARGE,
    variables: { id },
    fetchPolicy: 'no-cache'
  })
}

export const getStoreServiceAreas = (client, payload) => {
  return client.query({
    query: GET_STORE_SERVICE_AREAS,
    variables: payload,
    fetchPolicy: 'no-cache'
  })
}

export const removeStoreSlotTimings = (client, payload) => {
  return client.mutate({
    mutation: REMOVE_STORE_SLOTS_TIMINGS,
    variables: { input: payload },
    fetchPolicy: 'no-cache'
  })
}

export const onToggleStore = (client, payload, isStoreEnable) => {
  return client.mutate({
    mutation: isStoreEnable ? PROCESS_STORE_DISABLE : PROCESS_STORE_ENABLE,
    variables: { input: payload },
    fetchPolicy: 'no-cache'
  })
}

export const storeProductsAvailability = (client, payload) => {
  return client.mutate({
    mutation: STORE_PRODUCTS_AVAILABILITY,
    variables: { input: payload },
    fetchPolicy: 'no-cache'
  })
}

export const fetchStoreInventory = (client, payload) => {
  return client.query({
    query: STORE_INVENTORY,
    variables: {
      input: payload
    },
    fetchPolicy: 'network-only'
  })
}

export const getStoreStaffStatus = (client, payload) => {
  return client.query({
    query: GET_STORE_STAFF_BUSY_STATUS,
    variables: {
      input: payload
    },
    fetchPolicy: 'network-only'
  })
}

export const getStoreStaff = (client, payload) => {
  return client.query({
    query: GET_STORE_STAFF,
    variables: payload,
    fetchPolicy: 'network-only'
  })
}
