import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { Col, Divider, Input, Row } from 'antd'
import { Button, Card, ImageUpload, IntlMessages, Text, Title } from 'components'
import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import Lottie from 'react-lottie'
import { updateOrderXOrganization, updatePMSOrganization } from 'services'
import styled from 'styled-components'
import { getOrgDetails, intlAlertMessage, setErrMsg, setOrgDetails } from 'Utils'
import { ASSOCIATION, COLORS, GSTIN_REGEX, PAN_NUMBER_REGEX } from 'Utils/constants'
import { getFileSystemId, getWcoreOrganizationId } from 'Utils/localStorageHandlers/getter'
import { defaultOptions, getUploadApiConfig } from 'Utils/onboardingUtils'

import { ErrorMsg } from './ErrorMsg'

const CardBlock = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `
const ErrorBlock = styled.div`
    position: absolute;
  `
const Status = styled.div`
    color: ${props => (props.pending ? COLORS.ERROR : COLORS.PRIMARY)};
    font-size: 13px;
    line-height: 31px;
  `
const StatusContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  `
const GSTBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 45%;
  margin-right: 46px;
  width: 45%;
  &:last-child {
    margin-right: 0;
  }
`
const BusinessLogoCard = (props) => {
  const organizationDetails = getOrgDetails()

  const [fileTypeError, setFileTypeError] = useState(false)
  const [fileSizeError, setFileSizeError] = useState(false)
  const [fileDimensionError, setFileDimensionError] = useState(false)
  const [organization, setOrganization] = useState(organizationDetails)
  const [legalBusinessName, setLegalBusinessName] = useState('')
  const [panNo, setPenNo] = useState('')
  const [isPanAccurate, setIsPanAccurate] = useState(false)
  const [GSTIN, setGSTIN] = useState('')
  const [isGSTINAccurate, setIsGSTINAccurate] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [fssaiNum, setFssaiNum] = useState('')

  const resetErrors = () => {
    setFileSizeError(false)
    setFileTypeError(false)
    setFileDimensionError(false)
  }

  useEffect(() => {
    setFssaiNum(organization?.fssai || '')
    const res = organization?.associations?.find(elem => elem?.orgId === organization.id)

    setLegalBusinessName(res?.terms?.legalBusinessName || '')
    res?.terms?.panNumber && onPanChangeHandler(res?.terms?.panNumber)
    res?.terms?.gstNumber && onGSTChangeHandler(res?.terms?.gstNumber)

  }, [])

  const onLogoSave = async url => {
    const org_id = getWcoreOrganizationId()

    try {
      const payload = {
        id: organization.id,
        orgLogo: url
      }
      const updateOrgRes = await updatePMSOrganization(props.pmsClient, payload)

      if (updateOrgRes?.data?.updateOrganization) {
        const payload = {
          id: org_id,
          brandLogo: url
        }

        await updateOrderXOrganization(props.client, payload)

        setOrganization(updateOrgRes.data.updateOrganization.organization)
        props.setOrganizationDetails(updateOrgRes.data.updateOrganization.organization)
      }
    } catch (err) {
      setErrMsg('newOrder.somethingWetWrongMsg', props.intl)
      console.log('Save brand logo error ', err)
    }
  }

  const onPanChangeHandler = val => {
    const verifyPan = PAN_NUMBER_REGEX.test(String(val))

    setPenNo(val.trim())
    setIsPanAccurate(verifyPan)
  }

  const onGSTChangeHandler = val => {
    const verifyGSTIN = GSTIN_REGEX.test(String(val))

    setGSTIN(val.trim())
    setIsGSTINAccurate(verifyGSTIN)
  }

  const saveOrgDetails = async () => {
    const { id } = getOrgDetails()

    try {
      const payload = {
        id,
        fssai: fssaiNum,
        associations: [
          {
            orgId: id,
            type: ASSOCIATION.CHANNEL,
            terms: {
              legalBusinessName: legalBusinessName.trim(),
              panNumber: panNo,
              gstNumber: GSTIN
            }
          }
        ]
      }

      setIsLoading(true)
      const updateRes = await updatePMSOrganization(
        props.pmsClient,
        payload
      )

      setIsLoading(false)
      if (updateRes?.data?.updateOrganization) {
        const orgDetails = updateRes.data.updateOrganization.organization

        setOrgDetails(orgDetails)
      }
    } catch (err) {
      setIsLoading(false)
      console.log('Save Organization details error', err)
    }
  }

  const { organizationId, token } = getUploadApiConfig()
  const pending = !organization?.orgLogo

  return (
    <Row>
      <Col xs={{ span: 24 }} sm={{ span: 18, offset: 3 }} lg={{ span: 12, offset: 6 }}>
        <div className="mt-1">
          <Card>
            <CardBlock>
              <ImageUpload
                allowedFileSize={5}
                onFileTypeError={() => {
                  setFileTypeError(true)
                }}
                onSizeError={() => {
                  setFileSizeError(true)
                }}
                onDimensionError={() => {
                  setFileDimensionError(true)
                }}
                onUpload={imageUrl => {
                  resetErrors()
                  onLogoSave(imageUrl)
                }}
                validateDimension={true}
                uploadApiConfig={{
                  organizationId,
                  authToken: token,
                  graphqlUrl: process.env.REACT_APP_WCORE_URL,
                  systemId: JSON.parse(getFileSystemId())
                }}
                previewUrl={organization?.orgLogo}
                key={organization?.orgLogo}
              />
              <div style={{ width: '83%' }}>
                <StatusContainer>
                  <Title level={'h3'}>
                    <IntlMessages id="onboarding.businessLogo" />
                    <span style={{ marginLeft: '3px' }}>*</span>
                  </Title>
                  <Status>
                    <Text
                      level="caption"
                      style={{
                        color: pending ? COLORS.ERROR : COLORS.PRIMARY,
                        fontSize: 11
                      }}>
                      <StatusContainer>
                        {pending ? (
                          <span>
                            *<IntlMessages id={'pending'} />
                          </span>
                        ) : (
                          <IntlMessages id={'completed'} />
                        )}
                        {!pending && <Lottie options={defaultOptions} height={31} width={31} style={{ marginLeft: 8 }} />}
                      </StatusContainer>
                    </Text>
                  </Status>
                </StatusContainer>
                <Text level={'body-2'}><IntlMessages id={organization?.logoUrl ? 'onboarding.businessLogoSuccessInfo' : 'onboarding.businessLogoInfo'} /></Text>
                <ErrorBlock>
                  {fileTypeError && (
                    <ErrorMsg msg={'error.onboarding.fileTypeError'} />
                  )}
                  {fileSizeError && (
                    <ErrorMsg msg={'error.onboarding.fileSizeError'} />
                  )}
                  {fileDimensionError && (
                    <ErrorMsg msg={'error.onboarding.logo.dimensionError'} />
                  )}
                </ErrorBlock>
              </div>
            </CardBlock>
            <Divider />
            <div style={{ marginTop: '3%' }}>
              <CardBlock>
                <GSTBlock>
                  <Text level={'caption'}>
                    <IntlMessages id="legalBusinessName" />
                  </Text>
                  <Input
                    placeholder={intlAlertMessage({
                      id: 'tamak',
                      intl: props.intl
                    })}
                    style={{ margin: '5px 0', width: '100%' }}
                    value={legalBusinessName}
                    onChange={e =>
                      setLegalBusinessName(e.target.value)
                    }
                  />
                </GSTBlock>
                <GSTBlock>
                  <Text level={'caption'}>
                    <IntlMessages id="onboarding.fssaiNum" />
                  </Text>
                  <Input
                    placeholder={intlAlertMessage({
                      id: 'onboarding.fssaiNum',
                      intl: props.intl
                    })}
                    style={{ margin: '5px 0', width: '100%' }}
                    value={fssaiNum}
                    onChange={e => setFssaiNum(e.target.value.trim())}
                    suffix={
                      fssaiNum ? (
                        fssaiNum.length >= 14 ? (
                          <CheckCircleFilled style={{ color: COLORS.SUCCESS }} />
                        ) : (
                          <CloseCircleFilled style={{ color: COLORS.ERROR }} />
                        )
                      ) : (
                        <span />
                      )
                    }
                  />
                </GSTBlock>
              </CardBlock>
              <CardBlock>
                <GSTBlock>
                  <Text level={'caption'}>
                    <IntlMessages id="GSTIN" />
                  </Text>
                  <Input
                    placeholder={intlAlertMessage({
                      id: 'XXXXXXXXX00',
                      intl: props.intl
                    })}
                    style={{ margin: '1% 0', flexBasis: '40%' }}
                    onChange={e => onGSTChangeHandler(e.target.value)}
                    value={GSTIN}
                    suffix={
                      GSTIN ? (
                        isGSTINAccurate ? (
                          <CheckCircleFilled style={{ color: COLORS.SUCCESS }} />
                        ) : (
                          <CloseCircleFilled style={{ color: COLORS.ERROR }} />
                        )
                      ) : (
                        <span />
                      )
                    }
                  />
                </GSTBlock>
                <GSTBlock>
                  <Text level={'caption'}>
                    <IntlMessages id="panNo" />.
                  </Text>
                  <Input
                    placeholder={intlAlertMessage({
                      id: 'AAAAAA0000A',
                      intl: props.intl
                    })}
                    style={{ margin: '5px 0', width: '100%' }}
                    value={panNo}
                    onChange={e => onPanChangeHandler(e.target.value)}
                    suffix={
                      panNo ? (
                        isPanAccurate ? (
                          <CheckCircleFilled style={{ color: COLORS.SUCCESS }} />
                        ) : (
                          <CloseCircleFilled style={{ color: COLORS.ERROR }} />
                        )
                      ) : (
                        <span />
                      )
                    }
                  />
                </GSTBlock>
              </CardBlock>
            </div>
            <div>
              <Button
                ghost={false}
                onClick={() => saveOrgDetails()}
                disabled={
                  !(
                    legalBusinessName &&
                      GSTIN ? isGSTINAccurate : true &&
                      isPanAccurate &&
                    fssaiNum
                  )
                }
                style={{ width: '10%' }}
                size="default"
                type="primary"
                loading={isLoading}>
                <IntlMessages id="button.save" />
              </Button>
            </div>
          </Card>
        </div>
      </Col>
    </Row>
  )
}

export default injectIntl(BusinessLogoCard)
