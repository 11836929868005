import { notification } from 'antd'
import { IntlMessages } from 'components'
import React, { Component } from 'react'
import { COLORS } from 'Utils/constants'

const key = 'network-error'

interface INetworkStatusProps {}

interface INetworkStatusState {}

const MINIMUM_DOWNLINK = 0.05

class NetworkStatus extends Component<INetworkStatusProps, INetworkStatusState> {
  connection

  constructor(props: Readonly<INetworkStatusProps>) {
    super(props)
    this.connection = window.navigator['connection'] || window.navigator['mozConnection'] || window.navigator['webkitConnection']
  }

  componentDidMount() {
    this.handleConnectionChange()
    this.connection.addEventListener('change', this.handleConnectionChange)
  }

  componentWillUnmount() {
    this.connection.removeEventListener('change', this.handleConnectionChange)
  }

  handleConnectionChange = () => {
    const condition = this.connection.downlink

    if (condition <= MINIMUM_DOWNLINK) {
      this.openNotification()
    } else {
      this.closeNotification()
    }
  }

  openNotification = () => {
    notification.warning({
      key,
      message: <IntlMessages id="console.slowInternetMessage" />,
      description: <IntlMessages id="console.AlertCheckNetworkSettings" />,
      duration: 0,
      style: {
        backgroundColor: COLORS.DARK_TEXT,
        color: COLORS.BG_WHITE
      },
      className: 'network-error-notification'
    })
  }

  closeNotification = () => {
    notification.close(key)
  }

  render() {
    return <></>
  }
}

export default NetworkStatus
