export const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image()

    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // Needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export async function getCroppedImg(imageSrc, pixelCrop, cropSize, rotation = 0) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  const maxSize = Math.max(image.width, image.height)
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

  /*
   * Set each dimensions to double largest dimension to allow for a safe area for the
   * image to rotate in without being clipped by canvas context
   */
  canvas.width = safeArea
  canvas.height = safeArea

  // Translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2)
  ctx.rotate(getRadianAngle(rotation))
  ctx.translate(-safeArea / 2, -safeArea / 2)

  // Draw rotated image and store data.
  ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5)
  const data = ctx.getImageData(0, 0, safeArea, safeArea)

  // Set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // Paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(data, Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x), Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y))

  // As Base64 string
  if (cropSize) {
    const canvasElement = document.createElement('canvas')
    const canvasElementCtx = canvasElement.getContext('2d')

    canvasElement.width = cropSize.width
    canvasElement.height = cropSize.height
    canvasElementCtx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, cropSize.width, cropSize.height)

    /*
     * Return canvasElement.toDataURL('image/jpeg');
     * As a blob
     */
    return new Promise(resolve => {
      canvasElement.toBlob(file => {
        resolve(file)
      }, 'image/jpeg')
    })
  }

  /*
   * Return canvas.toDataURL('image/jpeg');
   * As a blob
   */
  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve(file)
    }, 'image/jpeg')
  })

  /*
   * As a blob
   * return new Promise(resolve => {
   *   canvas.toBlob(file => {
   *     resolve(URL.createObjectURL(file));
   *   }, 'image/jpeg');
   * });
   */
}

export async function getRotatedImage(imageSrc, rotation = 0) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  const orientationChanged = rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270

  if (orientationChanged) {
    canvas.width = image.height
    canvas.height = image.width
  } else {
    canvas.width = image.width
    canvas.height = image.height
  }

  ctx.translate(canvas.width / 2, canvas.height / 2)
  ctx.rotate((rotation * Math.PI) / 180)
  ctx.drawImage(image, -image.width / 2, -image.height / 2)

  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve(URL.createObjectURL(file))
    }, 'image/jpeg')
  })
}

export const readFile = file => {
  return new Promise(resolve => {
    const reader = new FileReader()

    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

export const getFileSize = base64String => {
  /*
   * Let base64Length = base64String.length - (base64String.indexOf(',') + 1);
   * let padding = (base64String.charAt(base64String.length - 2) === '=') ? 2 : ((base64String.charAt(base64String.length - 1) === '=') ? 1 : 0);
   * let fileSize = base64Length * 0.75 - padding;
   */
  const base64str = base64String.substr(base64String.indexOf(',') + 1)
  const fileSize = atob(base64str).length

  return fileSize
}

export const isFileSizeExceedLimit = (base64String, allowedSizeInMB) => {
  let exceedLimit = false
  const sizeCheck = 1024 * 1024 * allowedSizeInMB
  const checkedSize = getFileSize(base64String) / sizeCheck

  if (Math.floor(checkedSize) > 0) {
    exceedLimit = true
  }

  return exceedLimit
}
