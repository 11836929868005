import { Col, Row } from 'antd'
import React from 'react'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { getOrgDetails } from 'Utils'
import { MENU_HELP_URL } from 'Utils/constants'
import { getUserData, setOnboarding } from 'Utils/onboardingUtils'

import CommonOnboardingCard from './commonOnboardingCard'

const VerifyMenu = props => {
  const history = useHistory()
  const { catalogs } = getUserData()
  const organization = getOrgDetails()
  const isCatalogCreated = Boolean(catalogs?.length) || false
  const isMenuVerified = catalogs?.length > 0 && catalogs[0].listable

  const goToCatalogDetails = () => {
    setOnboarding()
    const catalog = catalogs[0]

    history.push({ pathname: `/orderx/menu-list/${catalog.id}/detail` })
  }
  const isPending = !isMenuVerified
  const isCardDisable = !isCatalogCreated || !organization?.orgLogo

  return (
    <Row>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 18, offset: 3 }}
        lg={{ span: 12, offset: 6 }}>
        <div className="mt-1">
          <CommonOnboardingCard
            intl={props.intl}
            pending={isPending}
            disable={isCardDisable}
            onButtonClick={() => goToCatalogDetails()}
            title="onboarding.yourMenuCard.title"
            description={
              isPending
                ? 'onboarding.yourMenuCard.description'
                : 'onboarding.yourMenuCard.description.success'
            }
            buttonText="button.verify"
            helpUrl={MENU_HELP_URL}
            required={true}
          />
        </div>
      </Col>
    </Row>
  )
}

export default injectIntl(VerifyMenu)
