import { Radio as AntRadio } from 'antd'
import React from 'react'
import styled from 'styled-components'
import theme from 'styled-theming'
import { COLORS } from 'Utils/constants'

export interface RadioButtonProps {
  /**
   * Text to be displayed
   */
  label: string
  /**
   * Used for setting the currently selected value.
   */
  value: string
  /**
   * Disable radio button
   */
  disabled?: boolean
}

const hoverBgColor = theme('mode', {
  light: COLORS.PRIMARY_LIGHT
})
const textColor = theme('mode', {
  light: COLORS.PRIMARY
})
const StyledRadioButton = styled(AntRadio.Button)`
    &.ant-radio-button-wrapper {
      color: ${textColor};
      text-transform: uppercase;
      &:hover {
        background-color: ${hoverBgColor};
      }
    }
  `

/**
 * Peppo Radio button to show a single state
 */
export const RadioButton: React.FC<RadioButtonProps> = ({ disabled = false, ...props }) => {
  return (
    <StyledRadioButton disabled={disabled} value={props.value}>
      {props.label}
    </StyledRadioButton>
  )
}
