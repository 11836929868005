import { SearchOutlined } from '@ant-design/icons'
import { AutoComplete as AntAutoComplete, Input } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'

export interface AutoCompleteDropdownProps {
  disabled?: boolean
  dataSource?: any
  label?: string
}

const StyledAutoCompleteDropdown = styled(AntAutoComplete)`
  &.ant-select {
    width: 303px;
    border-radius: 25px;
  }
  &.ant-select-auto-complete.ant-select.ant-select-selection--single {
    width: 30%;
  }
  &.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft
    .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
    &:hover {
    }
  }
`
const StyledInput = styled(Input)`
  &.search-input {
    width: 303px;
    border-radius: 50px;
  }
  &.search-input.ant-input-affix-wrapper .ant-input {
    border-radius: 50px;
  }
`

export const AutoCompleteDropdown: React.FC<AutoCompleteDropdownProps> = ({
  disabled = false,
  ...props
}) => {
  const [value, setValue] = useState('')
  const [options, setOptions] = useState([])
  const onSelect = (data: any) => {
    const filterList = props.dataSource?.filter(
      (elem: any) => elem.toLowerCase().indexOf(data.toLowerCase()) !== -1
    )

    setOptions(!data ? [] : filterList)
  }
  const onChange = (data: any) => {
    setValue(data)
    onSelect(data)
  }

  return (
    <StyledAutoCompleteDropdown
      dropdownClassName="search-dropdown"
      value={value}
      dataSource={options}
      disabled={disabled}
      onSelect={onSelect}
      onChange={onChange}>
      <StyledInput
        className="search-input"
        placeholder={props.label}
        prefix={<SearchOutlined className="certain-category-icon" />}
      />
    </StyledAutoCompleteDropdown>
  )
}
