// Import theme from 'styled-theming';
import { Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { COLORS } from 'Utils/constants'

const { Text: AntText } = Typography

export interface TextProps {
  /**
   * How large should the Title be?
   */
  level: 'body-1' | 'body-2' | 'button' | 'caption' | 'overline'
  /**
   * Apply Custom Css
   */
  style?: Object
}

const StyledText = styled(AntText)`
  &.ant-typography {
    font-family: Roboto;
    letter-spacing: 0px;
  }
  &.text-body-1 {
    font-size: 14px;
    font-weight: 600;
    color: ${COLORS.NORMAL_TEXT};
  }
  &.text-body-2 {
    font-size: 14px;
    font-weight: normal;
    color: ${COLORS.NORMAL_TEXT};
  }
  &.text-button {
    font-size: 14px;
    font-weight: 600;
    color: ${COLORS.NORMAL_TEXT};
  }
  &.text-caption {
    font-size: 12px;
    font-weight: normal;
    color: ${COLORS.NORMAL_TEXT};
  }
  &.text-overline {
    font-size: 10px;
    font-weight: normal;
    color: ${COLORS.NORMAL_TEXT};
  }
`

/**
 * Peppo Text component which provides different types of body text
 */
export const Text: React.FC<TextProps> = ({ level = 'body-1', ...props }) => {
  return (
    <StyledText className={`text-${level}`} style={props.style}>
      {props.children}
    </StyledText>
  )
}
