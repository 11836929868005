import React from 'react'
import styled from 'styled-components'
// Import theme from 'styled-theming';
import { COLORS } from 'Utils/constants'

import { Text } from '../Text'

export interface TagProps {
  /**
   * Text to display in tag
   */
  label: string
  /**
   * Bg color of tag
   */
  bgColor?: string
  /**
   * Color of txt in tag
   */
  color?: string
  /**
   * Apply custom css
   */
  style?: object
}

const StyledTag = styled.span`
  background-color: ${props => (props.bgColor ? props.bgColor : COLORS.BG_SHADE)};
  border-radius: 2px;
  border: 1px solid ${COLORS.GRAY_TEXT};
  padding: 0 5px 2px 5px;
  .text-overline {
    font-size: 9px;
    font-weight: normal;
    color: ${props => (props.color ? props.color : COLORS.GRAY_TEXT)};
  }
`

/**
 * Peppo Tag component
 */
export const Tag: React.FC<TagProps> = ({ ...props }) => {
  return (
    <StyledTag style={props.style}>
      <Text level="overline">{props.label}</Text>
    </StyledTag>
  )
}
