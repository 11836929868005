// Import theme from 'styled-theming';
import { Col, Row } from 'antd'
import { Button, TimeSlot, Title } from 'components'
import React from 'react'
import styled from 'styled-components'

export interface WeekTimeSlotsProps {
  /**
   * Day wise slots detail
   */
  dayWiseSlots?: any[]
  /**
   * Add new slot handler
   */
  onToggleAddSlot: any
  /**
   * Update slot handler
   */
  onToggleUpdateSlot: any
  /**
   * Apply custom css
   */
  style?: object
}

const StyledContainer = styled(Row)`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 6px;
  margin: 0;
  min-height: 170px;
  overflow-y: auto;
`

/**
 * Peppo WeekTimeSlots component to display day wise time slot details
 */
export const WeekTimeSlots: React.FC<WeekTimeSlotsProps> = ({ ...props }) => {
  const { dayWiseSlots, onToggleAddSlot, onToggleUpdateSlot } = props
  const daysLength = dayWiseSlots?.length

  return (
    <StyledContainer
      style={{
        width: daysLength === 7 ? '100%' : daysLength === 1 ? '25%' : `${100 - 100 / daysLength}%`
      }}>
      {dayWiseSlots.map((day, i) => {
        return (
          <Col
            style={{
              width: `${100 / daysLength}%`,
              padding: '0 5px'
            }}
            key={i}>
            <Title level="h6">{day.days[0]}</Title>
            {day.data &&
              day.data.map((slot, i) => {
                return <TimeSlot key={`${day.days[0]}-time-slot-${i}`} day={day} slot={slot} slotIndex={i} onSlotChange={onToggleUpdateSlot} />
              })}
            <Button
              style={{ width: '100%' }}
              type="secondary"
              onClick={() => {
                onToggleAddSlot(day.days[0])
              }}>
              + Add Slot
            </Button>
          </Col>
        )
      })}
    </StyledContainer>
  )
}
