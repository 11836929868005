import './styles.css'

import { ApolloProviderProps } from '@apollo/client/react/context'
import { withApollo } from '@apollo/client/react/hoc'
import {
  Button,
  Col,
  DatePicker,
  Input,
  Menu,
  Pagination,
  Row,
  Spin,
  Table
} from 'antd'
import OrderDetail from 'components/complex/orderDetail'
import { History } from 'history'
import moment from 'moment'
import * as React from 'react'
import { injectIntl } from 'react-intl'
import { getOrderHistoryAPI } from 'services'
import { getDeliveryDiscount, intlAlertMessage } from 'Utils'
import {
  COLORS,
  CONSOLE_ORDER_STATUS,
  DATE_FORMAT,
  DEFAULT_PAGE_NUMBER,
  HOUR_TO_MINUTE_TIME_FORMAT,
  MAX_ITEMS_PER_PAGE_ORDER_HISTORY,
  NOTE_TYPE,
  ORDER_HISTORY_HELP_URL,
  ORDER_HISTORY_TABS,
  ORDER_STATUS,
  ORDER_TYPE,
  ROLE_TYPE,
  YEAR_TO_DATE_FORMAT
} from 'Utils/constants'
import {
  getUserData,
  getWcoreOrganizationId
} from 'Utils/localStorageHandlers/getter'

import {
  Button as ConsoleButton,
  IntlMessages,
  Text,
  Title
} from '../../components'
const { RangePicker } = DatePicker
const { Search } = Input

interface iProps extends ApolloProviderProps<any> {
  history: History
  intl: any
}

interface iState {
  startDate: string
  endDate: string
  totalData: any
  isLoading: boolean
  isManager: false
  stores: any
  filter: string
  groupedOrders: any
  viewOrderData: any
  viewOrder: boolean
  refetchData: boolean
  orders: any
  isFiltered: boolean
  selectedOrderHistoryType: string
  selfPickupData: any
  paginationInfo: any
  pageSize: any
}

class OrderHistory extends React.Component<iProps, iState> {
  constructor(props: iProps) {
    super(props)
    const userData = getUserData()
    const roles = userData ? userData.roles : []
    const stores = userData ? userData.store : []
    let isManager = false

    roles.forEach(element => {
      if (element.name === ROLE_TYPE.MANAGER) {
        isManager = true
      }
    })
    const selectedOrderHistoryType = ORDER_HISTORY_TABS.DELIVERY

    this.state = {
      startDate: null,
      endDate: null,
      totalData: [],
      isLoading: true,
      isManager,
      stores,
      filter: '',
      groupedOrders: [],
      viewOrderData: null,
      viewOrder: false,
      refetchData: false,
      orders: [],
      isFiltered: true,
      selectedOrderHistoryType,
      selfPickupData: [],
      paginationInfo: null,
      pageSize: MAX_ITEMS_PER_PAGE_ORDER_HISTORY
    }
  }

  selfPickupColumnHeaders = [
    {
      name: 'time',
      title: 'Time',
      key: 'time',
      dataIndex: 'time',
      width: '8%',
      render: time => (
        <span>
          {time.split(' ')[0]}{' '}
          <span style={{ fontSize: '9px' }}>{time.split(' ')[1]}</span>
        </span>
      )
    },
    {
      name: 'order id',
      title: 'Order ID',
      key: 'orderId',
      dataIndex: 'orderId',
      width: '15%'
    },
    {
      name: 'customerDetails',
      title: 'Customer Details',
      key: 'customerDetails',
      dataIndex: 'customerDetails',
      width: '24%',
      render: person => (
        <div>
          <div>{person?.name || ''}</div>
          {person?.phoneNumber && <div>Ph: 0{person?.phoneNumber}</div>}
        </div>
      )
    },
    {
      name: 'itemCount',
      title: 'No. of Items',
      key: 'itemCount',
      dataIndex: 'itemCount',
      width: '17%'
    },
    {
      name: 'Bill',
      title: 'Bill Amount',
      key: 'Bill',
      dataIndex: 'Bill',
      width: '10%',
      render: price => (
        <span>
          {'\u20B9'}
          {'  '}
          {parseFloat(price).toFixed(2)}
        </span>
      )
    },
    {
      name: 'Status',
      title: 'Order Status',
      key: 'Status',
      dataIndex: 'Status',
      width: '14%',
      render: status => {
        return (
          <span className="circle" style={{ color: COLORS.DARK_TEXT }}>
            {CONSOLE_ORDER_STATUS[status]}
          </span>
        )
      }
    },
    {
      name: 'Action',
      title: 'Action',
      key: 'Action',
      dataIndex: 'Action',
      render: actions => actions
    }
  ]

  columnHeaders = [
    {
      name: 'time',
      title: 'Time',
      key: 'time',
      dataIndex: 'time',
      width: '8%',
      render: time => (
        <span>
          {time.split(' ')[0]}
          <span style={{ fontSize: '9px' }}>{time.split(' ')[1]}</span>
        </span>
      )
    },
    {
      name: 'order id',
      title: 'Order ID',
      key: 'orderId',
      dataIndex: 'orderId',
      width: '15%'
    },
    {
      name: 'customerDetails',
      title: 'Customer Details',
      key: 'customerDetails',
      dataIndex: 'customerDetails',
      width: '17%',
      render: person => (
        <div>
          <div>{person?.name || ''}</div>
          {person?.phoneNumber && <div>Ph: 0{person?.phoneNumber}</div>}
        </div>
      )
    },
    {
      name: 'assignedPerson',
      title: 'Assigned Delivery Person',
      key: 'assignedPerson',
      dataIndex: 'assignedPerson',
      width: '17%',
      render: person => (
        <div>
          <div>{person && person.name ? person.name : person}</div>
          {person && person.phone && <div>Ph: 0{person.phone}</div>}
        </div>
      )
    },
    {
      name: 'itemCount',
      title: 'No. of Items',
      key: 'itemCount',
      dataIndex: 'itemCount',
      width: '7%'
    },
    {
      name: 'Bill',
      title: 'Bill Amount',
      key: 'Bill',
      dataIndex: 'Bill',
      width: '10%',
      render: price => (
        <span>
          {'\u20B9'}
          {'  '}
          {parseFloat(price).toFixed(2)}
        </span>
      )
    },
    {
      name: 'Status',
      title: 'Order Status',
      key: 'Status',
      dataIndex: 'Status',
      width: '14%',
      render: status => {
        return (
          <span className="circle" style={{ color: COLORS.DARK_TEXT }}>
            {CONSOLE_ORDER_STATUS[status]}
          </span>
        )
      }
    },
    {
      name: 'Action',
      title: 'Action',
      key: 'Action',
      dataIndex: 'Action',
      render: actions => actions
    }
  ]

  componentDidMount() {
    this.getOrderHistory(DEFAULT_PAGE_NUMBER)
  }

  async getOrderHistory(customPageNumber) {
    const { startDate, endDate, isManager, stores, pageSize } = this.state
    const org_id = getWcoreOrganizationId()
    const payload = {
      organizationId: org_id,
      orderStatus: [
        ORDER_STATUS.ORDER_CANCELLED,
        ORDER_STATUS.ORDER_REJECTED,
        ORDER_STATUS.ORDER_DELIVERED,
        ORDER_STATUS.ORDER_COMPLETE,
        ORDER_STATUS.ORDER_PICKED
      ],
      pageOptions: {
        page: customPageNumber,
        pageSize
      }
    }

    if (startDate !== null) {
      payload['dateStart'] = moment(startDate).format('YYYY-MM-DDTHH:mm:ss')
    }
    if (endDate !== null) {
      if (
        moment(endDate).format(YEAR_TO_DATE_FORMAT) ===
        moment().format(YEAR_TO_DATE_FORMAT)
      ) {
        payload['dateEnd'] = moment()
          .format()
          .split('+')[0]
      } else {
        payload['dateEnd'] = `${moment(endDate).format(
          YEAR_TO_DATE_FORMAT
        )}T23:59:59`
      }
    } else {
      payload['dateEnd'] = moment()
        .format()
        .split('+')[0]
    }
    if (isManager) {
      payload['storeId'] = stores[0].id
    }

    /*
     * If (startDate !== null && endDate !== null) {
     *   if (payload['dateStart'] === payload['dateEnd']) {
     *     message.warn('Both the date filters cannot be the same date!');
     *     return false;
     *   }
     * }
     */
    this.setState({ isLoading: true })
    try {
      const response = await getOrderHistoryAPI(this.props.client, payload)
      const listData = response.data.orders.data
      const selfPickupData = listData.filter(
        order => order.orderType === ORDER_TYPE.MOBILE_PICKUP
      )

      this.setState({ isLoading: false })
      // Let todaysDate = moment(new Date()).format();
      const orders = listData.reduce((orders, order) => {
        const date = order.createdTime.split('T')[0]

        if (!orders[date]) {
          orders[date] = []
        }
        /*
         * If (
         *   date === todaysDate.split('T')[0] &&
         *   isClicked &&
         *   startDate &&
         *   endDate
         * ) {
         *   this.setState({
         *     isFiltered: true,
         *   });
         * }
         */
        orders[date].push(order)

        return orders
      }, {})
      const groupedOrders = Object.keys(orders).map(date => {
        return {
          date,
          orders: orders[date]
        }
      })

      this.setState({
        groupedOrders,
        orders: listData,
        selfPickupData,
        paginationInfo: response?.data?.orders?.paginationInfo
      })
    } catch (err) {
      console.log('Error : ', err)
      this.setState({ isLoading: false })
    }
  }

  viewOrderData = order => {
    const orderItems = order.product
    const totalAmount = order.totalOrderAmountNonRoundOff
    const deliveryDiscountCharge = getDeliveryDiscount(order)

    const specialInstructionsNote = order?.notes.find(
      note => note.noteType === NOTE_TYPE.SPECIAL_INSTRUCTION_STORE
    )

    const viewOrderData = {
      id: order.id,
      orderId: order.orderId,
      orderItems,
      totalAmount,
      customer: order.customer,
      allLineItemsTotalPrices: order.allLineItemsTotalPrices,
      orderDeliveryCharges: order.orderDeliveryCharges,
      allLineItemsTotalTaxes: order.allLineItemsTotalTaxes,
      orderLevelTaxes: order.orderLevelTaxes,
      allLineItemsTotalCharges: order.allLineItemsTotalCharges,
      orderLevelCharges: order.orderLevelCharges,
      store: order.store,
      shippingAddress: order.shippingAddress,
      orderStatus: order.orderStatus,
      orderDelivery: order.orderDelivery,
      createdTime: order.createdTime,
      deliveryDiscountCharge,
      note: specialInstructionsNote,
      orderType: order.orderType
    }

    this.setState({
      viewOrder: true,
      viewOrderData
    })
  }

  formAction = order => {
    if (order.orderStatus === ORDER_STATUS.ORDER_CREATED) {
      return (
        <Button type={'primary'} onClick={this.viewOrderData.bind(this, order)}>
          <IntlMessages id="orderTable.viewOrder" />
        </Button>
      )
    }

    return (
      <ConsoleButton
        type={'default'}
        style={{ borderColor: COLORS.PRIMARY, color: COLORS.PRIMARY }}
        onClick={this.viewOrderData.bind(this, order)}>
        <IntlMessages id="orderTable.viewOrder" />
      </ConsoleButton>
    )
  }

  onCancelClick = () => {
    this.setState({
      viewOrder: false
    })
  }

  closeModal = () => {
    this.setState({
      refetchData: true
    })
    this.setState({
      viewOrder: false,
      refetchData: false
    })
  }

  buildColumnData = orders => {
    const deliveryOrderData = orders.filter(
      order => order.orderType === ORDER_TYPE.MOBILE_DELIVERY
    )

    const columnOrders = deliveryOrderData.map((order, i) => {
      const actions = this.formAction(order)
      const deliveryDiscount = getDeliveryDiscount(order)
      const quantity = order.product.length

      return {
        time: moment(order.createdTime)
          .utc()
          .format(HOUR_TO_MINUTE_TIME_FORMAT),
        assignedPerson:
          order.orderStatus === ORDER_STATUS.ORDER_CREATED ||
          order.orderStatus === ORDER_STATUS.ORDER_REJECTED ? (
            <IntlMessages id="orderTable.notAssigned" />
            ) : order.orderDelivery.length &&
            order.orderDelivery[0].internalDeliveryStaff ? (
                {
                  name: order.orderDelivery[0].internalDeliveryStaff.name,
                  phone: order.orderDelivery[0].internalDeliveryStaff.phone.slice(2)
                }
              ) : order.orderDelivery.length &&
            order.orderDelivery[0].externalDeliveryDetails &&
            order.orderDelivery[0].externalDeliveryDetails.bpp_task_agent ? (
                  {
                    name:
                order.orderDelivery[0].externalDeliveryDetails.bpp_task_agent
                  .name.given_name,
                    phone:
                order.orderDelivery[0].externalDeliveryDetails.bpp_task_agent
                  .phones[0]
                  }
                ) : order.orderStatus === ORDER_STATUS.ORDER_ACCEPTED ? (
                  <IntlMessages id="orderTable.lookingForRider" />
                ) : (
                  <IntlMessages id="orderTable.notAssigned" />
                ),
        Bill: deliveryDiscount
          ? Math.ceil(order.totalOrderAmountNonRoundOff - deliveryDiscount)
          : Math.ceil(order.totalOrderAmountNonRoundOff),
        customerDetails:
          order.orderStatus !== ORDER_STATUS.ORDER_CREATED
            ? {
              name: order?.customer?.person?.firstName || null,
              phoneNumber: order.shippingAddress.contactNumber
                .split(':')
                .pop()
                .slice(2)
            }
            : null,
        Status: order.orderStatus,
        Action: actions,
        orderStatus: order.orderStatus,
        orderId: order.orderId,
        itemCount: quantity,
        orderDelivery: order.orderDelivery
      }
    })

    return columnOrders
  }

  SelfPickupColumnData = orders => {
    const pickupOrderData = orders.filter(
      order => order.orderType === ORDER_TYPE.MOBILE_PICKUP
    )

    const columnOrders = pickupOrderData.map((order, i) => {
      const actions = this.formAction(order)
      const deliveryDiscount = getDeliveryDiscount(order)
      const quantity = order.product.length

      return {
        time: moment(order.createdTime)
          .utc()
          .format(HOUR_TO_MINUTE_TIME_FORMAT),
        Bill: deliveryDiscount
          ? Math.ceil(order.totalOrderAmountNonRoundOff - deliveryDiscount)
          : Math.ceil(order.totalOrderAmountNonRoundOff),
        customerDetails:
          order.orderStatus !== ORDER_STATUS.ORDER_CREATED
            ? {
              name: order?.customer?.person?.firstName || null,
              phoneNumber: order?.customer?.person?.phoneNumber
                .split(':')
                .pop()
                .slice(2)
            }
            : null,
        Status: order.orderStatus,
        Action: actions,
        orderStatus: order.orderStatus,
        orderId: order.orderId,
        itemCount: quantity,
        orderDelivery: order.orderDelivery
      }
    })

    return columnOrders
  }

  onDateRangeChange = (value, dateString) => {
    if (value && Object.keys(value).length === 0) {
      this.setState({ startDate: null, endDate: null }, () => this.getOrderHistory(DEFAULT_PAGE_NUMBER))
    } else {
      this.setState({ startDate: value?.[0] || null, endDate: value?.[1] || null }, () => this.getOrderHistory(DEFAULT_PAGE_NUMBER))
    }
  }

  getRowClassNames = (record: any) => {
    let classes = ''

    classes =
      record.orderStatus === ORDER_STATUS.ORDER_CREATED
        ? 'light-red-bg-color'
        : record.orderStatus === ORDER_STATUS.ORDER_ACCEPTED ||
          record.orderStatus === ORDER_STATUS.ORDER_DELIVERY_FOUND ||
          record.orderStatus === ORDER_STATUS.ORDER_SHIPPED ||
          record.orderStatus === ORDER_STATUS.ORDER_READY
          ? 'light-yellow-bg-color'
          : ''
    if (
      record &&
      record.orderDelivery.length &&
      record.orderDelivery[0].externalDeliveryDetails
    ) {
      classes += ' peppo-delivery-indicator'
    }

    return classes
  }

  handlePageChange = page => {
    this.getOrderHistory(page)
  }

  handlePageSizeChange = (page, size) => {
    this.setState({
      pageSize: size
    }, () => this.getOrderHistory(page)
    )
  }

  render() {
    const {
      startDate,
      endDate,
      isLoading,
      groupedOrders,
      viewOrder,
      viewOrderData,
      filter,
      orders,
      isFiltered,
      selectedOrderHistoryType,
      paginationInfo
    } = this.state

    let isNoOrders = false
    let emptyText = <IntlMessages id="orderHistory.waitingFor1stOrder" />

    if (filter) {
      isNoOrders = !(
        orders.filter(
          order =>
            order.orderId.indexOf(filter) !== -1 ||
            (order.shippingAddress &&
              order.shippingAddress.contactNumber &&
              order.shippingAddress.contactNumber.indexOf(filter) !== -1)
        ).length > 0
      )
    }
    if (startDate !== null && endDate !== null && groupedOrders.length === 0) {
      emptyText = <IntlMessages id="orderHistory.noOrderFoundInSelectedDate" />
    }
    if (startDate !== null && endDate !== null && isNoOrders) {
      emptyText = <IntlMessages id="orderHistory.noOrderFoundInSelectedDate" />
    } else if (isNoOrders) {
      emptyText = <IntlMessages id="orderTable.searchedOrderNotFound" />
    }

    return (
      <div className="main">
        <Row className="order-history-header">
          <Col span={22}>
            <Title level="h2">
              <IntlMessages id="OrderHistory" />
            </Title>
          </Col>
          <Col span={2} style={{ marginTop: '7px' }}>
            <a
              className="menu-help"
              href={ORDER_HISTORY_HELP_URL}
              target="_blank"
              rel="noopener noreferrer">
              <IntlMessages id="menu.help" />
            </a>
          </Col>
        </Row>
        <div className="order-history-body">
          <Row className="filter-wrapper">
            <Col span={17}>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                <Menu
                  mode="horizontal"
                  selectable={true}
                  selectedKeys={[selectedOrderHistoryType]}
                  className="order-history-tabs"
                  onSelect={({ key }) => {
                    if (key === ORDER_HISTORY_TABS.DELIVERY) {
                      this.setState({
                        selectedOrderHistoryType: ORDER_HISTORY_TABS.DELIVERY
                      })
                    } else {
                      this.setState({
                        selectedOrderHistoryType: ORDER_HISTORY_TABS.SELF_PICKUP
                      })
                    }
                  }}>
                  <Menu.Item
                    style={{
                      minWidth: 'max-content',
                      textAlign: 'center'
                    }}
                    key={ORDER_HISTORY_TABS.DELIVERY}
                    className={
                      selectedOrderHistoryType === ORDER_HISTORY_TABS.DELIVERY
                        ? 'active'
                        : ''
                    }>
                    <IntlMessages id="delivery" />
                  </Menu.Item>
                  <Menu.Item
                    style={{
                      minWidth: 'max-content',
                      textAlign: 'center'
                    }}
                    key={ORDER_HISTORY_TABS.SELF_PICKUP}
                    className={
                      selectedOrderHistoryType ===
                      ORDER_HISTORY_TABS.SELF_PICKUP
                        ? 'active'
                        : ''
                    }>
                    <IntlMessages id="self-pickup" />
                  </Menu.Item>
                </Menu>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '10px'
                  }}>
                  <div>
                    <div>
                      <Text level="caption" style={{ paddingLeft: '5px' }}>
                        <IntlMessages id="orderHistory.selectDate" />
                      </Text>
                    </div>
                    <RangePicker
                      className="filterComp"
                      format={DATE_FORMAT}
                      separator="-"
                      onChange={this.onDateRangeChange}
                    />
                  </div>
                  {startDate && (
                    <ConsoleButton
                      type="link"
                      style={{ marginBottom: '-15px' }}
                      onClick={() => {
                        this.setState({ endDate: null, startDate: null }, () => {
                        this.getOrderHistory(DEFAULT_PAGE_NUMBER)
                        })
                      }}>
                      <IntlMessages id="console.clear" />
                    </ConsoleButton>
                  )}
                </div>
              </Row>
            </Col>
            <Col span={6} className="input-search-wrapper">
              <Search
                placeholder={intlAlertMessage({
                  id: 'orderHistory.searchByCustomerNo',
                  intl: this.props.intl
                })}
                className="input-search"
                onChange={e => {
                  !e.target.value && this.setState({ filter: e.target.value })
                }}
                onSearch={value => {
                  this.setState({ filter: value })
                }}
              />
            </Col>
          </Row>
          {filter === '' && isLoading ? (
            <div className="justifyContent-center" style={{ height: '60vh' }}>
              <Spin
                size="large"
                tip={intlAlertMessage({
                  id: 'orderTable.message.consoleIsLoading',
                  intl: this.props.intl
                })}
              />
            </div>
          ) : groupedOrders.length > 0 && !isNoOrders ? (
            groupedOrders.map((data, indx) => {
              const orders = filter
                ? data.orders.filter(
                    order =>
                      order.orderId.indexOf(filter) !== -1 ||
                      (order.shippingAddress &&
                        order.shippingAddress.contactNumber &&
                        order.shippingAddress.contactNumber.indexOf(filter) !==
                          -1)
                  )
                : data.orders

              const columnsData = this.buildColumnData(orders)
              const SelfPickupColumnData = this.SelfPickupColumnData(orders)

              return (
                <div key={indx}>
                  {indx === 0 && (
                    <div>
                      <Table
                        rowClassName={(record: any, index) => {
                          return this.getRowClassNames(record)
                        }}
                        className="order-history-table"
                        columns={
                          selectedOrderHistoryType ===
                          ORDER_HISTORY_TABS.DELIVERY
                            ? this.columnHeaders
                            : this.selfPickupColumnHeaders
                        }
                        dataSource={[]}
                        pagination={false}
                        loading={isLoading}
                        rowKey={`orderHistory${indx}`}
                        locale={{
                          emptyText
                        }}
                      />
                      {selectedOrderHistoryType ===
                      ORDER_HISTORY_TABS.DELIVERY ? (
                        moment(data.date).format(DATE_FORMAT) !==
                          moment(new Date()).format(DATE_FORMAT) ||
                        isFiltered ? (
                          <>
                            {columnsData.length ? (
                              <p className="order-date">
                                {moment(data.date).format(DATE_FORMAT)}
                              </p>
                            ) : null}
                            <Table
                              rowClassName={(record: any, index) => {
                                return this.getRowClassNames(record)
                              }}
                              className="order-history-table"
                              columns={this.columnHeaders}
                              showHeader={false}
                              dataSource={columnsData}
                              pagination={false}
                              rowKey={`orderHistory${indx * indx}`}
                            />
                          </>
                        ) : null
                      ) : (
                        <>
                          {this.state.selfPickupData.length ? (
                            <>
                              {SelfPickupColumnData.length ? (
                                <p className="order-date">
                                  {moment(data.date).format(DATE_FORMAT)}
                                </p>
                              ) : null}
                              <Table
                                rowClassName={(record: any, index) => {
                                  return this.getRowClassNames(record)
                                }}
                                className="order-history-table"
                                columns={this.selfPickupColumnHeaders}
                                showHeader={false}
                                dataSource={SelfPickupColumnData}
                                pagination={false}
                                rowKey={`orderHistory${indx * indx}`}
                                locale={{
                                  emptyText: (
                                    <IntlMessages id="orderHistory.waitingFor1stOrder" />
                                  )
                                }}
                              />
                            </>
                          ) : (
                            <div className="empty-text">
                              <IntlMessages id="orderHistory.waitingFor1stOrder" />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {selectedOrderHistoryType === ORDER_HISTORY_TABS.DELIVERY
                    ? Object.keys(columnsData).length > 0 &&
                      indx !== 0 && (
                        <div>
                          {indx > 0 && (
                            <p className="order-date">
                              {moment(data.date).format(DATE_FORMAT)}
                            </p>
                          )}
                          <Table
                            rowClassName={(record: any, index) => {
                              return this.getRowClassNames(record)
                            }}
                            className="orderx-table"
                            columns={this.columnHeaders}
                            showHeader={!(indx > 0)}
                            dataSource={columnsData}
                            pagination={false}
                            // Loading={isLoading}
                            rowKey={`orderHistory${indx}`}
                            locale={{
                              emptyText: (
                                <IntlMessages id="orderHistory.waitingFor1stOrder" />
                              )
                            }}
                          />
                        </div>
                      )
                    : Object.keys(SelfPickupColumnData).length > 0 &&
                      indx !== 0 && (
                        <div>
                          {indx > 0 && (
                            <p className="order-date">
                              {moment(data.date).format(DATE_FORMAT)}
                            </p>
                          )}
                          <Table
                            rowClassName={(record: any, index) => {
                              return this.getRowClassNames(record)
                            }}
                            className="orderx-table"
                            columns={this.selfPickupColumnHeaders}
                            showHeader={!(indx > 0)}
                            dataSource={SelfPickupColumnData}
                            pagination={false}
                            rowKey={`orderHistory${indx}`}
                            locale={{
                              emptyText: (
                                <IntlMessages id="orderHistory.waitingFor1stOrder" />
                              )
                            }}
                          />
                        </div>
                      )}
                </div>
              )
            })
          ) : (
            <Table
              rowClassName={(record: any, index) => {
                return this.getRowClassNames(record)
              }}
              className="order-history-table"
              columns={this.columnHeaders}
              dataSource={[]}
              pagination={false}
              locale={{ emptyText }}
            />
          )}
          {paginationInfo?.totalItems && (
            <Pagination
              style={{ textAlign: 'end', margin: '10px 0 0 0' }}
              defaultCurrent={DEFAULT_PAGE_NUMBER}
              pageSize={this.state.pageSize}
              total={paginationInfo?.totalItems}
              onChange={this.handlePageChange}
              onShowSizeChange={this.handlePageSizeChange}
              pageSizeOptions={[50, 100]}
            />
          )}
        </div>
        {viewOrder ? (
          <OrderDetail
            viewOrder={viewOrder}
            viewOrderData={viewOrderData}
            closeModal={this.closeModal}
            onCancelClick={this.onCancelClick}
            isOrderHistory={true}
            intl={this.props.intl}
            key={viewOrderData?.id}
          />
        ) : null}
      </div>
    )
  }
}
// @ts-ignore
export default injectIntl(withApollo(OrderHistory))
