export const setItemUpDownArrowPosition = rowIndex => {

  const arr = []
  
  arr.push(rowIndex)

  arr.forEach((_, index) => {
    const upDownIcon = document.querySelector(`.item-row-${index}`)

    if (!upDownIcon) return
    upDownIcon['style']['display'] = 'inline-block'
  })
}

export const hideItemUpDownArrow = arr => {
  arr.forEach((_, index) => {
    const upDownIcon = document.querySelector(`.item-row-${index}`)

    if (!upDownIcon) return
    upDownIcon['style']['display'] = 'none'
  })
}
