/* eslint-disable prefer-named-capture-group*/
export const PINCODE_REGEX = /\d+/g
export const ADMIN_PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$/
export const PHONE_NUMBER_REGEX = /^[0]?(91)?[6789]\d{9}$/
export const DELIVERY_CHARGE_REGEX = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/
export const USER_NAME_REGEX = /^\S{3,}$/
export const PASSWORD_REGEX = /^\S{5,}$/
export const ALPHA_NUMERIC_WITHOUT_SPACE_REGEX = /^[a-zA-Z0-9]*$/
export const PAN_NUMBER_REGEX = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/
export const GSTIN_REGEX = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/
export const MINI_URL_REGEX = /^[aA-zZ0-9-]+$/g
export const PERCENTAGE_PRICE_CHANGE_REGEX = /^-?(?:[0-9]\d*)?(?:\.\d+)?$/
export const DEAL_PRICE_REGEX = /\B(?=(\d{3})+(?!\d))/g
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const PRICE_REGEX = /\B(?=(\d{3})+(?!\d))/g
export const PRODUCT_PRICE_REGEX = /^(?:[1-9]\d*)?(?:\.\d+)?$/
export const GEOLOCATION_REGEX = /((-?\d+.?\d+) (-?\d+.?\d+))/
export const VALUE_REGEX = /^(?:[0-9]\d*)?(?:\.\d+)?$/
