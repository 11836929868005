import './index.css'

import { ApolloProviderProps } from '@apollo/client/react/context'
import { withApollo } from '@apollo/client/react/hoc'
import { Col, message, Row, Switch, Table } from 'antd'
import { Button, IntlMessages, Text } from 'components'
import { History } from 'history'
import React from 'react'
import { injectIntl } from 'react-intl'
import { getAllChannels, getAllStores, onToggleStore } from 'services'
import { getPosOrganization } from 'services/posService'
import { getViewType, intlAlertMessage } from 'Utils'
import {
  COLORS,
  CONSOLE_STORE_STATUS,
  POS_STATUS,
  VIEW_TYPES
} from 'Utils/constants'
import {
  getChannelsData,
  getPosData,
  getPosEnableStores,
  getWcoreOrganizationId
} from 'Utils/localStorageHandlers/getter'
import { setPosEnableStoreData } from 'Utils/localStorageHandlers/setter'

import { HeaderText } from '../../styles'
import { POSProviders } from './components'
import POSMenuSharingCodeModal from './components/posMenuSharingCode'
import SupportModal from './components/supportTeam'

interface StoreListProps extends ApolloProviderProps<any> {
  history: History
  intl: any
}
interface StoreListState {
  dataSource: any
  allStoresFromApi: any
  orgId: string
  isFetching: boolean
  isPOSModalVisible: boolean
  isMenuSharingModalVisible: boolean
  posId: string
  showSupportModal: boolean
  posEnableStoreData: any
}

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '30%',
    render: storeActions => storeActions
  },
  {
    title: 'POS Status',
    dataIndex: 'posStatus',
    key: 'posStatus',
    width: '58%',
    render: storeActions => storeActions
  },
  {
    name: 'Status',
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: statusActions => statusActions
  }
]

class StoreList extends React.Component<StoreListProps, StoreListState> {
  processing: boolean

  orgId: string | { [key: string]: any }

  channels: any

  isBMSDealsOnlyView: boolean

  constructor(props: StoreListProps) {
    super(props)
    this.processing = false
    this.channels = []
    this.state = {
      dataSource: [],
      allStoresFromApi: [],
      orgId: '',
      isFetching: false,
      isPOSModalVisible: false,
      isMenuSharingModalVisible: false,
      posId: '',
      showSupportModal: false,
      posEnableStoreData: []
    }
  }

  componentDidMount() {
    const channels = getChannelsData()
    const viewType = getViewType()

    this.isBMSDealsOnlyView = viewType === VIEW_TYPES.BMS_DEALS_ONLY
    this.getPosOrgs()

    if (channels) {
      this.getAllStore()
    } else {
      this.getChannel()
    }
  }

  getPosOrgs = () => {
    this.setState({
      posEnableStoreData: getPosEnableStores(),
      posId: getPosData()?.posId || ''
    })
  }

  getPOSEnabledStores = async () => {
    try {
      const posOrganizations = await getPosOrganization()

      if (posOrganizations?.length) {
        const isPosEnabled = posOrganizations.filter(
          elem => elem.orgId === this.orgId
        )

        this.setState({
          posEnableStoreData: isPosEnabled
        })
        setPosEnableStoreData(isPosEnabled)
      }
      this.setState({
        showSupportModal: true,
        isMenuSharingModalVisible: false
      })
    } catch (error) {
      console.warn(error)
      this.setState({
        isMenuSharingModalVisible: false
      })
    }
  }

  async getChannel() {
    this.setState({ isFetching: true })
    try {
      const response = await getAllChannels(this.props.client)

      this.channels = response.data.channels.data
      localStorage.setItem('channels', JSON.stringify(this.channels))
      this.getAllStore()
    } catch (err) {
      this.setState({ isFetching: false })
      console.error('Get channels error', err)
    }
  }

  formStatusAction = (store, storeIndex, isStorePosEnabled) => {
    return (
      <>
        <Switch
          title={
            store.enable
              ? CONSOLE_STORE_STATUS.INACTIVE
              : CONSOLE_STORE_STATUS.ACTIVE
          }
          disabled={
            isStorePosEnabled && isStorePosEnabled.status === POS_STATUS.ACTIVE
          }
          style={{
            minWidth: '70px',
            borderColor: COLORS.BORDER_DARK,
            color: COLORS.BORDER_DARK
          }}
          className={
            isStorePosEnabled && isStorePosEnabled.status === POS_STATUS.ACTIVE
              ? 'isStorePosEnabled'
              : null
          }
          checkedChildren={CONSOLE_STORE_STATUS.ACTIVE}
          unCheckedChildren={CONSOLE_STORE_STATUS.INACTIVE}
          checked={store.enable}
          onChange={this.processStoreEnableDisable.bind(
            this,
            store,
            storeIndex
          )}
        />
        {isStorePosEnabled && isStorePosEnabled.status === POS_STATUS.ACTIVE ? (
          <Text
            level="body-1"
            style={{ color: COLORS.GRAY_TEXT, marginLeft: '14px' }}>
            {'POS'}
          </Text>
        ) : null}
      </>
    )
  }

  formStoreAction = store => {
    return (
      <div
        className="header"
        onClick={(event: any) => {
          this.props.history.push(`/orderx/store/${store.id}/edit`)
        }}>
        {store.name}
      </div>
    )
  }

  processStoreEnableDisable = async (store, storeIndex) => {
    if (this.processing) return
    this.processing = true
    const storeEnableDisableDetailsInput = {
      storeId: store.id,
      organizationId: this.orgId
    }
    const { dataSource } = this.state
    const prevStoreDataSource = dataSource[storeIndex]
    const updatedStore = { ...store, enable: !store.enable }
    const isStorePosEnabled = this.state.posEnableStoreData?.find(
      elem => elem.storeId === store.id
    )
    const statusActions = this.formStatusAction(
      updatedStore,
      storeIndex,
      isStorePosEnabled
    )
    const storeActions = this.formStoreAction(updatedStore)
    const updatedStoreDataSource = {
      key: updatedStore.id,
      name: storeActions,
      address:
        updatedStore.addressLine1 === null
          ? ''
          : `${updatedStore.addressLine1},${updatedStore.addressLine2}`,
      email: updatedStore.email,
      customerCare: '',
      status: statusActions
    }

    dataSource.splice(storeIndex, 1, updatedStoreDataSource)
    this.setState({ dataSource: [...dataSource] })
    message.loading(
      intlAlertMessage({ id: 'menu.saving', intl: this.props.intl }),
      2
    )

    try {
      await onToggleStore(
        this.props.client,
        storeEnableDisableDetailsInput,
        store.enable
      )
      this.processing = false
    } catch (err) {
      this.processing = false
      dataSource.splice(storeIndex, 1, prevStoreDataSource)
      this.setState({ dataSource: [...dataSource] })
      console.warn('Store status change error:', err)
    }
  }

  getPosStatus = status => {
    if (status === POS_STATUS.ACTIVE) {
      return (
        <Text level="body-2" style={{ color: COLORS.SUCCESS }}>
          {'Active'}
        </Text>
      )
    } else if (status === POS_STATUS.IN_PROGRESS) {
      return (
        <Text level="body-2" style={{ color: COLORS.SECONDARY }}>
          {'Pending'}
        </Text>
      )
    } else if (status === POS_STATUS.INACTIVE) {
      return (
        <Text level="body-2" style={{ color: COLORS.ERROR }}>
          {'Inactive'}
        </Text>
      )
    }
 
    return status
  }

  async getAllStore() {
    const org_id = getWcoreOrganizationId()

    this.orgId = org_id
    this.setState({ isFetching: true })
    try {
      const allStoresResponse = await getAllStores(this.props.client, org_id)
      const dataSource = []
      // eslint-disable-next-line
      allStoresResponse.data.stores.data.map((store, storeIndex) => {
        const isStorePosEnabled = this.state.posEnableStoreData?.find(
          elem => elem.storeId === store.id
        )
        const statusActions =
          !this.isBMSDealsOnlyView &&
          this.formStatusAction(store, storeIndex, isStorePosEnabled)
        const storeActions = this.formStoreAction(store)

        dataSource.push({
          key: store.id,
          name: storeActions,
          storeName: store.name,
          address:
            store.addressLine1 === null
              ? ''
              : `${store.addressLine1},${store.addressLine2}`,
          email: store.email,
          customerCare: '',
          status: statusActions,
          posStatus: isStorePosEnabled
            ? this.getPosStatus(isStorePosEnabled.status)
            : <Text level="body-2" style={{ textAlign: 'center' }}>{'-'}</Text>
        })
      })

      this.setState({
        dataSource,
        allStoresFromApi: allStoresResponse.data.stores,
        orgId: org_id,
        isFetching: false
      })
    } catch (err) {
      console.warn('Store List Error:', err)
      this.setState({ isFetching: false })
    }
  }

  render() {
    const {
      dataSource,
      isPOSModalVisible,
      isMenuSharingModalVisible,
      showSupportModal
    } = this.state

    return (
      <div className="gx-main-content-wrapper">
        <Row>
          <Col span={16}>
            <div
              className="header"
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={() => {
                this.getAllStore()
              }}>
              <HeaderText type="H1">
                <IntlMessages id="stores" />
              </HeaderText>
            </div>
          </Col>
          {!this.isBMSDealsOnlyView && (
            <Col span={8} className="gx-text-right">
              <Button
                style={{
                  border: `1px solid ${
                    dataSource.length ? COLORS.PRIMARY : COLORS.BORDER_DARK
                  }`,
                  width: '33%',
                  backgroundColor: dataSource.length && COLORS.BG_WHITE
                }}
                disabled={!dataSource.length}
                type="link"
                onClick={() => {
                  this.setState({ isPOSModalVisible: true })
                }}>
                <IntlMessages id="store.integratePOS" />
              </Button>
              <Button
                style={{ width: '30%' }}
                type="primary"
                onClick={() => {
                  this.props.history.push('/orderx/store', {})
                }}>
                <IntlMessages id="addStore" />
              </Button>
            </Col>
          )}
        </Row>
        <Table
          className="orderx-table"
          style={{ marginTop: '20px' }}
          dataSource={dataSource}
          columns={!this.isBMSDealsOnlyView ? columns : [columns[0]]}
          pagination={false}
          loading={this.state.isFetching}
        />
        {isPOSModalVisible ? (
          <POSProviders
            isPOSModalVisible={isPOSModalVisible}
            onClose={() =>
              this.setState({ isPOSModalVisible: false, posId: '' })
            }
            petpoojaPosId={this.state.posId}
            onSubmit={() =>
              this.setState({
                isPOSModalVisible: false,
                isMenuSharingModalVisible: true
              })
            }
            onPosFormSubmit={() => {
              this.setState({
                isPOSModalVisible: false
              })
            }}
            intl={this.props.intl}
          />
        ) : null}
        {isMenuSharingModalVisible ? (
          <POSMenuSharingCodeModal
            onClose={() => this.setState({ isMenuSharingModalVisible: false })}
            getPosStatus={(status) => this.getPosStatus(status)}
            onBackBtnClicked={() => {
              this.setState({
                isMenuSharingModalVisible: false,
                isPOSModalVisible: true
              })
            }}
            onSubmit={() => {
              this.getPOSEnabledStores()
              this.getPosOrgs()
            }}
            intl={this.props.intl}
            dataSource={dataSource}
          />
        ) : null}
        {showSupportModal ? (
          <SupportModal
            posID={this.state.posId}
            onClose={() => this.setState({ showSupportModal: false })}
          />
        ) : null}
      </div>
    )
  }
}
// @ts-ignore
export default injectIntl(withApollo(StoreList))
