import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { message } from 'antd'
import { persistCache } from 'apollo-cache-persist'
import { API_ERROR, ERROR_EXCEPTIONS } from 'Utils/constants'
import { getAuthToken } from 'Utils/localStorageHandlers/getter'

import defaults from '../defaults'
import resolvers from '../resolvers'
import typeDefs from '../typeDefs'

message.config({
  maxCount: 2
})

const orderXhttpLink = createHttpLink({
  uri: process.env.REACT_APP_WCORE_URL
})

const pmshttpLink = createHttpLink({
  uri: process.env.REACT_APP_PMS_URL
})

const authLink = setContext((_, { headers }) => {
  // Get the authentication token from local storage if it exists
  const token = getAuthToken()

  // Return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // Console.log(graphQLErrors);
    if (
      graphQLErrors[0].extensions &&
      (graphQLErrors[0].extensions.code === 'UNTH' ||
        graphQLErrors[0].extensions.code === 'UPDNM')
    ) {
      // Message.warn(API_ERROR["UNTH"]);
      localStorage.clear()
      sessionStorage.clear()
    }

    if (
      graphQLErrors[0].extensions &&
      graphQLErrors[0].extensions.code === 'UNTH'
    ) {
      // eslint-disable-next-line no-restricted-globals
      location.reload()
      message.warn('Session expired. Please login again')
    }

    if (
      graphQLErrors[0].message &&
      !ERROR_EXCEPTIONS.includes(graphQLErrors[0].message)
    ) {
      if (
        graphQLErrors[0].extensions &&
        graphQLErrors[0].extensions.code &&
        API_ERROR[graphQLErrors[0].extensions.code as string]
      ) {
        message.warn(API_ERROR[graphQLErrors[0].extensions.code as string])
      } else {
        message.warn(graphQLErrors[0].message)
      }
    }
  } else if (networkError) {
    message.error(
      'Hey! Sorry, we are experiencing some issues. Please check your internet connection or try again after sometime'
    )
    console.log(networkError)
  }
})

export const configureClient = (cb = null) => {
  const cache = new InMemoryCache()
  const client = new ApolloClient({
    cache,
    link: from([onErrorLink, authLink, orderXhttpLink]),
    typeDefs,
    resolvers
  })

  try {
    // See above for additional options, including other storage providers.
    persistCache({
      cache,
      storage: window.sessionStorage as any
    })
  } catch (error) {
    console.error('Error restoring Apollo cache', error)
  }
  if (cb !== null) cb(client)

  return client
}

export const configurePmsClient = (cb = null) => {
  const cache = new InMemoryCache()
  const client = new ApolloClient({
    cache,
    link: from([onErrorLink, authLink, pmshttpLink]),
    typeDefs,
    resolvers
  })

  try {
    // See above for additional options, including other storage providers.
    persistCache({
      cache,
      storage: window.sessionStorage as any
    })
  } catch (error) {
    console.error('Error restoring Apollo cache', error)
  }
  if (cb !== null) cb(client)

  return client
}

/**
 * The ClientManager class defines the `getInstance` method that lets clients access
 * the unique ClientManager instance.
 */
export class ClientManager {
  private static instance: any

  pms: ApolloClient<any>

  /**
   * The ClientManager's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  private constructor() {
    configurePmsClient(client => {
      this.pms = client
    })
  }

  /**
   * The static method that controls the access to the ClientManager instance.
   *
   * This implementation let you subclass the ClientManager class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): ClientManager {
    if (!ClientManager.instance) {
      ClientManager.instance = new ClientManager()
    }

    return ClientManager.instance
  }
}
