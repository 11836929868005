import { Tabs as AntdTabs, TabsProps } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { COLORS } from 'Utils/constants'


export const Tabs: React.FunctionComponent<TabsProps> = styled(AntdTabs)`
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: ${COLORS.PRIMARY};
  font-weight: 500;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: ${COLORS.PRIMARY};
}
.ant-tabs-nav::before {
  display: block;
}
.ant-tabs-ink-bar {
  position: absolute;
  background: ${COLORS.PRIMARY};
  pointer-events: none;
  height: 3px !important;
}
` as any
