import { useEffect, useState } from 'react'
import { getOrgDetails, getUserDetails, getViewType, isAdmin } from 'Utils'
import { EMAIL_REGEX, ORG_STATUS, VIEW_TYPES } from 'Utils/constants'
import { ROUTES } from 'Utils/constants/routes'
import { getAuthToken } from 'Utils/localStorageHandlers/getter'

import * as serviceWorker from '../../../serviceWorker'


const useSignIn = (props) => {
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(true)
  const { history } = props

  useEffect(() => {
    goToNextPage()
  }, [])

  const goToNextPage = () => {
    if (!getAuthToken()) return
    let redirectRoute = ROUTES.ORDER_PAGE

    const usrDetails = getUserDetails()
    const orgDetails = getOrgDetails()
    const viewType = getViewType()

    const isBMSDealsOnlyView = viewType === VIEW_TYPES.BMS_DEALS_ONLY
    const onboardingStatus = orgDetails && orgDetails.onboardingStatus

    if (isBMSDealsOnlyView) {
      redirectRoute = ROUTES.ORDER_REPORT
      history.push(redirectRoute)

      return
    }

    if (isAdmin()) {
      if (!orgDetails) {
        redirectRoute = '/'
        localStorage.clear()
        serviceWorker.unregister()
        history.push(redirectRoute)

        return
      }
      if (usrDetails.resetPassword) {
        redirectRoute = '/changePassword'
        history.replace(redirectRoute)

        return
      }
      if (onboardingStatus && onboardingStatus === ORG_STATUS.INITIATED) {
        redirectRoute = ROUTES.ONBOARDING_PAGE
      }
    }
    history.push(redirectRoute)
  }
  const handleSubmit = async (values, signIn) => {
    const { email, password } = values.target
    const isValidEmail = EMAIL_REGEX.test(email.value)

    const signinInput = {
      password: password.value
    }
    // eslint-disable-next-line
    isValidEmail ? signinInput['email'] = email.value : signinInput['userName'] = email.value

    const data = await signIn({
      variables: {
        input: signinInput
      }
    })

    if (data?.data.signIn) {
      goToNextPage()
    } else {
      console.log('Login Failed')
    }
  }

  return { isRememberMeChecked, handleSubmit, setIsRememberMeChecked, goToNextPage }
}

export default useSignIn