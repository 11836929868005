/* eslint-disable max-classes-per-file */
import './orderreceipt.css'

import { Modal } from 'antd'
import { IMAGES } from 'assets'
import { Button, IntlMessages } from 'components'
import React, { Component } from 'react'
import ReactToPrint from 'react-to-print'
import { getUserDetails, intlAlertMessage } from 'Utils'
import { ORDER_TYPE, PEPPO_GSTIN } from 'Utils/constants'
import { getFormattedPhoneNumber } from 'Utils/OrderUtils'


interface IOrderReceiptProps {
  items: any[]
  orderDetails: any
  deliveryDiscountCharge: any
}

interface IOrderReceiptState {}

class OrderReceipt extends Component<IOrderReceiptProps, IOrderReceiptState> {
  render() {
    const { items, orderDetails, deliveryDiscountCharge } = this.props
    const totalAmount = orderDetails.totalAmount - deliveryDiscountCharge

    return (
      <div className="ticket">
        <img src={IMAGES.peppo_logo_transparent} alt="Peppo logo" />
        <p className="centered">
          {getUserDetails().organization.name}
          <br />
          {orderDetails.createdTime.split('T')[0]}
          <br />
          <strong>
            <IntlMessages id="newOrder.orderID" />: {orderDetails.orderId}
          </strong>
        </p>
        <table>
          <thead>
            <tr>
              <th className="quantity">Q.</th>
              <th className="description">Description</th>
              <th className="price">Rs</th>
            </tr>
          </thead>
          <tbody>
            {items.map(item => {
              return (
                <tr key={item.itemName.name + item.price}>
                  <td className="quantity">{parseFloat(item.quantity.substring(1)).toFixed(2)}</td>
                  <td className="description">
                    <div>{item.itemName.name}</div>
                    {item.itemName.addons.length > 0 && (
                      <div>
                        <strong>Addons: </strong>
                        {item.itemName.addons.join('+')}
                      </div>
                    )}
                  </td>
                  <td className="price">{item.price}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <table className="item-price-wrapper">
          <tbody>
            <tr>
              <td className="label">
                <IntlMessages id="newOrder.ItemsPrice" />
              </td>
              <td>
                <IntlMessages id="newOrder.indianRupeeSign" />
                {parseFloat(orderDetails.allLineItemsTotalPrices).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className="label">
                <IntlMessages id="newOrder.packagingCharges" />
              </td>
              <td>
                <IntlMessages id="newOrder.indianRupeeSign" />
                {parseFloat(orderDetails.allLineItemsTotalCharges + orderDetails.orderLevelCharges).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className="label">
                <IntlMessages id="newOrder.taxes" />
              </td>
              <td>
                <IntlMessages id="newOrder.indianRupeeSign" />
                {parseFloat(orderDetails.allLineItemsTotalTaxes + orderDetails.orderLevelTaxes).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td>
                <strong>
                  <IntlMessages id="subtotal" />
                </strong>
              </td>
              <td>
                <strong>
                  <IntlMessages id="newOrder.indianRupeeSign" />
                  {parseFloat(orderDetails.allLineItemsTotalTaxes + orderDetails.orderLevelTaxes + orderDetails.allLineItemsTotalPrices + orderDetails.allLineItemsTotalCharges + orderDetails.orderLevelCharges).toFixed(2)}
                </strong>
              </td>
            </tr>
            {orderDetails.orderType === ORDER_TYPE.MOBILE_DELIVERY &&
            <>
              <tr>
                <td>
                  <IntlMessages id="newOrder.deliveryCharge" />
                </td>
                <td>
                  <IntlMessages id="newOrder.indianRupeeSign" />
                  {parseFloat(orderDetails.orderDeliveryCharges).toFixed(2)}
                </td>
              </tr>
              <tr>
                <td>
                  <IntlMessages id="order.discountForDeliveryCharge" />
                </td>
                <td>
                  -<IntlMessages id="newOrder.indianRupeeSign" />
                  {parseFloat(deliveryDiscountCharge.toString()).toFixed(2)}
                </td>
              </tr>
            </>
            }
            <tr>
              <td>
                <strong>
                  <IntlMessages id="newOrder.totalAmount" />
                </strong>
              </td>
              <td>
                <strong>
                  <IntlMessages id="newOrder.indianRupeeSign" />
                  {parseFloat(Math.ceil(totalAmount).toString()).toFixed(2)}
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
        {orderDetails.orderType === ORDER_TYPE.MOBILE_DELIVERY ?
          <p className="left">
            <strong>
              <IntlMessages id="newOrder.deliveryAddress" />{' '}
            </strong>
            <br />
            {orderDetails.shippingAddress.name}
            <br />
            {`${orderDetails.shippingAddress.addressLine1}, ${orderDetails.shippingAddress.addressLine2}, ${orderDetails.shippingAddress.city}`}
            <br />
            {`${orderDetails.shippingAddress.state}-${orderDetails.shippingAddress.zip},  ${orderDetails.shippingAddress.country}`}
            <br /><br/>
            <IntlMessages id="register.customerName" />: <strong>{orderDetails?.customer?.person?.firstName}</strong>
            <br />
            <IntlMessages id="register.mobileNumber" />: <strong>{getFormattedPhoneNumber(orderDetails?.customer?.person?.phoneNumber)}</strong>
            <br />
            <br />
            <IntlMessages id="register.peppoGSTIN" />: <strong>{PEPPO_GSTIN}</strong>
          </p>
        : (
          <p className="left">
            <IntlMessages id="register.customerName" />: <strong>{orderDetails?.customer?.person?.firstName}</strong>
            <br />
            <IntlMessages id="register.mobileNumber" />: <strong>{getFormattedPhoneNumber(orderDetails?.customer?.person?.phoneNumber)}</strong>
            <br />
            <br />
            <IntlMessages id="register.peppoGSTIN" />: <strong>{PEPPO_GSTIN}</strong>
          </p>
        )}
      </div>
    )
  }
}

interface IOrderReceiptToPrintProps {
  viewOrderReceipt: boolean
  onClose?: any
  items: any[]
  orderDetails: any
  intl: any
  deliveryDiscountCharge: any
}

interface IOrderReceiptToPrintState {}

class OrderReceiptToPrint extends Component<IOrderReceiptToPrintProps, IOrderReceiptToPrintState> {
  componentRef: OrderReceipt

  render() {
    const { viewOrderReceipt, onClose, items, orderDetails, intl, deliveryDiscountCharge } = this.props

    return (
      <Modal width={280} visible={viewOrderReceipt} title={null} onCancel={onClose} footer={null} className="view-order-modal">
        <OrderReceipt ref={el => (this.componentRef = el)} items={items} orderDetails={orderDetails} deliveryDiscountCharge={deliveryDiscountCharge} />
        <ReactToPrint
          trigger={() => (
            <Button type="primary" size="small" ghost={true}>
              Print
            </Button>
          )}
          content={() => this.componentRef}
          onAfterPrint={onClose}
          documentTitle={`${intlAlertMessage({
            id: 'peppo',
            intl
          })}-${orderDetails.createdTime.split('T')[0]}-${orderDetails.orderId}`}
        />
      </Modal>
    )
  }
}

export default OrderReceiptToPrint
