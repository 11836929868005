import { Button, IntlMessages, Modal } from 'components'
import React, { ReactElement } from 'react'
import styled from 'styled-components'

const SingleButtonContainerStyle = styled.div`
  display: flex;
  justify-content: end;
`

export interface DeleteModalProps {
  title: ReactElement
  visible: boolean
  onCancel: any
  onConfirm: any
}

export const DeleteModal: React.FC<DeleteModalProps> = ({ ...props }) => {
  const { visible, onCancel, onConfirm, title } = props

  return (
    <Modal
      visible={visible}
      title={title}
      onClose={() => {
        onCancel()
      }}
      width={400}>
      <SingleButtonContainerStyle>
        <Button
          key="rejectDelete"
          onClick={() => {
            onCancel()
          }}
          style={{ width: '130px', marginBottom: 0 }}>
          <IntlMessages id="newOrder.Cancel" />
        </Button>
        ,
        <Button
          type={'danger'}
          key="confirmDelete"
          onClick={() => {
            onConfirm()
          }}
          style={{ width: '130px', marginBottom: 0 }}>
          {<IntlMessages id="button.delete" />}
        </Button>
      </SingleButtonContainerStyle>
    </Modal>
  )
}
