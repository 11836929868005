import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { Text } from 'components'
import { intlAlertMessage } from 'Utils'
import { COLORS } from 'Utils/constants'
import { MiniUrlValidationRules } from 'Utils/onboardingUtils'

import * as Styles from './CreateMiniUrlPage/styles'

export const TooltipComponent = ({ intl }) => {
  return (
    <Styles.TooltipContainer>
      {MiniUrlValidationRules.map(item => (
        <Styles.TooltipCon key={item.id}>
          {item.isValid ? (
            <CheckCircleFilled
              style={{ color: COLORS.SUCCESS, margin: '5px' }}
            />
          ) : (
            <CloseCircleFilled style={{ color: COLORS.ERROR, margin: '5px' }} />
          )}
          <div>
            <Text level={'caption'}>
              {intlAlertMessage({ id: item.label, intl })}
            </Text>
          </div>
        </Styles.TooltipCon>
      ))}
    </Styles.TooltipContainer>
  )
}
