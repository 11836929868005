import { InputNumber, message } from 'antd'
import { Card, IntlMessages, Text, Title } from 'components'
import { orderxClient } from 'index'
import React, { Component } from 'react'
import { IntlShape } from 'react-intl'
import * as storeService from 'services/storeService'
import { intlAlertMessage } from 'Utils'
import { COLORS, RUPEE } from 'Utils/constants'
import { setIsUnsavedStoreDetails } from 'Utils/localStorageHandlers/setter'
import { StoreChargeTypeEnum } from 'Utils/storeUtils'

import { DeliveryFleetRadioButtonStyle } from '../styles'


interface IDeliveryAreaProps {
  intl: IntlShape
  getStoreData: any
  setData: any
  storeId: string
}



class DeliveryChargeAndArea extends Component<IDeliveryAreaProps, any> {
  polygon: google.maps.Polygon

  map: google.maps.Map<HTMLElement>

  marker: google.maps.Marker

  addMarkerListener: google.maps.MapsEventListener

  drawingManager: google.maps.drawing.DrawingManager

  polygonCompleteListener: google.maps.MapsEventListener

  searchInput: any

  constructor(props) {
    super(props)
    this.searchInput = React.createRef<HTMLInputElement>()
    this.state = {
      deliveryChargeValue: 0,
      mapData: {
        polygonArray: [],
        polygonGeoJSON: {},
        places: [
          {
            id: '',
            placeName: '',
            storeId: '',
            selected: true,
            mainPlace: true,
            radius: [0],
            address: '686691',
            center: { lat: null, lng: null }
          }
        ],
        center: { lat: null, lng: null }
      }
    }
  }

  componentDidMount() {
    const { getStoreData, storeId } = this.props

    if (storeId && !getStoreData()?.deliveryCharge && !getStoreData()?.deliveryArea) {
      this.getDeliveryChargeAndArea()
    }
    else this.stateInitializer()
  }

  getDeliveryChargeAndArea = async () => {
    const { storeId, setData } = this.props

    const [getStoreChargeResponse, getDeliveryAreaResponse] = await Promise.all([storeService.getStoreCharge(orderxClient, storeId),
      storeService.getDeliveryArea(orderxClient, storeId),
    ])
    const deliveryChargeDetails = getStoreChargeResponse.data
      .getStoreCharges.data.find(charge => charge.chargeType === StoreChargeTypeEnum.DELIVERY_CHARGE)

    setData({
      deliveryCharge: deliveryChargeDetails,
      deliveryArea: getDeliveryAreaResponse?.data?.getStoreDeliverArea
    })
    this.stateInitializer()

  }

  stateInitializer = () => {
    const { getStoreData } = this.props
    const { mapData } = this.state
    const { deliveryArea = {}, store, deliveryCharge } = getStoreData()
    const deliveryChargeValue = deliveryCharge?.chargeValue
    const geographicalArea = (deliveryArea?.area && JSON.parse(deliveryArea?.area))
    let polygonArray = []

    if (geographicalArea) {
      polygonArray = geographicalArea?.features?.[0]?.geometry.coordinates[0].map(([lat, lng]) => ({ lat, lng }))
    }
    this.setState({
      mapData: {
        ...mapData,
        polygonGeoJSON: { ...deliveryArea, area: geographicalArea || {} },
        polygonArray,
        center: store.geoLocation
      },
      deliveryChargeValue
    }, this.initMap)
  }

  handleDeliveryChargeChange = (value) => {
    this.setState({ deliveryChargeValue: value })
    this.props.setData({ deliveryChargeValue: value })
    setIsUnsavedStoreDetails(true)
  }

  handleMapDataChange = (updatedFields) => {
    const { mapData } = this.state
    const updatedMapData = {
      ...mapData,
      ...updatedFields
    }

    this.setState({ mapData: updatedMapData })
    this.props.setData({
      mapData: updatedMapData
    })
    setIsUnsavedStoreDetails(true)
  }

  addMarker = location => {
    this.marker && this.marker.setMap(null)
    this.marker = new google.maps.Marker({
      position: location,
      map: this.map
    })
  }

  renderCustomMapButton = (mapData) => {
    const controlDiv = document.createElement('div')

    controlDiv.setAttribute('id', 'draw-reset')
    const controlUI = document.createElement('div')

    controlUI.style.backgroundColor = COLORS.PRIMARY
    controlUI.style.borderRadius = '4px'
    controlUI.style.margin = '5px 10px'
    controlUI.style.cursor = 'pointer'
    controlUI.style.textAlign = 'center'
    controlDiv.appendChild(controlUI)

    const controlText = document.createElement('div')

    controlText.style.color = COLORS.BG_WHITE
    controlText.style.fontFamily = 'Roboto,Arial,sans-serif'
    controlText.style.fontSize = '13px'
    controlText.style.padding = '6px 10px'
    controlText.innerHTML = 'Draw'
    if (mapData.polygonArray.length) {
      controlText.innerHTML = 'Reset'
    }
    controlUI.appendChild(controlText)
    controlDiv.addEventListener('click', () => {
      const buttonName = controlText.innerHTML

      if (buttonName === 'Draw') {
        if (!mapData.center.lat || !mapData.center.lng || !this.marker) {
          message.error(
            intlAlertMessage({
              id: 'store.errMsg.selectMarkerOnMap',
              intl: this.props.intl
            })
          )
        } else {
          controlText.innerHTML = 'Reset'
          this.initDrawingManager(mapData)
        }
      } else {
        this.polygonCompleteListener && this.stopPolygonCompleteListener()
        controlText.innerHTML = 'Draw'
        mapData.polygonArray = []
        this.handleMapDataChange({ polygonArray: [] })
        this.drawingManager && this.drawingManager.setMap(null)
        this.drawingManager && this.drawingManager.setDrawingMode(null)
        this.polygon && this.polygon.setMap(null)
      }
    })

    return controlDiv
  }

  drawPolygon = polygonArray => {
    this.polygon = new google.maps.Polygon({
      paths: polygonArray,
      strokeColor: COLORS.PRIMARY,
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: COLORS.PRIMARY_SHADE,
      fillOpacity: 0.75
    })
    this.polygon.setMap(this.map)
  }

  startAddMarkerListener = () => {
    this.addMarkerListener = this.map.addListener('click', event => {
      this.marker && this.marker.setMap(null)
      this.addMarker(event.latLng)
    })
  }

  stopAddMarkerListener = () => {
    this.addMarkerListener.remove()
  }

  initDrawingManager = (mapData) => {
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      polygonOptions: {
        fillColor: COLORS.PRIMARY_SHADE,
        strokeColor: COLORS.PRIMARY,
        fillOpacity: 0.85,
        strokeWeight: 1,
        editable: true,
        clickable: false,
        geodesic: true,
        zIndex: 1
      }
    })

    this.startPolygonCompleteListener(mapData)

    this.drawingManager.setMap(this.map)
  }

  startPolygonCompleteListener = (mapData) => {
    this.polygonCompleteListener = google.maps.event.addListener(this.drawingManager, 'polygoncomplete', polygon => {
      if (this.containsLocationCheck(mapData, polygon)) {
        const polygonArray = []
        let polygonGeoJSON = {}
        const GeoJSON = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [[]]
              },
              properties: {}
            }
          ]
        }
        const vertices = polygon.getPath().getArray()

        vertices.forEach(vertices => {
          polygonArray.push({ lat: vertices.lat(), lng: vertices.lng() })
          GeoJSON.features[0].geometry.coordinates[0].push([vertices.lat(), vertices.lng()])
        })

        polygonGeoJSON = {
          ...mapData.polygonGeoJSON,
          area: GeoJSON,
          areaType: 'GEO_AREA'
        }

        this.handleMapDataChange({
          polygonArray,
          polygonGeoJSON
        })
        polygon.setMap(null)
        this.drawPolygon(polygonArray)
        this.drawingManager.setDrawingMode(null)
      } else {
        message.error(
          intlAlertMessage({
            id: 'store.errMsg.OutOfDeliveryArea',
            intl: this.props.intl
          })
        )
        polygon.setMap(null)
      }
    })
  }

  stopPolygonCompleteListener = () => {
    this.polygonCompleteListener.remove()
  }

  containsLocationCheck = (mapData, polygon) => {
    return google.maps.geometry.poly.containsLocation(new google.maps.LatLng(Number(mapData.center.lat), Number(mapData.center.lng)), polygon)
  }

  initMap = () => {
    const { mapData } = this.state

    this.map = new google.maps.Map(document.getElementById('dMap'), {
      center: mapData.center,
      zoom: 14,
      disableDefaultUI: true,
      zoomControl: true,
      fullscreenControl: true
    })

    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(this.renderCustomMapButton(mapData))

    if (mapData.polygonArray.length) {
      this.drawPolygon(mapData.polygonArray)
    }
    if (mapData.center.lat && mapData.center.lng) {
      this.addMarker(new google.maps.LatLng(Number(mapData.center.lat), Number(mapData.center.lng)))
    }
  }

  render() {
    const { deliveryChargeValue } = this.state

    return (
      <Card style={{ margin: '12px 0px' }}>
        <Title level='h4' style={{ margin: '5px 0 0 0' }} >
          <IntlMessages id="store.errMsg.defineDeliveryChargesAndArea" />
        </Title>
        <DeliveryFleetRadioButtonStyle span={12} className="agentsInputWrapper">
          <div className="InputLabel">
            <Title level='h5'>
              <IntlMessages id="store.infoMsg.DeliveryCharge" />
            </Title>
            <Title level='h5' style={{ marginTop: '-10px', fontSize: '15px' }}>
              <IntlMessages id="store.infoMsg.fixChargePerOrder" />
              <span>*</span>
            </Title>
          </div>
          <InputNumber
            value={deliveryChargeValue}
            addonBefore={RUPEE}
            controls={false}
            size="large"
            placeholder={intlAlertMessage({
              id: 'customer.enterYourDeliveryCharge',
              intl: this.props.intl
            })}
            style={{ width: '10%', marginLeft: '5%' }}
            onChange={this.handleDeliveryChargeChange}
          />
        </DeliveryFleetRadioButtonStyle>
        <div id="dMap" className="mapWrapper" />
      </Card>
    )
  }
}

export default DeliveryChargeAndArea
