import Icon, { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Slider,
  Table,
  TimePicker,
  Tooltip,
  Typography
} from 'antd'
import { ICONS, IMAGES } from 'assets'
import { Button as PeppoButton, Card, IntlMessages, Switch, Text as PeppoText, Title } from 'components'
import moment from 'moment'
import React from 'react'
import { HeaderText } from 'styles'
import { getViewType, intlAlertMessage, setErrMsg } from 'Utils'
import { COLORS, HH_MM_FORMAT, PHONE_NUMBER_REGEX, STORE_HELP_URL, VIEW_TYPES } from 'Utils/constants'
import { columnHeaders, getTenDigitNumber, padWithZeroes } from 'Utils/storeUtils'

import { CardBlock, DeliveryFleetRadioButtonStyle, DeliveryPartnersImgStyles, RadiusBarDotStyle } from '../styles'
import DeliveryDiscount from './deliveryDiscount'
const { Text } = Typography

function StoreForm(props) {
  const {
    storeFormDetails,
    isEdit,
    days,
    showAddSlotDialog,
    toggleAddSlot,
    daysLength,
    onAddSlotCreation,
    slotOnChangeHandler,
    onUpdateSlotClickHandler,
    isNewTimeSlot,
    selectedDay,
    isOnboarding,
    goBackToOnboarding,
    onFleetChange,
    fleetOption,
    toggleLocationPopup,
    searchedLocationText,
    streetAddressText,
    isApplyAllDays,
    onCheckUsernameAvailability,
    intl,
    stores,
    editPassword,
    toggleEditPassword,
    addServiceabilityArea,
    serviceabilityRadius,
    createOrUpdateStore,
  } = props

  let visibleCount = 0
  const viewType = getViewType()
  const isBMSDealsOnlyView = viewType === VIEW_TYPES.BMS_DEALS_ONLY

  days.forEach(day => {
    visibleCount =
      day.data.length > 0 ? visibleCount + day.data.length : visibleCount
  })

  function onChange(type, value, isSavedChanges?) {
    storeFormDetails[type] = value
    props.onChange('storeFormDetails', storeFormDetails, isSavedChanges)
  }
  const handleChange = value => {
    addServiceabilityArea(value)
  }

  function validatePhone(value) {
    if (!PHONE_NUMBER_REGEX.test(value)) {
      setErrMsg('store.validMobileNum', intl)

      return false
    }
  }

  async function checkUsername() {
    if (!storeFormDetails.userName) return
    const isAvailable = await onCheckUsernameAvailability()

    if (!isAvailable) {
      message.error(
        intlAlertMessage({
          id: 'store.errMsg.duplicateUserName',
          intl
        })
      )
    }
  }

  function isPasswordEnable() {
    let enablePassword = false

    if (!isEdit) {
      enablePassword = true
    } else if (editPassword) {
      enablePassword = true
    }

    return enablePassword
  }

  function checkStoreName() {
    const isStoreNameExist =
      storeFormDetails.storeName &&
      stores?.indexOf(storeFormDetails.storeName.toLocaleLowerCase()) !== -1

    if (isStoreNameExist) {
      message.error(
        intlAlertMessage({
          id: 'store.storeNameIsUnique',
          intl
        })
      )
    }
  }

  const getDeliveryDiscount = (val, status) => {
    createOrUpdateStore(val, status)
  }
  const columnsData = props.buildDeliveryAgentColumnData()

  return (
    <Col className={'agentSectionContainer'} span={24}>

      <Card style={{ margin: '24px 0' }}>
        <Title level='h4' style={{ marginBottom: '24px' }}>
          <IntlMessages id="profile.basicInfo" />
        </Title>
        <CardBlock className="agentsInputWrapper">
          <Title level='h5'>
            <IntlMessages id="store.enterYourStore’sName" />
          </Title>
          <Input
            placeholder={intlAlertMessage({
              id: 'appModule.name',
              intl: props.intl
            })}
            value={storeFormDetails.storeName}
            style={{ border: `1px solid ${COLORS.BORDER}`, width: '60%' }}
            onChange={e => {
              onChange('storeName', e.target.value)
            }}
            onBlur={checkStoreName}
          />
          <div style={{ width: '4%' }} />
        </CardBlock>
        <CardBlock>
          <Title level='h5'>
            <IntlMessages id="store.enterYourStore’sLocation" />
          </Title>
          {searchedLocationText && streetAddressText ? (
            <Title level='h5' style={{ width: '60%', marginTop: 0 }}>
              {streetAddressText}
              {','} {searchedLocationText}
            </Title>
          ) : (
            <Button
              className="store-location-btn"
              onClick={() => toggleLocationPopup()}
              style={{ border: `1px solid ${COLORS.BORDER}`, width: '60%' }}>
              {' '}
              <IntlMessages id="store.clickToSetYourStoreLocation" />
            </Button>
          )}
          {(searchedLocationText && streetAddressText) ? (
            <PeppoButton
              type='link'
              onClick={() => toggleLocationPopup()}
              style={{ fontWeight: '600' }}
            >
              <IntlMessages id="store.change" />
            </PeppoButton>
          ) : <div style={{ width: '4%' }} />}
        </CardBlock>
      </Card>

      {!isBMSDealsOnlyView && <Card style={{ margin: '24px 0' }}>
        <Row className={'flexWrapper'}>
          <Col span={24}>
            <Row>
              <Col
                span={24}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'baseline',
                  justifyContent: 'space-between'
                }}>
                <Title level='h4' style={{ marginBottom: '24px' }}>
                  <IntlMessages id="store.StoreOpen&CloseTime" />
                  <Tooltip
                    title={intlAlertMessage({
                      id: 'storeDeliveryTimings',
                      intl
                    })}
                    className="store-tooltip">
                    <ExclamationCircleOutlined className="store-tooltip-icon" />
                  </Tooltip>
                </Title>
                {visibleCount >= 2 && (
                  <div
                    onClick={props.clearAllSlots}
                    style={{
                      color: COLORS.ERROR,
                      fontWeight: 'bold',
                      cursor: 'pointer'
                    }}>
                    Clear All
                  </div>
                )}
              </Col>
            </Row>
            <Row className={'daytimingswrapper'}>
              {days.map((day, i) => {
                return (
                  <Col
                    style={{
                      width: `${100 / daysLength}%`,
                      padding: '0 5px'
                    }}
                    key={i}>
                    <PeppoText level='body-1' style={{ color: COLORS.DARK_TEXT }}>{day.name}</PeppoText>
                    {day.data &&
                      day.data.map((slot, i) => {
                        return (
                          <div
                            key={i}
                            onClick={() => {
                              slotOnChangeHandler(day.name, slot, i)
                            }}
                            className={'showSlotTimeBtn'}>
                            <div>
                              <Text>
                                {moment(
                                  padWithZeroes(slot.openTime),
                                  HH_MM_FORMAT
                                ).format('hh:mm a')}
                              </Text>{' '}
                              -{' '}
                              <Text>
                                {moment(
                                  padWithZeroes(slot.closeTime),
                                  HH_MM_FORMAT
                                ).format('hh:mm a')}
                              </Text>
                            </div>
                          </div>
                        )
                      })}
                    <PeppoButton
                      type='secondary'
                      style={{ width: '100%' }}
                      onClick={() => {
                        toggleAddSlot(day.name)
                      }}>
                      + <IntlMessages id="addSlot" />
                    </PeppoButton>
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>
      </Card>}

      <Card style={{ margin: '24px 0' }}>
        <Title level='h4' style={{ marginBottom: '24px' }}>
          {isEdit ? (
            <IntlMessages id="store.storeManagersDetails" />
          ) : (
            <IntlMessages id="store.storeManagerLogin" />
          )}
          <Tooltip
            title={intlAlertMessage({
              id: 'store.info.storeManagersCredentials',
              intl
            })}
            className="store-tooltip">
            <ExclamationCircleOutlined className="store-tooltip-icon" />
          </Tooltip>
        </Title>

        <Row
          className={
            'flexWrapper justifyContentSpaceBetween create-manager-wrapper'
          }>
          <Col
            span={24}
            className="agentsInputWrapper"
            style={{ marginTop: '10px', marginBottom: 0 }}>
            <Row>
              <Col span={9}>
                <Title level='h5'>
                  <IntlMessages id="store.storeManagersPhoneNum" />
                  <Tooltip
                    title={intlAlertMessage({
                      id: 'store.info.phoneNumWillVisibleToCustomer',
                      intl
                    })}
                    className="store-tooltip">
                    <ExclamationCircleOutlined className="store-tooltip-icon" />
                  </Tooltip>
                </Title>
              </Col>
              <Col span={10}>
                <Input
                  addonBefore="91"
                  placeholder={intlAlertMessage({
                    id: 'tenDigitNumber',
                    intl: props.intl
                  })}
                  style={{ border: `1px solid ${COLORS.BORDER}` }}
                  value={getTenDigitNumber(storeFormDetails.phone)}
                  type={'number'}
                  onChange={e => {
                    if (e.target.value.length > 10) return false
                    onChange('phone', e.target.value)
                  }}
                  onBlur={() => {
                    validatePhone(storeFormDetails.phone)
                  }}
                  onKeyDown={evt =>
                    ['e', 'E', '+', '-', '.'].includes(evt.key) &&
                    evt.preventDefault()
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col
            span={24}
            className="agentsInputWrapper"
            style={{ marginTop: '10px', marginBottom: 0 }}>
            <Row>
              <Col span={9}>
                <Title level='h5'>
                  <IntlMessages id="store.username" />
                </Title>
              </Col>
              <Col span={10}>
                <Input
                  placeholder={intlAlertMessage({
                    id: 'enterYourFirstName',
                    intl: props.intl
                  })}
                  style={{ border: `1px solid ${COLORS.BORDER}` }}
                  disabled={isEdit}
                  value={storeFormDetails.userName}
                  onChange={e => {
                    onChange('userName', e.target.value)
                  }}
                  onBlur={checkUsername}
                />
                {!isEdit && (
                  <PeppoText level='caption'>
                    <IntlMessages id="store.usernameMustContain3Letters" />
                  </PeppoText>
                )}
              </Col>
            </Row>
          </Col>
          <Col
            span={24}
            className="agentsInputWrapper"
            style={{ marginTop: '10px', marginBottom: '10px' }}>
            <Row>
              <Col span={9}>
                <Title level='h5'>
                  <IntlMessages id="appModule.password" />
                </Title>
              </Col>
              <Col span={10}>
                <Input
                  placeholder={intlAlertMessage({
                    id: 'enterYourPassword',
                    intl: props.intl
                  })}
                  style={{ border: `1px solid ${COLORS.BORDER}` }}
                  disabled={!isPasswordEnable()}
                  value={storeFormDetails.password}
                  onChange={e => {
                    onChange('password', e.target.value)
                  }}
                />
                {(!isEdit || editPassword) && (
                  <PeppoText level='caption'>
                    <IntlMessages id="store.errMsg.passwordShouldHave5Char" />
                  </PeppoText>
                )}
              </Col>
              {isEdit && (
                <Col span={5} style={{ textAlign: 'end' }}>
                  <PeppoButton type='link'
                    style={{ fontWeight: 600 }}
                    onClick={() => toggleEditPassword()}
                  >
                    {editPassword ? (
                      <span style={{ color: COLORS.ERROR }}>
                        <IntlMessages id="store.discard" />
                      </span>
                    ) : (
                      <IntlMessages id="store.edit" />
                    )}
                  </PeppoButton>
                </Col>
              )}
            </Row>
          </Col>
          {!isBMSDealsOnlyView && <Col
            span={24}
            className="mb-2 mt-1">
            <Row align="middle">
              <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                <Icon style={{ paddingRight: '4px' }} component={() => <img src={ICONS.whatsapp} alt="whatsapp-icon" />} />
                <Title level='h5' style={{ marginTop: '10px' }}>
                  <IntlMessages id="store.storeManagersWhatsappSupport" />
                </Title>
              </Col>

              <Col span={10}>
                <Switch
                  checked={storeFormDetails['whatsappSupport']}
                  onChange={() => {
                    onChange('whatsappSupport', !storeFormDetails['whatsappSupport'])
                  }}
                />
              </Col>
            </Row>
          </Col>}
        </Row>
      </Card>

      {!isBMSDealsOnlyView && <>
        <Card style={{ margin: '24px 0' }}>
          <CardBlock>
            <Title level='h4'>
              <IntlMessages id="store.partnerDeliveryServices" />
            </Title>
            <CardBlock style={{ width: '22%' }}>
              <PeppoText level='caption'>
                <IntlMessages id="store.partners" />:
              </PeppoText>
              <DeliveryPartnersImgStyles src={IMAGES.dunzo} alt='dunzo' />
              <DeliveryPartnersImgStyles src={IMAGES.shadow_fax} alt='shadow_fax' />
              <DeliveryPartnersImgStyles src={IMAGES.wefast} alt='wefast' />
            </CardBlock>
          </CardBlock>
          <Row>
            <div className="fleet-check-text">
              <Title level="h5" style={{ marginBottom: '0px' }}>
                <IntlMessages id="store.serviceabilityAreaTitle" />
              </Title>
              <PeppoText level="caption">
                ({<IntlMessages id="deliveryRadius" />})
              </PeppoText>
            </div>
            <div
              style={{
                width: '71%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <div className="delivery-radius-container">
                <span className="dot-container">
                  {[...Array(5)].map((val, key) => (
                    <RadiusBarDotStyle key={key} />
                  ))}
                </span>
                <Slider
                  {...props}
                  min={3}
                  max={50}
                  onChange={handleChange}
                  value={serviceabilityRadius}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '-7px'
                  }}>
                  <PeppoText level="overline">3 km</PeppoText>
                  <PeppoText level="overline">50 km</PeppoText>
                </div>
              </div>
              <div className="radius-value">{serviceabilityRadius}</div>
            </div>
          </Row>
          <DeliveryDiscount
            getDeliveryDiscount={(val, status) =>
              getDeliveryDiscount(val, status)
            }
            deliveryDiscount={props.deliveryDiscount}
            deliveryDiscountStatus={props.deliveryDiscountStatus}
            intl={props.intl}
          />
        </Card>

        <Card>
          <Title level='h4'>
            <IntlMessages id="store.configureDeliveryFleet" />
          </Title>
          <DeliveryFleetRadioButtonStyle>
            <Title level="h5" style={{ paddingRight: '5%' }}>
              <IntlMessages id="store.doYouHaveYourOwnDeliveryFleet?" />
            </Title>
            <div>
              <Radio.Group
                buttonStyle="solid"
                value={fleetOption}
                onChange={e => onFleetChange(e)}>
                <Radio.Button
                  value="yes"
                  style={{ border: `1px solid ${COLORS.BORDER}`, backgroundColor: fleetOption === 'yes' ? COLORS.PRIMARY : COLORS.BG_WHITE }}>
                  <IntlMessages id="button.yes" />
                </Radio.Button>
                <Radio.Button
                  value="no"
                  style={{ border: `1px solid ${COLORS.BORDER}`, backgroundColor: fleetOption === 'no' ? COLORS.PRIMARY : COLORS.BG_WHITE }}>
                  <IntlMessages id="button.no" />
                </Radio.Button>
              </Radio.Group>
            </div>
            {fleetOption === 'no' && (
              <span className="fleet-msg-wrapper">
                <CheckCircleOutlined className="fleet-msg-icon" />
                <Text className="fleet-msg-text">
                  <IntlMessages id="store.peppoDeliveryMsg" />
                </Text>
              </span>
            )}
          </DeliveryFleetRadioButtonStyle>
          {fleetOption === 'yes' && (
            <>
              {columnsData && columnsData.length > 0 && (
                <div className={'deliveryAgentTableWrapper'}>
                  <Table
                    className="orderx-table"
                    rowKey={(record, index) => `agent-row-${index + 1}`}
                    dataSource={columnsData}
                    columns={columnHeaders}
                    pagination={false}
                    scroll={{
                      x: 0,
                      y: 208
                    }}
                  />
                </div>
              )}
              <PeppoButton type='secondary' onClick={() => props.isAgentDetailsModalVisibleHandler()}>
                <IntlMessages id="store.addAgentTitle" />
              </PeppoButton>
            </>
          )
          }

        </Card>

        <Modal
          title={
            isNewTimeSlot ? (
              <IntlMessages id="addSlot" />
            ) : (
              <IntlMessages id="updateSlot" />
            )
          }
          visible={showAddSlotDialog}
          className={'add-slot-modal-styles'}
          onCancel={() => {
            toggleAddSlot()
          }}
          footer={
            isNewTimeSlot
              ? [
                <Button
                  key="createSlot"
                  className={'createSlotBtn'}
                  onClick={() => {
                    onAddSlotCreation()
                  }}
                  disabled={
                    !storeFormDetails.openTime || !storeFormDetails.closeTime
                  }>
                  <IntlMessages id="create" />
                </Button>
              ]
              : [
                <Button
                  key="createSlot"
                  className={'createSlotBtn'}
                  onClick={() => {
                    onUpdateSlotClickHandler()
                  }}
                  disabled={
                    !storeFormDetails.openTime || !storeFormDetails.closeTime
                  }>
                  <IntlMessages id="update" />
                </Button>,
                <Button
                  type="primary"
                  danger
                  key="deleteSlot"
                  className={'deleteSlotBtn'}
                  onClick={() => {
                    props.onChange('showDeleteSlotDialog', true, true)
                  }}>
                  <IntlMessages id="button.delete" />
                </Button>
              ]
          }>
          <Col span={24}>
            <Row>
              <Col span={12}>
                <IntlMessages id="openTime" />
              </Col>
              <Col span={12}>
                <IntlMessages id="closeTime" />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <TimePicker
                  use12Hours
                  style={{ width: '100%' }}
                  onChange={(time, timeString) => {
                    onChange('openTime', time, true)
                  }}
                  format="hh:mm a"
                  minuteStep={1}
                  value={storeFormDetails.openTime}
                  size="large"
                />
              </Col>
              <Col span={12}>
                <TimePicker
                  use12Hours
                  style={{ width: '100%' }}
                  onChange={(time, timeString) => {
                    onChange('closeTime', time, true)
                  }}
                  format="hh:mm a"
                  minuteStep={1}
                  value={storeFormDetails.closeTime}
                  size="large"
                />
              </Col>
            </Row>
            {isNewTimeSlot && (
              <Row>
                <Col span={12}>
                  <div>
                    <Checkbox
                      style={{ marginTop: '10px' }}
                      checked={isApplyAllDays}
                      onChange={e => {
                        props.onChange('isApplyAllDays', e.target.checked, true)
                      }}>
                      {' '}
                      <IntlMessages id="store.applyToAllDays" />
                    </Checkbox>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Modal>
        <Modal
          className={'delete-slot-modal'}
          visible={props.showDeleteSlotDialog}
          title={
            props.isDayClose ? (
              intlAlertMessage(
                { id: 'markStoreClosedForDay', intl },
                {
                  day: `${selectedDay.substring(0, 1)}${selectedDay
                    .substring(1)
                    .toLowerCase()}`
                }
              )
            ) : (
              <IntlMessages id="store.confirmationMsg.aboutDeleteSelectedSlot" />
            )
          }
          onCancel={() => {
            props.onChange('showDeleteSlotDialog', false, true)
          }}
          footer={[
            <Button
              key="rejectDelete"
              onClick={() => {
                props.onChange('showDeleteSlotDialog', false, true)
              }}>
              <IntlMessages id="newOrder.Cancel" />
            </Button>,
            <Button
              type="primary"
              danger
              key="confirmDelete"
              onClick={() => {
                props.isDayClose
                  ? props.closeDay()
                  : props.onDeleteSlotClickHandler()
              }}>
              {props.isDayClose ? (
                <IntlMessages id="button.yes" />
              ) : (
                <IntlMessages id="button.delete" />
              )}
            </Button>
          ]}></Modal>

        <Modal
          visible={props.showDeliveryFleet}
          title={
            <IntlMessages id="store.confirmationMsg.disableDeliveryFleet" />
          }
          onCancel={() => {
            props.onCancelDeliverFleet()
          }}
          footer={[
            <Button
              key="rejectDelete"
              onClick={() => {
                props.onCancelDeliverFleet()
              }}>
              <IntlMessages id="button.no" />
            </Button>,
            <Button
              type="primary"
              danger
              loading={props.fleetBtnLoader}
              key="confirmDelete"
              onClick={() => {
                props.deleteBulkActiveAgent()
              }}>
              <IntlMessages id="button.yes" />
            </Button>
          ]}></Modal>
      </>}
    </Col>
  )
}

export default StoreForm

