import { message } from 'antd'
import { MERCHANT_DISCOUNT } from 'Utils/constants/langConstant'

import * as serviceWorker from '../serviceWorker'
import { COLORS, DEFAULT_CONSOLE_VIEW_CONFIG, NOTIFICATION_TYPE, ONBOARDING_ROUTES, ORDER_STATUS, ORG_CONSTANT, ORG_STATUS, PRODUCT_TYPE_ENUM, ROLE_TYPE, VIEW_TYPES } from './constants'
import { getDiscountTypes, getUserData } from './localStorageHandlers/getter'

export const randomString = L => {
  let s = ''
  const randomchar = () => {
    const n = Math.floor(Math.random() * 62)

    if (n < 10) return n // 1-10
    if (n < 36) return String.fromCharCode(n + 55) // A-Z

    return String.fromCharCode(n + 61) // A-z
  }

  while (s.length < L) s += randomchar()

  return s
}

export const getOrderStatusColor = status => {
  let color = COLORS.DARK_TEXT

  switch (status) {
      case ORDER_STATUS.ORDER_CREATED:
        color = COLORS.ERROR_DARK
        break
      case ORDER_STATUS.ORDER_ACCEPTED:
        color = COLORS.PRIMARY
        break
      case ORDER_STATUS.ORDER_DELIVERY_FOUND:
        color = COLORS.PRIMARY
        break
      case ORDER_STATUS.ORDER_SHIPPED:
        color = COLORS.PRIMARY
        break
      case ORDER_STATUS.ORDER_READY:
        color = COLORS.PRIMARY
        break
      case ORDER_STATUS.ORDER_DELIVERED:
        color = COLORS.DARK_TEXT
        break
      case ORDER_STATUS.ORDER_COMPLETE:
        color = COLORS.DARK_TEXT
        break
      default:
        color = COLORS.DARK_TEXT
  }

  return color
}

export const isRestaurantLive = () => {
  const orgDetails = getOrgDetails()

  return orgDetails && orgDetails.onboardingStatus !== ORG_STATUS.INITIATED
}

export const getUserDetails = () => {
  const user = getUserData()

  return user
}

export const setUserDetails = userData => {
  userData && localStorage.setItem('userData', JSON.stringify(userData))
}

export const getOrgDetails = () => {
  const org = JSON.parse(localStorage.getItem('organization'))

  return org
}

export const getStrikeoutDiscountType = () => {
  const discountTypes = getDiscountTypes()
  let strikeoutDiscountType

  if (discountTypes && discountTypes.length) {
    strikeoutDiscountType = discountTypes.find(discountType => discountType.discountTypeCode === ORG_CONSTANT.STRIKEOUT_DISCOUNT_CODE)
  }

  return strikeoutDiscountType
}

export const downloadQR = () => {
  const canvas = document.getElementById('qr-code') as HTMLCanvasElement
  const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
  const downloadLink = document.createElement('a')

  downloadLink.href = pngUrl
  downloadLink.download = 'qr-code.png'
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

export const copyToClipboard = value => {
  const textField = document.createElement('textarea')

  textField.innerText = value
  document.body.appendChild(textField)
  textField.select()
  document.execCommand('copy')
  textField.remove()
  message.success('Coppied!')
}

export const setOrgDetails = orgData => {
  orgData && localStorage.setItem('organization', JSON.stringify(orgData))
}

export const isAdmin = () => {
  const user = getUserDetails()
  const roles = user?.roles
  const isAdmin = roles?.find(element => element.name === ROLE_TYPE.ADMIN)

  return Boolean(isAdmin)
}

export const isManager = () => {
  const user = getUserDetails()
  const roles = user?.roles
  const isManager = roles?.find(element => element.name === ROLE_TYPE.MANAGER)

  return Boolean(isManager)
}

export const showSideBar = () => {
  if (document.querySelector('.gx-site-logo.gx-onboarding-logo')) {
    document.querySelector('.gx-site-logo.gx-onboarding-logo').classList.add('gx-d-none')
  }
  if (document.querySelector('.gx-app-sidebar')) {
    document.querySelector('.gx-app-sidebar').classList.remove('gx-d-none')
  }
}

export const isOnboaringRoute = routeName => {
  let routeFound = false

  ONBOARDING_ROUTES.forEach(route => {
    if (routeName.indexOf(route) !== -1) {
      routeFound = true
    }
  })

  return routeFound
}

export const downloadImage = async (imageUrl, imageName) => {
  const image = await fetch(imageUrl)
  const imageBlog = await image.blob()
  const imageURL = URL.createObjectURL(imageBlog)
  const link = document.createElement('a')

  link.href = imageURL
  link.download = imageName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const registerServiceWorker = () => {
  serviceWorker.firebaseMessagingServiceWorker()
  if ('serviceWorker' in navigator) {
    console.log('Registered service worker')
    navigator.serviceWorker.addEventListener('message', event => {
      const notification = event.data

      console.log('Received a message from service worker: ', event.data)
      if (!getUserDetails()) {
        return
      }
      switch (notification.type) {
          case NOTIFICATION_TYPE.MENU_UPLOAD_UPDATE:
            localStorage.removeItem('isMenuUploading')
            window.location.reload()
            break
      }
      /*
       * If (notification.background) {
       *   window.location.reload();
       * }
       */
    })
  }
}
export const getDeliveryDiscount = (order: any) => {
  let deliveryDiscount = 0

  order.orderPayments.forEach(order => {
    if (order.paymentType.code === MERCHANT_DISCOUNT && order.externalOrderDetails.amount) {
      deliveryDiscount = order.externalOrderDetails.amount
    }
  })

  return deliveryDiscount
}

export const handleBeforeUnload = (e, message) => {
  e.preventDefault()
  /*
   * TODO: confirm which nessage to be used and keep only one
   * Const message = 'Are you sure you want to leave? All provided data will be lost.'
   */

  e.returnValue = message

  return message
}

export const addWindowEventListener = (eventName, eventHandler) => {
  window.addEventListener(eventName, eventHandler)
}

export const removeWindowEventListener = (eventName, eventHandler) => {
  window.removeEventListener(eventName, eventHandler)
}

export const reorderArray = (originalArr, oldIndex, direction) => {
  let newIndex = direction === 'up' ? oldIndex - 1 : oldIndex + 1

  if (oldIndex === originalArr.length - 1 && direction === 'down') {
    newIndex = 0
  } else if (oldIndex === 0 && direction === 'up') {
    newIndex = originalArr.length - 1
  }
  originalArr.splice(newIndex, 0, originalArr.splice(oldIndex, 1)[0])

  return {
    newArr: [...originalArr],
    newIndex
  }
}

export const compareArrayValues = (arr1, arr2) => {
  let match = true

  if (Array.isArray(arr1) && Array.isArray(arr2)) {
    arr1.forEach(value => {
      if (!arr2.includes(value)) {
        match = false
      }
    })
  } else {
    match = false
  }

  return match
}

export const getItemNameAlongWithAddons = itemDetails => {
  const itemNameAlongWithAddons = {
    name: itemDetails.product.name,
    addons: []
  }

  if (itemDetails.children && itemDetails.children.length) {
    const addonItems = itemDetails.children.filter(child => {
      return child.product.productType === PRODUCT_TYPE_ENUM.ADDON
    })
    const variantItem = itemDetails.children.find(child => {
      return child.product.productType === PRODUCT_TYPE_ENUM.VARIANT
    })

    if (variantItem) {
      itemNameAlongWithAddons.name = variantItem.product.name
    }
    if (addonItems.length) {
      itemNameAlongWithAddons.addons = addonItems.map(addonItem => addonItem.product.name)
    }
  }

  return itemNameAlongWithAddons
}

export const getItemPriceAlongWithAddons = itemDetails => {
  let totalPrice = itemDetails.lineTotalProductPrices

  if (itemDetails.children && itemDetails.children.length) {
    const addonItems = itemDetails.children.filter(child => {
      return child.product.productType === PRODUCT_TYPE_ENUM.ADDON
    })
    const variantItem = itemDetails.children.find(child => {
      return child.product.productType === PRODUCT_TYPE_ENUM.VARIANT
    })

    if (variantItem) {
      totalPrice = variantItem.lineTotalProductPrices
    }

    if (addonItems.length) {
      totalPrice += addonItems.reduce(
        (total, addonItem) => total + addonItem.lineTotalProductPrices,
        0
      )
    }
  }

  return totalPrice
}

export const getTimeSlotWithPaddingZero = (number, length = 4) => {
  let timeSlot = `${number}`

  while (timeSlot.length < length) {
    timeSlot = `0${timeSlot}`
  }

  return `${timeSlot.slice(0, 2)}:${timeSlot.slice(2)}`
}

export const getUniqueCode = name => {
  return name
    .split(' ')
    .join('_')
    .toLowerCase()
}

export const isDuplicateCode = (code, codes) => {
  return codes.indexOf(code) !== -1
}

export const formatField = value => {
  return value || ''
}

export const intlAlertMessage = (props, placeholder={}) => {
  return props.intl.formatMessage({
    id: props.id || ''
  }, { ...placeholder })
}
export const setErrMsg = (id, intl) => {
  message.error(
    intlAlertMessage({
      id,
      intl
    })
  )
}
export const setInfoMessage = (id, intl) => {
  message.info(
    intlAlertMessage({
      id,
      intl
    })
  )
}
export const Auxiliary = props => props.children


export const parallelCall = (client, payload, requestCallback, channels) => {
  const requests = channels.map(channel => {
    return requestCallback(client, { ...payload, channel: channel.id })
  })

  return Promise.all(requests)
}
export const getChannelRequests = (client, channels, requestCallback, payload) => {
  return channels.map(channel => {
    return requestCallback(client, { ...payload, channel: channel.id })
  })
}

export const getViewType = () => {
  const orgDetails = getOrgDetails()
  const {
    store_management,
    menu_management,
    order_management,
    order_history,
    pay_direct_transactions,
    reports } = orgDetails?.menuConfiguration || DEFAULT_CONSOLE_VIEW_CONFIG

  const { food_order_settlements, pay_direct_settlements } = reports

  const isPeppoOnlyView = store_management
    && menu_management
    && order_management
    && order_history
    && !pay_direct_transactions
    && food_order_settlements
    && !pay_direct_settlements

  if (isPeppoOnlyView) return VIEW_TYPES.PEPPO_ONLY
  else if (!isPeppoOnlyView) return VIEW_TYPES.BMS_DEALS_ONLY

  return VIEW_TYPES.PEPPO_BMS
}