/* eslint-disable react/jsx-no-undef */
import { IMAGES } from 'assets'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { getOrgDetails, getViewType } from 'Utils'
import { COLORS, ORG_STATUS, POS_STATUS, VIEW_TYPES } from 'Utils/constants'
import { FONTWEIGHT } from 'Utils/constants/styles'
import { getPosEnableStores } from 'Utils/localStorageHandlers/getter'

import { Text } from '../../simple'
import IntlMessages from '../intlMessages'

const VIEW_TYPE_LOGO_URL = {
  [VIEW_TYPES.PEPPO_ONLY]: IMAGES.peppo_logo_transparent,
  [VIEW_TYPES.BMS_DEALS_ONLY]: IMAGES.peppo_bms_logo
}

const StyledText = styled.div`
  height: 21px;
  background-color: ${COLORS.ALERT_SHADE};
  text-align: center;
  padding: 3px 8px;
  border-radius: 4px;
`

function SidebarLogo() {
  const orgDetails = getOrgDetails()
  const viewType = getViewType()
  const isBMSDealsOnlyView = viewType === VIEW_TYPES.BMS_DEALS_ONLY
  const isPosEnabled = getPosEnableStores()
  let isPosRequested = false
  let isPosLive = false

  isPosEnabled?.forEach(store => {
    if (store.status === POS_STATUS.ACTIVE || store.status === POS_STATUS.INACTIVE) {
      isPosLive = true
    } else if (store.status === POS_STATUS.IN_PROGRESS) {
      isPosRequested = true
    }
  })

  let path

  if (orgDetails?.onboardingStatus === ORG_STATUS.INITIATED) {
    path = '/orderx/onboarding'
  } else if (isBMSDealsOnlyView) {
    path = '/orderx/reports'
  } else {
    path = '/orderx/orders'
  }

  const logoUrl = VIEW_TYPE_LOGO_URL[viewType]

  return (
    <div
      className={`orderX-Logo ${isBMSDealsOnlyView ? 'peppo-bms-logo' : ''}`}>
      <Link to={path}>
        <img
          style={{ marginBottom: '2px', width: '180px' }}
          alt="product_logo"
          src={logoUrl}
        />
      </Link>
      {isPosEnabled?.length ? (
        <StyledText>
          <Text
            level="overline"
            style={{
              color: COLORS.SECONDARY,
              fontWeight: FONTWEIGHT.FONT_MEDIUM
            }}>
            {isPosLive ? <IntlMessages id="pos.POSIntegrated" /> : isPosRequested ? <IntlMessages id="pos.POSIntegrationRequested" />: null}
          </Text>
        </StyledText>
      ) : null}
    </div>
  )
}

export default SidebarLogo
