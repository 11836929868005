import React, { useEffect, useState } from 'react'
import { sendResetPasswordLink } from 'services/userService'

const useForgotPassword = (props) => {
  const [isEmailSent, setIsEmailSent] = useState(false)
  const [email, setEmail] = useState('')
  const [showStepsModal, setShowStepsModal] = useState(false)
  const [sendingEmail, setSendingEmail] = useState(false)
  const [isResetLinkExpire, setIsResetLinkExpire] = useState(false)

  const handleSendResetPasswordLink = (email: any) => {
    if (!email) return
    sendResetPasswordLink(props.client, { email })

  }

  useEffect(() => {

    // eslint-disable-next-line no-param-reassign
    setIsResetLinkExpire(props.history.location.state
      ? props.history.location.state.isLinkExpire
      : false)

  }, [])

  return { handleSendResetPasswordLink, isEmailSent, email, showStepsModal, sendingEmail, setIsEmailSent, setEmail, setShowStepsModal, setSendingEmail, isResetLinkExpire }
}

export default useForgotPassword