import { Checkbox as AntCheckbox } from 'antd'
import React from 'react'
import styled from 'styled-components'
import theme from 'styled-theming'
import { COLORS } from 'Utils/constants'
const AntCheckboxGroup = AntCheckbox.Group

export interface optionProps {
  /**
   * Text to be displayed
   */
  label: string
  /**
   * Used for setting the currently selected value.
   */
  value: string
  /**
   * Disable checkbox
   */
  disabled?: boolean
}

export interface CheckboxGroupProps {
  /**
   * Default selected value
   */
  defaultValue?: string[]
  /**
   * The name property of all input[type="checkbox"] children
   */
  name: string
  /**
   * Disable all checkboxes
   */
  disabled?: boolean
  /**
   * Specifies options
   */
  options?: string[] | Array<optionProps>
  /**
   * Used for setting the currently selected value.
   */
  value?: string[]
  /**
   * The callback function that is triggered when the state changes.
   */
  onChange: (checkedValue: string) => void
  /**
   * Apply custom css
   */
  style?: object
}

const checkboxBorderColor = theme('mode', { light: COLORS.BORDER_DARK })
/*
 * Const unCheckedBgColor = theme.variants('mode', 'variant', {
 *   toggle2: { light: COLORS.ERROR },
 * });
 */

const StyledCheckboxGroup = styled(AntCheckboxGroup)`
    .ant-checkbox-inner {
      border-color: ${checkboxBorderColor};
      border-radius: 4px;
      width: 19px;
      height: 19px;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner {
      border-color: ${COLORS.PRIMARY};
    }

    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-indeterminate .ant-checkbox-inner {
      border-color: ${COLORS.PRIMARY};
      background-color: ${COLORS.PRIMARY};
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      width: 15px;
      background-color: ${COLORS.BG_WHITE};
      height: 2px;
    }
  `

/**
 * Peppo Switch selector to represent the switching between two states or on-off state
 */
export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ ...props }) => {
  return <StyledCheckboxGroup {...props}>{props.children}</StyledCheckboxGroup>
}
