import { Divider, Modal } from 'antd'
import { IntlMessages, Text, Title } from 'components'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { intlAlertMessage } from 'Utils'
import { COLORS } from 'Utils/constants'

const InnerModalStyle = styled.div`
    width: 329px;
    height: 206px;
    background: #f3f3f3;
    border-radius: 4px;
    opacity: 1;
    padding: 12px;
    margin-left: -12px;
    margin-top: 8px;
  `
const PackageChargeStyle = styled.div`
    display: flex;
    justify-content: space-between;
    height: 20px;
  `
const DeliveryDiscountBlock = styled.div`
    width: 307px;
    height: 50px;
    background: #f4f7f6;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `
const MAGIC_NUMBERS = {
  '200': '₹200.00',
  '10': '₹10.00',
  '1': '₹1.00',
  '50': '₹50.00',
  '20': '₹20.00',
  '261': '₹261',
  '241': '₹241'
}
const DeliveryChargeDiscountModal = ({ isVisible, onCancel, intl }) => {
  return (
    <Modal title={intlAlertMessage({ id: 'deliveryChargeDiscount', intl })} centered visible={isVisible} footer={null} onCancel={() => onCancel()} width={353} className="delivery-discount-modal">
      <ul style={{ paddingLeft: '6px', marginBottom: '16px', marginTop: '0' }}>
        <li>
          <IntlMessages id="store.deliveryDiscount" />
        </li>
        <li>
          <IntlMessages id="store.deliveryDiscountSpecialOffer" />
        </li>
      </ul>
      <Text level="caption" style={{ marginLeft: '-10px', marginBottom: '8px' }}>
        <IntlMessages id="store.sampleBillOnApp" />
      </Text>
      <InnerModalStyle>
        <PackageChargeStyle>
          <Text level="body-2" style={{ fontSize: '13px' }}>
            <IntlMessages id="subtotal" />
          </Text>
          <Text level="body-1">{MAGIC_NUMBERS[200]}</Text>
        </PackageChargeStyle>
        <PackageChargeStyle>
          <Text level="body-2" style={{ fontSize: '13px' }}>
            <IntlMessages id="menu.packingCharge" />
          </Text>
          <Text level="body-2" style={{ fontSize: '13px' }}>
            {MAGIC_NUMBERS[10]}
          </Text>
        </PackageChargeStyle>
        <PackageChargeStyle>
          <Text level="body-2" style={{ fontSize: '13px' }}>
            <IntlMessages id="menu.taxes" />
          </Text>
          <Text level="body-2" style={{ fontSize: '13px' }}>
            {MAGIC_NUMBERS[1]}
          </Text>
        </PackageChargeStyle>
        <PackageChargeStyle>
          <Text level="body-2" style={{ fontSize: '13px' }}>
            <IntlMessages id="newOrder.deliveryCharge" />
          </Text>
          <Text level="body-2" style={{ fontSize: '13px' }}>
            {MAGIC_NUMBERS[50]}
          </Text>
        </PackageChargeStyle>
        <DeliveryDiscountBlock>
          <Text level="caption" style={{ color: COLORS.PRIMARY, width: '130px' }}>
            <IntlMessages id="store.10percentDiscount" />
          </Text>
          <Title level="h6" style={{ color: COLORS.PRIMARY, margin: '0', fontSize: '15px' }}>
            -{MAGIC_NUMBERS[20]}
          </Title>
        </DeliveryDiscountBlock>
        <Divider />
        <PackageChargeStyle>
          <Text level="body-1">
            <IntlMessages id="newOrder.total" />
          </Text>
          <div>
            <s>
              <Text level="caption" style={{ fontSize: '11px', marginRight: '4px' }}>
                {MAGIC_NUMBERS[261]}
              </Text>
            </s>
            <Text level="body-1">{MAGIC_NUMBERS[241]}</Text>
          </div>
        </PackageChargeStyle>
      </InnerModalStyle>
    </Modal>
  )
}

export default DeliveryChargeDiscountModal
