/* eslint-disable prefer-named-capture-group */

import { ApolloProviderProps } from '@apollo/client/react/context'
import { withApollo } from '@apollo/client/react/hoc'
import { InputNumber, message } from 'antd'
import { Card, IntlMessages, Title } from 'components'
import * as compose from 'lodash/flowRight'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { getDeliveryArea, getStoreCharge } from 'services'
import { intlAlertMessage } from 'Utils'
import { COLORS } from 'Utils/constants'

import { DeliveryFleetRadioButtonStyle } from '../styles'


interface IDeliveryAreaProps extends ApolloProviderProps<any> {
  mapData: any
  onDataChange: any
  org_id: string | { [key: string]: any }
  storeGeoLocation: any
  location: any
  deliveryChargePerKm: any
  intl: any
}

class DeliveryArea extends Component<IDeliveryAreaProps, {}> {
  polygon: google.maps.Polygon

  map: google.maps.Map<HTMLElement>

  marker: google.maps.Marker

  addMarkerListener: google.maps.MapsEventListener

  drawingManager: google.maps.drawing.DrawingManager

  polygonCompleteListener: google.maps.MapsEventListener

  searchInput: any

  constructor(props: IDeliveryAreaProps) {
    super(props)
    this.searchInput = React.createRef<HTMLInputElement>()
  }

  componentDidMount() {
    const { mapData, storeGeoLocation, location, deliveryChargePerKm } = this.props
    const urlDetails = location.pathname.split('/')

    if (urlDetails[urlDetails.length - 1] === 'edit' && !deliveryChargePerKm) {
      getDeliveryArea(this.props.client, urlDetails[3])
        .then(storeDeliveryAreaResponse => {
          mapData.polygonGeoJSON = storeDeliveryAreaResponse.data.getStoreDeliverArea
          mapData.polygonGeoJSON && mapData.polygonGeoJSON.area && (mapData.polygonGeoJSON.area = JSON.parse(storeDeliveryAreaResponse.data.getStoreDeliverArea.area))
          const polygonArrayTemp = []

          mapData.polygonGeoJSON &&
            mapData.polygonGeoJSON.area &&
            mapData.polygonGeoJSON.area.features &&
            mapData.polygonGeoJSON.area.features[0].geometry.coordinates[0].forEach(coord => {
              polygonArrayTemp.push({
                lat: coord[0],
                lng: coord[1]
              })
            })
          mapData.polygonArray = polygonArrayTemp
          this.props.onDataChange('mapData', mapData, true)
          this.initMap(mapData, storeGeoLocation)
        })
        .catch(error => console.log(error))

      getStoreCharge(this.props.client, urlDetails[3])
        .then(storeChargeResponse => {
          if (storeChargeResponse.data.getStoreCharges) {
            const deliveryChargePerKm = storeChargeResponse.data.getStoreCharges.data[0].chargeValue
            const deliveryChargeId = storeChargeResponse.data.getStoreCharges.data[0].id

            this.props.onDataChange('deliveryChargePerKm', deliveryChargePerKm, true)
            this.props.onDataChange('deliveryChargeId', deliveryChargeId, true)
          }
        })
        .catch(err => console.log(err))
    } else {
      setTimeout(() => this.initMap(mapData, storeGeoLocation), 100)
    }
  }

  addMarker = location => {
    this.marker && this.marker.setMap(null)
    this.marker = new google.maps.Marker({
      position: location,
      map: this.map
    })
  }

  renderCustomMapButton = (mapData, storeGeoLocation) => {
    const controlDiv = document.createElement('div')

    controlDiv.setAttribute('id', 'draw-reset')
    const controlUI = document.createElement('div')

    controlUI.style.backgroundColor = COLORS.PRIMARY
    controlUI.style.borderRadius = '4px'
    controlUI.style.margin = '5px 10px'
    controlUI.style.cursor = 'pointer'
    controlUI.style.textAlign = 'center'
    controlDiv.appendChild(controlUI)

    const controlText = document.createElement('div')

    controlText.style.color = COLORS.BG_WHITE
    controlText.style.fontFamily = 'Roboto,Arial,sans-serif'
    controlText.style.fontSize = '13px'
    controlText.style.padding = '6px 10px'
    controlText.innerHTML = 'Draw'
    if (mapData.polygonArray.length) {
      controlText.innerHTML = 'Reset'
    }
    controlUI.appendChild(controlText)
    controlDiv.addEventListener('click', () => {
      const buttonName = controlText.innerHTML

      if (buttonName === 'Draw') {
        if (!mapData.center.lat || !mapData.center.lng || !this.marker) {
          message.error(
            intlAlertMessage({
              id: 'store.errMsg.selectMarkerOnMap',
              intl: this.props.intl
            })
          )
        } else {
          controlText.innerHTML = 'Reset'
          this.initDrawingManager(mapData, storeGeoLocation)
        }
      } else {
        this.polygonCompleteListener && this.stopPolygonCompleteListener()
        controlText.innerHTML = 'Draw'
        mapData.polygonArray = []
        this.props.onDataChange('mapData', mapData)
        this.drawingManager && this.drawingManager.setMap(null)
        this.drawingManager && this.drawingManager.setDrawingMode(null)
        this.polygon && this.polygon.setMap(null)
      }
    })

    return controlDiv
  }

  drawPolygon = polygonArray => {
    console.log('Draw polygon', polygonArray)
    console.log(this.map)
    this.polygon = new google.maps.Polygon({
      paths: polygonArray,
      strokeColor: COLORS.PRIMARY,
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: COLORS.PRIMARY_SHADE,
      fillOpacity: 0.85
    })
    this.polygon.setMap(this.map)
  }

  startAddMarkerListener = () => {
    this.addMarkerListener = this.map.addListener('click', event => {
      this.marker && this.marker.setMap(null)
      this.addMarker(event.latLng)
    })
  }

  stopAddMarkerListener = () => {
    this.addMarkerListener.remove()
  }

  initDrawingManager = (mapData, storeGeoLocation) => {
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      polygonOptions: {
        fillColor: COLORS.PRIMARY_SHADE,
        strokeColor: COLORS.PRIMARY,
        fillOpacity: 0.85,
        strokeWeight: 1,
        editable: true,
        clickable: false,
        geodesic: true,
        zIndex: 1
      }
    })

    this.startPolygonCompleteListener(mapData, storeGeoLocation)

    this.drawingManager.setMap(this.map)
  }

  startPolygonCompleteListener = (mapData, storeGeoLocation) => {
    this.polygonCompleteListener = google.maps.event.addListener(this.drawingManager, 'polygoncomplete', polygon => {
      if (this.containsLocationCheck(mapData, polygon)) {
        const polygonArray = []
        const GeoJSON = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [[]]
              },
              properties: {}
            }
          ]
        }
        const vertices = polygon.getPath().getArray()

        vertices.forEach(vertices => {
          polygonArray.push({ lat: vertices.lat(), lng: vertices.lng() })
          GeoJSON.features[0].geometry.coordinates[0].push([vertices.lat(), vertices.lng()])
        })
        mapData.polygonArray = polygonArray
        mapData.polygonGeoJSON = {
          ...mapData.polygonGeoJSON,
          area: GeoJSON,
          areaType: 'GEO_AREA'
        }

        this.props.onDataChange('mapData', mapData)
        polygon.setMap(null)
        this.drawPolygon(polygonArray)
        this.drawingManager.setDrawingMode(null)
      } else {
        message.error(
          intlAlertMessage({
            id: 'store.errMsg.OutOfDeliveryArea',
            intl: this.props.intl
          })
        )
        polygon.setMap(null)
      }
    })
  }

  stopPolygonCompleteListener = () => {
    this.polygonCompleteListener.remove()
  }

  containsLocationCheck = (mapData, polygon) => {
    return google.maps.geometry.poly.containsLocation(new google.maps.LatLng(Number(mapData.center.lat), Number(mapData.center.lng)), polygon)
  }

  initMap = (mapData, storeGeoLocation) => {
    const latLng = storeGeoLocation.match(/((-?\d+.?\d+) (-?\d+.?\d+))/)
    const lat = Number(latLng[2])
    const lng = Number(latLng[3])

    this.map = new google.maps.Map(document.getElementById('dMap'), {
      center: { lat, lng },
      zoom: 14,
      disableDefaultUI: true,
      zoomControl: true,
      fullscreenControl: true
    })

    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(this.renderCustomMapButton(mapData, storeGeoLocation))

    if (mapData.polygonArray.length) {
      this.drawPolygon(mapData.polygonArray)
    }
    if (mapData.center.lat && mapData.center.lng) {
      this.addMarker(new google.maps.LatLng(Number(mapData.center.lat), Number(mapData.center.lng)))
    }
  }

  render() {
    let { deliveryChargePerKm } = this.props

    return (
      <Card style={{ margin: '24px 50px' }}>
        <Title level='h4' style={{ margin: '5px 0 0 0' }} >
          <IntlMessages id="store.errMsg.defineDeliveryChargesAndArea" />
        </Title>
        <DeliveryFleetRadioButtonStyle span={12} className="agentsInputWrapper">
          <div className="InputLabel">
            <Title level='h5'>
              <IntlMessages id="store.infoMsg.DeliveryCharge" />
            </Title>
            <Title level='h5' style={{ marginTop: '-10px', fontSize: '15px' }}>
              <IntlMessages id="store.infoMsg.fixChargePerOrder" />
              <span>*</span>
            </Title>
          </div>
          <InputNumber
            value={deliveryChargePerKm}
            prefix={intlAlertMessage({
              id: 'rupeeSymbol',
              intl: this.props.intl
            })}
            size="large"
            placeholder={intlAlertMessage({
              id: 'customer.enterYourDeliveryCharge',
              intl: this.props.intl
            })}
            style={{ border: `1px solid ${COLORS.BORDER}`, width: '10%', marginLeft: '5%', alignItems: 'center' }}
            onChange={val => {
              deliveryChargePerKm = val
              this.props.onDataChange(
                'deliveryChargePerKm',
                deliveryChargePerKm
              )
            }}
          />
        </DeliveryFleetRadioButtonStyle>
        <div id="dMap" className="mapWrapper " />
      </Card>
    )
  }
}

export default compose(withRouter, withApollo)(DeliveryArea)
