export const setFileSystemId = (id) => {
  localStorage.setItem('fileSystemId', JSON.stringify(id))
}
  
export const setIsUnsavedStoreDetails = (flag) => {
  localStorage.setItem('isUnsavedStoreDetails', JSON.stringify(flag))
}
  
export const setStoreFormats = data => {
  return localStorage.setItem('storeFormats', JSON.stringify(data))
}
  
export const setChannels = data => {
  return localStorage.setItem('channels', JSON.stringify(data))
}
  
export const setUserData = (data) => {
  return localStorage.setItem('userData', JSON.stringify(data))
}
export const setPMSOrgnization = (data) => {
  return localStorage.setItem('organization', JSON.stringify(data))
}

export const setPosData = (data) => {
  return localStorage.setItem('posData', JSON.stringify(data))
}

export const  setPosEnableStoreData = (data) => {
  return localStorage.setItem('posEnableStores', JSON.stringify(data))
}

export const setSubscriptionNotification = (data) => {
  return localStorage.setItem('subscriptionNotification', JSON.stringify(data))
}