import './style.css'

import * as React from 'react'

import { Colors } from '../../../styles'

const HeaderText = props => {
  if (props.color) {
    return (
      <div
        style={{
          color: props.color,
          margin: props.margin ? props.margin : '0'
        }}
        className={matchCSS(props.type)}>
        {props.children}
      </div>
    )
  }

  return (
    <div style={matchFontColor(props.type, props)} className={matchCSS(props.type)}>
      {props.children}
    </div>
  )
}

function matchFontColor(type, props) {
  let value = ''

  switch (type) {
      case 'H1':
        value = Colors['Dark-Text']
        break
      case 'H2':
        value = Colors['Dark-Text']
        break
      case 'H3':
        value = Colors['Normal-Text']
        break
      case 'H4':
        value = Colors['Dark-Text']
        break
      case 'H5':
        value = Colors['Normal-Text']
        break
      case 'H6':
        value = Colors['Light-Text']
        break
      default:
        value = Colors['Dark-Text']
  }

  return { color: value, margin: props.margin ? props.margin : '0' }
}

function matchCSS(type) {
  switch (type) {
      case 'H1':
        return 'h1'
      case 'H2':
        return 'h2'
      case 'H3':
        return 'h3'
      case 'H4':
        return 'h4'
      case 'H5':
        return 'h5'
      case 'H6':
        return 'h6'
      default:
        return 'h3'
  }
}

export default HeaderText
