import { List } from 'antd'
import React from 'react'
import { SortableElement } from 'react-sortable-hoc'

import CategoryItem from './CategoryItem'

const CategoriesList = props => {
  const SortableCategoryItem = SortableElement(CategoryItem)
  const {
    categories,
    categoryDetails,
    isFetchingCategory,
    onCategorySelection,
  } = props

  return (
    <List
      dataSource={categories ? categories : []}
      loading={isFetchingCategory}
      renderItem={(category, index) => (
        <SortableCategoryItem
          distance={1}
          onCategorySelection={() => onCategorySelection(category)}
          category={category}
          index={index}
          categoryDetails={categoryDetails}
        />
      )}
    />
  )
}

export default CategoriesList
