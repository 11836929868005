import { LockOutlined } from '@ant-design/icons'
import { Form, Input } from 'antd'
import { Button, IntlMessages } from 'components'
import useResetPassword from 'pages/authentication/initialResetPassword/useResetPassword'
import React from 'react'
import { intlAlertMessage } from 'Utils'
import { COLORS } from 'Utils/constants/styles'

interface IProps {
  onSubmit: any
  intl: any
  loading: boolean
  submitButtonText?: string
  submitButtonClassName?: string
}

const ResetPasswordForm = (props: IProps) => {
  const { intl, loading, submitButtonText, onSubmit } = props
  const { formRef, handleConfirmBlur, validateNewPassword, VerifyPassword } = useResetPassword(props)

  return (
    <Form
      ref={formRef}
      onFinish={values => onSubmit(values)}
      className="login-form">
      <Form.Item
        key="current-pwd"
        name="currentPassword"
        rules={[
          {
            required: true,
            message: intlAlertMessage({
              id: 'enterCurrentPassword',
              intl
            })
          }
        ]}
        hasFeedback>
        <Input.Password
          visibilityToggle={false}
          prefix={<LockOutlined style={{ color: COLORS.DARK_TEXT }} />}
          placeholder={intlAlertMessage({
            id: 'currentpassword',
            intl
          })}
        />
      </Form.Item>
      <Form.Item
        key="pwd"
        name="password"
        rules={[
          {
            required: true,
            message: (
              <IntlMessages id="pleaseInputYourPassword" key="pass-error" />
            )
          },
          {
            validator: validateNewPassword
          }
        ]}
        hasFeedback>
        <Input.Password
          visibilityToggle={false}
          prefix={<LockOutlined style={{ color: COLORS.DARK_TEXT }} />}
          placeholder={intlAlertMessage({
            id: 'newPassword',
            intl
          })}
        />
      </Form.Item>
      <Form.Item
        key="confirm-pwd"
        name="confirmPassword"
        rules={[
          {
            required: true,
            message: (
              <IntlMessages
                id="pleaseConfirmYourPassword"
                key="confirm-pass-error"
              />
            )
          },
          {
            validator: VerifyPassword
          }
        ]}
        hasFeedback>
        <Input.Password
          prefix={<LockOutlined style={{ color: COLORS.DARK_TEXT }} />}
          onBlur={handleConfirmBlur}
          placeholder={intlAlertMessage({
            id: 'confirmPassword',
            intl
          })}
        />
      </Form.Item>
      <Form.Item style={{ textAlign: 'center' }}>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          style={{
            marginTop: 0,
            marginBottom: 0,
            width: 200
          }}>
          <IntlMessages id={submitButtonText || 'menu.submit'} />
        </Button>
      </Form.Item>
    </Form>
  )

}

export default ResetPasswordForm
