import { Card as AntCard } from 'antd'
import React from 'react'
import styled from 'styled-components'
import theme from 'styled-theming'
import { COLORS } from 'Utils/constants'

export interface CardProps {
  /**
   * How large should the Title be?
   */
  title?: string
  style?: object
}
const cardBackgroundColor = theme('mode', { light: COLORS.BG_WHITE })
const StyledCard = styled(AntCard)`
    &.ant-card {
      box-shadow: 0px 1px 2px ${COLORS.BOX_SHADOW};
      border: 1px solid ${COLORS.BORDER};
      border-radius: 4px;
      opacity: 1;
      background: ${cardBackgroundColor};
      width: 100%;
    }
    .ant-card-body {
      padding: 16px;
    }
  `

/**
 * Peppo Card component
 */
export const Card: React.FC<CardProps> = ({ ...props }) => {
  if (props?.title) {
    return (
      <StyledCard style={props.style} title={props.title}>
        {props.children}
      </StyledCard>
    )
  }

  return <StyledCard style={props.style}>{props.children}</StyledCard>
}
