import { Modal as AntdModal } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { Text } from '../Text'

export interface ModalProps {
  /**
   * Title of modal
   */
  title: any
  /**
   * Sub Title of modal
   */
  subTitle?: any
  /**
   * Visibility flag
   */
  visible: boolean
  /**
   * Width of modal
   */
  width?: number
  /**
   * Close/Cancel event handler
   */
  onClose: () => void
  /**
   * Apply custom css
   */
  style?: object
}

const StyledModal = styled(AntdModal)`
    border-radius: 8px;
    &.ant-modal .ant-modal-content {
      width: ${props => props.width || 380}px;
    }
    &.ant-modal .ant-modal-body {
      margin-top: -20px;
      margin-left: -9px;
      padding-bottom: 16px;
      padding-right: 10px;
    }
    &.ant-modal .ant-modal-header {
      padding: 10px 15px;
    }
  `
const StyledSubTitle = styled.div`
    margin-top: -20px;
  `
const StyledBody = styled.div`
    padding-top: 10px;
  `

export const Modal: React.FC<ModalProps> = props => {
  const { title, visible, onClose, style, width, subTitle } = props

  return (
    <div>
      <StyledModal
        title={title}
        centered
        visible={visible}
        onCancel={() => {
          onClose()
        }}
        footer={null}
        width={width || 320}
        style={style}>
        {Boolean(subTitle) && (
          <StyledSubTitle>
            <Text level={'body-2'} style={{ fontSize: '13px' }}>
              {subTitle}
            </Text>
          </StyledSubTitle>
        )}
        <StyledBody>{props.children}</StyledBody>
      </StyledModal>
    </div>
  )
}
