import { Button, IntlMessages, Modal, Text, Title } from 'components'
import { SEARCH_POS_ID_HELP_URL } from 'Utils/constants'

import POS_IMAGES from '../assests/image'

const SupportModal = ({ onClose, posID }) => {

  return (
    <Modal title={null} visible={true} onClose={() => onClose()} width={594}>
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <img
          src={ POS_IMAGES.success_monitor}
          alt="support_team"
          width={'122px'}
          height={'122px'}
        />
        <div style={{ marginTop: '6px' }}>
          <Text level="body-2">
            <IntlMessages id="store.petpoojaId" />
          </Text>
          <Text level="body-1"> {posID ? ` ${posID}` : ' XXXXX'}</Text>
        </div>
        
        <Title level="h4" style={{ marginTop: '8px', fontSize: '18px' }}>
          <IntlMessages
            id={ 'pos.integrationRequestSent' }
          />
        </Title>
        <div style={{ marginTop: '-10px' }}>
          <Button type="link">
            <a href={SEARCH_POS_ID_HELP_URL} target={'_blank'} rel="noreferrer">
              <IntlMessages id="learnMore" />
            </a>
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default SupportModal
