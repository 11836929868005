import 'antd/dist/antd.css'

import { Button } from 'antd'
import React from 'react'
import styled from 'styled-components'

import Colors from '../../../../styles/colors'

const ButtonContainer = styled.div`
  margin: ${props => (props.margin ? `${props.margin}` : '0px')};
  min-width: 140px !important;
  .ant-btn-primary {
    background-color: ${Colors.Primary} !important;
    color: #fcfcfc !important;
    border-width: 0px !important;
    &:hover {
      background-color: ${Colors['P-Hover']} !important;
    }
    &:disabled {
      background-color: ${Colors['P-Disabled']} !important;
    }
  }
`

export interface PrimaryButtonProps {
  margin?: string
  disabled?: boolean
  onClick?: () => void
  loading?: boolean
}

class PrimaryButton extends React.Component<PrimaryButtonProps> {
  render() {
    if (this.props.disabled) {
      return (
        <ButtonContainer margin={this.props.margin}>
          <Button style={{ minWidth: '140px' }} disabled={this.props.disabled} type="primary" onClick={this.props.onClick}>
            {this.props.children}
          </Button>
        </ButtonContainer>
      )
    }

    return (
      <ButtonContainer margin={this.props.margin}>
        <Button style={{ minWidth: '140px' }} type="primary" onClick={this.props.onClick} loading={this.props.loading}>
          {this.props.children}
        </Button>
      </ButtonContainer>
    )
  }
}

export default PrimaryButton
