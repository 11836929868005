import { withApollo } from '@apollo/client/react/hoc'
import { IMAGES } from 'assets'
import { ForgotPasswordForm } from 'components'
import { History } from 'history'
import * as compose from 'lodash/flowRight'
import * as React from 'react'
import { injectIntl } from 'react-intl'
import { COLORS } from 'Utils/constants/styles'

import useForgotPassword from './useForgotPassword'

interface IProps {
  history?: History
  intl: any
}



const ForgotPassword = (props: IProps) => {

  const { history, intl } = props
  const { handleSendResetPasswordLink } = useForgotPassword(props)

  return (
    <div
      style={{ background: `${COLORS.PRIMARY_MEDIUM} 0% 0% no-repeat padding-box` }}
      className="gx-app-change-pw-wrap">
      <div className="gx-app-change-pw-container">
        <div className="peppo-white-logo gx-text-center">
          <img src={IMAGES.peppo_logo_white} alt="Peppo white logo" />
        </div>
        <div className="gx-app-change-pw-main-content">
          <div className="gx-app-change-pw-content">
            <ForgotPasswordForm
              history={history}
              onSubmit={handleSendResetPasswordLink}
              intl={intl}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(compose(withApollo)(ForgotPassword))
