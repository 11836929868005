import { Col, Row } from 'antd'
import { Button, IntlMessages, Text } from 'components'
import React from 'react'
import styled from 'styled-components'
import { COLORS, DAYS_ENUM } from 'Utils/constants'

import { SaveButtonContainer, StyledContainer } from '../../../Utils/menuUtils/styleConstants'

const StyledEmptyBox = styled.div`
    height: 35px;
    width: auto;
    max-width: 90%;
    background-color: ${COLORS.DISABLED};
    margin-top: 15px;
  `
const StyledSlotBox = styled.div`
    border: 1px solid ${COLORS.BORDER};
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
  `

const EmptyTimeSlotsTable = () => {
  return (
    <StyledContainer span={17}>
      <StyledSlotBox>
        {DAYS_ENUM.map(dayName => (
          <div style={{ width: '120px' }} key={dayName}>
            <Text level="body-2" style={{ color: COLORS.GRAY_TEXT }}>
              {dayName}
            </Text>
            <StyledEmptyBox />
          </div>
        ))}
      </StyledSlotBox>
      <SaveButtonContainer>
        <Row>
          <Col span={23} style={{ padding: 0 }}>
            <Button
              type="primary"
              disabled={true}
              style={{ minWidth: '120px' }}>
              <IntlMessages id="button.save" />
            </Button>
          </Col>
        </Row>
      </SaveButtonContainer>
    </StyledContainer>
  )
}

export default EmptyTimeSlotsTable