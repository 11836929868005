import Icon, { ExclamationCircleOutlined } from '@ant-design/icons'
import { Col, Input, Row, Tooltip } from 'antd'
import { ICONS } from 'assets'
import { Button as PeppoButton, Card, IntlMessages, Switch, Text as PeppoText, Title } from 'components'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { setErrMsg } from 'Utils'
import { COLORS, PHONE_NUMBER_REGEX, ROLE_TYPE, STATUS } from 'Utils/constants'
import { setIsUnsavedStoreDetails } from 'Utils/localStorageHandlers/setter'
import { formatMobileNumber, getTenDigitNumber } from 'Utils/storeUtils'

export default function StoreManager({ storeId, useStoreData }) {
  const [storeData, setStoreData] = useStoreData()
  const [isEditPassword, setIsEditPassword] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [isWhatsappSupportEnabled, setIsWhatsappSupportEnabled] = useState(true)

  const intl = useIntl()
  const isEdit = Boolean(storeId)

  function toggleEditPassword() {
    isEditPassword && setPassword('')
    setIsEditPassword(!isEditPassword)
  }

  function validatePhone(value) {
    if (!PHONE_NUMBER_REGEX.test(value)) {
      setErrMsg('store.validMobileNum', intl)

      return false
    }
  }
  useEffect(() => {
    if (storeData()?.store) {
      const store = storeData().store
      const { staff } = store
      const managerDetails = staff.find(staff => staff.status === STATUS.ACTIVE && staff.staffRole === ROLE_TYPE.STORE_MANAGER)
      const { name, phone, whatsappSupport, id } = managerDetails

      setStoreData({ storeManager: { id } })
      setName(name)
      setPhoneNumber(getTenDigitNumber(phone))
      setIsWhatsappSupportEnabled(whatsappSupport)
    }
  }, [])

  useEffect(() => {
    const { storeManager = {} } = storeData()

    setStoreData({
      storeManager: {
        ...storeManager,
        name,
        phone: formatMobileNumber(phoneNumber),
        whatsappSupport: isWhatsappSupportEnabled,
        password,
        isEditPassword,
      }
    })
  }, [name, phoneNumber, isWhatsappSupportEnabled, password, isEditPassword])

  return (
    <Card style={{ margin: '12px 0' }}>
      <Title level='h4' style={{ marginBottom: '24px' }}>
        {isEdit ? (
          <IntlMessages id="store.storeManagersDetails" />
        ) : (
          <IntlMessages id="store.storeManagerLogin" />
        )}
        <Tooltip
          title={intl.formatMessage({ id: 'store.info.storeManagersCredentials' })}
          className="store-tooltip">
          <ExclamationCircleOutlined className="store-tooltip-icon" />
        </Tooltip>
      </Title>

      <Row
        className={
          'flexWrapper justifyContentSpaceBetween create-manager-wrapper'
        }>
        <Col
          span={24}
          className="agentsInputWrapper"
          style={{ marginTop: '10px', marginBottom: 0 }}>
          <Row>
            <Col span={9}>
              <Title level='h5'>
                <IntlMessages id="store.storeManagersPhoneNum" />
                <Tooltip
                  title={intl.formatMessage({ id: 'store.info.phoneNumWillVisibleToCustomer' })}
                  className="store-tooltip">
                  <ExclamationCircleOutlined className="store-tooltip-icon" />
                </Tooltip>
              </Title>
            </Col>
            <Col span={10}>
              <Input
                addonBefore="91"
                placeholder={intl.formatMessage({ id: 'tenDigitNumber' })}
                value={phoneNumber}
                type={'number'}
                onChange={e => {
                  if (e?.target?.value?.length > 10) return false
                  setPhoneNumber(e.target.value)
                  setIsUnsavedStoreDetails(true)
                }}
                onBlur={() => {
                  validatePhone(phoneNumber)
                }}
                onKeyDown={evt =>
                  ['e', 'E', '+', '-', '.'].includes(evt.key) &&
                  evt.preventDefault()
                }
              />
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          className="agentsInputWrapper"
          style={{ marginTop: '10px', marginBottom: 0 }}>
          <Row>
            <Col span={9}>
              <Title level='h5'>
                <IntlMessages id="store.username" />
              </Title>
            </Col>
            <Col span={10}>
              <Input
                placeholder={intl.formatMessage({ id: 'enterYourFirstName' })}
                style={{ border: `1px solid ${COLORS.BORDER}` }}
                disabled={isEdit}
                value={name}
                onChange={e => {
                  setName(e.target.value)
                }}

              />
              {!isEdit && (
                <PeppoText level='caption'>
                  <IntlMessages id="store.usernameMustContain3Letters" />
                </PeppoText>
              )}
            </Col>
          </Row>
        </Col>
        <Col
          span={24}
          className="agentsInputWrapper"
          style={{ marginTop: '10px', marginBottom: '10px' }}>
          <Row>
            <Col span={9}>
              <Title level='h5'>
                <IntlMessages id="appModule.password" />
              </Title>
            </Col>
            <Col span={10}>
              <Input
                placeholder={intl.formatMessage({ id: 'enterYourPassword' })}
                style={{ border: `1px solid ${COLORS.BORDER}` }}
                disabled={!(!isEdit || isEditPassword)}
                value={password}
                onChange={e => {
                  setPassword(e.target.value)
                  setIsUnsavedStoreDetails(true)
                }}
              />
              {(!isEdit || isEditPassword) && (
                <PeppoText level='caption'>
                  <IntlMessages id="store.errMsg.passwordShouldHave5Char" />
                </PeppoText>
              )}
            </Col>
            {isEdit && (
              <Col span={5} style={{ textAlign: 'end' }}>
                <PeppoButton type='link'
                  style={{ fontWeight: 600 }}
                  onClick={toggleEditPassword}
                >
                  {isEditPassword ? (
                    <span style={{ color: COLORS.ERROR }}>
                      <IntlMessages id="store.discard" />
                    </span>
                  ) : (
                    <IntlMessages id="store.edit" />
                  )}
                </PeppoButton>
              </Col>
            )}
          </Row>
        </Col>
        <Col
          span={24}
          className="mb-2 mt-1">
          <Row align="middle">
            <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
              <Icon style={{ paddingRight: '4px' }} component={() => <img src={ICONS.whatsapp} alt="whatsapp-icon" />} />
              <Title level='h5' style={{ marginTop: '10px' }}>
                <IntlMessages id="store.storeManagersWhatsappSupport" />
              </Title>
            </Col>

            <Col span={10}>
              <Switch
                checked={isWhatsappSupportEnabled}
                onChange={() => {
                  setIsWhatsappSupportEnabled(!isWhatsappSupportEnabled)
                  setIsUnsavedStoreDetails(true)
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}