import { Col, Row } from 'antd'
import { IMAGES } from 'assets'
import QRCode from 'qrcode.react'
import React from 'react'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { copyToClipboard, downloadQR, getOrgDetails } from 'Utils/index'

import CommonOnboardingCard from './commonOnboardingCard'

const QRCodeContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 40px;
  border: 1px solid #e6e6e6;
  padding: 5px 5px 1px 5px;
  margin: 19px 0;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
    &:after {
      content: '';
      background: url(${IMAGES.download_img}) center no-repeat;
      background-repeat: no-repeat;
      background-size: 42px 26px;
      opacity: 1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
`

const MiniAppUrlCard = props => {
  const history = useHistory()
  const organization = getOrgDetails()

  const onCreateUrl = () => {
    history.push({
      pathname: '/orderx/onboarding/create-mini-url'
    })
  }

  const miniAppUrl = organization.miniAppUrlLink

  return (
    <Row>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 18, offset: 3 }}
        lg={{ span: 12, offset: 6 }}>
        <div className="mt-1">
          <CommonOnboardingCard
            intl={props.intl}
            pending={!miniAppUrl}
            disable={!organization?.orgLogo}
            miniAppUrl={miniAppUrl}
            onButtonClick={() => onCreateUrl()}
            title="onboarding.createMiniUrl"
            description="onboarding.createMiniUrlInfo"
            buttonText="createUrl"
            required={true}
            copyToClipboard={() => copyToClipboard(miniAppUrl)}
          />
          {miniAppUrl && (
            <>
              <QRCodeContainer onClick={() => downloadQR()}>
                <QRCode
                  id="qr-code"
                  value={miniAppUrl}
                  style={{ height: '80px', width: '80px' }}
                />
              </QRCodeContainer>
            </>
          )}
        </div>
      </Col>
    </Row>
  )
}

export default injectIntl(MiniAppUrlCard)
