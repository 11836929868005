import { GET_ORDER_COUNT, GET_ORDER_HISTORY, GET_RESTAURANTS_ORDERS, SHIPPED_ORDER } from 'services/query'

export const getOrderHistoryAPI = (client, payload) => {
  return client.query({
    query: GET_ORDER_HISTORY,
    variables: payload,
    fetchPolicy: 'network-only'
  })
}

export const getOrderCount = (client, payload) => {
  return client.query({
    query: GET_ORDER_COUNT,
    variables: payload,
    fetchPolicy: 'network-only'
  })
}

export const getRestaurantOrders = (client, payload) => {
  return client.query({
    query: GET_RESTAURANTS_ORDERS,
    variables: payload,
    fetchPolicy: 'network-only'
  })
}

export const onShipOrder = (client, id) => {
  return client.mutate({
    mutation: SHIPPED_ORDER,
    variables: {
      id
    },
    fetchPolicy: 'no-cache'
  })
}
