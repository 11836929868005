import { Col, Divider, Row } from 'antd'
import { IntlMessages, Title } from 'components'
import { getViewType } from 'Utils'
import {
  ENVIRONMENT,
  MASTER_SETTLEMENT_REPORT_EMBADE_LINK,
  PROD_SETTLEMENT_REPORT_EMBADE_LINK,
  VIEW_TYPES
} from 'Utils/constants'

import { MetabaseReports, PromotionsHistory } from './components'
export default function OrderReports() {
  const viewType = getViewType()
  const isBMSDealsOnlyView = viewType === VIEW_TYPES.BMS_DEALS_ONLY
  const env = process.env.REACT_APP_SENTRY_ENV
  const dashboardURL =
    env === ENVIRONMENT.MASTER
      ? MASTER_SETTLEMENT_REPORT_EMBADE_LINK
      : PROD_SETTLEMENT_REPORT_EMBADE_LINK

  return (
    <div className="main">
      <Row className="order-history-header">
        <Col span={22}>
          <Title level="h2">
            <IntlMessages id={isBMSDealsOnlyView ? 'bmsDeals' : 'reports'} />
          </Title>
        </Col>
        {!isBMSDealsOnlyView && (
          <Col span={2} style={{ marginTop: '7px' }}>
            <a
              className="menu-help"
              href="https://help.peppo.co.in/collections/order-history"
              target="_blank"
              rel="noopener noreferrer">
              <IntlMessages id="menu.help" />
            </a>
          </Col>
        )}
      </Row>
      {isBMSDealsOnlyView ? (
        <PromotionsHistory />
      ) : (
        <MetabaseReports dashboardURL={dashboardURL} />
      )}
    </div>
  )
}
