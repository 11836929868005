import { ApolloClient, ApolloProvider, gql, InMemoryCache } from '@apollo/client'
import { graphql } from '@apollo/client/react/hoc'
import { Layout } from 'antd'
import * as compose from 'lodash/flowRight'
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { getOrgDetails } from 'Utils'
import { ORG_STATUS } from 'Utils/constants'
import { getAuthToken } from 'Utils/localStorageHandlers/getter'
import splitLinkHandler from 'Utils/subscriptionHelper'

import SidebarContent from '../SidebarContent'

const { Sider } = Layout

interface IProps {
  toggleCollapsedSideNav?: any
  navCollapsed?: boolean
  location?: any
  width?: number
}
export const Sidebar=(props: IProps) => {
  const orgDetails = getOrgDetails()
  const [collapse, setCollapse] = React.useState(true)
  const isOnboarding = orgDetails.onboardingStatus === ORG_STATUS.INITIATED
 

  const splitLink = splitLinkHandler(getAuthToken())

  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache()
  })

  const toggleSidebar = collapse => {
    setCollapse(collapse)
  }


  return (
    <ApolloProvider client={client}>
      <Sider
        className={`gx-app-sidebar gx-layout-sider-dark ${isOnboarding &&
          'gx-d-none'}`}
        collapsible
        collapsed={collapse}
        onCollapse={toggleSidebar}
        width={280}
        collapsedWidth={48}>
        
        <SidebarContent isCollapsed={collapse} />
      </Sider>
    </ApolloProvider>
  )
}


const TOGGLE_COLLAPSED_SIDENAV = gql`
  mutation toggleSideNav($navCollapsed: Boolean) {
    toggleCollapsedSideNav(navCollapsed: $navCollapsed) @client
  }
`
const mapStateToProps = ({ settings }: any) => {
  const { navCollapsed, width } = settings.settings

  return { navCollapsed, width }
}

export default compose(
  withRouter,
  /*
   * Graphql(GET_SETTINGS, { name: 'settings', props: mapStateToProps }),
   * graphql(UPDATE_WINDOW_WIDTH, { name: "updateWindowWidth" }), 
   */
  graphql(TOGGLE_COLLAPSED_SIDENAV, { name: 'toggleCollapsedSideNav' })
)(Sidebar)
