import './index.css'

import { Input, Typography } from 'antd'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { COLORS } from 'Utils/constants'
const { Text } = Typography
const InputFieldContainer = styled.div`
    width: ${props => (props.width ? `${props.width}` : 'auto')};
    margin: ${props => (props.margin ? `${props.margin}` : '0')};
  `

interface InputFieldProps {
  placeholder?: string
  title?: ReactElement
  allowClear?: boolean
  onInputChange: any
  onInputPressEnter?: any
  margin?: string
  value?: string
  width?: string
  important?: boolean
  isTextArea?: boolean
  numberOfRows?: number
  isDisabled?: boolean
}

const { TextArea } = Input

class InputField extends React.Component<InputFieldProps> {
  render() {
    const { margin, width, important, title, isTextArea, isDisabled, value, placeholder, allowClear, onInputChange,numberOfRows, onInputPressEnter } = this.props
    
    return (
      <InputFieldContainer margin={margin} width={width}>
        <div>
          <Text className="if-title">
            {title}
            {important !== undefined &&
              (important ? (
                <span style={{ color: COLORS.ERROR }}>*</span>
              ) : null)}
          </Text>
        </div>
        {/* <Row className="if-input-container"> */}
        {isTextArea === undefined && (
          <Input
            className="if-input"
            disabled={
              isDisabled !== undefined
                ? isDisabled
                : false
            }
            value={value}
            placeholder={placeholder}
            allowClear={allowClear}
            onChange={event => onInputChange(event)}
            onPressEnter={event => {
              onInputPressEnter(event)
            }}
          />
        )}
        {isTextArea !== undefined && (
          <TextArea
            rows={
              numberOfRows !== undefined
                ? numberOfRows
                : 2
            }
            className="if-input"
            disabled={
              isDisabled !== undefined
                ? isDisabled
                : false
            }
            value={value}
            placeholder={placeholder}
            allowClear={allowClear}
            onChange={event => onInputChange(event)}
            onPressEnter={event => {
              onInputPressEnter(event)
            }}
          />
        )}
        {/* </Row> */}
      </InputFieldContainer>
    )
  }
}

export default InputField
