export const MENU_HELP_URL = 'https://help.peppo.co.in/collections/menu-related-changes#general'
export const STORE_HELP_URL = 'https://help.peppo.co.in/collections/change-store-related-information#basic-store-information'
export const BROWSE_COVER_IMG = 'https://drive.google.com/drive/folders/1zF4OpZX6g-yHhVdjkq_DTv9GssVHANL4'

export const SAMPLE_EXCEL_SHEET = 'https://docs.google.com/spreadsheets/d/12tCUlZlHIbRDCJJ7qFPUSyn2yrVo9ncN/copy'
export const GPAY_BUSINESS = 'https://pay.google.com/business/console'
export const GPAY_BUSINESS_API = 'https://pay.google.com/business/console/create'
export const WHATSAPP_BOT_URL = 'https://api.whatsapp.com/send?phone=15616784970&text=Hi@'
export const TERMS_CONDITION_REDIRECT_URL = 'https://www.peppo.co.in/terms-of-service'

export const CANVA_SDK_URL = 'https://sdk.canva.com/designbutton/v2/api.js'
export const PROD_SETTLEMENT_REPORT_EMBADE_LINK = 'https://peppo-metabase.wcore.in/public/question/2ba2e532-b8d5-420c-a325-86d39edd8f6c'
export const MASTER_SETTLEMENT_REPORT_EMBADE_LINK = 'https://reports.peppo.co.in/public/question/e9fe1505-b234-4584-ae1b-2638cc6abf95'

export const BLACKLIST_KEYWORDS = 'https://peppo-console-blacklist-names.s3.ap-south-1.amazonaws.com/data.txt'
export const CATEGORY_HELP_URL = 'https://help.peppo.co.in/collections/menu-related-changes#category-customization'
export const ITEMS_HELP_URL = 'https://help.peppo.co.in/collections/menu-related-changes#item-customization'
export const ORDER_HISTORY_HELP_URL = 'https://help.peppo.co.in/collections/order-history'
export const PROFILE_INFORMATION_HELP_URL = 'https://help.peppo.co.in/collections/profile-information'
export const SEARCH_POS_ID_HELP_URL = 'https://help.peppo.co.in/articles/looking-for-pos-id'
export const MENU_SHARING_CODE_HELP_URL = 'https://help.peppo.co.in/articles/requesting-the-menu-sharing-code'
export const POS_HELP_URL = 'https://help.peppo.co.in/collections/change-store-related-information#pos-information'
