import { ADD_DISCOUNT_TYPE, CREATE_CHARGE_TYPE, CREATE_TAG, CREATE_TAX_TYPE, FILESYSTEMS, GET_BANK_DETAILS, GET_CHANNEL, GET_CHARGE_TYPES, GET_DISCOUNT_TYPES, GET_LEGAL_ORG_DOC, GET_ORG_INFO, GET_PARTNERS, GET_TAGS, GET_TAX_TYPES, GET_TAX_VALUES, REMOVE_CHARGE_FOR_FORMAT_CHANNEL, REMOVE_TAX_FOR_FORMAT_CHANNEL, UPDATE_ORG } from 'services/query'

export const createDiscountType = (client, discountTypeInput) => {
  return client
    .mutate({
      mutation: ADD_DISCOUNT_TYPE,
      variables: { input: discountTypeInput },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.error('create discount values for product error', e)
    })
}

export const getDiscountTypes = client => {
  return client
    .query({
      query: GET_DISCOUNT_TYPES,
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.error('Get discount types error', e)
    })
}

export const createTags = (client, tagInput) => {
  return client.mutate({
    mutation: CREATE_TAG,
    variables: { input: tagInput },
    fetchPolicy: 'no-cache'
  })
    .catch(e => {
      console.log('create tag err', e)
    })
}

export const getFileSystems = (client, orgId) => {
  return client
    .query({
      query: FILESYSTEMS,
      variables: {
        orgId,
        status: 'ACTIVE'
      },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e, 'get file system id error')
    })
}

export const getAllChannels = client => {
  return client.query({
    query: GET_CHANNEL,
    fetchPolicy: 'no-cache'
  })
}

export const getTags = (client, filter = {}) => {
  return client.query({
    query: GET_TAGS,
    variables: {
      filter
    },
    fetchPolicy: 'network-only'
  })
}

export const getTaxTypes = (client, organizationId) => {
  return client.query({
    query: GET_TAX_TYPES,
    variables: { organizationId },
    fetchPolicy: 'no-cache'
  })
}

export const getChargeTypes = (client, chargeTypeCode = []) => {
  return client.query({
    query: GET_CHARGE_TYPES,
    variables: {
      input: {
        chargeTypeCode
      }
    },
    fetchPolicy: 'no-cache'
  })
}

export const getTaxValues = (client, payload) => {
  return client.query({
    query: GET_TAX_VALUES,
    variables: {
      filter: payload,
      pageOptions: { page: 1, pageSize: 1 }
    },
    fetchPolicy: 'no-cache'
  })
}

export const createTaxType = (client, payload) => {
  return client.mutate({
    mutation: CREATE_TAX_TYPE,
    variables: { input: payload },
    fetchPolicy: 'no-cache'
  })
}

export const createChargeType = (client, payload) => {
  return client.mutate({
    mutation: CREATE_CHARGE_TYPE,
    variables: { input: payload },
    fetchPolicy: 'no-cache'
  })
}

export const removeFormatChannelTax = (client, payload) => {
  return client.mutate({
    mutation: REMOVE_TAX_FOR_FORMAT_CHANNEL,
    variables: { input: payload },
    fetchPolicy: 'no-cache'
  })
}

export const removeFormatChannelCharge = (client, payload) => {
  return client.mutate({
    mutation: REMOVE_CHARGE_FOR_FORMAT_CHANNEL,
    variables: { input: payload },
    fetchPolicy: 'no-cache'
  })
}

export const updateOrderXOrganization = (client, payload) => {
  return client.mutate({
    mutation: UPDATE_ORG,
    variables: { organization: payload },
    fetchPolicy: 'no-cache'
  })
}

export const getOrganizationInfo = (client, id) => {
  return client.query({
    query: GET_ORG_INFO,
    variables: { id },
    fetchPolicy: 'no-cache'
  })
}

export const getBankDetails = (client, organizationId) => {
  return client.query({
    query: GET_BANK_DETAILS,
    variables: {
      filter: { organizationId }
    },
    fetchPolicy: 'network-only'
  })
}

export const getLegalOrganizationDoc = (client, organizationId) => {
  return client.query({
    query: GET_LEGAL_ORG_DOC,
    variables: { organizationId },
    fetchPolicy: 'no-cache'
  })
}

export const getPartners = (client, payload) => {
  return client.query({
    query: GET_PARTNERS,
    variables: {
      filter: payload
    },
    fetchPolicy: 'network-only'
  })
}

export * from './customisationServices'
export * from './categoryService'
export * from './menuService'
export * from './menuTimingService'
export * from './orderService'
export * from './pmsServices'
export * from './productService'
export * from './storeService'
