import { Input } from 'antd'
import { SearchProps } from 'antd/lib/input'
import React from 'react'
import styled from 'styled-components'
import { COLORS } from 'Utils/constants'

const { Search: AntDSearch } = Input

export const Search: React.FunctionComponent<SearchProps> = styled(AntDSearch)`
&.ant-input-search .ant-input-wrapper .ant-input-affix-wrapper {
  border-radius: 20px;
}
.ant-input-affix-wrapper:focus {
  border-color: ${COLORS.PRIMARY};
  box-shadow: 0 0 0 2px ${COLORS.PRIMARY};
}
.ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px ${COLORS.PRIMARY_LIGHT};
  border-color: ${COLORS.PRIMARY};
}
.ant-input-affix-wrapper .ant-input-suffix {
  right: 32px;
}

&.ant-input-search .ant-input-wrapper .ant-input-affix-wrapper .ant-input {
  border-radius: 20px;
}
&.ant-input-search .ant-input-group .ant-input-group-addon {
  left: -30px;
  background: none;
  z-index: 1;
}
.ant-input-search-button {
  width: 24px;
  height: 24px;
  border: none;
}

` as any