import './styles.css'

import { ArrowRightOutlined } from '@ant-design/icons'
import { ApolloClient } from '@apollo/client'
import { ApolloProviderProps } from '@apollo/client/react/context'
import { withApollo } from '@apollo/client/react/hoc'
import { Card, Col, message, Row, Spin, Typography } from 'antd'
import { ClientManager } from 'appApollo/client'
import { IMAGES } from 'assets'
import { Button, FileUpload, IntlMessages } from 'components'
import Loader from 'components/simple/loader'
import { History } from 'history'
import QRCode from 'qrcode.react'
import * as React from 'react'
import { injectIntl } from 'react-intl'
import { getBankDetails, getLegalOrganizationDoc, getOrganizationInfo, getPMSBankDetails, updateOrderXOrganization, updatePMSOrganization } from 'services'
import styled from 'styled-components'
import { copyToClipboard, downloadQR, formatField, getOrgDetails, getViewType, intlAlertMessage, randomString } from 'Utils'
import { COLORS, PROFILE, PROFILE_INFORMATION_HELP_URL, VIEW_TYPES } from 'Utils/constants'
import { getFileSystemId, getUserData, getWcoreOrganizationId } from 'Utils/localStorageHandlers/getter'

import { HeaderText } from '../../styles'
const { Text } = Typography
const QRCodeContainer = styled.div`
    position: absolute;
    bottom: 0;
    right: 40px;
    border: 1px solid #e6e6e6;
    padding: 5px 5px 1px 5px;
    margin-bottom: 18px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
      &:after {
        content: '';
        background: url(${IMAGES.download_img}) center no-repeat;
        background-repeat: no-repeat;
        background-size: 42px 26px;
        opacity: 1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  `

interface profileProps extends ApolloProviderProps<any> {
  history: History
  intl: any
}
interface profileState {
  imageUrl: string
  showUploader: any
  beneficiaryName: string
  accountNumber: string
  ifsc: string
  busiType: string
  organization: object
  primaryBankDetails: object
  gst: string
  pancard: string
  profileLoader: boolean
}

interface orgAddress {
  addressLine1?: string
  addressLine2?: string
  city?: string
  state?: string
  pinCode?: string
  country?: string
}

class Profile extends React.Component<profileProps, profileState> {
  imageData: object

  pmsOrgDetails: any

  userData: any

  pmsClient: ApolloClient<any>

  fileSystemId: string

  isBMSDealsOnlyView: boolean

  constructor(props) {
    super(props)
    this.imageData = {}
    this.userData = getUserData()
    this.pmsOrgDetails = getOrgDetails()
    this.pmsClient = ClientManager.getInstance().pms
    this.state = {
      imageUrl: null,
      showUploader: false,
      beneficiaryName: '',
      accountNumber: '',
      ifsc: '',
      busiType: '',
      organization: {},
      primaryBankDetails: {},
      gst: '',
      pancard: '',
      profileLoader: false
    }
  }

  componentDidMount() {
    this.getOrgDetails()
    this.getOrgLegalDocInfo()
    this.fileSystemId = getFileSystemId()
    const viewType = getViewType()

    this.isBMSDealsOnlyView = viewType === VIEW_TYPES.BMS_DEALS_ONLY

  }

  buildBankColumnsData = (bankDetails, busiType) => {
    const bankData = bankDetails.map(bank => {
      return {
        key: bank.id,
        ifsc: bank.ifscCode,
        beneficiaryName: bank.beneficiaryName,
        accountNumber: bank.accountNumber,
        busiType
      }
    })

    return bankData
  }

  async getOrgDetails() {
    const { businessType } = this.pmsOrgDetails
    const org_id = getWcoreOrganizationId()

    this.setState({ profileLoader: true })
    try {
      const response = await getOrganizationInfo(this.props.client, org_id)
      const data = response.data.organization

      this.setState({
        organization: data,
        imageUrl: data.brandLogo,
        busiType: businessType,
        profileLoader: false
      })
      this.getPrimaryBankDetails()
    } catch (err) {
      console.error(err)
      this.setState({
        profileLoader: false
      })
    }
  }

  getPrimaryBankDetails = async () => {
    const { id } = this.pmsOrgDetails

    /*
     * Let bankDataSource = this.buildBankColumnsData(
     *   data.bankAccount,
     *   data.businessType
     * );
     */
    try {
      const bankDetailsRes = await getPMSBankDetails(this.pmsClient, id)
      const bankDetails = bankDetailsRes?.data?.bankDetailsForOrg?.bankDetails

      if (bankDetails) {
        const { accountNo, beneficiaryName, ifscCode } = bankDetails
        const primaryBankDetails = {
          accountNumber: accountNo,
          beneficiaryName,
          ifscCode
        }

        this.setState({
          primaryBankDetails,
          profileLoader: false
        })
      }
    } catch (err) {
      this.setState({
        profileLoader: false
      })
      console.log('Get bank details error', err)
    }
  }

  async getOrgLegalDocInfo() {
    const org_id = getWcoreOrganizationId()

    try {
      const res = await getLegalOrganizationDoc(this.props.client, org_id)
      const data = res.data.getLegalOrganizationDocuments

      this.setState({
        gst: data[0] ? data[0].legalDocumentType : null,
        pancard: data[0] ? data[0].legalDocumentValue : null
      })
    } catch (err) {
      console.error(err)
    }
  }

  async getBankDetails() {
    const org_id = getWcoreOrganizationId()

    try {
      const response = await getBankDetails(this.props.client, org_id)
      const { data } = response.data.getBankAccounts

      if (data.length > 0) {
        this.setState({
          accountNumber: data[0].accountNumber,
          ifsc: data[0].ifscCode,
          beneficiaryName: data[0].beneficiaryName,
          busiType: data[0].accountType
        })
      }
    } catch (err) {
      console.error(err)
    }
  }

  showImageUpload() {

    if (!this.fileSystemId) {
      message.warn(
        intlAlertMessage({
          id: 'category.warnMsg.imgMissingforFilesystem',
          intl: this.props.intl
        })
      )
    } else {
      this.setState({
        showUploader: true
      })
    }
  }

  updateOrg = async data => {
    const organization = getOrgDetails()
    const org_id = getWcoreOrganizationId()
    const payload = {
      id: organization.id,
      orgLogo: data
    }

    try {
      const updateOrgRes = await updatePMSOrganization(this.pmsClient, payload)

      const orderxPayload = {
        id: org_id,
        brandLogo: data
      }

      if (updateOrgRes && updateOrgRes.data && updateOrgRes.data.updateOrganization) {
        const res = await updateOrderXOrganization(this.props.client, orderxPayload)
        const data = res.data.updateOrganization

        this.setState({ showUploader: false, imageUrl: data.brandLogo })
      }
    } catch (error) {
      console.log(error)
    }
  }

  fileUploadSave(data) {

    this.imageData = {
      publicUrl: data
    }
    this.updateOrg(data)
  }

  removeImageUpload = () => {
    const data = ''

    this.updateOrg(data)
  }

  fileUploadCancel(data) {
    this.setState({ showUploader: false })
  }

  render() {
    const { imageUrl, showUploader, organization, primaryBankDetails, pancard, gst, profileLoader } = this.state
    const { addressLine1, addressLine2, city, state, pinCode, country }: orgAddress = organization
    const miniURL = getOrgDetails()?.miniAppUrlLink
    const email = this.userData ? this.userData.email : ''

    return (
      profileLoader ? <Loader intl={this.props.intl} /> : (
        <Row className="profile-wrapper">
          <Col span={24}>
            <div className="customize-body-container">
              <Row>
                <Col offset={21} span={3} style={{ marginTop: 11 }}>
                  <a
                    className="menu-help"
                    href={PROFILE_INFORMATION_HELP_URL}
                    target="_blank"
                    rel="noopener noreferrer">
                    <IntlMessages id="menu.help" />
                  </a>
                </Col>
              </Row>
              <Row>
                <Col span={3}></Col>
                <Col span={15}>
                  <Card bordered={false} className="profile-card">
                    <HeaderText type="H3">
                      <IntlMessages id="profile.basicInfo" />
                    </HeaderText>
                    <Row className="sub-row">
                      <Col span={6}>
                        <Text>
                          <IntlMessages id="profile.brandImage" />
                        </Text>
                      </Col>
                      <Col span={4}>
                        {imageUrl === null || imageUrl.length < 20 ? (
                          <div
                            className="no-profile-image"
                            onClick={() => {
                              this.showImageUpload()
                            }}>
                            <span>
                              <IntlMessages id="category.message.clickHereToAttachTheImage" />
                            </span>
                          </div>
                        ) : (
                          <img
                            src={imageUrl}
                            className="profile-image"
                            onClick={() => {
                              this.showImageUpload()
                            }}
                            alt="Brand logo"
                          />
                        )}
                      </Col>
                      <Col span={9} className="profile-image-text">
                        <Text>
                          <IntlMessages id="imageUpload.imageDimensions" />:
                          512x512 px
                        </Text>
                        <br />
                        <Text>
                          <IntlMessages id="category.maximumFileSize" />: 1 MB
                        </Text>
                        <br />
                        <Text>
                          <IntlMessages id="component.imageUpload.imageProportion" />
                          : Square
                        </Text>
                        <br />
                        <Text>
                          <IntlMessages id="category.supportedImageFormat" />
                        </Text>
                      </Col>
                      <Col span={5} style={{ textAlign: 'right' }}>
                        {imageUrl !== null && imageUrl.length > 20 ? (
                          <span
                            className="apply-text"
                            onClick={() => {
                              this.removeImageUpload()
                            }}>
                            <IntlMessages id="category.removeImage" />
                          </span>
                        ) : (
                          <span
                            className="apply-text"
                            onClick={() => {
                              this.showImageUpload()
                            }}>
                            <IntlMessages id="category.attachImg" />
                          </span>
                        )}
                      </Col>
                      <FileUpload
                        key={randomString(4)}
                        title={<IntlMessages id="category.attachImg" />}
                        isVisible={showUploader}
                        fileSystemId={this.fileSystemId}
                        onSave={this.fileUploadSave.bind(this)}
                        onCancel={this.fileUploadCancel.bind(this)}
                        client={this.props.client}
                        allowedSizeInMB={1}
                        singleFile={true}
                        imgDimension={'512x512 px'}
                        fileSize={'1 MB'}
                        imgProportion={'Square'}
                        fileTypeList={['jpg', 'jpeg', 'png']}
                        accept="image/png, image/jpeg, image/jpg">
                        {this.props.children}
                      </FileUpload>
                    </Row>
                    <Row className="sub-row">
                      <Col span={6}>
                        <Text>
                          <IntlMessages id="profile.restaurantName" />
                        </Text>
                      </Col>
                      <Col span={18}>
                        <Text>{organization['name']}</Text>
                      </Col>
                    </Row>
                    <Row className="sub-row">
                      <Col span={6}>
                        <Text>
                          <IntlMessages id="appModule.email" />
                        </Text>
                      </Col>
                      <Col span={18}>
                        <Text>{email}</Text>
                      </Col>
                    </Row>
                    <Row className="sub-row">
                      <Col span={6}>
                        <Text>
                          <IntlMessages id="appModule.phone" />
                        </Text>
                      </Col>
                      <Col span={18}>
                        <Text>
                          {organization && organization['phoneNumber']}
                        </Text>
                      </Col>
                    </Row>
                  </Card>
                  {!this.isBMSDealsOnlyView && miniURL && (
                    <Card bordered={false} className="profile-card">
                      <HeaderText type="H2">
                        <IntlMessages id="profile.miniAppURL" />
                      </HeaderText>
                      <div style={{ lineHeight: '1px', marginTop: '10px' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                          }}>
                          <Text style={{ fontWeight: 500 }}>{miniURL}</Text>
                          <Button
                            type="link"
                            onClick={() => copyToClipboard(miniURL)}
                            style={{ marginBottom: 0, color: COLORS.PRIMARY }}>
                            Copy Link
                          </Button>
                        </div>
                        <br />
                        <div>
                          <ArrowRightOutlined
                            style={{
                              color: COLORS.DARK_TEXT,
                              marginRight: '5px',
                              fontWeight: 500
                            }}
                          />
                          <Text>
                            <IntlMessages id="onboarding.createMiniUrlSuccess2" />
                          </Text>
                        </div>
                      </div>
                      <QRCodeContainer onClick={() => downloadQR()}>
                        <QRCode
                          id="qr-code"
                          value={miniURL}
                          style={{ height: '80px', width: '80px' }}
                        />
                      </QRCodeContainer>
                    </Card>
                  )}
                  <Card bordered={false} className="profile-card">
                    <HeaderText type="H2">
                      <IntlMessages id="profile.organizationInfo" />
                    </HeaderText>
                    <Row className="sub-row">
                      <Col span={6}>
                        <Text>
                          <IntlMessages id="profile.legalName" />
                        </Text>
                      </Col>
                      <Col span={18}>
                        <Text>{organization['name']}</Text>
                      </Col>
                    </Row>
                    <Row className="sub-row">
                      <Col span={6}>
                        <Text>
                          <IntlMessages id="address" />
                        </Text>
                      </Col>
                      <Col span={18}>
                        <Text>{`${formatField(addressLine1)} ${formatField(
                          addressLine2
                        )}  ${formatField(city)} ${formatField(
                          state
                        )} ${formatField(pinCode)}  ${formatField(
                          country
                        )}`}</Text>
                      </Col>
                    </Row>
                    <Row className="sub-row">
                      <Col span={6}>
                        <Text>
                          <IntlMessages id="panNum" />
                        </Text>
                      </Col>
                      <Col span={18}>
                        <Text>{pancard ? pancard : '-'}</Text>
                      </Col>
                    </Row>
                    <Row className="sub-row">
                      <Col span={6}>
                        <Text>
                          <IntlMessages id="GSTNum" />
                        </Text>
                      </Col>
                      <Col span={18}>
                        <Text>{gst ? gst : '-'}</Text>
                      </Col>
                    </Row>
                  </Card>
                  <Card bordered={false} className="profile-card">
                    <Row className="sub-row">
                      <Col span={16}>
                        <HeaderText type="H2">
                          <IntlMessages id="accInfo" />
                        </HeaderText>
                      </Col>
                    </Row>
                    <Row className="sub-row">
                      <Col span={6}>
                        <Text>
                          <IntlMessages id="profile.businessType" />
                        </Text>
                      </Col>
                      <Col span={18}>
                        <Text>{organization['businessType']}</Text>
                      </Col>
                    </Row>
                    {/* <Row className="sub-row">
                      <Col span={6}>
                        <Text>BANK NAME</Text>
                      </Col>
                      <Col span={18}>
                        <Text>{primaryBankDetails['name']}</Text>
                      </Col>
                    </Row> */}
                    <Row className="sub-row">
                      <Col span={6}>
                        <Text>
                          <IntlMessages id="profile.bankAccNum" />
                        </Text>
                      </Col>
                      <Col span={18}>
                        <Text>{primaryBankDetails['accountNumber']}</Text>
                      </Col>
                    </Row>
                    <Row className="sub-row">
                      <Col span={6}>
                        <Text>
                          <IntlMessages id="IFSCCode" />
                        </Text>
                      </Col>
                      <Col span={18}>
                        <Text>{primaryBankDetails['ifscCode']}</Text>
                      </Col>
                    </Row>
                    <Row className="sub-row">
                      <Col span={6}>
                        <Text>
                          <IntlMessages id="profile.beneficiaryName" />
                        </Text>
                      </Col>
                      <Col span={18}>
                        <Text>{primaryBankDetails['beneficiaryName']}</Text>
                      </Col>
                    </Row>
                  </Card>
                  <div>
                    <span style={{ fontSize: '13px' }}>
                      {PROFILE.CHANGE_DETAIL_TEXT}
                    </span>
                    {/* <a
                      href={helpLink}
                      target="_blank"
                    > */}
                    <span style={{ fontWeight: 'bold' }}>
                      {PROFILE.HELP_EMAIL}
                    </span>
                    {/* </a> */}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>)
    )
  }
}

// @ts-ignore
export default injectIntl(withApollo(Profile))
