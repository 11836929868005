import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { getOrgDetails, getUserDetails } from 'Utils'
import { getUserData, setOnboarding } from 'Utils/onboardingUtils'

import CommonOnboardingCard from './commonOnboardingCard'

const OutletInfoCard = props => {
  const history = useHistory()
  const organization = getOrgDetails()
  const userDetails = getUserDetails()
  const [isStoreCreated, setIsStoreCreated] = useState(false)

  useEffect(() => {
    const { stores } = getUserData()
    const isStoreCreated = stores && stores.length > 0

    setIsStoreCreated(isStoreCreated)
  }, [isStoreCreated])

  const goToCreateStore = () => {
    setOnboarding()
    history.push({ pathname: '/orderx/store' })
  }

  const isDisable = !userDetails.catalogs?.length || !organization?.orgLogo

  return (
    <Row>
      <Col
        xs={{ span: 24 }}
        sm={{ span: 18, offset: 3 }}
        lg={{ span: 12, offset: 6 }}>
        <div className="mt-1">
          <CommonOnboardingCard
            intl={props.intl}
            pending={!isStoreCreated}
            onButtonClick={() => {
              goToCreateStore()
            }}
            disable={isDisable}
            title="onboarding.outletInfo"
            description={
              !isStoreCreated
                ? 'onboarding.outletInfoDesc'
                : 'onboarding.outletInfoDesc.success'
            }
            buttonText="configureYourStore"
            required={true}
          />
        </div>
      </Col>
    </Row>
  )
}

export default injectIntl(OutletInfoCard)
