import { Col, Form,Input, Row } from 'antd'
import { FormInstance } from 'antd/es/form'
import { Button, Card, IntlMessages, Title } from 'components'
import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { updatePMSBankDetails, updatePMSOrganization } from 'services'
import styled from 'styled-components'
import { getOrgDetails, intlAlertMessage, setOrgDetails, showSideBar } from 'Utils'
import { ALPHA_NUMERIC_WITHOUT_SPACE_REGEX, ORG_STATUS } from 'Utils/constants'
import { blockCopyPasteAccNumber, getUserData } from 'Utils/onboardingUtils'


const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .ant-row {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
`

const BankAccountCard = (props) => {
  const history = useHistory()
  const [confirmDirty, setConfirmDirty] = useState(false)
  const [accountNumber, setAccountNumber] = useState('')
  const [confirmAccNum, setConfirmAccNum] = useState('')
  const [ifscCode, setIfscCode] = useState('')
  const [beneficiaryName,  setBeneficiaryName] = useState('')
  const [addingBankAccount, setAddingBankAccount] = useState(false)
  const formRef = React.createRef<FormInstance>()
  const [organization, setOrgnization] = useState(getOrgDetails())
  const { stores, catalogs } = getUserData()
  const isStoreCreated = stores && stores.length
  const isMenuVerified = catalogs.length && catalogs[0].listable
  const readyToAddAccount = isMenuVerified && isStoreCreated && organization?.miniAppUrlLink
  
  useEffect(() => {
    blockCopyPasteAccNumber()
    const bankDetailsData = JSON.parse(localStorage.getItem('bankDetails'))

    formRef.current.setFieldsValue(bankDetailsData)
  }, [])

  

  const handleConfirmBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target

    setConfirmDirty(confirmDirty || Boolean(value))
  }

  const compareToAccountNumber = (_,value: any) => {

    if (value && value !== formRef.current.getFieldValue('accountNumber')) {
      return Promise.reject(
        new Error(intlAlertMessage({
          id: 'onboarding.errMsg.accountNumDoNotMatch',
          intl: props.intl
        }))
      )
    }

    return Promise.resolve()
  }

  if (
    accountNumber &&
    confirmAccNum &&
    beneficiaryName &&
    ifscCode
  ) {
    const bankDetails = {
      accountNumber,
      conAccountNumber: confirmAccNum,
      beneficiaryName,
      ifsc: ifscCode
    }

    localStorage.setItem('bankDetails', JSON.stringify(bankDetails))
  }
  const createBankAccount = async values => {
    setAddingBankAccount(true)
    savePmsBankDetails(values)
  }

  const savePmsBankDetails = async values => {
    const { beneficiaryName, ifsc, accountNumber } = values

    try {
      const bankDetailsInput = {
        id: organization.id,
        bankDetails: {
          accountNo: accountNumber,
          name: beneficiaryName.trim(),
          ifscCode: ifsc,
          beneficiaryName: beneficiaryName.trim()
        }
      }

      const updateBankDetailsRes = await updatePMSBankDetails(props.client, bankDetailsInput)

      if (updateBankDetailsRes?.data?.updateBankDetails?.success) {
        updateOnboardingStatus()
      }
    } catch (err) {
      console.log('Save bank details error', err)
      setAddingBankAccount(false)
    }
  }

  const updateOnboardingStatus = async () => {
    try {
      const payload = {
        id: organization.id,
        onboardingStatus: ORG_STATUS.ORDER_READY,
        status: ORG_STATUS.ACTIVE
      }
      const updateOrgRes = await updatePMSOrganization(props.client, payload)

      if (updateOrgRes && updateOrgRes.data && updateOrgRes.data.updateOrganization) {
        setOrgnization(updateOrgRes.data.updateOrganization.organization)
        setAddingBankAccount(false)
        setOrgDetails(updateOrgRes.data.updateOrganization.organization)
        localStorage.removeItem('isOnboarding')
        showSideBar()
        history.push({
          pathname: '/orderx/orders',
          state: {
            isOnboarding: true
          }
        })
      }
    } catch (err) {
      setAddingBankAccount(false)
      console.log('Update onboarding status error', err)
    }
  }

  return (
    <Row>
      <Col xs={{ span: 24 }} sm={{ span: 18, offset: 3 }} lg={{ span: 12, offset: 6 }}>
        <Form
      ref={formRef}
      onFinish={async values => createBankAccount(values)}
      className="mt-1">
          <Card>
            <Title level={'h3'}>
              <IntlMessages id={'onboarding.bankAccountCard.title'} />
            </Title>
            <StyledRow>
              <div style={{ flexGrow: 1, flexBasis: '50%' }}>
                <div className="input-label">
                  <IntlMessages id="onboarding.accNum" />
                  <span style={{ marginLeft: '3px' }}>*</span>
                </div>
                <Form.Item
              name="accountNumber"
              rules={ [
                {
                  required: true,
                  message: (
                    <IntlMessages
                      id="error.onboarding.bankAccountCard.accountNumber.required"
                      key="acc-required-err"
                    />
                  )
                },
                {
                  pattern: ALPHA_NUMERIC_WITHOUT_SPACE_REGEX,
                  message: (
                    <IntlMessages
                      id="error.onboarding.bankAccountCard.accountNumber.invalid"
                      key="acc-invalid-err"
                    />
                  )
                }
              ]}
            >
                  <Input
                onChange={e =>
                  setAccountNumber(e.target.value)
                }
                id="add-bank-account_accountNumber"
                placeholder={intlAlertMessage({
                  id: 'onboarding.accNum',
                  intl: props.intl
                })}
              />
                </Form.Item>
              </div>
              <div style={{ flexGrow: 1, marginLeft: '50px', flexBasis: '50%' }}>
                <div className="input-label">
                  <IntlMessages id="onboarding.confirmAccNum" />
                  <span style={{ marginLeft: '3px' }}>*</span>
                </div>
                <Form.Item
              name="conAccountNumber"
              rules={[
                {
                  required: true,
                  message: (
                    <IntlMessages
                      id="error.onboarding.bankAccountCard.conAccountNumber.required"
                      key="conacc-required-err"
                    />
                  )
                },
                {
                  pattern: ALPHA_NUMERIC_WITHOUT_SPACE_REGEX,
                  message: (
                    <IntlMessages
                      id="error.onboarding.bankAccountCard.conAccountNumber.invalid"
                      key="conacc-invalid-err"
                    />
                  )
                },
                {
                  validator: compareToAccountNumber
                }
              ]}
              hasFeedback
            >
                  <Input
                id="add-bank-account_confirmAccountNumber"
                onChange={e =>
                  setConfirmAccNum(e.target.value)
                }
                onBlur={handleConfirmBlur}
                placeholder={intlAlertMessage({
                  id: 'onboarding.confirmAccNum',
                  intl: props.intl
                })}
              />
                </Form.Item>
              </div>
            </StyledRow>
            <StyledRow style={{ marginTop: 16 }}>
              <div style={{ flexGrow: 1, flexBasis: '50%' }}>
                <div className="input-label">
                  <IntlMessages id="IFSCCode" />
                  <span style={{ marginLeft: '3px' }}>*</span>
                </div>
                <Form.Item
              name="ifsc"
              rules={[
                {
                  required: true,
                  message: (
                    <IntlMessages
                      id="error.onboarding.bankAccountCard.ifsc.required"
                      key="ifsc-required-err"
                    />
                  )
                },
                {
                  pattern: ALPHA_NUMERIC_WITHOUT_SPACE_REGEX,
                  message: (
                    <IntlMessages
                      id="error.onboarding.bankAccountCard.ifsc.invalid"
                      key="ifsc-invalid-err"
                    />
                  )
                }
              ]}
            >
                  <Input
                onChange={e => setIfscCode(e.target.value)}
                placeholder={intlAlertMessage({
                  id: 'IFSCCode',
                  intl: props.intl
                })}
              />
                </Form.Item>
              </div>

              <div style={{ flexGrow: 1, marginLeft: '50px', flexBasis: '50%' }}>
                <div className="input-label">
                  <IntlMessages id="onboarding.beneficiaryName" />
                  <span style={{ marginLeft: '3px' }}>*</span>
                </div>
                <Form.Item
              name="beneficiaryName"
              rules={[
                {
                  required: true,
                  message: (
                    <IntlMessages
                      id="error.onboarding.bankAccountCard.beneficiaryName.required"
                      key="ben-required-err"
                    />
                  )
                },
                {
                  min: 4,
                  message: (
                    <IntlMessages
                      id="error.onboarding.bankAccountCard.beneficiaryNameMustBe4Char"
                      key="ben-min-err"
                    />
                  )
                },
                {
                  whitespace: true,
                  message: (
                    <IntlMessages
                      id="error.onboarding.bankAccountCard.beneficiaryName.invalid"
                      key="ben-invalid-err"
                    />
                  )
                }
              ]}
            >
                  <Input
                onChange={e =>
                  setBeneficiaryName(e.target.value)
                }
                placeholder={intlAlertMessage({
                  id: 'onboarding.beneficiaryName',
                  intl: props.intl
                })}
              />
                </Form.Item>
              </div>
            </StyledRow>
          </Card>
          <div className="mt-2">
            <Button
          type="primary"
          htmlType="submit"
          loading={addingBankAccount}
          disabled={!readyToAddAccount}>
              <IntlMessages id="button.letsGetStarted" />
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  )
}


export default injectIntl(BankAccountCard)
