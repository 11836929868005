import { Col, Row } from 'antd'
import { IntlMessages, Title } from 'components'
import React from 'react'
import { COLORS } from 'Utils/constants'

const OnboardingHeader = () => {
  return (
    <div className="onboarding-header">
      <Row style={{ height: '37vh', alignItems: 'center' }}>
        <Col offset={3} span={14}>
          <div style={{ width: 300 }}>
            <Title level="h1" style={{ color: COLORS.BG_WHITE }}>
              <IntlMessages id="joinPeppo" />
            </Title>
          </div>
          <div style={{ width: 450 }}>
            <Title level="h4" style={{ color: COLORS.BG_WHITE }}>
              <IntlMessages id="subTitleText" />
            </Title>
          </div>
          {/* <div>
            {' '}
            <Button type="primary">
              <IntlMessages id="seeOurGuide" />
            </Button>
          </div> */}
        </Col>
      </Row>
    </div>
  )
}

export default OnboardingHeader
