import { DAYS_ENUM, WEEK_DAYS_SORTER } from 'Utils/constants'

export const getParsedMenuTimings = menuTimings => {
  const parsedMenuTimings = menuTimings.map(time => {
    try {
      time.days = JSON.parse(time.days)
    } catch (e) {
      // Console.error("Json is already parsed");
    }

    return time
  })

  DAYS_ENUM.forEach(day => {
    if (!dayExistsInWeek(day, parsedMenuTimings)) {
      const missingDay = {
        days: [day.toUpperCase()],
        data: []
      }

      parsedMenuTimings.push(missingDay)
    }
  })

  return parsedMenuTimings
}

export const getSortedWeekDays = weekDays => {
  weekDays.sort(function sortByDay(a, b) {
    const weekDay1 = a.days[0].toLowerCase()
    const weekDay2 = b.days[0].toLowerCase()

    return WEEK_DAYS_SORTER[weekDay1] - WEEK_DAYS_SORTER[weekDay2]
  })

  return weekDays
}

export const dayExistsInWeek = (day, weekDays) => {
  let dayfound = false

  weekDays.forEach(weekDay => {
    if (weekDay.days[0].toLowerCase() === day.toLowerCase()) {
      dayfound = true
    }
  })

  return dayfound
}

export const isNewTimeGroup = (savedTimeGroups, timeGroupCode) => {
  return savedTimeGroups.indexOf(timeGroupCode) === -1
}

export const isNoTimeSlot = menuTimings => {
  let isNoSlot = true

  menuTimings.forEach(day => {
    if (day.data && day.data.length > 0) {
      isNoSlot = false
    }
  })

  return isNoSlot
}

export const getUpdatedTimeGroups = (timeGroups, updatedTimeGroup) => {
  let updatedTimeGroups = []

  if (!updatedTimeGroup) {
    updatedTimeGroups = [...timeGroups]
  }
  if (!timeGroups.length) {
    updatedTimeGroups = [updatedTimeGroup]
  } else {
    const timeGroupIndex = timeGroups.findIndex(timeGroup => {
      return timeGroup.code.toLowerCase() === updatedTimeGroup.code.toLowerCase()
    })

    updatedTimeGroups = [...timeGroups]
    updatedTimeGroups.splice(timeGroupIndex, 1, updatedTimeGroup)
  }

  return updatedTimeGroups
}

export const updateTimeGroupTimings = timeGroup => {
  const updatedTimeGroup = { ...timeGroup }

  updatedTimeGroup.timings = getParsedMenuTimings(updatedTimeGroup.timings)
  updatedTimeGroup.timings = getSortedWeekDays(updatedTimeGroup.timings)

  return updatedTimeGroup
}

export const getNewTimeSlots = timeGroup => {
  const timeSlots = []

  timeGroup.timings.forEach(day => {
    const newTimeSlots = day.data.filter(slot => {
      return !slot.id
    })

    if (newTimeSlots.length) {
      timeSlots.push({
        days: day.days[0],
        data: newTimeSlots
      })
    }
  })

  return timeSlots
}

export const getModifiedTimeSlots = timeGroup => {
  const timeSlots = []

  timeGroup.timings.forEach(day => {
    const modifiedTimeSlots = day.data.filter(slot => {
      return Boolean(slot.id)
    })

    if (modifiedTimeSlots.length) {
      modifiedTimeSlots.forEach(modifiedSlot => {
        timeSlots.push({
          id: modifiedSlot.id,
          days: day.days[0],
          openTime: modifiedSlot.openTime,
          closeTime: modifiedSlot.closeTime
        })
      })
    }
  })

  return timeSlots
}

export const updateTimeGroupWithAddedTimeSlot = (originalTimeGroup, timeGroupWithAddedTimeSlot) => {
  const updatedTimeGroupWithAddedTimeSlot = { ...originalTimeGroup }

  updatedTimeGroupWithAddedTimeSlot.timings.forEach(day => {
    const matchedDay = timeGroupWithAddedTimeSlot.timings.find(d => {
      return d.days[0].toLowerCase() === day.days[0].toLowerCase()
    })

    if (matchedDay) {
      day.data.forEach(slot => {
        const matchedSlot = matchedDay.data.find(timeSlot => {
          return timeSlot.openTime === slot.openTime && timeSlot.closeTime === slot.closeTime
        })

        if (matchedSlot) {
          slot.id = matchedSlot.id
        }
      })
    }
  })

  return updatedTimeGroupWithAddedTimeSlot
}

export const checkForDuplicateAndOverlapTimeSlot = (timeGroup, timeSlot, selectedSlotIndex) => {
  const slotStatus = { isDuplicate: false, isOverlap: false }
  const matchedDay = timeGroup.timings.find(day => {
    return day.days[0].toLowerCase() === timeSlot.days.toLowerCase()
  })

  if (matchedDay) {
    const slots = matchedDay.data.filter((d, k) => k !== selectedSlotIndex)

    slots.forEach((slot, j) => {
      if (slot.openTime === timeSlot.data.openTime && slot.closeTime === timeSlot.data.closeTime) {
        slotStatus.isDuplicate = true

        return
      }
      if ((timeSlot.data.openTime >= slot.openTime && timeSlot.data.openTime <= slot.closeTime) || (timeSlot.data.closeTime >= slot.openTime && timeSlot.data.closeTime <= slot.closeTime) || (timeSlot.data.openTime <= slot.openTime && timeSlot.data.closeTime >= slot.closeTime)) {
        slotStatus.isOverlap = true
      }
    })
  }

  return slotStatus
}

export const getTimeGroupWithModifiedTime = (timeGroup, timeSlot, selectedSlotIndex) => {
  timeGroup.timings = timeGroup.timings.map(day => {
    if (day.days[0].toLowerCase() === timeSlot.days.toLowerCase()) {
      day.data = day.data.map((slot, slotIndex) => {
        if (slotIndex === selectedSlotIndex) {
          slot.openTime = timeSlot.data.openTime
          slot.closeTime = timeSlot.data.closeTime
        }

        return slot
      })
    }

    return day
  })

  return timeGroup
}

export const getTimeGroupWithRemovedTime = (timeGroup, timeSlot, selectedSlotIndex) => {
  timeGroup.timings = timeGroup.timings.map(day => {
    if (day.days[0].toLowerCase() === timeSlot.days.toLowerCase()) {
      day.data.splice(selectedSlotIndex, 1)
    }

    return day
  })

  return timeGroup
}

export const getAllTimeSlotIds = timeGroup => {
  const slotIds = []

  timeGroup.timings = timeGroup.timings.forEach(day => {
    day.data.forEach(timeSlot => {
      if (timeSlot.id) {
        slotIds.push(timeSlot.id)
      }
    })
  })

  return slotIds
}

export const getTimeGroupsWithRemovedGroup = (timeGroups, timeGroupToRemove) => {
  const timeGroupIndex = timeGroups.findIndex(timeGroup => timeGroup.code.toLowerCase() === timeGroupToRemove.code.toLowerCase())

  timeGroups.splice(timeGroupIndex, 1)

  return timeGroups
}

export const addToSavedTimeGroups = (savedTimeGroups, newTimeGroupCode) => {
  if (savedTimeGroups.indexOf(newTimeGroupCode) === -1) {
    savedTimeGroups.push(newTimeGroupCode)
  }

  return savedTimeGroups
}

export const removeFromSavedTimeGroups = (savedTimeGroups, timeGroupCodeToRemove) => {
  const indexToRemove = savedTimeGroups.indexOf(timeGroupCodeToRemove)

  if (indexToRemove !== -1) {
    savedTimeGroups.splice(indexToRemove, 1)
  }

  return savedTimeGroups
}
