import { IntlMessages, Text } from 'components'
import React from 'react'
import { COLORS } from 'Utils/constants'


export const ErrorMsg = ({ msg }) => {
  return (
    <Text level="caption" style={{ color: COLORS.ERROR }}>
      <IntlMessages id={msg} />
    </Text>
  )
}