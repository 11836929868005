import { getUserData } from 'Utils/localStorageHandlers/getter'

function MetabaseReports({
  dashboardURL,
  isTitled = true,
  storeName = '',
  startDate = '',
  endDate = ''
}) {
  const org = getUserData()

  return (
    <div>
      <iframe
        src={`${dashboardURL}?org_id=${org.organization.id}&store_name=${storeName}&start_dt=${startDate}&end_dt=${endDate}#hide_parameters=org_id,store_name,start_dt,end_dt&titled=${isTitled}`}
        frameBorder="0"
        width="100%"
        height="500"
        style={{ backgroundColor: 'transparent' }}></iframe>
    </div>
  )
}

export default MetabaseReports
