import React from 'react'
import styled from 'styled-components'
import theme from 'styled-theming'
import { COLORS } from 'Utils/constants'
// Import PropTypes from 'prop-types';

export interface TextButtonProps {
  /**
   * Button label
   */
  label: string
  /**
   * Can be set to primary, secondary, danger˙
   */
  type?: 'primary' | 'danger' | 'secondary'
  /**
   * Disabled state of button
   */
  disabled?: boolean
  /**
   * How large should the button be?
   */
  size?: 'small' | 'default' | 'large'
  /**
   * Optional click handler
   */
  onClick?: () => void
  /**
   * Apply any style
   */
  style?: any
}

const textColor = theme.variants('mode', 'type', {
  primary: { light: COLORS.PRIMARY },
  secondary: { light: COLORS.DARK_TEXT },
  danger: { light: COLORS.ERROR }
})
const textHoverColor = theme.variants('mode', 'type', {
  primary: { light: COLORS.PRIMARY_DARK },
  secondary: { light: COLORS.DARK_TEXT },
  danger: { light: COLORS.ERROR_DARK }
})
const fontSize = theme.variants('mode', 'size', {
  small: { light: '13px' },
  large: { light: '16px' }
})
const buttonHeight = theme.variants('mode', 'size', {
  small: { light: '26px' },
  default: { light: '35px' },
  large: { light: '45px' }
})
const buttonPadding = theme.variants('mode', 'size', {
  small: { padding: '0 4px' },
  default: { light: '0 8px' },
  large: { light: '0 12px' }
})
const TButton = styled.button`
    color: ${textColor};
    cursor: pointer;
    background: transparent;
    border: none;
    font-family: Roboto, Medium;
    height: ${buttonHeight};
    padding: ${buttonPadding};
    &:hover {
      color: ${textHoverColor};
    }
    &:disabled {
      color: ${COLORS.GRAY_TEXT};
      cursor: not-allowed;
    }
    &.text-btn-small {
      font-size: ${fontSize};
    }
    &.text-btn-large {
      font-size: ${fontSize};
    }
    &.text-btn-secondary {
      &:hover {
        background: ${COLORS.DISABLED};
      }
    }
  `

/**
 * Peppo Text Button UI component for user interaction
 */
export const TextButton: React.FC<TextButtonProps> = ({ type = 'primary', disabled = false, size = 'default', label = 'Text Button', ...props }) => {
  return (
    <TButton type={type} className={`text-btn-${type} text-btn-${size}`} disabled={disabled} onClick={() => props.onClick()} size={size} {...props}>
      {label}
    </TButton>
  )
}
