import { gql } from '@apollo/client'
export const UPDATE_PRODUCT_SORT_SEQUENCE = gql`
  mutation updateProductCategorySortSeq(
    $input: UpdateProductCategorySortInput
  ) {
    updateProductCategorySortSeq(input: $input) {
      id
      product {
        id
        name
        code
        status
        listable
        imageUrl
        description
        extend
      }
      sortSeq
    }
  }
`

export const UPDATE_CATEGORY_SORT_SEQUENCE = gql`
  mutation updateCategorySortSeq($input: UpdateCategorySortSeqInput!) {
    updateCategorySortSeq(input: $input) {
      id
      name
      description
      code
      imageUrl
      sortSeq
      listable
      status
    }
  }
`

export const UPDATE_OPTION_SORT_SEQUENCE = gql`
  mutation updateOptionSortSeq($input: updateOptionSeqInput) {
    updateOptionSortSeq(input: $input) {
      id
      name
      description
      code
      optionValues {
        id
        value
        option {
          id
          name
          code
        }
      }
      externalOptionId
      sortSeq
    }
  }
`

export const UPDATE_OPTION_VALUE_SORT_SEQ = gql`
  mutation updateOptionValueSortSeq($input: updateOptionValueSeqInput) {
    updateOptionValueSortSeq(input: $input) {
      id
      value
      code
      sortSeq
      option {
        id
        name
        code
      }
    }
  }
`

export const GET_DISCOUNT_TYPES = gql`
  query discountTypes($input: DiscountTypesInput) {
    discountTypes(input: $input) {
      id
      name
      discountTypeCode
    }
  }
`
export const CREATE_TAG = gql`
  mutation createTag($input: TagInput){
    createTag(input: $input) {
      id
      tagName
      code
      active
      tagType
      organization {
        id
        code
      }
    }
  }
`

export const GET_DISCOUNT_VALUES = gql`
  query productDiscountValues($filter: ProductDiscountValueFilter) {
    productDiscountValues(
      filter: $filter
      pageOptions: { page: 1, pageSize: 1000 }
    ) {
      data {
        id
        discountValue
        product {
          id
        }
        channel {
          id
          name
        }
      }
    }
  }
`

export const ADD_DISCOUNT_TYPE = gql`
  mutation createDiscountType($input: DiscountTypeCreateInput) {
    createDiscountType(input: $input) {
      id
      name
      discountTypeCode
    }
  }
`

export const UPDATE_PRICE_FOR_PRODUCTS = gql`
  mutation updatePriceValueForProducts($input: [ProductPriceValueUpdateInput]) {
    updatePriceValueForProducts(input: $input) {
      id
      product {
        id
        name
      }
      priceValue
      basePrice
    }
  }
`

export const UPDATE_DISCOUNT_FOR_PRODUCTS = gql`
  mutation updateDiscountValueForProducts(
    $input: [ProductDiscountValueUpdateInput]
  ) {
    updateDiscountValueForProducts(input: $input) {
      id
      product {
        id
        name
      }
      discountValue
      discountValueType
    }
  }
`

export const CREATE_DISCOUNT_FOR_PRODUCTS = gql`
  mutation createDiscountValueForProducts($input: [ProductDiscountValueInput]) {
    createDiscountValueForProducts(input: $input) {
      data {
        id
        product {
          id
          name
        }
        discountValue
        discountValueType
      }
    }
  }
`

export const UPDATE_CHARGE_FOR_PRODUCTS = gql`
  mutation updateChargeValueForProducts($input: [ProductValueUpdateInput]) {
    updateChargeValueForProducts(input: $input) {
      id
      product {
        id
        name
      }
      chargeValue
      chargeType {
        id
        name
      }
    }
  }
`
export const MENU_DISCOUNT_VALUE = gql`
  query productDiscountValue($filter: ProductDiscountValue) {
    productDiscountValue(filter: $filter) {
      id
      discountValueType
      discountValue
      product {
        id
      }
    }
  }
`

export const CREATE_MENU_DISCOUNT_VALUE = gql`
  mutation createDiscountValueForProduct($input: ProductDiscountValueInput) {
    createDiscountValueForProduct(input: $input) {
      id
      discountValue
    }
  }
`
export const GET_CATALOG_WITH_CATEGORIES_AND_PRODUCTS = gql`
  query catalog($id: ID!) {
    catalog(id: $id) {
      categories {
        id
        name
        code
        status
        listable
        sortSeq
        productType
        products {
          id
          name
          code
        }
        menuTimings {
          name
          code
          timings {
            days
            data {
              id
              openTime
              closeTime
            }
          }
        }
      }
    }
  }
`
export const GET_ALL_CATEGORIES = gql`
  query categories($catalogId: ID) {
    categories(catalogId: $catalogId) {
      id
      name
      code
      status
      listable
      sortSeq
      productType
      products {
        id
        name
        code
        tags {
          tag {
            id
            tagName
            code
            tagType
          }
        }
      }
      menuTimings {
        name
        code
        timings {
          days
          data {
            id
            openTime
            closeTime
          }
        }
      }
    }
  }
`

export const CREATE_PRICE_FOR_PRODUCTS = gql`
  mutation createPriceValueForProducts($input: [ProductPriceValueInput]) {
    createPriceValueForProducts(input: $input) {
      id
      priceValue
      product {
        id
        name
      }
    }
  }
`

export const GET_ALL_PRODUCTS = gql`
  query($input: ProductSearchInput!) {
    products(input: $input) {
      id
      name
      description
      status
      imageUrl
      productType
      listable
      extend
      variants {
        id
        sku
        product {
          id
          code
          name
        }
      }
    }
  }
`

export const GET_PRODUCT_PRICE_VALUES = gql`
  query productPriceValues($filter: ProductPriceValueFilter) {
    productPriceValues(
      filter: $filter
      pageOptions: { page: 1, pageSize: 1000 }
    ) {
      data {
        id
        priceValue
        basePrice
        product {
          id
        }
        storeFormat {
          id
        }
        channel {
          id
          name
        }
      }
    }
  }
`

export const CREATE_PRODUCT = gql`
  mutation createProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      id
      name
      code
      description
      imageUrl
      status
      listable
      productType
      extend
      tags {
        tag {
          id
          tagName
          code
          tagType
          active
        }
        id
      }
      productRelationShip {
        id
        parent {
          id
          name
        }
        child {
          id
          name
          options {
            id
            name
            optionValues {
              id
              value
            }
          }
          extend
        }
        parentType
        childType
        relationship
        config
        product {
          menuTimings {
            name
            code
            timings {
              days
              data {
                id
                openTime
                closeTime
              }
            }
          }
        }
      }
    }
  }
`

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      id
      name
      code
      description
      imageUrl
      status
      listable
      productType
      extend
      tags {
        tag {
          id
          tagName
          code
          tagType
          active
        }
        id
      }
      menuTimings {
        name
        code
        timings {
          days
          data {
            id
            openTime
            closeTime
          }
        }
      }
      options {
        id
        name
        optionValues {
          id
          value
        }
      }
      variants {
        id
        product {
          id
          name
        }
        optionValues {
          id
          value
        }
      }
      productPrices {
        productChargeValues {
          id
          product {
            id
            name
          }
          storeFormat {
            id
            name
          }
          channel {
            id
            name
          }
          chargeValue
          chargeType {
            id
            name
          }
        }
        productPriceValues {
          id
          product {
            id
            name
          }
          storeFormat {
            id
            name
          }
          channel {
            id
            name
          }
          priceValue
        }

        productDiscountValues {
          id
          product {
            id
            name
          }
          storeFormat {
            id
            name
          }
          channel {
            id
            name
          }
          discountValue
          discountType {
            id
            name
          }
        }
      }
      productRelationShip {
        id
        parent {
          id
          name
        }
        child {
          id
          name
          options {
            id
            name
            optionValues {
              id
              value
            }
          }
          extend
          menuTimings {
            name
            code
            timings {
              days
              data {
                id
                openTime
                closeTime
              }
            }
          }
          productPrices {
            productChargeValues {
              id
              product {
                id
                name
              }
              storeFormat {
                id
                name
              }
              channel {
                id
                name
              }
              chargeValue
              chargeType {
                id
                name
              }
            }
            productPriceValues {
              id
              product {
                id
                name
              }
              storeFormat {
                id
                name
              }
              channel {
                id
                name
              }
              priceValue
            }

            productDiscountValues {
              id
              product {
                id
                name
              }
              storeFormat {
                id
                name
              }
              channel {
                id
                name
              }
              discountValue
              discountType {
                id
                name
              }
            }
          }
        }
        parentType
        childType
        relationship
        config
      }
    }
  }
`

export const CREATE_PRICE_FOR_PRODUCT = gql`
  mutation createPriceValueForProduct($input: ProductPriceValueInput!) {
    createPriceValueForProduct(input: $input) {
      id
      priceValue
      product {
        id
        name
      }
    }
  }
`

export const UPDATE_PRICE_FOR_PRODUCT = gql`
  mutation updatePriceValueForProduct($input: ProductPriceValueUpdateInput!) {
    updatePriceValueForProduct(input: $input) {
      id
      priceValue
      product {
        id
        name
      }
    }
  }
`

export const CREATE_CHARGE_FOR_PRODUCT = gql`
  mutation createChargeValueForProduct($input: ProductValueInput!) {
    createChargeValueForProduct(input: $input) {
      id
      chargeValue
      chargeType {
        id
        name
      }
      product {
        id
        name
      }
    }
  }
`

export const UPDATE_CHARGE_FOR_PRODUCT = gql`
  mutation updateChargeValueForProduct($input: ProductValueUpdateInput!) {
    updateChargeValueForProduct(input: $input) {
      id
      chargeValue
      chargeType {
        id
        name
      }
      product {
        id
        name
      }
    }
  }
`

export const REMOVE_CHARGE_FOR_PRODUCT = gql`
  mutation removeProductChargeValue($id: ID!) {
    removeProductChargeValue(id: $id) {
      id
      product {
        id
        name
      }
      storeFormat {
        id
        name
      }
      chargeValue
      chargeType {
        id
        name
      }
    }
  }
`

export const ADD_TAGS_TO_PRODUCT = gql`
  mutation addTagsToProduct($input: ProductTagInput!) {
    addTagsToProduct(input: $input) {
      id
      tag {
        id
        tagName
        code
        active
      }
      product {
        id
        name
      }
    }
  }
`

export const REMOVE_TAGS_FROM_PRODUCT = gql`
  mutation removeTagsFromProduct($input: ProductTagUpdateInput!) {
    removeTagsFromProduct(input: $input) {
      id
      tag {
        id
        tagName
        code
        active
      }
      product {
        id
        name
      }
    }
  }
`

export const GET_PRODUCT_BY_CATEGORY = gql`
  query productCategoriesByCategoryId($categoryId: ID!) {
    productCategoriesByCategoryId(categoryId: $categoryId) {
      id
      product {
        id
        name
        code
        status
        listable
        imageUrl
        description
        extend
        productType
        tags {
          tag {
            id
            tagName
            code
            tagType
            active
          }
          id
        }
        options {
          id
          name
          optionValues {
            id
            value
          }
        }
        variants {
          id
          product {
            id
            name
          }
          optionValues {
            id
            value
          }
        }
        productPrices {
          productChargeValues {
            id
            product {
              id
              name
            }
            storeFormat {
              id
              name
            }
            channel {
              id
              name
            }
            chargeValue
            chargeType {
              id
              name
            }
          }
          productPriceValues {
            id
            product {
              id
              name
            }
            storeFormat {
              id
              name
            }
            channel {
              id
              name
            }
            priceValue
            basePrice
          }

          productDiscountValues {
            id
            product {
              id
              name
            }
            storeFormat {
              id
              name
            }
            channel {
              id
              name
            }
            discountValue
            discountType {
              id
              name
            }
          }
        }
        productRelationShip {
          id
          parent {
            id
            name
          }
          child {
            id
            name
            options {
              id
              name
              optionValues {
                id
                value
              }
            }
            extend
            menuTimings {
              name
              code
              timings {
                days
                data {
                  id
                  openTime
                  closeTime
                }
              }
            }
            productPrices {
              productChargeValues {
                id
                product {
                  id
                  name
                }
                storeFormat {
                  id
                  name
                }
                channel {
                  id
                  name
                }
                chargeValue
                chargeType {
                  id
                  name
                }
              }
              productPriceValues {
                id
                product {
                  id
                  name
                }
                storeFormat {
                  id
                  name
                }
                channel {
                  id
                  name
                }
                priceValue
              }

              productDiscountValues {
                id
                product {
                  id
                  name
                }
                storeFormat {
                  id
                  name
                }
                channel {
                  id
                  name
                }
                discountValue
                discountType {
                  id
                  name
                }
              }
            }
          }
          parentType
          childType
          relationship
          config
        }
      }
      sortSeq
      product {
        menuTimings {
          name
          code
          timings {
            days
            data {
              id
              openTime
              closeTime
            }
          }
        }
      }
    }
  }
`

export const PRODUCT_BY_CATEGORY_ID = gql`
  query productCategoriesByCategoryId($categoryId: ID!) {
    productCategoriesByCategoryId(categoryId: $categoryId) {
		product {
			id
			name
			code
			status
			listable
			productType
		}
	}
  }
`

export const GET_CATALOGS = gql`
  query catalogs($organizationId: ID!) {
    catalogs(organizationId: $organizationId) {
      id
      name
      catalogCode
      listable
    }
  }
`

export const GET_STORE_CATALOGS = gql`
  query getStoreCatalog($filter: StoreCatalogFilter) {
    getStoreCatalog(filter: $filter) {
      catalog {
        id
        name
        catalogCode
        listable
      }
    }
  }
`

export const GET_CATALOG_DETAIL = gql`
  query catalog($id: ID!) {
    catalog(id: $id) {
      id
      name
      listable
      catalogCode
      description
      status
      categories {
        id
        name
        description
        code
        status
        imageUrl
        listable
        sortSeq
        products {
          id
          name
          code
          status
          listable
          productType
		    }
        productType
      }
    }
  }
`

export const UPDATE_CATALOG = gql`
  mutation updateCatalog($input: UpdateCatalogInput!) {
    updateCatalog(input: $input) {
      id
      name
      catalogCode
      listable
      status
      description
    }
  }
`

export const DELETE_CATALOG = gql`
  mutation deleteCatalog($id: ID!, $organizationId: ID!) {
    deleteCatalog(id: $id, organizationId: $organizationId) {
      id
      name
      catalogCode
      listable
      status
      description
      listable
    }
  }
`

export const CREATE_CATEGORY = gql`
  mutation createCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      id
      name
      description
      code
      imageUrl
      listable
      status
      sortSeq
      productType
    }
  }
`

export const UPDATE_CATEGORY = gql`
  mutation updateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      id
      name
      description
      code
      imageUrl
      sortSeq
      listable
      status
    }
  }
`

export const ADD_TAXES_FOR_PRODUCT = gql`
  mutation linkTaxValuesForProduct($input: LinkProductTaxTypeValues) {
    linkTaxValuesForProduct(input: $input) {
      id
      taxValue
      taxLevel {
        id
        name
        taxTypeCode
      }
    }
  }
`

export const ADD_CHARGES_FOR_PRODUCT = gql`
  mutation linkChargeValuesForProduct(
    $input: LinkProductChargeValueForProduct
  ) {
    linkChargeValuesForProduct(input: $input) {
      id
      chargeType {
        id
        name
        chargeTypeCode
      }
      chargeValue
    }
  }
`

export const STORE_INVENTORY = gql`
  query storeInventory($input: storeInventoryFilter!) {
    storeInventory(input: $input) {
      product {
        id
        code
        name
        description
        imageUrl
        listable
      }
      inventoryAvailable
    }
  }
`

export const REPUBLISH_CATALOG = gql`
  query republishCatalog($codes: [String]!) {
    republishCatalog(codes: $codes)
  }
`
export const GET_PRODUCT_TAX_VALUES = gql`
query productTaxTypeValues($filter: ProductTaxTypeValueFilter) {
  productTaxTypeValues(filter: $filter) {
    data {
      id
      taxValue
      taxLevel {
        id
        name
        taxTypeCode
      }
    }
  }
}
`

export const GET_PRODUCT_CHARGE_VALUES = gql`
query productChargeValues(
	$filter: ProductValueFilter
	$pageOptions: PageOptions!
) {
	productChargeValues(filter: $filter, pageOptions: $pageOptions) {
		data {
      id
			chargeValue
			chargeType {
				id
				name
				chargeTypeCode
			}
		}
	}
}
`