import { CaretDownOutlined } from '@ant-design/icons'
import { Checkbox, Col, Collapse, Row } from 'antd'
import {
  Button,
  CheckboxGroup,
  IntlMessages,
  Modal,
  MoveUpDown,
  TextButton,
  Title
} from 'components'
import React, { useState } from 'react'
import { intlAlertMessage } from 'Utils'
import { COLORS, MENU_TABS, PRODUCT_TYPE_ENUM } from 'Utils/constants'
import { hideItemUpDownArrow, setItemUpDownArrowPosition } from 'Utils/menuUtils/customizeHelper'

import {
  getAddonsGroupWithSelection,
  getSelectedAddonsGroups,
  selectedAddonValidateSelection
} from '../../../Utils/menuUtils/commonFunc'
import {
  ButtonsContainer,
  StyledCheckbox,
  StyledCollapse
} from '../../../Utils/menuUtils/styleConstants'
import SelectedAddons from '../components/selectedAddons'

const { Panel } = Collapse

const ProductAddons = ({
  categoriesWithProducts,
  productRelationships,
  onTabChange,
  onAddonGroupsChange,
  onProductRelationChange,
  intl,
}) => {
  const selectedAddons = getAddonsGroupWithSelection(
    categoriesWithProducts,
    productRelationships
  )
  const [showAddonsGroupPopup, setShowAddonsGroupPopup] = useState(false)
  const [addonGroups, setAddonGroups] = useState(selectedAddons)
  const [selectedAddonGroups, setSelectedAddonGroups] = useState(
    getSelectedAddonsGroups(selectedAddons)
  )
  const [previousAddonGroups, setPreviousAddonGroups] = useState([])

  const onAddonChange = (
    addonGroupId,
    selectedValues,
    updateSelectedAddons
  ) => {
    const addonGroupIndex = addonGroups.findIndex(
      addonGroup => addonGroup.value === addonGroupId
    )
    const newCustomizationGroups = [...addonGroups]
    const selectedCustomizationGroup = {
      ...newCustomizationGroups[addonGroupIndex]
    }

    selectedCustomizationGroup.customisations = selectedCustomizationGroup.customisations.map(
      cs => {
        const newCs = { ...cs }

        newCs.selected = selectedValues.includes(cs.value)
        newCs.config.min =
          selectedValues.length < newCs.config.min
            ? selectedValues.length
            : newCs.config.min

        return newCs
      }
    )
    selectedCustomizationGroup.selected =
      selectedValues.length === selectedCustomizationGroup.customisations.length
    selectedCustomizationGroup.indeterminate =
      Boolean(selectedValues.length) &&
      selectedValues.length < selectedCustomizationGroup.customisations.length

    newCustomizationGroups[addonGroupIndex] = selectedCustomizationGroup
    setAddonGroups(newCustomizationGroups)
    updateSelectedAddons && addSelectedGroup()
  }

  const onMinMaxChange = (addonGroupId, min, max, updateSelectedAddons) => {
    const addonGroupIndex = addonGroups.findIndex(
      addonGroup => addonGroup.value === addonGroupId
    )

    const minNumber = Number(min)
    const maxNumber = Number(max)
    const newCustomizationGroups = [...addonGroups]
    const selectedCustomizationGroup = {
      ...newCustomizationGroups[addonGroupIndex]
    }

    selectedCustomizationGroup.customisations = selectedCustomizationGroup.customisations.map(
      cs => {
        const newCs = { ...cs }

        newCs.config.min = isNaN(minNumber) ? '' : minNumber
        newCs.config.max = isNaN(maxNumber) ? '' : maxNumber

        return newCs
      }
    )
    newCustomizationGroups[addonGroupIndex] = selectedCustomizationGroup
    setAddonGroups(newCustomizationGroups)
    if (updateSelectedAddons) {
      addSelectedGroup()
    }
  }

  const onAddonGroupChange = (cgIndex, selectedValues) => {
    const newCustomizationGroups = [...addonGroups]
    const selectedCustomizationGroup = { ...newCustomizationGroups[cgIndex] }
    const isSelected = selectedValues.includes(selectedCustomizationGroup.value)
    let customisations = [...selectedCustomizationGroup.customisations]

    customisations = customisations.map(cs => {
      cs.selected = isSelected

      return cs
    })
    newCustomizationGroups[cgIndex] = {
      ...selectedCustomizationGroup,
      selected: isSelected,
      indeterminate: false,
      customisations: [...customisations]
    }
    setAddonGroups(newCustomizationGroups)
  }

  const addSelectedGroup = () => {
    const selectedGroup = getSelectedAddonsGroups(addonGroups)

    setShowAddonsGroupPopup(false)
    setSelectedAddonGroups(selectedGroup)
    onProductRelationChange(PRODUCT_TYPE_ENUM.ADDON, selectedGroup)
  }

  const launchAddonsGroupPopup = () => {
    const previousAddonGroups = addonGroups.map(addonGroup => {
      return { ...addonGroup }
    })

    setPreviousAddonGroups(previousAddonGroups)
    setShowAddonsGroupPopup(true)
  }

  const closeAddonsGroupPopup = () => {
    const addonGroups = previousAddonGroups.map(previousAddonGroup => {
      return { ...previousAddonGroup }
    })

    setShowAddonsGroupPopup(false)
    setAddonGroups(addonGroups)
  }

  return (
    <>
      <Row className="mt-1">
        <Col span={20}>
          <Title
            level="h5"
            background={true}
            style={{
              color: COLORS.DARK_TEXT,
              marginBottom: 0,
              fontSize: '15px'
            }}>
            <IntlMessages id="addons/Combos" />
          </Title>
        </Col>
        <Col span={4} className="no-pd-left">
          <Button
            type="secondary"
            style={{ width: '100%', height: '41px', marginBottom: 0 }}
            onClick={() => {
              launchAddonsGroupPopup()
            }}>
            <IntlMessages id="button.+add" />
          </Button>
        </Col>
      </Row>
      {selectedAddonGroups.length ? (
        <SelectedAddons
          selectedAddonGroups={selectedAddonGroups}
          onAddonChange={onAddonChange}
          onMinMaxChange={onMinMaxChange}
        />
      ) : null}
      <Modal
        title={<IntlMessages id="addons/Combos" />}
        visible={showAddonsGroupPopup}
        onClose={() => closeAddonsGroupPopup()}>
        <StyledCollapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <CaretDownOutlined style={{ color: COLORS.BORDER_DARK }} rotate={isActive ? 180 : 0} />
          )}
          expandIconPosition={'right'}>
          {addonGroups.map((cg, index) => (
            <Panel
              key={index}
              style={{ borderColor: '#f7f2f2' }}
              header={
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => {
                    hideItemUpDownArrow(addonGroups)
                    setItemUpDownArrowPosition(index)
                  }}>
                  <div style={{ width: '20px' }}>
                    <MoveUpDown
                      className={`item-row-${index}`}
                      rowIndex={index}
                      array={addonGroups}
                      onReorder={(newAddonGroups, index) => {
                        hideItemUpDownArrow(newAddonGroups)
                        setItemUpDownArrowPosition(index)
                        setAddonGroups(newAddonGroups)
                        onAddonGroupsChange(newAddonGroups)
                      }}
                      style={{
                        marginRight: '10px',
                        position: 'static',
                        height: '41px'
                      }}
                    />
                  </div>
                  <CheckboxGroup
                    onChange={selectedValues =>
                      onAddonGroupChange(index, selectedValues)
                    }
                    name={`${cg.label}-index`}
                    value={cg.selected ? [cg.value] : []}>
                    <Checkbox
                      value={cg.value}
                      indeterminate={cg.indeterminate}
                      onClick={e => {
                        e.stopPropagation()
                      }}>
                      {cg.label}
                    </Checkbox>
                  </CheckboxGroup>
                </div>
              }>
              <CheckboxGroup
                name={cg.label}
                onChange={selectedValues =>
                  onAddonChange(cg.value, selectedValues, false)
                }
                value={cg.customisations
                  .filter(cs => cs.selected)
                  .map(cus => cus.value)}>
                {cg.customisations.map((c, cIndex) => (
                  <div key={`customization-${cIndex}`}>
                    <StyledCheckbox
                      key={`customization-cb-${cIndex}`}
                      value={c.value}>
                      {c.label}
                    </StyledCheckbox>
                  </div>
                ))}
              </CheckboxGroup>
            </Panel>
          ))}
        </StyledCollapse>
        <ButtonsContainer>
          <TextButton
            label={intlAlertMessage({
              id: 'button.+createNew',
              intl
            })}
            onClick={() => onTabChange(MENU_TABS.ADDONS)}
          />
          <Button
            type="primary"
            onClick={() => addSelectedGroup()}
            style={{ width: '100px', marginBottom: 0 }}
            disabled={!selectedAddonValidateSelection(addonGroups)}>
            <IntlMessages id="add" />
          </Button>
        </ButtonsContainer>
      </Modal>
    </>
  )
}

export default ProductAddons
