import { CREATE_OR_UPDATE_STORE, FETCH_MENU, GET_ORG_DETAILS, GET_PMS_BANK_DETAILS, IS_URL_AVAILABLE, STORE, UPDATE_BANK_DETAILS, UPDATE_ORG } from 'services/query/pms'
import { randomString } from 'Utils'
import { getUploadApiConfig } from 'Utils/onboardingUtils'

export const updatePMSOrganization = (client, payload) => {
  return client.mutate({
    mutation: UPDATE_ORG,
    variables: {
      input: payload
    },
    fetchPolicy: 'no-cache'
  })
}

export const createUpdatePMSStore = (client, payload) => {
  return client.mutate({
    mutation: CREATE_OR_UPDATE_STORE,
    variables: {
      input: payload
    },
    fetchPolicy: 'no-cache'
  })
}

export const getPMSStore = (client, payload) => {
  return client.query({
    query: STORE,
    variables: payload,
    fetchPolicy: 'network-only'
  })
}

export const getPMSBankDetails = (client, id) => {
  return client.query({
    query: GET_PMS_BANK_DETAILS,
    variables: { id },
    fetchPolicy: 'network-only'
  })
}
export const updatePMSBankDetails = (client, payload) => {

  return client.mutate({
    mutation: UPDATE_BANK_DETAILS,
    variables: {
      input: payload
    },
    fetchPolicy: 'no-cache'
  })
}
export const getPMSOrganizationDetails = (client, id) => {
  return client.query({
    query: GET_ORG_DETAILS,
    variables: { id },
    fetchPolicy: 'no-cache'
  })
}

export const checkURLAvailability = (client, url) => {
  return client.query({
    query: IS_URL_AVAILABLE,
    variables: {
      code: url
    },
    fetchPolicy: 'no-cache'
  })
}

export const fetchMenu = (client, payload) => {
  return client.query({
    query: FETCH_MENU,
    variables: payload,
    fetchPolicy: 'no-cache'
    // Context: { timeout: 2*60*1000 },
  })
}

export const uploadMenuFile = async (options, firebaseToken) => {
  const { file, onProgress } = options
  const { organizationId, token } = getUploadApiConfig()

  onProgress('ok')
  const graphQLEndpoint = process.env.REACT_APP_PMS_URL
  const { name } = file
  const lastDot = name.lastIndexOf('.')
  const fileExtension = name.substring(lastDot + 1)
  const fileName = `menu_${randomString(16)}.${fileExtension}`
  const myHeaders = new Headers()

  myHeaders.append('accept', 'application/json')
  myHeaders.append('accept-encoding', 'gzip, deflate, br')
  myHeaders.append('connection', 'keep-alive')
  myHeaders.append('dnt', '1')
  myHeaders.append('Authorization', `Bearer ${token}`)
  myHeaders.append('origin', graphQLEndpoint)
  const body: any = {
    query:
      'mutation uploadMenuFile( $orgId: String, $file: Upload!, $firebaseToken: String) { uploadMenuFile(orgId: $orgId, file: $file, firebaseToken: $firebaseToken ){ success, message}}',
    variables: {
      file: null,
      orgId: organizationId,
      firebaseToken
    }
  }
  const formData = new FormData()

  formData.append('operations', JSON.stringify(body))
  const map = { nFile: ['variables.file'] }

  formData.append('map', JSON.stringify(map))
  formData.append('nFile', file, fileName)
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formData
  }


  return fetch(graphQLEndpoint, requestOptions)

}
