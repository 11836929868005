import { gql } from '@apollo/client'
export const GET_ORG_DETAILS = gql`
  query organization(
    $id: String
    $code: String
    $role: String
    $bppId: String
  ) {
    organization(id: $id, code: $code, role: $role, bppId: $bppId) {
      id
      code
      name
      onboardingStatus
      orgLogo
      miniAppUrlLink
      businessType
      fssai
      status
      associations {
        orgId
        type
        terms {
          featureImage
          legalBusinessName
          panNumber
          gstNumber
          merchantId
          vpa
        }
      }
      contact {
        type
        email
        phone
        address {
          street
          area
          city
          state
          pincode
          country
        }
      }
      menuConfiguration {
        store_management
        menu_management
        order_management
        order_history
        logo_type
        pay_direct_transactions
        reports {
          food_order_settlements
          pay_direct_settlements
        }
      }
    }
  }
`

export const UPDATE_ORG = gql`
  mutation updateOrganization($input: OrganizationInput) {
    updateOrganization(input: $input) {
      success
      organization {
        id
        code
        name
        onboardingStatus
        status
        orgLogo
        miniAppUrlLink
        fssai
        associations {
          orgId
          type
          terms {
            featureImage
            legalBusinessName
            panNumber
            gstNumber
            merchantId
            vpa
          }
        }
        contact {
          type
          email
          phone
          address {
            street
            area
            city
            state
            pincode
            country
          }
        }
      }
    }
  }
`

export const UPDATE_BANK_DETAILS = gql`
  mutation updateBankDetails($input: BankDetailsUpdateInput) {
    updateBankDetails(input: $input) {
      success
    }
  }
`

export const IS_URL_AVAILABLE = gql`
  query isCodeUnique($code: String) {
    isCodeUnique(code: $code) {
      success
    }
  }
`

export const UPLOAD_MENU = gql`
  mutation uploadMenuFile(
    $orgId: String
    $file: Upload!
    $firebaseToken: String
  ) {
    uploadMenuFile(orgId: $orgId, file: $file, firebaseToken: $firebaseToken) {
      success
      message
      catalogId
    }
  }
`

export const FETCH_MENU = gql`
  query fetchAndUploadMenu(
    $id: String!
    $menuLink: String
    $firebaseToken: String
  ) {
    fetchAndUploadMenu(
      id: $id
      menuLink: $menuLink
      firebaseToken: $firebaseToken
    ) {
      success
      message
      catalogId
    }
  }
`

export const GET_PMS_BANK_DETAILS = gql`
  query bankDetailsForOrg($id: String) {
    bankDetailsForOrg(id: $id) {
      bankDetails {
        accountNo
        branchName
        name
        beneficiaryName
        ifscCode
        frequency
      }
    }
  }
`

export const CREATE_OR_UPDATE_STORE = gql`
  mutation createOrUpdateStore($input: StoreInput) {
    createOrUpdateStore(input: $input) {
      success
      store {
        id
        name
        code
      }
    }
  }
`

export const STORE = gql`
  query store($wcoreStoreId: String) {
    store(wcoreStoreId: $wcoreStoreId) {
      id
      code
      name
      discounts {
        code
        discountValue
        appliedOn
        description
        status
      }
    }
  }
`
