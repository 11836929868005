import { Modal } from 'antd'
import { Button, Text } from 'components'
import React from 'react'
import styled from 'styled-components'
import { COLORS } from 'Utils/constants'

const StyledModal = styled(Modal)`
  border-radius: 8px;
  &.ant-modal .ant-modal-content {
    width: 380px;
  }
  &.ant-modal .ant-modal-body {
    margin-top: -20px;
    margin-left: -9px;
    padding-bottom: 16px;
  }
  &.ant-modal .ant-modal-header {
    padding: 16px 15px;
  }
`

const StrikeOutConfirmationModal = props => {
  return (
    <div>
      <StyledModal
        title={props.title}
        centered
        visible={props.isStrikeOutVisible}
        onCancel={() => {
          props.cancelButtonAction()
        }}
        footer={null}
        width={320}>
        <Text level="body-2">{props.desc}</Text>
        <div style={{ marginTop: '16px', marginBottom: 0 }}>
          <Button
            type="default"
            style={{
              color: COLORS.DARK_TEXT,
              minWidth: '121px',
              height: '34px',
              marginBottom: 0
            }}
            onClick={() => {
              props.cancelButtonAction()
            }}>
            {props.cancelButtonText}
          </Button>
          <Button
            loading={props.loading}
            type={props.okButtonType}
            style={{ minWidth: '121px', height: '34px', marginBottom: 0 }}
            onClick={() => {
              props.okButtonAction()
            }}>
            {props.okButtonText}
          </Button>
        </div>
      </StyledModal>
    </div>
  )
}

export default StrikeOutConfirmationModal
