import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { graphql, withApollo } from '@apollo/client/react/hoc'
import { Col, Dropdown, Input, Spin } from 'antd'
import { Button, IntlMessages, Modal, Text, Title } from 'components'
import * as compose from 'lodash/flowRight'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { getOptions, onCreateOption, onOptionValueSubmit, updateOptionValue } from 'services'
import { intlAlertMessage } from 'Utils'
import { COLORS } from 'Utils/constants'
import { getWcoreOrganizationId } from 'Utils/localStorageHandlers/getter'

import { PROCESS_DELIVERY_ACCEPTANCE } from '../../../services/query'
import { republishCatalog, setSuccessMessage } from '../../../Utils/menuUtils'
import { AddItemStyle, GroupItemStyle, ItemBoxStyle, MenuStyle, StyledInputBox } from '../../../Utils/menuUtils/styleConstants'

const Customization = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedCustomizationGroup, setSelectedCustomizationGroup] = useState('')
  const [selectedCustomizationGroupItem, setSelectedCustomizationGroupItem] = useState('')
  const [isCustomizationModalOpen, setIsCustomizationModalOpen] = useState(false)
  const [customizationName, setCustomizationName] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [customizationGroupData, setCustomizationGroupData] = useState([])
  const [optionValueString, setOptionValueString] = useState('')
  const [selectedCustomizationGroupOptionValues, setSelectedCustomizationGroupOptionValues] = useState([])
  const [optionValueName, setOptionValueName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdateOptionValueLoading, setIsUpdateOptionValueLoading] = useState(false)
  const [isCustomizationGroupLoading, setIsCustomizationGroupLoading] = useState(false)

  useEffect(() => {
    getOptionsGroup()
  }, [])

  const getOptionsGroup = async () => {
    try {
      setIsCustomizationGroupLoading(true)
      const optionsRes = await getOptions(props.client)

      setCustomizationGroupData(optionsRes?.data?.options || [])
      setSelectedCustomizationGroup(optionsRes?.data?.options[0])
      const optionValues = optionsRes?.data.options.filter(item => item.id === optionsRes.data.options[0].id)[0].optionValues || []

      setSelectedCustomizationGroupItem(optionValues[0])
      setSelectedCustomizationGroupOptionValues(optionValues)
      setOptionValueName(optionValues[0]?.value)
      setIsCustomizationGroupLoading(false)

    } catch (error) {
      console.log(error)
      setIsCustomizationGroupLoading(false)
    }
  }

  const updateOptionValues = async () => {
    try {
      setIsUpdateOptionValueLoading(true)
      const res = await updateOptionValue(props.client, selectedCustomizationGroupItem, selectedCustomizationGroup, optionValueName)

      if (res?.data) {
        const selectedCustomizationIndex = selectedCustomizationGroupOptionValues.findIndex(elem => elem.id === selectedCustomizationGroupItem['id'])

        selectedCustomizationGroupOptionValues.splice(selectedCustomizationIndex, 1, res?.data?.updateOptionValue)
        setIsUpdateOptionValueLoading(false)
        props.onOptionsChange()
        setSuccessMessage('category.successMsg.itemUpdatedSuccessfully', props.intl)
      }
      republishCatalog(props.client)
    } catch (error) {
      setIsUpdateOptionValueLoading(false)
    }
  }

  const onOptionValueSubmitHandler = async () => {
    try {
      setIsLoading(true)
      const res = await onOptionValueSubmit(props.client, selectedCustomizationGroup, optionValueString)

      if (res.data) {
        setSuccessMessage('category.message.ItemCreatedSuccessfully', props.intl)
        selectedCustomizationGroupOptionValues.splice(selectedCustomizationGroupOptionValues.length, 0, res?.data?.createOptionValue)
        /* eslint-disable-next-line require-atomic-updates */
        selectedCustomizationGroup['optionValues'] = selectedCustomizationGroupOptionValues


        setIsLoading(false)
        setOptionValueName(optionValueString)
        setOptionValueString('')
        setSelectedCustomizationGroupOptionValues(selectedCustomizationGroupOptionValues)
        setSelectedCustomizationGroupItem(res?.data?.createOptionValue)
        props.onOptionsChange()
        republishCatalog(props.client)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = async () => {
    const org_id = getWcoreOrganizationId()

    try {
      setIsLoading(true)
      const createOptionRes = await onCreateOption(props.client, org_id, customizationName)

      customizationGroupData.splice(customizationGroupData.length, 0, createOptionRes?.data?.createOption)
      setSuccessMessage('category.message.ItemCreatedSuccessfully', props.intl)

      setCustomizationName('')
      setIsCustomizationModalOpen(false)
      setIsLoading(false)
      setSelectedCustomizationGroup(createOptionRes?.data?.createOption)
      setSelectedCustomizationGroupOptionValues(createOptionRes?.data?.createOption?.optionValues || [])
      setSelectedCustomizationGroupItem(createOptionRes?.data?.createOption?.optionValues?.[0])
      setOptionValueName(createOptionRes?.data?.createOption?.optionValues?.[0]?.value)
      props.onOptionsChange()
    } catch (error) {
      setIsLoading(false)
    }
  }

  const menu = () => (
    <MenuStyle style={{ height: '60vh', overflow: 'scroll' }}>
      <MenuStyle.Item
        style={{ color: COLORS.PRIMARY }}
        onClick={() => {
          setIsOpen(!isOpen)
          setIsCustomizationModalOpen(true)
        }}>
        <IntlMessages id="button.+createNewGroup" />
      </MenuStyle.Item>
      <MenuStyle.Item>
        {isCustomizationGroupLoading && (
          <div className="divCenter" style={{ marginTop: '-5px' }}>
            <Spin size="default" />
          </div>
        )}
      </MenuStyle.Item>
      {customizationGroupData.map((item, index) => (
        <MenuStyle.Item
          key={item.id}
          onClick={() => {
            setIsOpen(!isOpen)
            setSelectedCustomizationGroup(item)
            setSelectedCustomizationGroupOptionValues(item.optionValues)
            setSelectedCustomizationGroupItem(item?.optionValues?.[0])
            setOptionValueName(item?.optionValues?.[0]?.value)
          }}>
          {item.name}
        </MenuStyle.Item>
      ))}
    </MenuStyle>
  )

  return (
    <>
      <Col
        span={6}
        style={{
          background: 'white',
          padding: '15px 12px',
          marginBottom: '20px',
          height: '80vh'
        }}>
        <Title level="h6">
          <IntlMessages id="customization" />
        </Title>
        <div>
          <Dropdown
            overlay={menu}
            onVisibleChange={() =>
              setIsOpen(!isOpen)
            }
            visible={isOpen}>
            <StyledInputBox
              className={'box-default'}
              onClick={e => e.preventDefault()}>
              <span>
                {selectedCustomizationGroup ? (
                  selectedCustomizationGroup['name']
                ) : (
                  <IntlMessages id="addons/combo.selectAndCreateAnyGroup" />
                )}
              </span>
              {isOpen ? (
                <UpOutlined style={{ fontSize: '13px' }} />
              ) : (
                <DownOutlined style={{ fontSize: '13px' }} />
              )}
            </StyledInputBox>
          </Dropdown>
          {isCustomizationGroupLoading && (
            <div className="divCenter" style={{ marginTop: '-100px' }}>
              <Spin size="default" />
            </div>
          )}
          {selectedCustomizationGroup
            ? selectedCustomizationGroupOptionValues?.map((item, index) => (
              <GroupItemStyle
                key={item}
                style={
                  item === selectedCustomizationGroupItem
                    ? {
                      backgroundColor: COLORS.BG_SHADE,
                      color: COLORS.PRIMARY,
                      padding: '10px 12px',
                      fontWeight: '600'
                    }
                    : {}
                }
                onClick={() => {
                  setOptionValueName(item.value)
                  setSelectedCustomizationGroupItem(item)
                }
                }>
                {item.value}
              </GroupItemStyle>
            ))
            : null}
          {selectedCustomizationGroup ? (
            <ItemBoxStyle>
              <AddItemStyle
                placeholder={intlAlertMessage({
                  id: 'item.enterName',
                  intl: props.intl
                })}
                value={optionValueString}
                onChange={e =>
                  setOptionValueString(e.target.value)
                }></AddItemStyle>
              <Button
                type="primary"
                disabled={!optionValueString}
                onClick={() => onOptionValueSubmitHandler()}
                loading={isLoading}>
                <IntlMessages id="button.+add" />
              </Button>
            </ItemBoxStyle>
          ) : null}
        </div>
      </Col>
      <Col span={12} style={{ marginLeft: '20px' }}>
        <div
          style={{
            backgroundColor: COLORS.BG_WHITE,
            padding: '25px',
            display: 'flex',
            width: '500px'
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end'
            }}>
            <div>
              <IntlMessages id="menu.itemName" />
            </div>

            <Input
              style={{ width: '330px', marginTop: '5px' }}
              placeholder={intlAlertMessage({
                id: 'cheeseBurst',
                intl: props.intl
              })}
              value={optionValueName}
              onChange={e =>
                setOptionValueName(e.target.value)
              }
            />
          </div>
        </div>
        <Button
          type="primary"
          disabled={
            !(
              optionValueName &&
              selectedCustomizationGroupOptionValues.length
            )
          }
          style={{ width: '88px', height: '40px', marginTop: '12px' }}
          onClick={() => updateOptionValues()}
          loading={isUpdateOptionValueLoading}>
          <IntlMessages id="update" />
        </Button>
      </Col>
      <Modal
        visible={isCustomizationModalOpen}
        width={450}
        title=""
        onClose={() => setIsCustomizationModalOpen(false)}>
        <Title
          level="h4"
          style={{
            marginTop: '-8px',
            color: COLORS.DARK_TEXT,
            fontWeight: '500'
          }}>
          <IntlMessages id="customization.customizationName" />
        </Title>
        <Text level="caption" style={{ marginBottom: '15px' }}>
          <IntlMessages id="addons/combo.enterName" />
        </Text>
        <Input
          placeholder={intlAlertMessage({
            id: 'addons/combo.enterName',
            intl: props.intl
          })}
          value={customizationName}
          style={{ marginTop: '5px' }}
          onChange={e => setCustomizationName(e.target.value)}
        />
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            type="primary"
            disabled={!customizationName}
            style={{ height: '35px', width: '154px', marginTop: '16px' }}
            onClick={() => onSubmit()}>
            <IntlMessages id="button.submit" />
          </Button>
        </div>
      </Modal>
    </>
  )
}

const CustomizationComponent = compose(
  withRouter,
  graphql(PROCESS_DELIVERY_ACCEPTANCE, {
    name: 'pickUpOrder'
  })
)(withApollo(Customization))

export { CustomizationComponent }
