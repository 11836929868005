import { gql } from '@apollo/client'
export const CREATE_MENU_TIMINGS = gql`
  mutation createMenuTimings($input: menuTimingsInput!) {
    createMenuTimings(input: $input) {
      code
      name
      timings {
        days
        data {
          id
          openTime
          closeTime
        }
      }
    }
  }
`

export const GET_MENU_TIMINGS = gql`
  query getMenuTimings {
    getMenuTimings {
      code
      name
      timings {
        days
        data {
          id
          openTime
          closeTime
        }
      }
    }
  }
`

export const UPDATE_MENU_TIMINGS = gql`
  mutation updateMenuTimings($input: updateMenuTimingsInput!) {
    updateMenuTimings(input: $input) {
      code
      name
      timings {
        days
        data {
          id
          openTime
          closeTime
        }
      }
    }
  }
`

export const ADD_MENU_TIMINGS = gql`
  mutation addMenuTimings($input: addMenuTimingsInput!) {
    addMenuTimings(input: $input) {
      code
      name
      timings {
        days
        data {
          id
          openTime
          closeTime
        }
      }
    }
  }
`

export const REMOVE_MENU_TIMINGS = gql`
  mutation removeMenuTimings($input: removeMenuTimingsInput!) {
    removeMenuTimings(input: $input) {
      code
      name
      timings {
        days
        data {
          id
          openTime
          closeTime
        }
      }
    }
  }
`

export const REMOVE_MENU_TIMINGS_BY_ID = gql`
  mutation removeMenuTimingsById($input: removeMenuTimingsByIdInput!) {
    removeMenuTimingsById(input: $input) {
      code
      name
      timings {
        days
        data {
          id
          openTime
          closeTime
        }
      }
    }
  }
`

export const ADD_MENU_TIMINGS_FOR_CATEGORY = gql`
  mutation addMenuTimingsForCategory($input: addMenuTimingsForCategoryInput!) {
    addMenuTimingsForCategory(input: $input) {
      id
      code
      menuTimings {
        name
        code
        timings {
          days
          data {
            id
            openTime
            closeTime
          }
        }
      }
      category {
        id
        name
        code
        description
      }
    }
  }
`

export const REMOVE_MENU_TIMINGS_FOR_CATEGORY = gql`
  mutation removeMenuTimingsForCategory(
    $input: removeMenuTimingsForCategoryInput!
  ) {
    removeMenuTimingsForCategory(input: $input) {
      id
      code
      menuTimings {
        name
        code
        timings {
          days
          data {
            id
            openTime
            closeTime
          }
        }
      }
      category {
        id
        name
        code
        description
      }
    }
  }
`

export const UPDATE_MENU_TIMINGS_FOR_CATEGORY = gql`
  mutation updateMenuTimingsForCategory(
    $input: updateMenuTimingsForCategoryInput!
  ) {
    updateMenuTimingsForCategory(input: $input) {
      id
      code
      menuTimings {
        name
        code
        timings {
          days
          data {
            id
            openTime
            closeTime
          }
        }
      }
      category {
        id
        name
        code
        description
      }
    }
  }
`

export const UPDATE_MENU_TIMINGS_FOR_PRODUCT = gql`
  mutation updateMenuTimingsForProduct(
    $input: updateMenuTimingsForProductInput!
  ) {
    updateMenuTimingsForProduct(input: $input) {
      id
      code
      menuTimings {
        name
        code
        timings {
          days
          data {
            id
            openTime
            closeTime
          }
        }
      }
      product {
        id
        name
        code
        description
      }
    }
  }
`

export const ADD_MENU_TIMINGS_FOR_PRODUCT = gql`
  mutation addMenuTimingsToProduct($input: addMenuTimingsToProductInput!) {
    addMenuTimingsToProduct(input: $input) {
      id
      code
      menuTimings {
        name
        code
        timings {
          days
          data {
            id
            openTime
            closeTime
          }
        }
      }
      product {
        id
        name
        code
        description
      }
    }
  }
`

export const REMOVE_MENU_TIMINGS_FOR_PRODUCT = gql`
  mutation removeMenuTimingsForProduct(
    $input: removeMenuTimingsForProductInput!
  ) {
    removeMenuTimingsForProduct(input: $input) {
      id
      code
      menuTimings {
        name
        code
        timings {
          days
          data {
            id
            openTime
            closeTime
          }
        }
      }
      product {
        id
        name
        code
        description
      }
    }
  }
`
