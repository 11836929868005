import { CloseOutlined } from '@ant-design/icons'
import { Mutation } from '@apollo/client/react/components'
import { Col, Form, Input, Modal, Row } from 'antd'
import { IMAGES } from 'assets'
import { Button, IntlMessages, Text } from 'components'
import { History } from 'history'
import useForgotPassword from 'pages/authentication/forgotPassword/useForgotPassword'
import React from 'react'
import { CHANGE_PASSWORD } from 'services/query'
import { intlAlertMessage } from 'Utils'
import { ROUTES } from 'Utils/constants/routes'
import { COLORS, FONTWEIGHT } from 'Utils/constants/styles'

interface IProps {
  history?: History
  onSubmit: any
  intl: any
}

const ForgotPasswordForm = (props: IProps) => {
  const { history, intl, onSubmit } = props
  const { isEmailSent, email, showStepsModal, sendingEmail, setIsEmailSent, setEmail, setShowStepsModal, setSendingEmail, isResetLinkExpire } = useForgotPassword(props)

  return (
    <div>
      {!isEmailSent && (
        <Mutation mutation={CHANGE_PASSWORD} fetchPolicy="no-cache">
          {(changePassword: any, { loading, error, data }: any) => (
            <Form
              onFinish={async values => {
                const { email } = values

                setSendingEmail(true)
                await onSubmit(email)
                setIsEmailSent(true)
                setSendingEmail(false)
                setEmail(email)
              }}
              className="login-form">
              <Row style={{ marginBottom: '20px' }}>
                <Col span={20} style={{ paddingLeft: 0 }}>
                  <h2
                    style={{
                      marginBottom: 0,
                      color: COLORS.DARK_TEXT,
                      fontWeight: FONTWEIGHT.FONT_MEDIUM
                    }}>
                    {isResetLinkExpire ? (
                      <IntlMessages id="reset.email.form.reset.link.exp.title" />
                    ) : (
                      <IntlMessages id="reset.email.form.reset.title" />
                    )}
                  </h2>
                  <div style={{ fontSize: '13px' }}>
                    {isResetLinkExpire ? (
                      <IntlMessages id="reset.email.form.reset.link.exp.desc" />
                    ) : (
                      <IntlMessages id="reset.email.form.reset.desc" />
                    )}
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <Text level="caption">
                      <IntlMessages id="reset.email.form.forgot.password.owner" />
                    </Text>
                  </div>
                </Col>
                <Col
                  span={4}
                  style={{
                    paddingRight: 0,
                    textAlign: 'right',
                    color: COLORS.DARK_TEXT,
                    fontSize: '16px',
                    cursor: 'pointer'
                  }}>
                  <CloseOutlined
                    onClick={() => history.push(ROUTES.LOGIN_PAGE)}
                  />

                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <Text level="caption" style={{ fontSize: '11px' }}>
                    <IntlMessages id="reset.email.form.input.label" />
                  </Text>
                </Col>
              </Row>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: intlAlertMessage({
                      id: 'reset.email.form.email.required.error',
                      intl
                    })
                  },
                  {
                    type: 'email',
                    message: intlAlertMessage({
                      id: 'reset.email.form.email.validation.error',
                      intl
                    })
                  }
                ]}
                hasFeedback>
                <Input
                  placeholder={intlAlertMessage({
                    id: 'reset.email.form.email.placeholder',
                    intl
                  })}
                />
              </Form.Item>

              <Row>
                <Col
                  span={24}
                  style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                    marginBottom: '10px'
                  }}>
                  <Text level="caption">
                    <IntlMessages id="reset.email.form.forgot.password.manager" />
                  </Text>
                </Col>
              </Row>
              <Row style={{ justifyContent: 'center' }}>
                <Col span={24} style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={sendingEmail}
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      width: '100%'
                    }}>
                    <IntlMessages id="item.emailMeRecoveryLink" />
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Mutation>
      )}
      {isEmailSent && (
        <div>
          <Row>
            <Col span={20} style={{ paddingLeft: 0 }}></Col>
            <Col
              span={4}
              style={{
                paddingRight: 0,
                textAlign: 'right',
                color: COLORS.DARK_TEXT,
                fontSize: '16px',
                cursor: 'pointer'
              }}>
              <CloseOutlined
                onClick={() => history.push(ROUTES.LOGIN_PAGE)}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={24} className="gx-text-center">
              {' '}
              <img src={IMAGES.mail_sent} alt="mail sent" />
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ paddingLeft: 0 }}>
              <h2
                style={{
                  marginBottom: '10px',
                  color: COLORS.DARK_TEXT,
                  fontWeight: FONTWEIGHT.FONT_MEDIUM
                }}>
                <IntlMessages id="reset.email.form.email.title" />
              </h2>
              <div style={{ fontSize: '15px' }}>
                <p>
                  <IntlMessages id="reset.email.form.email.desc.1" />
                  {
                    <span
                      style={{ fontWeight: FONTWEIGHT.FONT_MEDIUM, color: COLORS.NORMAL_TEXT }}>
                      {email}
                    </span>
                  }
                  <IntlMessages id="reset.email.form.email.desc.2" />
                  <span
                    className="link-text"
                    style={{ float: 'none', cursor: 'pointer' }}
                    onClick={() => setShowStepsModal(true)}>
                    <IntlMessages id="clickHere" />
                  </span>
                </p>
                <IntlMessages id="reset.email.form.email.desc.3" />
                <span
                  className="link-text"
                  style={{ float: 'none', cursor: 'pointer' }}
                  onClick={() => setIsEmailSent(false)}>
                  <IntlMessages id="tryAgain" />
                </span>
              </div>
            </Col>
          </Row>
          <Modal
            className="steps-modal"
            width={385}
            visible={showStepsModal}
            title={<IntlMessages id="reset.email.form.email.desc.3" />}
            onCancel={() => setShowStepsModal(false)}
            footer={[]}
            style={{ top: '36%' }}>
            <Row>
              <Col span={24}>
                <p>
                  <IntlMessages id="reset.email.form.steps.1" /> ({email}).
                </p>
                <p>
                  <IntlMessages id="reset.email.form.steps.2" />
                </p>
                <p>
                  <IntlMessages id="reset.email.form.steps.3" />
                </p>
                <p>
                  <IntlMessages id="reset.email.form.steps.4" />
                </p>
              </Col>
            </Row>
          </Modal>
        </div>
      )}
    </div>
  )

}

export default ForgotPasswordForm
