import { Tooltip } from 'antd'
import { TextLimiter } from 'Utils/commonStyles'

type TrimmedTextProps = {
  showFullText?: boolean,
  limitText: number,
  text: string,
  style?: object
}


export const TrimmedText = (props: TrimmedTextProps) => {

  return (
    <Tooltip title={props.text} placement="topLeft">
      <TextLimiter
        showFullText={props.showFullText}
        limitText={props.limitText}
        style={props.style}>
        {props.text}
      </TextLimiter>
    </Tooltip>
  )
}