import { Button, Col, Input, InputNumber, Modal } from 'antd'
import { Button as ConsoleButton,IntlMessages, Title } from 'components'
import React from 'react'
import { intlAlertMessage } from 'Utils'
import { COLORS } from 'Utils/constants'



function DeliveryAgent(props) {
  const { deliveryAgentFormDetails } = props

  console.log(props.agentDetails)
  
  

  function onAgentFieldChange(type, value) {

    deliveryAgentFormDetails[type] = value
    props.onAgentFieldChange('deliveryAgentFormDetails', deliveryAgentFormDetails)
  }

  return (
    <Col className={'agentSectionContainer'} span={20}>
      <Modal
        visible={props.isAgentDetailsModalVisible}
        title={
          <Title level='h4'>
            {deliveryAgentFormDetails.id ||
            deliveryAgentFormDetails.staff_role ? <IntlMessages id="store.updateAgents" /> : <IntlMessages id="store.addNewAgents" />}
          </Title>
        }
        footer={null}
        onCancel={() => props.isAgentDetailsModalVisibleHandler()}
      >
        <div className="InputLabel">
          <IntlMessages id="appModule.name" />
        </div>
        <Input
          size="large"
          placeholder={intlAlertMessage({
          id: 'store.enterAgentName',
          intl: props.intl
          })}
          value={deliveryAgentFormDetails.name}
          style={{ border: `1px solid ${COLORS.BORDER}` }}
          onChange={e => {
            onAgentFieldChange('name', e.target.value)
          }}
        /> 
        <div className="InputLabel" style={{ marginTop: '12px' }}>
          <IntlMessages id="customer.phoneNumber" />
        </div>
        <InputNumber
          addonBefore="+91"
          size="large"
          controls={false}
          value={deliveryAgentFormDetails.phone}
          placeholder={intlAlertMessage({
            id: 'store.enterAgentsPhoneNumber',
            intl: props.intl
          })}
          style={{
              borderRadius: '5px',
              width: '100%'
          }}
          maxLength={10}
          onChange={e => {
            onAgentFieldChange('phone', e)
          }}
        />
        <Col>
          {deliveryAgentFormDetails.id ||
          deliveryAgentFormDetails.staff_role ? (
            <ConsoleButton
              loading={ props.isUpdatingAgent }
              type='primary'
              style={{ margin:'15px 0 0 0', padding: '0 30px' }}
              onClick={() => {
                props.updateAgentsHandler(deliveryAgentFormDetails.id)
              }}>
              <IntlMessages id="store.updateAgent" />
            </ConsoleButton>
            ) : (
              <ConsoleButton
                type='primary'
                style={{ margin:'15px 0 0 0', padding: '0 30px' }}
                onClick={() => {
                  props.addAgentsHandler()
                }}>
                <IntlMessages id="store.addAgent" />
              </ConsoleButton>
            )}
        </Col>
      </Modal>
      
      <Modal
        visible={props.showDeleteAgentDialog}
        title={
          <IntlMessages id="store.confirmationMsg.areYouSureYouWantToDelete" />
        }
        onCancel={() => {
          props.onAgentFieldChange('showDeleteAgentDialog', false)
        }}
        footer={[
          <Button
            key="rejectDelete"
            onClick={() => {
              props.onAgentFieldChange('showDeleteAgentDialog', false)
            }}>
            <IntlMessages id="newOrder.Cancel" />
          </Button>,
          <Button
            type="primary"
            danger
            key="confirmDelete"
            onClick={() => {
              props.deleteAgent(props.deleteAgentIndex)
            }}>
            <IntlMessages id="button.delete" />
          </Button>
        ]}></Modal>
    </Col>
  )
}

export default DeliveryAgent
