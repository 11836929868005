import { CREATE_CATEGORY, GET_ALL_CATEGORIES, UPDATE_CATEGORY_SORT_SEQUENCE } from 'services/query'

export const updateCategorySortSeq = (client, updateCategorySortSeqInput) => {
  return client.mutate({
    mutation: UPDATE_CATEGORY_SORT_SEQUENCE,
    variables: { input: updateCategorySortSeqInput },
    fetchPolicy: 'no-cache'
  })
}

export const getAllProductCategory = (client, catalog) => {
  return client
    .query({
      query: GET_ALL_CATEGORIES,
      variables: {
        catalogId: catalog.id
      },
      fetchPolicy: 'network-only'
    })
    .catch(e => {
      console.log(e, 'get all product error')
    })
}

export const createCategory = (client, createCategoryInput) => {
  return client
    .mutate({
      mutation: CREATE_CATEGORY,
      variables: {
        input: createCategoryInput
      }
    })
    .catch(e => {
      console.log(e, 'create category error')
    })
}
