import jwt from 'jsonwebtoken'
import {
  ADD_CHARGE_FOR_CATALOG,
  ADD_TAX_FOR_CATALOG,
  CREATE_CATEGORY,
  DELETE_CATALOG,
  GET_CATALOG_DETAIL,
  GET_CATALOG_WITH_CATEGORIES_AND_PRODUCTS,
  GET_CATALOGS,
  GET_STORE_CATALOGS,
  GET_STORE_FORMAT,
  REPUBLISH_CATALOG,
  UPDATE_CATALOG,
  UPDATE_CATEGORY,
  UPDATE_CHARGE_FOR_CATALOG,
  UPDATE_TAX_FOR_CATALOG } from 'services/query'
import { getAuthToken } from 'Utils/localStorageHandlers/getter'
import { getUserData } from 'Utils/onboardingUtils'

import { getUserData as userDetails } from './userService'

export const onDeleteCatalogue = (client, catalogId, orgId) => {
  return client
    .query({
      query: DELETE_CATALOG,
      variables: { id: catalogId, organizationId: orgId },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.log(e)
    })
}

export const getCatalogue = (client, orgId) => {
  return client.query({
    query: GET_CATALOGS,
    variables: { organizationId: orgId },
    fetchPolicy: 'network-only'
  })
}

export const getStoreCatalogue = (client, storeCatalogInput) => {
  return client.query({
    query: GET_STORE_CATALOGS,
    variables: { filter: storeCatalogInput },
    fetchPolicy: 'no-cache'
  })
}

export const getStoreFormat = client => {
  return client.query({
    query: GET_STORE_FORMAT,
    variables: { status: 'ACTIVE' },
    fetchPolicy: 'no-cache'
  })
}

export const getCategoriesWithProducts = (client, catalogId) => {
  return client
    .query({
      query: GET_CATALOG_WITH_CATEGORIES_AND_PRODUCTS,
      variables: { id: catalogId },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.error('Get catalog detail error', e)
    })
}

export const getCatalogDetails = (client, id) => {
  return client.mutate({
    mutation: GET_CATALOG_DETAIL,
    variables: { id },
    fetchPolicy: 'no-cache'
  })
}

export const updateCatalog = (client, payload) => {
  return client.mutate({
    mutation: UPDATE_CATALOG,
    variables: { input: payload },
    fetchPolicy: 'no-cache'
  })
}

export const addCatalogTax = (client, payload) => {
  return client.mutate({
    mutation: ADD_TAX_FOR_CATALOG,
    variables: { input: payload },
    fetchPolicy: 'no-cache'
  })
}

export const addCatalogCharge = (client, payload) => {
  return client.mutate({
    mutation: ADD_CHARGE_FOR_CATALOG,
    variables: { input: payload },
    fetchPolicy: 'no-cache'
  })
}

export const updateCatalogTax = (client, payload) => {
  return client.mutate({
    mutation: UPDATE_TAX_FOR_CATALOG,
    variables: { input: payload },
    fetchPolicy: 'no-cache'
  })
}

export const updateCatalogCharge = (client, payload) => {
  return client.mutate({
    mutation: UPDATE_CHARGE_FOR_CATALOG,
    variables: { input: payload },
    fetchPolicy: 'no-cache',
  })
}

export const updateCategory = (client, payload) => {
  return client.mutate({
    mutation: UPDATE_CATEGORY,
    variables: { input: payload },
    fetchPolicy: 'no-cache',
  })
}
export const createdCategory = (client, payload, createCategory) => {
  return client.mutate({
    mutation: CREATE_CATEGORY,
    variables: { input: { ...payload, ...createCategory } },
    fetchPolicy: 'no-cache',
  })
}

export const republishCatalog =async (client) => {
  const { stores } = getUserData()
  let storeCode

  if (!stores[0]?.code) {
    const storeRes = await getUserDetails(client)

    storeCode = storeRes.map(store => store.code)
  }else{
    storeCode = stores.map(store => store.code)
  }

  return client
    .query({
      query: REPUBLISH_CATALOG,
      variables: { codes: storeCode },
      fetchPolicy: 'no-cache'
    })
    .catch(e => {
      console.error('republish catalog error', e)
    })
}

const getUserDetails = async (client) => {
  const { id, org_id } = jwt.decode(getAuthToken())
  const response = await userDetails(client, { id, organizationId: org_id })

  return response?.data?.user?.store
}