import { Col, Row, Switch, Typography } from 'antd'
import { Button as AntButton,InputField, IntlMessages } from 'components'
import React, { useEffect, useState } from 'react'
import { getMenuTimings } from 'services'
import { intlAlertMessage } from 'Utils'
import { CONSOLE_MENU_CATEGORY_STATUS,MENU_TABS } from 'Utils/constants'

import { MenuTimingsDropdown } from '../components/MenuTimingsDropdown'

const Text = Typography

const CategoryForm = props => {
  const [menuTimings, setMenuTimings] = useState([])

  useEffect(() => {
    getAllMenuTimings()
  }, [])

  const getAllMenuTimings = async () => {
    try {
      const res = await getMenuTimings(props.client)

      res?.data && setMenuTimings(res.data.getMenuTimings)
    } catch (error) {
      console.log(error)
    }
  }
  
  const { isListed, categoryForm, onTabChange, isEdit, loading, updateListableProp, onChange, createOrUpdateCategory } = props

  return (
    <Col span={16}>
      <Row className="form-wrapper">
        <Col span={24}>
          <Row>
            <Col span={13} style={{ margin: '0 -17px' }}>
              <InputField
                title={<IntlMessages id="menu.categoryName" />}
                allowClear={true}
                value={categoryForm['name']}
                onInputChange={e => onChange('name', e.target.value)}
              />
            </Col>
            <Col span={7} style={{ marginTop: '7px' }}>
              <MenuTimingsDropdown
                isButtonAvailable={true}
                buttonLabel={intlAlertMessage({
                  id: 'menu.addNewTimeSlot',
                  intl: props.intl
                })}
                data={menuTimings}
                onButtonClick={() => {
                  onTabChange(MENU_TABS.MENU_TIMINGS)
                }}
                onChangeItem={item => {
                  onChange('menuTiming', item)
                }}
                selectedMenuTiming={categoryForm['menuTiming']}
                onMenuTimingHandler={() => {
                  onChange(
                    'isMenuTimingEnable',
                    !categoryForm['isMenuTimingEnable']
                  )
                }}
                isMenuTimingEnable={categoryForm['isMenuTimingEnable']}
              />
            </Col>
          </Row>

          <Row className="isListable">
            <Col span={2} style={{ padding: 0 }}>
              <Text>
                <IntlMessages id="category.listable" />?
              </Text>
            </Col>
            <Col span={4}>
              <Switch
                checked={isListed}
                checkedChildren={CONSOLE_MENU_CATEGORY_STATUS.ACTIVE}
                unCheckedChildren={CONSOLE_MENU_CATEGORY_STATUS.INACTIVE}
                onChange={e => {
                  updateListableProp()
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <AntButton
        type="primary"
        style={{
          marginLeft: '20px',
          marginTop: '20px',
          width: '120px'
        }}
        loading={loading}
        disabled={!categoryForm['name']}
        onClick={() => createOrUpdateCategory()}>
        {isEdit ? <IntlMessages id="update" /> : <IntlMessages id="create" />}
      </AntButton>
    </Col>
  )
}

export default CategoryForm
