import { message } from 'antd'
import { createDiscountType,createTags,getDiscountTypes, republishCatalog as republishCatalogService } from 'services'
import {
  compareArrayValues,
  getStrikeoutDiscountType,
  intlAlertMessage,
} from 'Utils'
import { ACTIONS, DISCOUNT_VALUE_TYPE_ENUM, ORG_CONSTANT, PRICE_REGEX, PRICE_TYPE, PRODUCT_TAG_ENUM, PRODUCT_TYPE_ENUM, PRODUCT_VARIANT_TYPE_ENUM, ROLE_TYPE, TAG_TYPE_ENUM } from 'Utils/constants'

export const isAdmin = userData => {
  const roles = userData && userData.roles ? userData.roles : []
  let isAdmin = false

  roles.forEach(element => {
    if (element.name === (ROLE_TYPE.ADMIN).toLocaleUpperCase()) {
      isAdmin = true
    }
  })

  return isAdmin
}

export const getStores = userData => {
  const stores = userData ? userData.store : []

  return stores
}

export const getNewDealPrice = (dealPrice, percentChange) => {
  let newDealPrice = dealPrice

  if (dealPrice !== null) {
    newDealPrice = dealPrice + (dealPrice * percentChange) / 100
    newDealPrice = newDealPrice < 0 ? 0 : Math.round(newDealPrice)
  }

  return newDealPrice
}

export const getValidatedPrices = (originalPrices, productData, priceType) => {
  const { discountValue, priceValue, dealPrice } = productData
  let newDiscountValue = discountValue
  let newPriceValue = priceValue
  let newDealPrice = dealPrice

  if (priceValue !== null && dealPrice !== null) {
    if (dealPrice > priceValue) {
      if (priceType === PRICE_TYPE.DEAL) {
        newPriceValue = dealPrice
      } else {
        newDealPrice = priceValue
      }
    }
  } else if (priceValue !== null && dealPrice === null) {
    newDealPrice = newPriceValue
  } else if (priceValue === null && dealPrice !== null) {
    newPriceValue = dealPrice
  }

  if (priceValue === null && dealPrice === null) {
    newDiscountValue = null
  } else {
    newDiscountValue = newPriceValue - newDealPrice
  }
  const newOriginalPrices = originalPrices.map(op => {
    return { ...op }
  })

  const originalProductData = getPriceDataWithProductId([...newOriginalPrices], productData.productId)

  return {
    ...productData,
    discountValue: newDiscountValue,
    priceValue: newPriceValue,
    dealPrice: newDealPrice,
    priceValueChanged: originalProductData?.priceValue !== newPriceValue,
    discountValueChanged: originalProductData?.discountValue !== newDiscountValue
  }
}

export const getDiscountValue = (basePrice, dealPrice) => {
  let discountValue = null

  if (Boolean(basePrice) && (Boolean(dealPrice) || dealPrice === 0)) {
    discountValue = Math.ceil(basePrice - dealPrice)
  }

  return discountValue
}

export const getPriceDataWithProductId = (updatedPrices, productId) => {
  let productPriceData = updatedPrices.find((price: any) => {
    return productId === price.productId
  })

  if (!productPriceData) {
    productPriceData = updatedPrices.find((price: any) => {
      return compareArrayValues(productId, price.productId)
    })
  }

  return productPriceData
}

export const prepareFormatChannelPrices = (formats, channels) => {
  const formatChannelPrices = []

  channels?.forEach(channel => {
    const formatChannelPrice = {
      format: formats[0],
      channel,
      prices: []
    }

    formatChannelPrices.push(formatChannelPrice)
  })

  return formatChannelPrices
}

export const prepareOriginalPrices = products => {
  const originalPrices = products.map(product => {
    const originalPrice = {
      productId: product.id,
      tag: product.tag,
      priceValueChanged: false,
      chargeValueChanged: false,
      discountValueChanged: false,
      chargeValue: product.chargeValue,
      chargeValueId: product.chargeValueId,
      dealPrice: product.dealPrice,
      discountValue: product.discountValue,
      discountValueId: product.discountValueId,
      priceValue: product.priceValue,
      priceValueId: product.priceValueId
    }

    return originalPrice
  })

  return originalPrices
}

export const updateOriginalPricesWithProductPrice = (originalPrices, productPrices) => {
  const updatedOriginalPrices = originalPrices.map(originalPrice => {
    const updatedOriginalPrice = { ...originalPrice }
    const productPrice = productPrices.find(pPrice => pPrice.product.id === originalPrice.productId)
    const updatedProductPrice = { ...productPrice }

    if (!productPrice) {
      updatedOriginalPrice.priceValueId = null
      updatedOriginalPrice.priceValue = null
      updatedOriginalPrice.dealPrice = null
    } else {
      updatedOriginalPrice.priceValueId = updatedProductPrice.id
      updatedOriginalPrice.priceValue = updatedProductPrice.priceValue
      updatedOriginalPrice.dealPrice = updatedProductPrice.priceValue
    }

    return updatedOriginalPrice
  })

  return updatedOriginalPrices
}

export const updateOriginalPricesWithPackagingPrice = (originalPrices, packagingPrices) => {
  const updatedOriginalPrices = originalPrices.map(originalPrice => {
    const updatedOriginalPrice = { ...originalPrice }
    const packagingPrice = packagingPrices.find(pPrice => pPrice.product.id === originalPrice.productId)
    const updatedPackagingPrice = { ...packagingPrice }

    if (!packagingPrice) {
      updatedOriginalPrice.chargeValueId = null
      updatedOriginalPrice.chargeValue = null
    } else {
      updatedOriginalPrice.chargeValueId = updatedPackagingPrice.id
      updatedOriginalPrice.chargeValue = Math.round(updatedPackagingPrice.chargeValue)
    }

    return updatedOriginalPrice
  })

  return updatedOriginalPrices
}

export const updateOriginalPricesWithDiscountValue = (originalPrices, discountPrices) => {
  const updatedOriginalPrices = originalPrices.map(originalPrice => {
    const updatedOriginalPrice = { ...originalPrice }
    const discountValue = discountPrices.find(dPrice => dPrice.product.id === originalPrice.productId)
    const updatedDiscountValue = { ...discountValue }

    if (discountValue) {
      updatedOriginalPrice.discountValueId = updatedDiscountValue.id
      updatedOriginalPrice.discountValue = updatedDiscountValue.discountValue
      updatedOriginalPrice.dealPrice = Math.round(updatedOriginalPrice.priceValue - updatedDiscountValue.discountValue)
    } else {
      updatedOriginalPrice.discountValueId = null
      updatedOriginalPrice.discountValue = null
    }

    return updatedOriginalPrice
  })

  return updatedOriginalPrices
}

export const getProductsToUpdate = (products, property, propertyId) => {
  const productsToUpdate = products.filter(p => p[property] && p[propertyId] !== null)

  return productsToUpdate
}

export const getProductsToCreate = (products, property, propertyId) => {
  const productsToCreate = products.filter(p => p[property] && p[propertyId] === null)

  return productsToCreate
}

export const getProductsWithUpdatedPropertyId = (originalProducts, newProducts, propertyId, actionType) => {

  const copyOfOriginalProducts = [...originalProducts]
  const productsWithUpdatedPropertyId = copyOfOriginalProducts.map(cop => {
    const product = newProducts.find(np => np.productId === cop.productId)
    const propertyIdValue = product[propertyId]
    const updatedCop = { ...cop }

    updatedCop[propertyId] = propertyIdValue

    return updatedCop
  })

  if (actionType === ACTIONS.CREATE) {
    return productsWithUpdatedPropertyId.filter(p => p[propertyId] === null)
  }

  return productsWithUpdatedPropertyId.filter(p => p[propertyId] !== null)
}

export const preparePriceValueUpdateInput = productsForPriceUpdate => {
  const products = [...productsForPriceUpdate]
  const priceValueUpdateInput = products.map(p => {
    const { priceValueId, priceValue } = p

    return { productPriceValueId: priceValueId, productPrice: priceValue }
  })

  return priceValueUpdateInput
}

export const prepareChargeValueCreateInput = (productsForChargeCreate, format, channel, chargeType) => {
  const products = [...productsForChargeCreate]
  const chargeValueCreateInput = products.map(p => {
    const { productId, chargeValue } = p

    return {
      productId,
      chargeValue,
      storeFormat: format.id,
      channel: channel.id,
      chargeType: chargeType.id
    }
  })

  return chargeValueCreateInput
}

export const prepareChargeValueUpdateInput = productsForChargeUpdate => {
  const products = [...productsForChargeUpdate]
  const chargeValueUpdateInput = products.map(p => {
    const { chargeValueId, chargeValue } = p

    return { productChargeValueId: chargeValueId, chargeValue }
  })

  return chargeValueUpdateInput
}

export const prepareDiscountValueUpdateInput = productsForDiscountUpdate => {
  const products = [...productsForDiscountUpdate]
  const discountValueUpdateInput = products.map(p => {
    const { discountValueId, discountValue } = p

    return {
      productDiscountValueId: discountValueId,
      discountValue
    }
  })

  return discountValueUpdateInput
}

export const prepareDiscountValueCreateInput = (productsForDiscountCreate, format, channel, discountType) => {
  const products = [...productsForDiscountCreate]
  const discountValueCreateInput = products.map(p => {
    const { productId, discountValue } = p

    return {
      productId,
      discountValue,
      discountValueType: DISCOUNT_VALUE_TYPE_ENUM.ABSOLUTE,
      storeFormat: format.id,
      channel: channel.id,
      discountType: discountType.id
    }
  })

  return discountValueCreateInput
}

export const preparePriceValueCreateInput = (productsForPriceCreate, format, channel) => {
  const products = [...productsForPriceCreate]
  const priceValueCreateInput = products.map(p => {
    const { productId, priceValue } = p

    return {
      productId,
      productPrice: priceValue,
      storeFormat: format.id,
      channel: channel.id
    }
  })

  return priceValueCreateInput
}

export const fetchAndUpdateDiscountTypes = async client => {
  const discountType = getStrikeoutDiscountType()

  if (discountType) {
    return discountType
  }
  let strikeOutDiscountType
  let discountTypes = []
  const discountTypesRes = await getDiscountTypes(client)

  if (discountTypesRes?.data?.discountTypes) {
    strikeOutDiscountType = discountTypesRes.data.discountTypes.find(discountType => discountType.discountTypeCode === ORG_CONSTANT.STRIKEOUT_DISCOUNT_CODE)
    discountTypes = discountTypesRes.data.discountTypes
  }
  if (!strikeOutDiscountType) {
    const discountTypeRes = await createDiscountType(client, {
      name: ORG_CONSTANT.STRIKEOUT_DISCOUNT_NAME,
      discountTypeCode: ORG_CONSTANT.STRIKEOUT_DISCOUNT_CODE
    })

    strikeOutDiscountType = discountTypeRes?.data?.createDiscountType
    discountTypes.push(strikeOutDiscountType)
  }
  localStorage.setItem('discountTypes', JSON.stringify(discountTypes))

  return strikeOutDiscountType
}

export const fetchAndCreateMustTryTag = async (client, tags) => {
  let mustTryTag

  mustTryTag = tags.getTags.data.find(tag => tag.tagName === PRODUCT_TAG_ENUM.MUST_TRY)

  if (!mustTryTag) {
    try {
      const createTagRes = await createTags(client, {
        name: PRODUCT_TAG_ENUM.MUST_TRY,
        tagType: TAG_TYPE_ENUM.PROPERTY
      })

      mustTryTag = createTagRes.data.createTag
    } catch (error) {
      console.log(error)
    }
  }

  return mustTryTag

}

export const isMustTryEnabled = (product) => {
  return product?.tags?.find(tag => tag?.tag?.tagName === PRODUCT_TAG_ENUM.MUST_TRY)
}

export const getMustTryTagCount = (categories) => {
  let count = 0

  categories.forEach(category => {
    category.products.forEach(item => {
      if (isMustTryEnabled(item)) {
        count += 1 
      }
    })
  })

  return count
}

export const getCustomizationGroups = options => {
  const filteredOptions = options.filter(option => {
    return option.optionValues.length > 0
  })
  const customizationGroups = []

  filteredOptions.forEach(option => {
    const customizationGroup = {}

    customizationGroup['label'] = option.name
    customizationGroup['value'] = option.id
    customizationGroup['indeterminate'] = false
    customizationGroup['selected'] = false
    customizationGroup['customizations'] = []
    customizationGroup['saved'] = false
    option.optionValues.forEach(optionValue => {
      const customization = {}

      customization['label'] = optionValue.value
      customization['value'] = optionValue.id
      customization['indeterminate'] = false
      customization['saved'] = false
      customizationGroup['customizations'].push(customization)
    })
    customizationGroups.push(customizationGroup)
  })

  return customizationGroups
}

export const getCustomisationGroupsWithSelections = (options, productRelationships) => {
  const customizationGroups = getCustomizationGroups(options)

  const filteredProductRelationships = productRelationships.filter(pr => pr.childType === PRODUCT_TYPE_ENUM.VARIANT)

  customizationGroups.forEach(customizationGroup => {
    let selectionCount = 0

    customizationGroup.customizations.forEach(customization => {
      customization.productId = customization.value
      filteredProductRelationships.forEach(pr => {
        pr.child.options.forEach(option => {
          const optionValue = option.optionValues.find(optionValue => optionValue.id === customization.value)

          if (optionValue) {
            customization.selected = true
            customization.saved = true
            selectionCount += 1
          }
        })
      })
    })
    if (customizationGroup.customizations.length && selectionCount === customizationGroup.customizations.length) {
      customizationGroup.selected = true
      customizationGroup.saved = true
      customizationGroup.indeterminate = false
    } else if (selectionCount > 0) {
      customizationGroup.indeterminate = true
      customizationGroup.selected = false
    }
  })

  return customizationGroups
}

export const getSelectedCutomisationGroups = customizationGroups => {
  let selectedCustomizationGroups = customizationGroups.filter(cg => cg.selected || cg.indeterminate)

  selectedCustomizationGroups = selectedCustomizationGroups.map(sCG => {
    const newSCG = { ...sCG }
    
    newSCG.customizations = newSCG.customizations.map(customization => {
      return { ...customization }
    })
    newSCG.customizations = newSCG.customizations.filter(c => c.selected)

    return newSCG
  })

  return selectedCustomizationGroups
}

export const getNestedCustomizationGroups: any = customizationGroups => {
  if (!customizationGroups || !customizationGroups.length) {
    return { nestedCustomisationGroups: {}, productVariantIds: [] }
  }
  const productVariantIds = []
  const groupCount = customizationGroups.length
  let nestedCustomizationGroups = {}

  switch (groupCount) {
      case 1: {
        const group = getStructuredGroup(customizationGroups[0])

        nestedCustomizationGroups = { ...group }
        nestedCustomizationGroups['customizations'].forEach(nCG => {
          productVariantIds.push(nCG.productId)
        })
        break
      }
      case 2: {
        const secondGroup = getStructuredGroup(customizationGroups[1])
        const firstGroup = getStructuredGroup(customizationGroups[0])

        firstGroup.customizations = firstGroup.customizations.map(c => {
          const cg = { ...c }

          secondGroup.customizations = secondGroup.customizations.map(sc => {
            const scg = { ...sc }

            scg.productId = [sc.value, cg.value]
            productVariantIds.push(scg.productId)

            return scg
          })
          cg.nestedCustomizations = { ...secondGroup }

          return cg
        })
        nestedCustomizationGroups = { ...firstGroup }
        break
      }
      case 3: {
        const thirdLevelgroup = getStructuredGroup(customizationGroups[2])
        const secondLevelGroup = getStructuredGroup(customizationGroups[1])
        const firstLevelGroup = getStructuredGroup(customizationGroups[0])

        secondLevelGroup.customizations = secondLevelGroup.customizations.map(c => {
          const cg = { ...c }

          cg.nestedCustomizations = { ...thirdLevelgroup }

          return cg
        })
        firstLevelGroup.customizations = firstLevelGroup.customizations.map(c => {
          const cg = { ...c }
          const customisationId = cg.value
          const newSecondLevelGroup = JSON.parse(JSON.stringify(secondLevelGroup))

          newSecondLevelGroup.customizations.forEach(slgc => {
            slgc.nestedCustomizations.customizations = slgc.nestedCustomizations.customizations.map(customization => {
              const productId = [customization.value, slgc.value, customisationId]
              const scg = { ...customization, productId }

              productVariantIds.push(productId)

              return scg
            })
          })
          cg.nestedCustomizations = { ...newSecondLevelGroup }

          return cg
        })
        nestedCustomizationGroups = { ...firstLevelGroup }
        break
      }
      default: {
        nestedCustomizationGroups = {}
      }
  }

  return {
    nestedCustomizationGroups,
    productVariantIds
  }
}

const getStructuredGroup = group => {
  const newGroup = { ...group }
  const { label, value, selected, indeterminate } = newGroup

  newGroup.groupLabel = label
  newGroup.groupValue = value
  newGroup.groupSelected = selected
  newGroup.groupIndeterminate = indeterminate
  delete newGroup.label
  delete newGroup.value
  delete newGroup.selected
  delete newGroup.indeterminate

  return newGroup
}

export const getAddonsGroupFromCategories = categories => {
  let addons = categories.filter(c => {
    return c.productType === PRODUCT_TYPE_ENUM.ADDON || c.productType === PRODUCT_TYPE_ENUM.COMBO
  })

  addons = addons.filter(addon => {
    return addon.products.length > 0
  })
  const addonGroup = []

  addons.forEach(ad => {
    const adg = {}

    adg['label'] = ad.name
    adg['value'] = ad.id
    adg['indeterminate'] = false
    adg['selected'] = false
    adg['customisations'] = []
    adg['saved'] = false
    ad.products.forEach(p => {
      const custom = {}

      custom['label'] = p.name
      custom['value'] = p.id
      custom['selected'] = false
      custom['saved'] = false
      custom['config'] = {
        min: 0,
        max: 0
      }
      adg['customisations'].push(custom)
    })
    addonGroup.push(adg)
  })

  return addonGroup
}

export const getAddonsGroupWithSelection = (categoriesWithProducts, productRelationships) => {
  const addonGroups = getAddonsGroupFromCategories(categoriesWithProducts)

  productRelationships.forEach(pr => {
    addonGroups.forEach(adG => {
      let selectionCount = 0

      adG.customisations.forEach(custom => {
        if (pr.child.id === custom.value) {
          custom.selected = true
          custom.saved = true
          selectionCount += 1
        }
      })
      if (selectionCount > 0) {
        adG.customisations.forEach(customisation => {
          customisation.config = {
            min: pr?.config?.min || 0,
            max: pr?.config?.max || 0
          }
        })
      }
      if (adG.customisations.length && selectionCount === adG.customisations.length) {
        adG.selected = true
        adG.saved = true
        adG.indeterminate = false
      } else if (selectionCount > 0) {
        adG.indeterminate = true
        adG.selected = false
      }
    })
  })

  return addonGroups
}

export const getSelectedAddonsGroups = addonGroups => {
  let selectedAddonsGroups = addonGroups.filter(cg => cg.selected || cg.indeterminate)

  selectedAddonsGroups = selectedAddonsGroups.map(sCG => {
    const newSCG = { ...sCG }

    newSCG.customisations = newSCG.customisations.map(customization => {
      return { ...customization }
    })

    return newSCG
  })

  return selectedAddonsGroups
}

export const updateProductVariantPricesWithId = (productRelationships, productVariantPrices, formatChannel) => {
  const productVariants = productRelationships.filter(pr => pr.childType === PRODUCT_TYPE_ENUM.VARIANT)

  if (!productVariants.length) {
    return []
  }
  const discountType = getStrikeoutDiscountType()

  productVariants.forEach(pr => {
    productVariantPrices.forEach(productVariantPrice => {
      let productFound = false

      if (pr.child.options.length > 1) {
        const productId = []

        pr.child.options.forEach(option => {
          productId.push(option.optionValues[0].id)
        })
        if (compareArrayValues(productId, productVariantPrice.productId)) {
          productVariantPrice.productId = pr.child.id
          productFound = true
        }
      } else if (pr.child.options[0].optionValues[0].id === productVariantPrice.productId) {
        productFound = true
        productVariantPrice.productId = pr.child.id
      }
      if (productFound && pr.child.productPrices && pr.child.productPrices.productPriceValues) {
        const productPrice = pr.child.productPrices.productPriceValues.find(pPrice => pPrice.storeFormat.id === formatChannel.format.id && pPrice.channel.id === formatChannel.channel.id)

        if (productPrice) productVariantPrice.priceValueId = productPrice.id
      }
      if (productFound && pr.child.productPrices && pr.child.productPrices.productChargeValues) {
        const productPrice = pr.child.productPrices.productChargeValues.find(pPrice => pPrice.storeFormat.id === formatChannel.format.id && pPrice.channel.id === formatChannel.channel.id)

        if (productPrice) productVariantPrice.chargeValueId = productPrice.id
      }
      if (productFound && pr.child.productPrices && pr.child.productPrices.productDiscountValues) {
        const productPrice = pr.child.productPrices.productDiscountValues.find(pPrice => pPrice.storeFormat.id === formatChannel.format.id && pPrice.channel.id === formatChannel.channel.id && discountType.id === pPrice.discountType.id)

        if (productPrice) productVariantPrice.discountValueId = productPrice.id
      }
    })
  })

  return productVariantPrices
}

export const updateProductIdWithOptionValuesId = (productVariantPrices, productRelationships) => {
  const productVariants = productRelationships.filter(pr => pr.childType === PRODUCT_TYPE_ENUM.VARIANT)
  const updatedProductVariantPrices = productVariantPrices.map(productVariantPrice => {
    const updatedProductVariantPrice = { ...productVariantPrice }
    const productRelationship = productVariants.find(productRelationship => productRelationship.child.id === updatedProductVariantPrice.productId)

    if (productRelationship.child.options.length > 1) {
      const productId = []

      productRelationship.child.options.forEach(option => {
        productId.push(option.optionValues[0].id)
      })
      updatedProductVariantPrice.productId = productId
    } else {
      updatedProductVariantPrice.productId = productRelationship.child.options[0].optionValues[0].id
    }

    return updatedProductVariantPrice
  })

  return updatedProductVariantPrices
}

export const getOldProductVariantPrices = (productVariantPrices, productVariantIds) => {
  return productVariantPrices.filter(productVariantPrice => {
    let found = productVariantIds.includes(productVariantPrice.productId)

    if (!found) {
      productVariantIds.forEach(productVariantId => {
        if (compareArrayValues(productVariantPrice.productId, productVariantId)) {
          found = true
        }
      })
    }

    return found
  })
}

export const getRemovedProductRelationShipsInputForVariant = (productVariantIds, productRelationShips) => {
  const productVariants = productRelationShips.filter(pr => pr.childType === PRODUCT_TYPE_ENUM.VARIANT)
  const removedRelationships = []

  productVariants.forEach(pr => {
    let found = false

    if (pr.child.options.length > 1) {
      const productId = []

      pr.child.options.forEach(option => {
        productId.push(option.optionValues[0].id)
      })
      productVariantIds.forEach(productVariantId => {
        if (compareArrayValues(productVariantId, productId)) {
          found = true
        }
      })
    } else if (productVariantIds.includes(pr.child.options[0].optionValues[0].id)) {
      found = true
    }
    if (!found) {
      removedRelationships.push({ id: pr.id })
    }
  })

  return removedRelationships
}

export const getExtendFromSelectedTag = selectedTag => {
  return {
    extend_non_veg: Boolean(selectedTag && selectedTag.toLowerCase() === PRODUCT_TAG_ENUM.NON_VEG),
    extend_veg: Boolean(selectedTag && selectedTag.toLowerCase() === PRODUCT_TAG_ENUM.VEG)
  }
}

export const getUpdateCategorySortSeqInput = (categories, orgId) => {
  const categorySeq = categories.map((c, index) => {
    return {
      id: c.id,
      sortSeq: index
    }
  })
  const updateCategorySortSeqInput = {
    organizationId: orgId,
    categorySeq
  }

  return updateCategorySortSeqInput
}

export const getCategoryData = categoryData => {
  const { name, code, id, listable, imageUrl, description } = categoryData

  return {
    id,
    name,
    code,
    listable,
    imageUrl,
    description
  }
}

export const priceFormatter = value => {
  return `₹ ${value}`.replace(PRICE_REGEX, ',')
}

export const setSuccessMessage = (id, intl) => {
  message.success(
    intlAlertMessage({
      id,
      intl,
    })
  )
}
export const setWarningMessage = (id, intl) => {
  message.warn(
    intlAlertMessage({
      id,
      intl,
    })
  )
}
export const setErrorMessage = (id, intl) => {
  message.error(
    intlAlertMessage({
      id,
      intl
    })
  )
}

export const getItemCode = elem => {
  return elem
    .split(' ')
    .join('_')
    .toLowerCase()
}

export const republishCatalog = async (client: any) => {
  try {
    await republishCatalogService(client)
  }
  catch(err) {
    console.log('RepublishCatalog Error', err)
  }
}

export const isMenuTimingNotAvailableForCategory = (categoryForm, intl) => {
  if (categoryForm['isMenuTimingEnable'] && (!categoryForm['menuTiming'] || !Object.keys(categoryForm['menuTiming']).length)) {
    setErrorMessage('category.errMsg.selectTimings', intl)

    return true
  }
}

export const isCategoryNameExist = (categories, categoryForm, categoryDetails, intl) => {
  const newCategoryName = categoryForm['name'].toLowerCase().trim()
  let currentCategories = [...categories]
  
  if (categoryDetails.id) {
    currentCategories = categories.filter(c => c.id !== categoryDetails['id'])
  }
  if (newCategoryName && currentCategories.length && currentCategories.map(category => category.name.toLowerCase().trim()).indexOf(newCategoryName) !== -1) {
    setErrorMessage('category.errMsg.categoryNameAlreadyExists', intl)

    return true
  }
}

export const ValidateMinMaxAddonValue = (selectedAddonGroup) => {
  const selectedAddonsCount = selectedAddonGroup.customisations.filter(
    cs => cs.selected
  ).length
  let minValue = selectedAddonGroup.customisations[0].config.min
  let maxValue = selectedAddonGroup.customisations[0].config.max

  maxValue =
    maxValue > selectedAddonsCount || maxValue === 0
      ? selectedAddonsCount
      : maxValue

  minValue = minValue > maxValue ? maxValue : minValue

  return { minValue, maxValue }
}

export const selectedAddonValidateSelection = addonGroups => {
  let valid = false
  const selectedGroup = addonGroups.filter(
    ag => ag.selected || ag.indeterminate
  ).length

  if (selectedGroup > 0) {
    valid = true
  }

  return valid
}

export const selectedCustomizationValidateSelection = (customisationGroups) => {
  let valid = false
  const selectedGroup = customisationGroups.filter(
    cg => cg.selected || cg.indeterminate
  )

  if (selectedGroup.length < 4) {
    valid = true
  }

  return valid
}

export const getUpdatedPriceData = (productId, productVariantPrices, newProductVariantPrices) => {
  let variantType = PRODUCT_VARIANT_TYPE_ENUM.OLD
  let productPriceData = getPriceDataWithProductId(
    [...productVariantPrices],
    productId
  )

  if (!productPriceData) {
    variantType = PRODUCT_VARIANT_TYPE_ENUM.NEW
    productPriceData = getPriceDataWithProductId(
      [...newProductVariantPrices],
      productId
    )
  }

  return { variantType, productPriceData }
}