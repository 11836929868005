import { HttpLink, split } from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { SubscriptionClient } from 'subscriptions-transport-ws'

import { getAuthToken } from './localStorageHandlers/getter'


const authT = `Bearer ${  getAuthToken()}`

const orderXhttpLink = new HttpLink({
  uri: process.env.REACT_APP_WCORE_URL
})


const splitLinkHandler = (jwt) => split(
  ({ query }) => {
    const definition = getMainDefinition(query)

    return (
      definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
    )
  },
  new WebSocketLink(
    new SubscriptionClient(process.env.REACT_APP_SUBSCRIPTION_URL, {
      connectionParams: {
        authorization: `Bearer ${jwt}`
      }
    })
  ),
  orderXhttpLink,
)

export default splitLinkHandler